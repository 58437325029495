
import { KEYFEATURES, ORGANIZATIONSLOGO, BACKEDBY, OVERVIEW, MARKETPLACE } from "./action";

const initialState = {
    keyFeaturesData: [],
    organizationsLogoData: [],
    backedByData: [],
    overviewData: [],
    marketplaceData: []
  };

const reducer = (state = initialState, action) => {
    switch (action.type) {
      
      case KEYFEATURES: {
        return {
          ...state,
          keyFeaturesData: action.payload,
        };
      }
      case ORGANIZATIONSLOGO: {
        return {
          ...state,
          organizationsLogoData: action.payload,
        };
      }
      case BACKEDBY: {
        return {
          ...state,
          backedByData: action.payload,
        };
      }
      case OVERVIEW: {
        return {
          ...state,
          overviewData: action.payload,
        };
      }
      case MARKETPLACE: {
        return {
          ...state,
          marketplaceData: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default reducer;