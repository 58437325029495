import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {createKeyFeatures,getKeyFeaturesDetail,updateKeyFeatures} from "../action";
import {uploadImage} from "../../../action";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../utilities/constants";
import Sidebar from "../../../Components/Navigation/Sidebar";
import Topbar from "../../../Components/Navigation/Topbar";
import PageHeading from "../../../Components/PageHeading";
import { toast } from "react-toastify";

const label = { inputProps: { 'aria-label': 'controlled' } };

const UpdateKeyFeature = (props) => {
  const fileInputRef = useRef();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [formData, setFormData] = useState({ sensitiveContent: false });
  const { state } = useLocation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image_id, setImageId] = useState('');

  useEffect(() => {
    if (state?.id) {
      props.getKeyFeaturesDetail(state.id);
      console.log('getKeyFeaturesDetail',state?.id)
    }
  }, [state?.id]);

  useEffect(() => {
    if(props.getFeatureDetails){
      setTitle(props.getFeatureDetails.title);
      setDescription(props.getFeatureDetails.description);
      setImageId(props.getFeatureDetails.image.id);
      setPreview(props.getFeatureDetails.image.media);
    }
  }, [props.getFeatureDetails]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    } 
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };


  const handleReset = () => {
    formRef.current.reset();
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formDataItem = {
  //     title,
  //     description,
  //     image_id
  //   };
  //   if (!title || !description) {
  //     toast.error('All fields are required.');
  //     return;
  //   }
  //   if(state?.id){
  //     await props.updateKeyFeatures(formDataItem,state?.id).then(res => {
  //       toast.success('Key Features updated Successfully');
  //       navigate(`/admin/content/keyFeatures`);
  //     }).catch(error => {
  //       toast.error(error || "Error in added Key Features");
  //     });
  //   }else{
  //     // onAdd(newItem);
  //     if (image_id) {
  //       await props.createKeyFeatures(formDataItem).then(res => {
  //         toast.success('Key Features added successfully');
  //         navigate(`/admin/content/keyFeatures`);
  //       }).catch(error => {
  //         toast.error(error || "Error in added Key Features");
  //       });
  //     } else {
  //       toast.error("Image is required");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataItem = {
      title,
      description,
    };
    if (!title || !description || (!state?.id && !selectedFile )) {
      toast.error('All fields are required.');
      return;
    }
    if (state?.id) {
      let uploadedImage;
      if(selectedFile){
        let formData = new FormData();
        formData.append('media', selectedFile);
        uploadedImage = await props.uploadImage(formData)
      }
      
      props.updateKeyFeatures(formDataItem, state?.id, selectedFile ? uploadedImage.data.id : image_id)
          .then(res => {
            toast.success('Key Features updated Successfully');
            navigate(`/admin/content/keyFeatures`);
          }).catch(error => {
            toast.error(error || "Error in add keyFeatures");
          });

    } else {
      // onAdd(newItem);
        let formData = new FormData();
        formData.append('media', selectedFile);
        props.uploadImage(formData)
          .then((res) => {
            const imageId = res.data.id;
            props.createKeyFeatures(formDataItem, imageId)
              .then(res => {
                toast.success('Key Features added successfully');
                navigate(`/admin/content/keyFeatures`);
              }).catch(error => {
                toast.error(error || "Error in add keyFeatures");
              });
          }).catch((error) => {
            console.log("Image Required");
          });

      } 
  };

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Add New"
                buttonName=""
                buttonLink=""
                buttonIcon=""
              />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form ref={formRef} method="post" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Title
                          </Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="Title"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Description
                          </Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="Description"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Add Image
                          </Form.Label>
                        </Form.Group>
                        {selectedFile ? (
                          <img src={preview} className="imgthumb" style={{ width: 150, height: 150, objectFit: 'contain' }} />
                        ) : (
                          props.getFeatureDetails && (
                            <img
                              src={`${props.getFeatureDetails.image.media}`}
                              className="imgthumb"
                              style={{ width: 150, height: 150, objectFit: 'contain' }}
                            />
                          )
                        )}
                      </div>
                      <div className="">
                        <Form.Group className="mb-3">
                          <a
                            className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <i
                              className={`fas fa-plus fa-sm text-white-50 p-1`}
                            ></i>
                          </a>
                          <Form.Control
                            type="file"
                            className="form-control"
                            accept='image/*'
                            placeholder=""
                            onChange={onSelectFile}
                            ref={fileInputRef}
                            hidden
                          />
                        </Form.Group>
                      </div>
                      <div className=" mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ admingetFeatureReducer }) => {
  const { getFeatureDetails } = admingetFeatureReducer;
  return {
    getFeatureDetails,
  };
};

export default connect(mapStateToProps, {
  uploadImage,
  createKeyFeatures,
  getKeyFeaturesDetail,
  updateKeyFeatures
})(UpdateKeyFeature);

