import { FAILUR, PROCCESSING, SUCCESS, STOPPROCESSING } from "../../utilities/constants";
import { CURRICULUM_DETAILS, CURRICULUM_TOPIC_LIST,SUCCESS_UPDATED_COURSE, COURSE_DELETED_SUCCESS,THINGS_TO_WATCH_SUCCESS, THINGS_TO_READ_SUCCESS,GET_SUGGESTION_LIST_SUCCESS, SEARCH_CURRICULAM_SUCCESS, GET_RECOMMENDED_LIST_SUCCESS, RECOMMENDED_COURSE_DELETED_SUCCESS,SUCCESS_UPDATED_RECOMMENDED, GENERATE_MCQS_ERROR, GENERATE_MCQS_LOADING, GENERATE_MCQS_SUCCESS, GENERATE_CURRICULAM_SUCCESS, GENERATE_TOPICS_SUCCESS, GENERATE_TOPICS_ERROR,GENERATE_SUBTOPIC_SUCCESS, GENERATE_SUBSUBTOPIC_SUCCESS, CURRICULAM_BY_USER_SUCCESS, CREATE_CURRICULUM_SUCCESS, CREATE_CURRICULUM_ERROR, CREATE_CURRICULUM_LOADING, CHECK_USER_MCQ_SUCCESS, CHECK_USER_MCQ_ERROR, CHECK_USER_MCQ_LOADING, SEE_RESULT_LOADING, SEE_RESULT_ERROR, SEE_RESULT_SUCCESS, VIEW_DETAIL_ERROR, VIEW_DETAIL_LOADING, VIEW_DETAIL_SUCCESS, SUBMIT_MCQ_ERROR, SUBMIT_MCQ_LOADING, SUBMIT_MCQ_SUCCESS, GENERATE_CURRICULUM_LOADING, ACTIVITY_MATRIX, GENERATE_CURRICULUM_TOC_SUCCESS, GENERATE_CURRICULUM_TOC_LOADING, CREATE_COURSE_ERROR, CREATE_COURSE_LOADING, CREATE_COURSE_SUCCESS } from "./action";


const initialState = {
    loading: false,
    error: null,
    curriculumDetails: [],
    topicListData: null,
    totalCurriculumCount : 0,
    curriculumDeleted:null,
    curriculumUpdated:null,
    thingsToReadList:null,
    suggestionList:[],
    recommendedList:[],
    searchList:null,
    generateMcqsLoading : false,
    generateMcqsSuccess : null,
    generateMcqsError : null,
    newGenTopicData : null,
    subTopicData : null,
    subTopicIndices : null,
    subSubTopicData : null,
    subSubTopicIndices : null,
    listByUser:[],
    createCurriculumLoading : false,
    createCurriculumSuccess : null,
    createCurriculumError : null,
    checkMcqUserSubmissionLoading : false,
    checkMcqUserSubmissionSuccess : null,
    checkMcqUserSubmissionError : null,
    seeResultLoading : false,
    seeResultSuccess : null,
    seeResultError : null,
    viewDetailLoading : false,
    viewDetailSuccess : null,
    viewDetailError : null,
    submitMcqLoading : false,
    submitMcqSuccess : null,
    submitMcqError : null,
    generateCurriculumLoading : false,
    generateCurriculumtocLoading : false,
    activitymatrix : null,
    createCourseLoading : false,
    createCourseSuccess : null,
    createCourseError : null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CURRICULUM_DETAILS : {
            return {
                ...state,
                loading: false,
                curriculumDetails:action.payload.results,
                totalCurriculumCount: action.payload.count,
                searchList:null
            };
        }
        case CURRICULUM_TOPIC_LIST : {
            return {
                ...state,
                loading: false,
                topicListData: action.payload,
                searchList:null
            };
        }
        case PROCCESSING : {
            return {
                ...state,
                loading: true,
            };
        }
        case GENERATE_CURRICULUM_LOADING: {
            return {
                ...state,
                generateCurriculumLoading: true,
            };
        }
        case GENERATE_CURRICULUM_TOC_LOADING: {
            return {
                ...state,
                generateCurriculumtocLoading: true,
            };
        }
        case STOPPROCESSING : {
            return {
                ...state,
                loading: false,
            };
        }
        case SUCCESS_UPDATED_COURSE : {
            return {
                ...state,
                loading: false,
                curriculumUpdated: action.payload
            };
        }
        case COURSE_DELETED_SUCCESS : {
            return {
                ...state,
                loading: false,
                curriculumDeleted: action.payload
            };
        }
        case THINGS_TO_WATCH_SUCCESS : {
            return {
                ...state,
                loading: false,
                thingsToWatchList: action.payload
            };
        }
        case THINGS_TO_READ_SUCCESS : {
            return {
                ...state,
                loading: false,
                thingsToReadList: action.payload
            };
        }
        case GET_SUGGESTION_LIST_SUCCESS : {
            return {
                ...state,
                loading: false,
                suggestionList: action.payload
            };
        }
        case SEARCH_CURRICULAM_SUCCESS : {
            return {
                ...state,
                loading: false,
                searchList: action.payload.data
            };
        }
        case GET_RECOMMENDED_LIST_SUCCESS : {
            return {
                ...state,
                loading: false,
                recommendedList:action.payload.data
            };
        }
        case GENERATE_CURRICULAM_SUCCESS : {
            return {
                ...state,
                generateCurriculumLoading: false,
                searchList:[action.payload.data]
            };
        }
        case GENERATE_CURRICULUM_TOC_SUCCESS : {
            return {
                ...state,
                generateCurriculumtocLoading: false,
                searchList:[action.payload.data]
            };
        }
        case RECOMMENDED_COURSE_DELETED_SUCCESS : {
            return {
                ...state,
                loading: false,
                recommendedList: action.payload.data
            };
        }
        case SUCCESS_UPDATED_RECOMMENDED : {
            return {
                ...state,
                loading: false,
            };
        }
        case FAILUR : {
            return {
                ...state,
                loading: false,
                generateCurriculumLoading: false,
                generateCurriculumtocLoading: false,
                error: action.payload
            };
        }
        case GENERATE_MCQS_SUCCESS : {
            return {
                ...state,
                generateMcqsLoading : false,
                generateMcqsSuccess : action.payload,
                generateMcqsError : null
            }
        }
        case GENERATE_MCQS_LOADING : {
            return {
                ...state,
                generateMcqsLoading : true,
                generateMcqsError : null,
                generateMcqsSuccess : null
            }
        }
        case GENERATE_MCQS_ERROR : {
            return {
                ...state,
                generateMcqsLoading : false,
                generateMcqsError : action.payload,
                generateMcqsSuccess : null
            }
        }
        case GENERATE_TOPICS_SUCCESS : {
            return {
                ...state,
                loading : false,
                newGenTopicData : action.payload,
            }
        }
        case GENERATE_SUBTOPIC_SUCCESS : {
            return {
                ...state,
                loading : false,
                subTopicData : action.payload,
                subTopicIndices:action.indices
            }
        }
        case GENERATE_SUBSUBTOPIC_SUCCESS : {
            return {
                ...state,
                loading : false,
                subSubTopicData : action.payload,
                subSubTopicIndices:action.indices
            }
        }
        case CURRICULAM_BY_USER_SUCCESS : {
            return {
                ...state,
                loading : false,
                listByUser : action.payload.data,
            }
        }
        case GENERATE_TOPICS_ERROR : {
            return {
                ...state,
                loading: false,
                newGenTopicData : null,
                error: action.payload
            }
        }
        case CREATE_CURRICULUM_SUCCESS : {
            return {
                ...state,
                createCurriculumLoading : false,
                createCurriculumSuccess : action.payload,
                createCurriculumError : null
            }
        }
        case CREATE_CURRICULUM_LOADING : {
            return {
                ...state,
                createCurriculumLoading : true,
                createCurriculumError : null,
                createCurriculumSuccess : null
            }
        }
        case CREATE_CURRICULUM_ERROR : {
            return {
                ...state,
                createCurriculumLoading : false,
                createCurriculumError : action.payload,
                createCurriculumSuccess : null
            }
        }
        case CHECK_USER_MCQ_SUCCESS : {
            return {
                ...state,
                checkMcqUserSubmissionLoading : false,
                checkMcqUserSubmissionSuccess : action.payload,
                checkMcqUserSubmissionError : null
            }
        }
        case CHECK_USER_MCQ_LOADING : {
            return {
                ...state,
                checkMcqUserSubmissionLoading : true,
                checkMcqUserSubmissionError : null,
                checkMcqUserSubmissionSuccess : null
            }
        }
        case CHECK_USER_MCQ_ERROR : {
            return {
                ...state,
                checkMcqUserSubmissionLoading : false,
                checkMcqUserSubmissionError : action.payload,
                checkMcqUserSubmissionSuccess : null
            }
        }
        case SEE_RESULT_SUCCESS : {
            return {
                ...state,
                seeResultLoading : false,
                seeResultSuccess : action.payload,
                seeResultError : null
            }
        }
        case SEE_RESULT_LOADING : {
            return {
                ...state,
                seeResultLoading : true,
                seeResultError : null,
                seeResultSuccess : null
            }
        }
        case SEE_RESULT_ERROR : {
            return {
                ...state,
                seeResultLoading : false,
                seeResultError : action.payload,
                seeResultSuccess : null
            }
        }
        case VIEW_DETAIL_SUCCESS : {
            return {
                ...state,
                viewDetailLoading : false,
                viewDetailSuccess : action.payload,
                viewDetailError : null
            }
        }
        case VIEW_DETAIL_LOADING : {
            return {
                ...state,
                viewDetailLoading : true,
                viewDetailError : null,
                viewDetailSuccess : null
            }
        }
        case VIEW_DETAIL_ERROR : {
            return {
                ...state,
                viewDetailLoading : false,
                viewDetailError : action.payload,
                viewDetailSuccess : null
            }
        }
        case SUBMIT_MCQ_SUCCESS : {
            return {
                ...state,
                submitMcqLoading : false,
                submitMcqSuccess : action.payload,
                submitMcqError : null
            }
        }
        case SUBMIT_MCQ_LOADING : {
            return {
                ...state,
                submitMcqLoading : true,
                submitMcqError : null,
                submitMcqSuccess : null
            }
        }
        case SUBMIT_MCQ_ERROR : {
            return {
                ...state,
                submitMcqLoading : false,
                submitMcqError : action.payload,
                submitMcqSuccess : null
            }
        }
        case ACTIVITY_MATRIX: {
            return {
                ...state,
                loading: false,
                activitymatrix: action.payload,
            }
        }
        case CREATE_COURSE_SUCCESS: {
            return {
                ...state,
                createCourseLoading: false,
                createCourseSuccess: action.payload,
                createCourseError: null
            }
        }
        case CREATE_COURSE_LOADING: {
            return {
                ...state,
                createCourseLoading: true,
                createCourseError: null
            }
        }
        case CREATE_COURSE_ERROR: {
            return {
                ...state,
                createCourseLoading: false,
                createCourseError: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default reducer;