import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
import Images from "../../constants/Images";

export const KEYFEATURES = "KEYFEATURES";
export const ORGANIZATIONSLOGO = "ORGANIZATIONSLOGO";
export const BACKEDBY = "BACKEDBY";
export const OVERVIEW = "OVERVIEW";
export const MARKETPLACE = "MARKETPLACE";

export const getKeyFeatures = () => async (dispatch) => {
    // dispatch({
    //   type: KEYFEATURES,
    // payload: [
    //     {
    //         "title": "Curriculum Builder",
    //         "subTitle": "Instant pre-learning material for your curriculum of degree programs and job topics of all levels in every field.",
    //         "imageUrl": Images.CurriculumBuilder,
    //     },
    //     {
    //         "title": "Upskillr Assitant",
    //         "subTitle": "Provide quick, smart, and well-organized responses to avoid repetitive inquiries and overwhelm users with excessively complex documents, URLs, and videos.",
    //         "imageUrl": Images.UpskillrAssitant,
    //     },
    //     {
    //         "title": "Multi-search Writing",
    //         "subTitle": "Compose educational or professional articles that can determine, based on the user's inquiry, which data source should be employed to provide a response.",
    //         "imageUrl": Images.MultiSearchWriting,
    //     },
    //     {
    //         "title": "Learn, discover,curate ",
    //         "subTitle": "Daily collections of the most popular blog posts, videos, lectures, and articles from all the major content platforms.",
    //         "imageUrl": Images.LearnDiscoverCurate,
    //     },
    //     {
    //         "title": "CareerPal",
    //         "subTitle": "Unlock your potential with an all-in-one AI-powered companion that provides personalized support for tutoring, skill building, interview prep, and beyond.",
    //         "imageUrl": Images.CareerPal,
    //     },
    //     {
    //         "title": "Tools Advisory",
    //         "subTitle": "Must-known and industry-level used software tools for your domain",
    //         "imageUrl": Images.ToolsAdvisory,
    //     }
    // ]
    // });
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.keyfeatures,
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {
                 dispatch({
                    type: KEYFEATURES,
                    payload: response,
                });

            }
        })
        .catch((error) => {
            throw error?.response?.message;
        });
  };

  export const getOrganizationsLogo = () => async (dispatch) => {
    // dispatch({
    //   type: ORGANIZATIONSLOGO,
    // payload: [
    //     {
    //         data:[ Images.GoolgeLogoImage, Images.YoutubeLogoImage, Images.MediumLogoImage, Images.CourseraLogoImage]
    //     },
    //     {
    //         data:[ Images.SkillShareLogoImage, Images.RelevelLogoImage, Images.UdemyLogoImage, Images.EDxLogoImage, Images.IimJobsLogoImage]
    //     },
    //     {
    //         data:[ Images.WizeLogoImage, Images.SimplyHiredLogoImage, Images.NaukriLogoImage, Images.ExpertronLogoImage]
    //     }
    // ]
    // });
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.organizations,
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {
                 dispatch({
                    type: ORGANIZATIONSLOGO,
                    payload: response,
                });

            }
        })
        .catch((error) => {
            throw error?.response?.message;
        });
  };

  export const getBackedBy = () => async (dispatch) => {
    // dispatch({
    //   type: BACKEDBY,
    // payload: [
    //     {imageUrl: Images.PIEDS},
    //     {imageUrl: Images.MapMyIndia},
    //     {imageUrl: Images.BIRAC_1_Transformed},
    // ]
    // });
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.backedby,
    };
    return api(data)
        .then(async (response) => {
            // console.log("response",response.data);
            return await response.data
        }).then(async (response) => {
            if (response) {
                 dispatch({
                    type: BACKEDBY,
                    payload: response,
                });

            }
        })
        .catch((error) => {
            throw error?.response?.message;
        });
  };

  export const getOverview = () => async (dispatch) => {
    // dispatch({
    //   type: OVERVIEW,
    // payload: [
    //     {
    //         title: 'Overview of our Curriculum Builder',
    //         subTitle: 'Multi Search Writer',
    //         description: 'Compose educational or professional articles that can determine, based on a users inquiry, which data source to utilize in order to provide a response',
    //         videoUrl: '',
    //         videoTagline: 'A 1 minute video clip that summarizes all the key features',
    //         backgroundType: "yellowCardStyle"
    //     },
    //     {
    //         title: 'Overview of our Upskillr Assistant',
    //         subTitle: 'Upskillr Assistantr',
    //         description: 'Obtain immediate responses and suggestions from any document, webpage, or video, and avoid spending numerous hours reading, scrolling, searching, and waiting..',
    //         videoUrl: '',
    //         videoTagline: 'A 1 minute video clip that summarizes all the key features',
    //         backgroundType: "yellowCardStyle"
    //     },
    //     {
    //         title: 'Overview of our Mid Search Writer',
    //         subTitle: 'Multi Search Writer',
    //         description: 'Compose educational or professional articles that can determine, based on a users inquiry, which data source to utilize in order to provide a response',
    //         videoUrl: '',
    //         videoTagline: 'A 1 minute video clip that summarizes all the key features',
    //         backgroundType: "yellowCardStyle"
    //     },
    //     {
    //         title: 'Overview of CareerPal',
    //         subTitle: 'CareerPal',
    //         description: 'Unlock your potential with an all-in-one AI-powered companion that provides personalized support for tutoring, skill building, case studies, interview prep, and beyond.',
    //         videoUrl: '',
    //         videoTagline: 'A 1 minute video clip that summarizes all the key features',
    //         backgroundType: "yellowCardStyle"
    //     },
    // ]
    // });
    
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.overview,
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {
                 dispatch({
                    type: OVERVIEW,
                    payload: response,
                });

            }
        })
        .catch((error) => {
            throw error?.response?.message;
        });
  };

  export const getMarket = () => async (dispatch) => {
    // dispatch({
    //   type: MARKETPLACE,
    // payload: [
    //     {
    //         image: 'image-1',
    //         name: 'image-1'
    //     },
    //     {
    //         image: 'image-2',
    //         name: 'image-2'
    //     },
    //     {
    //         image: 'image-3',
    //         name: 'image-3'
    //     },
    //     {
    //         image: 'image-4',
    //         name: 'image-4'
    //     },
    // ]
    // });
    
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.marketplace,
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {
                 dispatch({
                    type: MARKETPLACE,
                    payload: response,
                });

            }
        })
        .catch((error) => {
            throw error?.response?.message;
        });
  };