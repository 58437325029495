import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { PROCCESSING, STOPPROCESSING } from "../../utilities/constants";
import { api } from "../../utilities/api";
import { toast } from 'react-toastify';
export const PRICING_DETAILS = 'PRICING_DETAILS';
export const INITIATE_PAYMENT_SUCCESS = 'INITIATE_PAYMENT_SUCCESS';
export const INITIATE_PAYMENT_ERROR = 'INITIATE_PAYMENT_ERROR';
export const INITIATE_PAYMENT_LOADING = 'INITIATE_PAYMENT_LOADING';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_ERROR = 'UPDATE_PAYMENT_ERROR';
export const UPDATE_PAYMENT_LOADING = 'UPDATE_PAYMENT_LOADING';
export const CONTACT_SALES_SUCCESS = 'CONTACT_SALES_SUCCESS';
export const CONTACT_SALES_ERROR = 'CONTACT_SALES_ERROR';
export const CONTACT_SALES_LOADING = 'CONTACT_SALES_LOADING';

export const getPricingDetails = () => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.subscription,
    };
  dispatch({ type: PROCCESSING });
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {
                
                 dispatch({
                    type: PRICING_DETAILS,
                    payload: response,
                });
                dispatch({ type: STOPPROCESSING });
            }
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error?.response?.message;
        });
};

export const initiatePayment = (payload) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.initiate_payment,
        data : payload
    };
    dispatch({
        type: INITIATE_PAYMENT_LOADING,
        payload: {initialPaymentLoading : true},
    });
    return api(data)
        .then(async (response) => {
            return response.data
        }).then(async (response) => {
            if (response) {

                if(response.data.status === 0){
                    dispatch({
                        type: INITIATE_PAYMENT_ERROR,
                        payload: response.data.error_desc,
                    });
                    throw response.data.error_desc;
                }

                dispatch({
                    type: INITIATE_PAYMENT_SUCCESS,
                    payload: response,
                });
                return response;
            }
        }).catch((error) => {
            dispatch({
                type: INITIATE_PAYMENT_ERROR,
                payload: { initialPaymentError : error?.response?.data?.message },
            });
            throw error?.response?.data?.message;
        });
};

export const updateTransaction = (payload) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.update_transaction,
        data : payload
    };
    dispatch({
        type: UPDATE_PAYMENT_LOADING,
        payload: {updatePaymentLoading : true},
    });
    return api(data)
        .then(async (response) => {
            return await response.data;
        }).then(async (response) => {
            if (response) {
                if(response.data.status === false){
                    dispatch({
                        type: UPDATE_PAYMENT_ERROR,
                        payload: response.data.error_desc,
                    });
                    return;
                }
                dispatch({
                    type: UPDATE_PAYMENT_SUCCESS,
                    payload: response.data,
                });
            }
        }).catch((error) => {
            dispatch({
                type: UPDATE_PAYMENT_ERROR,
                payload: { updatePaymentError : error?.response?.statusText },
            });
            throw error?.response?.statusText;
        });
};

export const contactSales = (payload) => async (dispatch) => {
    try{
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.contact_sales,
            data: payload
        };
        dispatch({
            type: CONTACT_SALES_LOADING,
            payload: { contactSalesLoading: true },
        });
        return api(data)
            .then(async (response) => {
                if (response) {
                    if (response.data.status === false) {
                        dispatch({
                            type: CONTACT_SALES_ERROR,
                            payload: response.data.error,
                        });
                        return;
                    }
                    dispatch({
                        type: CONTACT_SALES_SUCCESS,
                        payload: response.data,
                    });
                }
            }).catch((error) => {
                dispatch({
                    type: CONTACT_SALES_ERROR,
                    payload: error?.response?.error || 'Internal Server Error'
                });
            });
    }catch(err){
        console.log("Error", err);
        dispatch({
            type: CONTACT_SALES_ERROR,
            payload: err?.response?.error || 'Internal Server Error'
        });
    }
};