import React, { useState } from "react";
import { Box, MenuItem, Select, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import MockData from '../../pages/homePage/data'
import ViewHeading from "../ViewHeading";
import MachineLearningCard from "./MachineLearningCard";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";

const ThingsToWatch = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const thingsToWatch = useSelector((state)=> state.skillMapReducer.thingsToWatch);

    return (
        <div className='containers pt-0 thingsToWatchContainer'>

            <div style={{ display: 'flex', alignItems: 'center',marginBottom: 30 }}>
                <div style={{width:150,marginRight:40, display: "flex", flexDirection: 'column'}}>
                    <label className='inputLabel'>{MockData.VideoLevel.label}</label>
                    <Select
                        // options={MockData.Industry.options}
                        value={props?.videoLevel}
                        // hasSelectAll={false}
                        onChange={(event)=>props?.onChange(event, "level")}
                        style={{border:props?.provider?.length > 0 ? '2px solid #004aad' : '2px solid #A0abbb'}}
                        // overrideStrings={{selectSomeItems:MockData.videoLevel.label}}
                        // labelledBy={MockData.Industry.label}
                    >
                        {
                            MockData.VideoLevel.options?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                        }
                    </Select>
                </div>
                <div style={{width:150, display: "flex", flexDirection: 'column'}}>
                    <label className='inputLabel'>{MockData.VideoDuration.label}</label>
                    <Select
                        // options={MockData.Industry.options}
                        value={props?.videoDuration}
                        style={{border:props?.provider?.length > 0 ? '2px solid #004aad' : '2px solid #A0abbb'}}
                        // hasSelectAll={false}
                        onChange={(event)=> props?.onChange(event, "duration")}
                        // overrideStrings={{selectSomeItems:MockData.videoLevel.label}}
                        // labelledBy={MockData.Industry.label}
                    >
                        {
                            MockData.VideoDuration.options?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                        }
                    </Select>
                </div>
            </div>
            
            <ViewHeading title={props?.title} containerStyles={{ marginBottom: 10, textTransform: 'capitalize' }} />
            <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="thingsToWatchTabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor=""
                >
                    <Tab label="Videos" className="tabBtn" />
                    <Tab label="Channel" className="tabBtn" />
                    <Tab label="Playlist" className="tabBtn" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{ marginTop: 12 }}>
                <div className='row'>
                {!thingsToWatch?.youtubeVideos?.length > 0  && <ViewHeading title={" No results found"} containerStyles={{ textTransform: 'capitalize' }} />}

                    {
                        thingsToWatch?.youtubeVideos?.map(video =>
                            <div className='col-md-4'>
                                <MachineLearningCard 
                                    // type={'Simplilearn'}
                                    title={video?.title || ""}
                                    // courseTime={'27:01'}
                                    showButtons={false}
                                    // timeFormat={'Minutes'}
                                    // date={'March 31'}
                                    imageUrl={video?.thumbnails?.high?.url}
                                    onCardClick={()=> window.open(video?.video_url, "_blank")}
                                    payloadAction={{
                                        "id": video?.videoId,
                                        "title": video?.title,
                                        "type": "thing_to_watch",
                                        "image": video?.thumbnails.medium.url,
                                        "url" : video?.video_url
                                    }}
                                    section="youtubeVideos"
                                    clickAction={props.clickAction}
                                    URL={video?.video_url}
                                />
                            </div>
                        )
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} style={{ marginTop: 12 }}>
                <div className='row'>
                {!thingsToWatch?.youtubeChannels?.length > 0  && <ViewHeading title={" No results found"} containerStyles={{ textTransform: 'capitalize' }} />}

                    {
                        thingsToWatch?.youtubeChannels?.map(channel =>
                            <div className='col-md-4'>
                                <MachineLearningCard 
                                    // type={'Simplilearn'}
                                    title={channel?.channelTitle || ""}
                                    // courseTime={'27:01'}
                                    showButtons={false}
                                    // timeFormat={'Minutes'}
                                    // date={'March 31'}
                                    imageUrl={channel?.thumbnails?.high?.url}
                                    onCardClick={()=> window.open(channel?.channel_url, "_blank")}
                                    payloadAction={{
                                        "id": channel?.channel_id,
                                        "title": channel?.title,
                                        "type": "thing_to_watch",
                                        "image": channel?.thumbnails.medium.url,
                                        "url" : channel?.channel_url
                                    }}
                                    section="youtubeChannels"
                                    clickAction={props.clickAction}
                                    URL={channel?.channel_url}
                                />
                            </div>
                        )
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={2} style={{ marginTop: 12 }}>
                <div className='row'>
                {!thingsToWatch?.youtubePlaylists?.length > 0  && <ViewHeading title={" No results found"} containerStyles={{ textTransform: 'capitalize' }} />}

                    {
                        thingsToWatch?.youtubePlaylists?.map(playlist =>
                            <div className='col-md-4'>
                                <MachineLearningCard 
                                    // type={'Simplilearn'}
                                    title={playlist?.title || ""}
                                    // courseTime={'27:01'}
                                    showButtons={false}
                                    // timeFormat={'Minutes'}
                                    // date={'March 31'}
                                    imageUrl={playlist?.thumbnails?.high?.url}
                                    onCardClick={()=> window.open(playlist?.playlist_url, "_blank")}
                                    payloadAction={{
                                        "id": playlist?.playlist_id,
                                        "title": playlist?.title,
                                        "type": "thing_to_watch",
                                        "image": playlist?.thumbnails.medium.url,
                                        "url" : playlist?.playlist_url
                                    }}
                                    section="youtubePlaylists"
                                    clickAction={props.clickAction}
                                    URL={playlist?.playlist_url}
                                />
                            </div>
                        )
                    }
                </div>
            </TabPanel>
        </div>
    )
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    style: PropTypes.any,
};
export default ThingsToWatch;