import React,{ useState } from "react";
import { Box, MenuItem, Select, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import MockData from '../../pages/homePage/data'
import ViewHeading from "../ViewHeading";
import MachineLearningCard from "./MachineLearningCard";
import { MultiSelect } from "react-multi-select-component";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./index.scss";
import { useSelector } from "react-redux";

const ThingsToWRead = (props) => {
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // const [industry, setIndustry] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const thingsToRead = useSelector((state)=> state.skillMapReducer.thingsToRead);

    return (
        <div className='containers pt-0 thingsToWatchContainer'>
            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}
            <Modal show={show} onHide={handleClose} className="modal-right">
                <Modal.Body>Please select your preferred Industry.</Modal.Body>
                <Modal.Footer>
                    <div className="button" onClick={handleClose} style={{width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 9, marginTop: 5 }}>
                        <Button style={{ backgroundColor: '#E6EDF7', color: '#004AAD', textTransform: 'capitalize',width:'100%' }} className='white' >{`Got it !`}</Button>
                    </div>
                </Modal.Footer>
            </Modal> 

                {/* <div  style={{width:260,marginRight:40,marginBottom:30, display: "flex", flexDirection: 'column'}}>
                    <label className='inputLabel'>{MockData.Industry.label}</label>
                    <Select
                        // options={MockData.Industry.options}
                        value={props?.industry}
                        // hasSelectAll={false}
                        onChange={props?.changeIndustry}
                        // overrideStrings={{selectSomeItems:MockData.videoLevel.label}}
                        // labelledBy={MockData.Industry.label}
                    >
                        {
                            MockData.Industry.options?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                        }
                         </Select>
                </div> */}
            <ViewHeading title={props?.title} containerStyles={{ marginBottom: 10, textTransform: 'capitalize' }}  />
            <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="thingsToWatchTabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor=""
                >
                    <Tab label="Medium" className="tabBtn" />
                    <Tab label="Google Books" className="tabBtn" />
                    <Tab label="Good reads" className="tabBtn" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{ marginTop: 12 }}>
                <div className='row'>
            {!thingsToRead?.medium?.length > 0  && <ViewHeading title={" No results found"} containerStyles={{ textTransform: 'capitalize' }} />}
                    {
                        thingsToRead?.medium?.map((book, index) =>
                            <div className='col-md-4'>
                                <MachineLearningCard 
                                    // type={'Simplilearn'}
                                    title={book?.Title || ''}
                                    // courseTime={'4'}
                                    showButtons={false}
                                    // timeFormat={'Min read'}
                                    // date={'March 31'}
                                    onCardClick={()=> window.open(book?.Link, "_blank")}
                                    imageUrl={book?.Picture}
                                    payloadAction={{
                                        "id": Math.round(1 + Math.random() * (100 - 1)),
                                        "title": book?.Title,
                                        "author": (typeof book.Author != 'undefined')?book.Author[0]:null,
                                        "type": "thing_to_read",
                                        "image": book?.Picture,
                                        "url" : book?.Link
                                    }}
                                    section="medium"
                                    clickAction={props.clickAction}
                                    // URL={channel?.channel_url}
                                />
                            </div>
                        )
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} style={{ marginTop: 12 }}>
                <div className='row'>
            {!thingsToRead?.googleBooks?.length > 0  && <ViewHeading title={" No results found"} containerStyles={{ textTransform: 'capitalize' }} />}
                    {
                        thingsToRead?.googleBooks?.map((book, index) =>
                            <div className='col-md-4'>
                                <MachineLearningCard 
                                    // type={'Simplilearn'}
                                    title={book?.Title || ''}
                                    // courseTime={'4'}
                                    showButtons={false}
                                    // timeFormat={'Min read'}
                                    // date={'March 31'}
                                    imageUrl={book?.Picture}
                                    onCardClick={()=> window.open(book?.Link, "_blank")}
                                    payloadAction={{
                                        "id": Math.round(1 + Math.random() * (100 - 1)),
                                        "title": book?.Title,
                                        "author": (typeof book.Author != 'undefined')?book.Author[0]:null,
                                        "type": "thing_to_read",
                                        "image": book?.Picture,
                                        "url" : book?.Link
                                    }}
                                    section="googleBooks"
                                    clickAction={props.clickAction}
                                    URL={book?.Link}
                                />
                            </div>
                        )
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={2} style={{ marginTop: 12 }}>
                <div className='row'>
                {!thingsToRead?.googleReadBooks?.length > 0  && <ViewHeading title={" No results found"} containerStyles={{ textTransform: 'capitalize' }} />}
                    {
                        thingsToRead?.googleReadBooks?.map((book, index) =>
                            <div className='col-md-4'>
                                <MachineLearningCard 
                                    // type={'Simplilearn'}
                                    title={book?.Title || ''}
                                    // courseTime={'4'}
                                    showButtons={false}
                                    // timeFormat={'Min read'}
                                    // date={'March 31'}
                                    imageUrl={book?.Picture}
                                    onCardClick={()=> window.open(book?.Link, "_blank")}
                                    payloadAction={{
                                        "id": Math.round(1 + Math.random() * (100 - 1)),
                                        "title": book?.Title,
                                        "author": (typeof book.Author != 'undefined')?book.Author[0]:null,
                                        "type": "thing_to_read",
                                        "image": book?.Picture,
                                        "url" : book?.Link
                                    }}
                                    section="googleReadBooks"
                                    clickAction={props.clickAction}
                                    URL={book?.Link}
                                />
                            </div>
                        )
                    }
                </div>
            </TabPanel>
        </div>
    )
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    style: PropTypes.any,
};
export default ThingsToWRead;