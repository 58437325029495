import { GET_ORDERS } from "./action";

const initialState = {
  orderList: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS: {
      return {
        ...state,
        orderList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
