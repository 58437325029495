import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { PROCCESSING, STOPPROCESSING } from "../../utilities/constants";
import { api } from "../../utilities/api";
export const ORDER_LIST = "ORDER_LIST";



export const getUserOrders = () => async (dispatch) => {

    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.get_orders_history,
    };
    dispatch({ type: PROCCESSING });

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {
                 dispatch({
                    type: ORDER_LIST,
                    payload: response,
                });
                dispatch({ type: STOPPROCESSING });
            }
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error?.response?.message;
        });

};