import { FAILUR, PROCCESSING } from "../../utilities/constants";
import { THINGS_READ, THINGS_WATCH, EMPTYSKILLMAP, THINGS_TODO, ACTIONS } from "./action";


const initialState = {
    error: null,
    thingsToWatch: null,
    thingsToRead: null,
    thingsToDo: null
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case THINGS_WATCH : {
            return {
                ...state,
                thingsToWatch: action.payload,
            };
        }
        case THINGS_READ : {
            return {
                ...state,
                thingsToRead: action.payload,
            };
        }
        case THINGS_TODO : {
            return {
                ...state,
                thingsToDo: action.payload,
            };
        }
        case EMPTYSKILLMAP : {
            return {
                ...state,
                thingsToWatch: null,
                thingsToRead: null,
                thingsToDo: null
            };
        }

        case ACTIONS: {
            
        }
        
        case FAILUR : {
            return {
                ...state,
                error: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;