import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import {  getAllBlogs, deleteBlogById } from "./action";
import { DELETE_MESSAGE } from "../../utilities/message";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from 'react-toastify'
import CustomDialog from "../Components/CustomDialog";

//jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Sidebar from "../Components/Navigation/Sidebar";
import Topbar from "../Components/Navigation/Topbar";
import PageHeading from "../Components/PageHeading";

import Loader from '../../components/Loader';

const BlogList = (props) => {
  $(document).ready(function () {
    setTimeout(function () {
      $("#dataTable").DataTable();
    }, 1000);
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const [blogId, setBlogId] = useState(null)
  const history = useNavigate();

  const handleDialogClose = () => {
    setBlogId(null);
    setShowDialog(false);
  };

  const deleteBlog = async (id) => {
    setBlogId(id);
    setDialogSubmit(true);
    setMessage(DELETE_MESSAGE);
    setShowDialog(true);
  };

  const handleEditData = (item) => {
    history(`/admin/blog/addNew?update_id=${item.id}`, {
      state: { id: item?.id },
    });
  };

  useEffect(() => {
    setLoading(true);
    props.getAllBlogs();
    setLoading(false);
  }, []);

  const deleteBlogDialog = () => {
      // setLoading(true);
      props?.deleteBlogById(blogId).then(response => {
        setLoading(false);
        if(response.status){
          props.getAllBlogs();
          toast.success('Data deleted successfully')
        }else{
          toast.error(response?.message)
        }
        setShowDialog(false)
      }).catch(error =>{
        setLoading(false);
        setShowDialog(false)
        toast.error('Something went wrong')
      }
      )
  }



  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper" className="blogs">
        {loading && <Loader /> }
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="contents">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Blogs"
                buttonName="Add New"
                buttonLink={"/admin/blog/addNew"}
                buttonIcon="fa-plus"
              />

              <div>

              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                  Blog list
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >
                        
                        <>
                          <thead>
                          <tr>
                            <th className="text-capitalize heading-section">Id</th>
                            <th className="text-capitalize">Image</th>
                            <th className="text-capitalize heading-section">Title</th>
                            <th className="text-capitalize heading-section">Created Date</th>
                            <th className="text-capitalize action-section">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                            {props?.blogList?.map((item, index) => (
                              <tr key={"tr" + index}>
                                <td>{item?.id}</td>
                                <td className="text-capitalize">
                                  {(item?.image) ?
                                    <img src={item?.image?.media} style={{ width: 40, height: 40, objectFit: 'contain' }} />
                                    : (
                                      <div style={{ width: 40, height: 40, background: '#CCE2FF' }}></div>
                                    )
                                  }
                                </td>
                                <td>{item?.title}</td>
                                <td>{item?.created_date}</td>
                                <td className="action">
                                  {
                                    <a className="text-primary">
                                      <i
                                        className="far fa-edit"
                                        onClick={() => handleEditData(item)}
                                        style={{ marginRight: 10 }}
                                      ></i>
                                    </a>
                                  }
                                  {
                                    <a
                                      className="deletePost delete text-danger"
                                      rowid="18"
                                      onClick={() => deleteBlog(item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        message={DELETE_MESSAGE}
        showDialog={showDialog}
        handleClose={() => handleDialogClose()}
        handleDialogSubmit={() => deleteBlogDialog()}
        isSubmit
      />
    </div>
  );
};

const mapStateToProps = ({adminBlogsReducer}) => {
  const { blogList } = adminBlogsReducer;
  return { blogList };
};

export default connect(mapStateToProps, {
  getAllBlogs,
  deleteBlogById
})(BlogList);
