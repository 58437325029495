import React, { useState } from 'react';
import Slider from 'react-slick';
import Images from '../../constants/Images';
import Card from '@mui/material/Card';
import moment from 'moment';
import '../home/home.scss';
import Colors from '../../constants/Colors';
import UpskillrUtils from '../../utilities/UpskillrUtils';
import { useNavigate } from 'react-router-dom';

const PreviousBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.PreArrow} style={{width:45,height:45,marginTop:20}}/>
        </div>
    )
}
const NextBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.NextArrow} style={{width:45,height:45,marginTop:20}}/>
        </div>
    )
}

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    className: "center",
    centerMode: true,
    centerPadding: "10px",
    variableWidth: true
  };


function BlogSlider(props) {
    const { sliderData, handleClick } = props;
    const {width, height} = UpskillrUtils.useWindowDimensions();
    const sliderWidth = width/2.5;
    const navigate = useNavigate();
    
    return (
            <div className='homeTestimonial'>
                <Slider
                    prevArrow={<PreviousBtn className={"prevBtn " + props.prevBtnStyle}/>}
                    nextArrow={<NextBtn className={"prevBtn "+ props.nextBtnStyle }/>}
                    {...settings}>
               {
                sliderData?.slice(0, 6)?.map((item,i)=>{
                    return(
                        <Card  elevation={0} style={{width:sliderWidth,borderRadius:24}} className='cardBox' key={'homeTestimonial'+i}>
                            <div className='cardBoxContents' style={{padding:0,marginBottom:10}} onClick={(e) => handleClick(e,item)}> 
                            <img src={item.image.media} style={{ width: '100%', height: 192,objectFit:'cover' }} />
                            </div>
                            <div className='profileArea' style={{border:0}}>
                                <div>
                                    <p className='profileDetailText'>{moment(item?.created_date).format('MMM DD, dddd')}</p>
                                    <h5>{item.heading}</h5>
                                </div>
                            </div>
                        </Card>
                    )
                })
               }
              
                </Slider>
        </div>
    );
}

export default BlogSlider;