
import { toast } from "react-toastify";
import { API_ENDPOINTS, METHOD_TYPE } from "../utilities/apiUrls";
import { api } from "../utilities/api";

export const UPLOAD_FILES = 'UPLOAD_FILES';
export const GET_FILES = 'GET_FILES';
export const ALL_ORDERS = 'ALL_ORDERS';
export const DASHBOARD_USERS = "DASHBOARD_USERS";
export const DASHBOARD_REVENUE = "DASHBOARD_REVENUE";
export const DASHBOARD_ORDERS = "DASHBOARD_ORDERS";

export const getDashboardUsers = (startDate=null, endDate=null) => async (dispatch) => {
    let apiUrl = API_ENDPOINTS.get_dashboard_users;
    if(startDate && endDate){
        apiUrl+="?start_date="+startDate+"&end_date="+endDate;
    }
    let data = {
        method: METHOD_TYPE.get,
        url: apiUrl,
    };
    return api(data)
        .then(async (response) => {
            return response.data
        }).then(async (response) => {
            if (response) {
                dispatch({
                    type: DASHBOARD_USERS,
                    payload: response,
                });
            }
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};

export const getDashboardRevenue = () => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.get_dashboard_revenue,
    };
    return api(data)
        .then(async (response) => {
            return response.data
        }).then(async (response) => {
            if (response) {
                dispatch({
                    type: DASHBOARD_REVENUE,
                    payload: response,
                });
            }
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};
export const getDashboardOrders = (startDate=null, endDate=null) => async (dispatch) => {
    let apiUrl = API_ENDPOINTS.get_dashboard_orders;
    if(startDate && endDate){
        apiUrl+="?start_date="+startDate+"&end_date="+endDate;
    }
    let data = {
        method: METHOD_TYPE.get,
        url: apiUrl,
    };
    return api(data)
        .then(async (response) => {
            return response.data
        }).then(async (response) => {
            if (response) {
                dispatch({
                    type: DASHBOARD_ORDERS,
                    payload: response,
                });
            }
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};

export const uploadImage = (fileData) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.your_uploads,
      data: fileData,
      headers: {
        "Content-Type": "image/jpeg",
    },
    };
    return api(data)
      .then(async (response) => {
        return response;
      })
      .then(async (response) => {
        if (response) {
          dispatch({
            type: UPLOAD_FILES,
            payload: response,
          });
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };

export const getImage = (id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.your_uploads+id+'/',
    };
    return api(data)
      .then(async (response) => {
        return response;
      })
      .then(async (response) => {
        if (response) {
          dispatch({
            type: GET_FILES,
            payload: response,
          });
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };

export const getUploadURL = (fileDetail) => async () => { 
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.your_uploads}?fileName=${fileDetail.id}&fileType=${fileDetail.media}`,
    };
    return api(data)
    .then(async (response) => {
            console.log('.data',response)
            return response
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};

export const updateUploadURL = (url, file) => async () => {
    let data = {
        method: METHOD_TYPE.put,
        url: url,
        data: file,
        headers: {
            "Content-Type": "image/png",
        },
    };
    return api(data)
        .then(async (response) => {
            toast.success(response?.data?.message);
            return response
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};
export const updateImageById = (imageId, file) => async () => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.your_uploads + imageId +'/',
        data: file,
        headers: {
            "Content-Type": "image/png",
        },
    };
    return api(data)
        .then(async (response) => {
            toast.success(response?.data?.message);
            return response
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};