import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
export const PROFILE_DETAILS = 'PROFILE_DETAILS'
export const LOADING = 'LOADING'


export const getProfileDetails = (user_id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.profile_details + user_id + "/",
    };
    dispatch({
        type : LOADING
    })
    return api(data)
        .then(async (response) => {
                dispatch({
                    type: PROFILE_DETAILS,
                    payload: response.data
                });

                return response;
        }).catch((error) => {
            throw 'Error in fetching profile details'
        });     
};

export const updateProfileDetails = (profileDetails , user_id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.update_profile+ user_id + "/",
        data: profileDetails
    };
    dispatch({
        type : LOADING
    })
    return api(data)
        .then(async (response) => {
            // if (response.status) {
                dispatch({
                    type: PROFILE_DETAILS,
                    payload: response.data,
                });
            //     toast.success(response?.message);
            // }
            return response;
        })
        .catch((error) => {
            // toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};

export const changePassword = (passwordData) => async () => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.change_password,
        data: passwordData
    };
    return await api(data)
        .then(async (response) => {
            // console.log("response",response);
            if (response.status) {
                return {
                    status: true,
                    result: response.data,
                };
            }
        })
        .catch((error) => {
            return {
                status: false,
                result: error?.response?.data
            };
        });
};