import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import Sidebar from "../Components/Navigation/Sidebar";
import Topbar from "../Components/Navigation/Topbar";
import PageHeading from "../Components/PageHeading";
import { DELETE_MESSAGE } from "../../utilities/message";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from 'react-toastify'
import CustomDialog from "../Components/CustomDialog";

//jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { getAllOrders } from './action'

const Orders = (props) => {
  $(document).ready(function () {
    setTimeout(function () {
      $("#dataTable").DataTable();
    }, 1000);
  });

  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState(null);
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const [userListArr, setUserListArr] = useState([]);
  const [type, setType] = useState('')
  const [check, setCheck] = useState(Boolean)
  const [userId, setUserId] = useState(null)
  const history = useNavigate();

  useEffect(() => {
    props.getAllOrders();
  }, []);

  const handleDialogClose = () => {
    setId(null);
    setShowDialog(false);
  };

  const deleteUser = async (id) => {
    setId(id);
    setDialogSubmit(true);
    setMessage(DELETE_MESSAGE);
    setShowDialog(true);
  };

  const handleDialogSubmit = () => {
    props
      .deleteUser(id)
      .then(async (response) => {
        onResponseRecieved(response);
      })
      .catch((error) => {
        onResponseFailed(error);
      });
  };

  const onResponseRecieved = (response) => {
    // props?.usersList();
    setDialogSubmit(false);
    setMessage(response?.message);
    setOpenModal(false);
    setShowDialog(false);
  };

  const onResponseFailed = (error) => {
    setDialogSubmit(false);
    setMessage(error?.response?.message);
    setOpenModal(false);
    setShowDialog(true);
  };

  const handleEditData = (userData) => {
    history("/admin/user/addNew", {
      state: { _id: userData?._id },
    });
  };

  const deleteUserDialog = () => {
    props?.toggleActiveandDeactive(userId, false)
      .then(response => {
        // props?.usersList(type);
        setShowDialog(false)
        toast.success(response?.message)
      }).catch(error => {
        setShowDialog(false)
        toast.error('Something went wrong')
      }
      )
  }

  const handleChange = (e, id) => {
    setUserId(id)
    setShowDialog(true)
  }



  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="contents">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Orders"
                buttonName="Add New"
              />

              <div>

              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Order Lists
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >

                      <thead>
                        <tr>
                          <th className="text-capitalize">Order Id</th>
                          <th className="text-capitalize">Type</th>
                          <th className="text-capitalize">price</th>
                          <th className="text-capitalize">Status</th>
                          {/* <th className="text-capitalize">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {(props?.orderList) && props?.orderList?.map((user, index) => (
                          <tr key={"tr" + index}>
                            <td>{user?.id}</td>
                            <td>{user?.subscription_type}</td>
                            <td>{user?.amount}</td>
                            <td className="text-capitalize">
                              {user?.status}
                            </td>
                            {/* <td className="action">
                                {
                                  <label className="switch" key={user?._id}>
                                    <input type="checkbox"
                                      value={user?.isActive}
                                      onChange={(e) => handleChange(e.target.checked, user?._id)} checked={user?.isActive} />
                                    <span className="slider round"></span>
                                  </label>
                                }
                              </td> */}
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        message={DELETE_MESSAGE}
        showDialog={showDialog}
        handleClose={() => handleDialogClose()}
        handleDialogSubmit={() => deleteUserDialog()}
        isSubmit
      />
    </div>
  );
};

const mapStateToProps = ({ adminOrderReducer }) => {
  const { orderList } = adminOrderReducer;
  return { orderList };
};

export default connect(mapStateToProps, {
  getAllOrders,
})(Orders);
// export default (Orders);
