import { GET_MEETOUTTEAM,ADD_MEETOURTEAM,UPDATE_MEETOURTEAM,DELETE_MEETOURTEAM,GET_MEETOURTEAMDETAILS } from "./action";

const initialState = {
  meetOurTeamList: null,
  createMeetOurTeam: null,
  deleteMeetOurTeam: null,
  updateMeetOurTeam: null,
  getTeamDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEETOUTTEAM: {
      return {
        ...state,
        meetOurTeamList: action.payload,
      };
    }
    case ADD_MEETOURTEAM: {
      return {
        ...state,
        MeetOurTeam: action.payload,
      };
    }
    case DELETE_MEETOURTEAM: {
      return {
        ...state,
        deleteMeetOurTeam: action.payload,
      };
    }
    case UPDATE_MEETOURTEAM: {
      return {
        ...state,
        updateMeetOurTeam: action.payload,
      };
    }
    case GET_MEETOURTEAMDETAILS: {
      return {
        ...state,
        getTeamDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
