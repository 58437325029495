import CryptoJS from 'crypto-js';
import { secretKey } from "./constants";

// Encryption function
export const encryptQueryParam = (value) => {
  const ciphertext = CryptoJS.AES.encrypt((value).toString(), secretKey).toString();
  return encodeURIComponent(ciphertext);
};

// Decryption function
export const decryptQueryParam = (encryptedValue) => {
  return new Promise((resolve, reject) => {
    try {
      const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedValue), secretKey);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      resolve(decryptedValue);
    } catch (error) {
      // Handle decryption errors
      console.error('Decryption error:', error);
      reject(error);
    }
  });
}