import React, { useState } from 'react';
import "../cofounder/CoFounderCard.css"
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';


function CoFounderCard(props) {
    const { founderName, description, tagLine, founderImage,LinkedInLink } = props
    return (
        <>
            <div className='CoFounderContainerCard' style={{ backgroundColor: Colors.light_white_background, borderColor: Colors.border_light_blue, borderRadius: 24, alignItems: 'center', width:'80%'}}>
                <div className='founderImageBackground' style={{ backgroundColor: Colors.border_light_blue, alignItems: 'flex-end', justifyContent: 'flex-end', borderRadius: 16,width:'100%' }}>
                    <img src={founderImage} style={{ width: '100%' }} />
                </div>
                <div className='CoFounderTextDetails' style={{paddingLeft:50}}>
                    <h3 style={{ color: Colors.dark_blue, fontSize: 33, fontFamily: "Poppins", fontWeight: '700', margin: 0,textAlign:'left' }}>
                        {founderName}
                    </h3>
                    <h4 style={{ color: Colors.dark_blue, fontSize: 20, fontFamily: "Poppins", fontWeight: '500', margin: 0,textAlign:'left' }}>
                        {tagLine}
                    </h4>
                    <p style={{ color: Colors.dark_blue, fontSize: 16, fontFamily: "Poppins", fontWeight: '500', marginTop: 14,textAlign:'left', }}>
                        {description}
                    </p>
                    <a href={LinkedInLink} target="_blank" rel="noopener noreferrer"   className='linkend_logo' style={{ backgroundColor: Colors.linkdein_background_color,marginTop: 14, alignItems: 'center', justifyContent: 'center', borderRadius: 12,width:48,height:48,cursor:'pointer' }}>
                        <img src={Images.LinkendLogo} style={{ width: 24,height:24 }} />
                    </a>
                </div>
            </div>
        </>
    );
}

export default CoFounderCard;