import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";


export const GET_ORDERS = "GET_ORDERS";

export const getAllOrders = (value) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: `${API_ENDPOINTS.get_orders}`,
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: GET_ORDERS,
          payload: response,
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

