import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../../assets/admin/index.scss';
import { Link, useNavigate } from 'react-router-dom'
import { API_URL} from '../../utilities/constants'
import { toast } from 'react-toastify';
import logo from '../../assets/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { userLogging } from '../../pages/login/action';
import Loader from '../../components/Loader'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'
import jwtDecode from 'jwt-decode';

function AdminLogin() {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const { userDetails } = useSelector((state) => state.loginReducer);
    
    // const token = 'your-jwt-token';

    useEffect(()=>{
        const isAdmin = localStorage.getItem('isAdmin');
            if(isAdmin && isAdmin==='true'){
               history('/admin/dashboard');
            }
    },[])

    const decodeJWT = (token) => {
        try {
          const decoded = jwtDecode(token);
          return decoded;
        } catch (error) {
          console.error('Error decoding JWT:', error);
          return null;
        }
      };
      
        const {
            register,
            handleSubmit,
            formState: { errors },
          } = useForm({
            resolver: yupResolver(yup.object().shape({
                email: yup.string().required("This is required"),
                password: yup.string().required("Password is required"),
            })),
          });
  
        const handleLogin = async (formData) => {
            try {
                setLoading(true);
                formData.email = btoa(formData.email);
                formData.password = btoa(formData.password);
                const user = await dispatch(userLogging({...formData, isAdmin: true}))
                setLoading(false);
                const decodedToken = decodeJWT(user.access);
              if (decodedToken.role === 'admin') {
                console.log('decodedToken',decodedToken)
                  localStorage.setItem('user', user.access)
                  localStorage.setItem('isAdmin', true)
                  toast.success('Login Successfull');
                  history('/admin/dashboard')
                } else {
                    toast.error('Login Error');
                }
            } catch (error) {
              setLoading(false);
              toast.error(error || "Login error");
            }
          }
    

    return (
        <div className='loginPage'>
            <div className="">
            {loading && <Loader /> }
                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-xl-6 col-lg-6 col-md-6">

                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <img src={logo} style={{width:100,height:50,objectFit:'contain'}} className='w-100'/>
                                                </div>
                                                <form onSubmit={handleSubmit(handleLogin)} className="user pt-4">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control form-control-user"
                                                            id="exampleInputUsername" aria-describedby="emailHelp" autoComplete='off'  {...register("email")}
                                                            placeholder="Enter Username..." />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" className="form-control form-control-user"
                                                           {...register("password")} 
                                                            id="exampleInputPassword" placeholder="Password" autoComplete='off'/>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox small">
                                                            <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                            <label className="custom-control-label" htmlFor="customCheck">Remember
                                                                Me</label>
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary btn-user btn-block"  type="submit" value="Sign Up">Login</button>
                                                </form>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    )
}

export default AdminLogin
