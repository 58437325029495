import {
    PROFILE_DETAILS, LOADING
} from './action';

const initialState = {
    profileDetails: {},
    loading:false,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_DETAILS: {
            return {
                ...state,
                profileDetails: action.payload,
                loading : false
            };
        }
        case LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
