import { LIKED_THINGS_TO_DO_ERROR, LIKED_THINGS_TO_DO_LOADING, LIKED_THINGS_TO_DO_SUCCESS, LIKED_THINGS_TO_READ_ERROR, LIKED_THINGS_TO_READ_LOADING, LIKED_THINGS_TO_READ_SUCCESS, LIKED_THINGS_TO_WATCH_ERROR, LIKED_THINGS_TO_WATCH_LOADING, LIKED_THINGS_TO_WATCH_SUCCESS } from "./action";

const initialState = {  
    likedThingsToDoSuccess: null,
    likedThingsToDoLoading: false,
    likedThingsToDoError: null,
    
    likedThingsToWatchSuccess: null,
    likedThingsToWatchLoading: false,
    likedThingsToWatchError: null,

    likedThingsToReadSuccess: null,
    likedThingsToReadLoading: false,
    likedThingsToReadError: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LIKED_THINGS_TO_DO_SUCCESS: {
            return {
                ...state,
                likedThingsToDoLoading: false,
                likedThingsToDoSuccess: action.payload,
                likedThingsToDoError: null
            }
        }
        case LIKED_THINGS_TO_DO_LOADING: {
            return {
                ...state,
                likedThingsToDoLoading: true,
                likedThingsToDoError: null
            }
        }
        case LIKED_THINGS_TO_DO_ERROR: {
            return {
                ...state,
                likedThingsToDoLoading: false,
                likedThingsToDoError: action.payload
            }
        }

        case LIKED_THINGS_TO_READ_SUCCESS: {
            return {
                ...state,
                likedThingsToReadLoading: false,
                likedThingsToReadSuccess: action.payload,
                likedThingsToReadError: null
            }
        }
        case LIKED_THINGS_TO_READ_LOADING: {
            return {
                ...state,
                likedThingsToReadLoading: true,
                likedThingsToReadError: null
            }
        }
        case LIKED_THINGS_TO_READ_ERROR: {
            return {
                ...state,
                likedThingsToReadLoading: false,
                likedThingsToReadError: action.payload
            }
        }

        case LIKED_THINGS_TO_WATCH_SUCCESS: {
            return {
                ...state,
                likedThingsToWatchLoading: false,
                likedThingsToWatchSuccess: action.payload,
                likedThingsToWatchError: null
            }
        }
        case LIKED_THINGS_TO_WATCH_LOADING: {
            return {
                ...state,
                likedThingsToWatchLoading: true,
                likedThingsToWatchError: null
            }
        }
        case LIKED_THINGS_TO_WATCH_ERROR: {
            return {
                ...state,
                likedThingsToWatchLoading: false,
                likedThingsToWatchError: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default reducer;