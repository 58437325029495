
import { UPLOAD, CHATS, GET_CONVERSATION, GET_REPLIES, QUERY_ASSISTANCE } from "./action";


const initialState = {
    conversations: [],
    replies: [],
    files: [],
    chats: null
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD : {
            return {
                ...state,
                files: [...state?.files, action.payload],
            };
        }

        case GET_CONVERSATION : {
            return {
                ...state,
                conversations: action.payload,
            };
        }

        case GET_REPLIES : {
            return {
                ...state,
                replies: action.payload,
            };
        }

        case QUERY_ASSISTANCE : {
            return {
                ...state,
                replies: [...state.replies, ...action.payload],
            };
        }
        
       
        default: {
            return state;
        }
    }
};

export default reducer;