import loginReducer from "../../client/pages/login/reducer";
import registerReducer from "../../client/pages/register/reducer"
import accountReducer from "../../client/pages/myAccount/reducer";
import homePageReducer from "../../client/pages/homePage/reducer"; 
import aboutUsReducer from "../../client/pages/aboutus/reducer";
import blogsReducer from "../../client/pages/blogs/reducer";
import creditsReducer from "../../client/pages/credit/reducer";
import pricingReducer from "../../client/pages/pricing/reducer";
import CurriculumReducer from "../../client/components/curriculumBuilder/reducer";
import { MenuOpenReducer } from "../admin/Components/Navigation/reducer";
import adminOrderReducer from "../admin/Orders/reducer";
import adminRecognitionReducer from "../admin/ContentManagments/Recogniations/reducer";
import adminReducer from "../admin/reducer";
import adminUserReducer from "../admin/UserManagement/Users/reducer";
import adminOurPartnerReducer from "../admin/ContentManagments/OurPartners/reducer";
import adminMeetOurTeamListReducer from "../admin/ContentManagments/MeetOurTeam/reducer";
import adminMeetOurCoFounderListReducer from "../admin/ContentManagments/MeetOurCoFounder/reducer";
import adminSubscriptionsReducer from "../admin/Subscriptions/reducer";
import adminKeyFeaturesReducer from "../admin/ContentManagments/KeyFeatures/reducer";
import adminUpdateSubscriptionReducer from "../admin/Subscriptions/reducer";
import adminHomeOverviewReducer from "../admin/ContentManagments/HomeOverviews/reducer";
import adminUpdateMeetOurTeamReducer from "../admin/ContentManagments/MeetOurTeam/reducer";
import adminMarketplaceReducer from "../admin/ContentManagments/Marketplace/reducer";
import adminOrganizationsReducer from "../admin/ContentManagments/Organizations/reducer";
import userOrderHistory from "../pages/orderHistory/reducer";
import adminBlogsReducer from "../admin/Blogs/reducer";
import adminRecognitionDetailReducer from "../admin/ContentManagments/Recogniations/reducer";
import admingetFeatureReducer from "../admin/ContentManagments/KeyFeatures/reducer";
import adminGetMarketDetails from "../admin/ContentManagments/Marketplace/reducer";
import adminGetOurPartnerReducer from "../admin/ContentManagments/OurPartners/reducer";
import admingetDetailsReducer from "../admin/ContentManagments/HomeOverviews/reducer";
import skillMapReducer from "../components/ourFeatures/reducer";
import assistantReducer from "../components/upSkillrAssistant/reducer"
import careerPalReducer from "../components/carrerPal/reducer"
import toolReducer from "../pages/ourFeatures/reducer"
import multiSearchReducer from "../components/multiSearch/reducer"
import savedLibraryReducer from "../pages/savedLibrary/reducer"
import hiddenLibraryReducer from "../pages/hiddenLibrary/reducer"
import likedLibraryReducer from "../pages/likedLibrary/reducer"

export const reducers = {
    menuState: MenuOpenReducer,
    loginReducer,
    registerReducer,
    homePageReducer,
    accountReducer,
    aboutUsReducer,
    pricingReducer,
    CurriculumReducer,
    blogsReducer,
    creditsReducer,
    adminOrderReducer,
    adminRecognitionReducer,
    adminReducer,
    adminUserReducer,
    adminOurPartnerReducer,
    adminMeetOurTeamListReducer,
    adminMeetOurCoFounderListReducer,
    adminSubscriptionsReducer,
    adminKeyFeaturesReducer,
    adminUpdateSubscriptionReducer,
    adminHomeOverviewReducer,
    adminUpdateMeetOurTeamReducer,
    adminMarketplaceReducer,
    adminOrganizationsReducer,
    userOrderHistory,
    adminBlogsReducer,
    adminRecognitionDetailReducer,
    admingetFeatureReducer,
    adminGetMarketDetails,
    adminGetOurPartnerReducer,
    admingetDetailsReducer,
    skillMapReducer,
    toolReducer,
    assistantReducer,
    careerPalReducer,
    multiSearchReducer,
    savedLibraryReducer,
    hiddenLibraryReducer,
    likedLibraryReducer
};