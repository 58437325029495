
import LoginSlider from './LoginSlider';
import './login.scss';
import { useForm } from "react-hook-form";
import { changePassword, sendForgotpasswordOTP, verifyForgotpasswordOTP } from './action'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, NavLink, } from "react-router-dom";
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { Button } from '@mui/material';


function ChangePassword(props) {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [newPass, setNewPass] = useState(false);
    const [newCPass, setNewCPass] = useState(false);
    const [isOtpSend, setIsOtpSend] = useState(false);
    const [otpId, setOtpId] = useState("");
    const [sessionId, setSessionId] = useState("");
    const [otp, setOTP] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const sendOtpForm = useForm({
        resolver: yupResolver(yup.object().shape({
            phone_number: yup.string().required("Mobile number is required").matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
        })),
    });
    const handleOnSendOtpClicked = async (formData) => {
        try {
            setLoading(true);
            const resData = await dispatch(sendForgotpasswordOTP(formData));
            if(resData.status && resData.result.status !== 'failure'){
                setIsOtpSend(true);
                setSessionId(resData.result.session_id);
                setOtpId(resData.result.otp_id);
                toast.success('OTP Sent Successfully');
                setLoading(false);
                return;
            }
            
            setIsOtpSend(false);
            toast.error(resData.result.error??resData.result.message);
            setLoading(false);            

        } catch (error) {
            setIsOtpSend(false);
            setLoading(false);
            toast.error(error || "Send otp error");
        }
    }
    const resendOTP = async() =>{
        setIsOtpSend(false);
    }

    const verifyOtpForm = useForm({
        resolver: yupResolver(yup.object().shape({
            phone_number: phoneNumber ? phoneNumber : yup.string().required("Mobile number is required").matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
            otp: yup.string().required("OTP is required"),
        })),
    });
    const handleOnVerifyOtp = () => {
        verifyOtpForm.handleSubmit(verifyOTP)();
    }
    const verifyOTP = async (data) => {
        if (data) {
            setLoading(true);
            let formData = { ...data, otp_id: otpId, session_id: sessionId };
            try {
                const verifyRes = await dispatch(verifyForgotpasswordOTP(formData));
                if (verifyRes.status) {
                    setPhoneNumber(formData.phone_number);
                    toast.success(verifyRes.result.message);
                    setOtpVerified(true);
                    setOTP(formData.otp);
                } else {
                    toast.error(verifyRes.result.error || "Error while verifying otp");
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error(error.message || "Error while verifying forgot password otp");
            }
        } else {
            setLoading(false);
            toast.error("Please fill required fields");
        }
    };
    const changePassForm = useForm({
        resolver: yupResolver(yup.object().shape({
            new_password: yup.string().required("New password is required"),
            confirm_new_password: yup.string().required("Confirm new password is required"),
        })),
    });

    const { newpassword, setnewpassword } = useState("");
    const handleOnUpdatePassword = async (formData) => {
        if (formData.new_password && formData.confirm_new_password) {
            if (formData.confirm_new_password !== formData.new_password){
                toast.error('Passwords do not match');
            } else{
                let submitFormData = {
                    otp: otp,
                    phone_number: phoneNumber,
                    new_password: formData.new_password,
                    otp_id:otpId
                }
                try {
                    const changePassResp = await dispatch(changePassword(submitFormData));
                    if (changePassResp.status) {
                        toast.success(changePassResp.result.message);
                        history('/login');
                    } else {
                        toast.error(changePassResp.result.error || "Error in changing your password");
                    }
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    toast.error(error.message || "Error while changing password");
                }
            }
        }
        
    }

    const renderSendOtpForm = () => {
        return (
            <form onSubmit={sendOtpForm.handleSubmit(handleOnSendOtpClicked)} className='forgotPassForm'>
                <label htmlFor="fname" className='formLabel'>Phone Number</label>
                <div className='mb-4'>
                    <div className='form-input forTwoField'>
                        <input type="text" placeholder='+91' disabled="disabled" style={{ width: 60, marginRight: 12 }} />
                        <input type="number" placeholder='000 000 0000' maxLength={10} {...sendOtpForm.register('phone_number')} />
                    </div>
                    <p className='validationError'>{sendOtpForm.formState.errors.phone_number?.message}</p>
                </div>
                {/* <!-- Submit button --> */}
                {<button className='formBtn signIn mt-4' type="submit" value="Sign Up">Send OTP</button>}
            </form>
        )
    }
    const renderVerifyOtpForm = () => {
        return (
            <form className='forgotPassForm'>
                <label htmlFor="fname" className='formLabel'>Phone Number</label>
                <div className='mb-4'>
                    <div className='form-input forTwoField'>
                        <input type="text" placeholder='+91' disabled="disabled" style={{ width: 60, marginRight: 12 }} />
                        <input type="text" placeholder='000 000 0000' {...verifyOtpForm.register('phone_number')} />
                    </div>
                    <p className='validationError'>{verifyOtpForm.formState.errors.phone_number?.message}</p>
                </div>

                <label htmlFor="otp" className='formLabel'>Enter OTP</label>
                <div className='mb-4'>
                    <div className='form-input'>
                        <input type="number" placeholder='000 000' id="otp" {...verifyOtpForm.register('otp')} />
                    </div>
                    <p className='validationError'>{verifyOtpForm.formState.errors.otp?.message}</p>
                </div>
                {/* <!-- Submit button --> */}
                <button className='formBtn signIn mt-4' type="button" onClick={handleOnVerifyOtp}>Verify OTP</button>
                <Button className='w-100 mt-3 resentOtpBtn' onClick={resendOTP}>Resend OTP</Button>
            </form>
        )
    }

    return (
        <section className="vh-100">
            {loading && <Loader />}
            <div className="container-fluid loginPage">
                <div className="row">
                    <img src={require('../../assets/change-pass-bg-vector.png')} className='change-bg-vector' />
                    <div className="col-md-6 col-lg-7 col-xl-7 px-5 pt-5 leftSlider">
                        <div className='d-flex justify-content-center'>
                            <NavLink to={"/"}><img src={require('../../assets/logo.png')} className='loginLogo' /></NavLink>
                        </div>
                        <h1 className='FutureReady'>Be Future Ready</h1>
                        <div className='py-3 loginSilderClass' style={{ marginTop: 60 }}>
                            <LoginSlider />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-5 changepass">
                        <div className='backgroundImage'>
                            <h1 className=''>Update Password</h1>

                            <div>
                                {!otpVerified &&
                                    <>{
                                        isOtpSend ? renderVerifyOtpForm() : renderSendOtpForm()
                                    }
                                    </>

                                }
                                {otpVerified &&
                                    <form onSubmit={changePassForm.handleSubmit(handleOnUpdatePassword)} className='changePassForm'>
                                        <label htmlFor="fname" className='formLabel'>New Password</label>
                                        <div className='mb-4'>
                                            <div className='form-input prelative'>
                                                <input type={newPass ? "text" : "password"} placeholder='Enter new password' {...changePassForm.register('new_password', { validate: (value) => setnewpassword(value) })} />
                                                <Button className='viewPassIcon' onClick={() => setNewPass(!newPass)}>
                                                    {newPass ? <i className="fa fa-eye-slash text-white" aria-hidden="true"></i> : <i className="fa fa-eye text-white"></i>}
                                                </Button>
                                            </div>
                                            <p className='validationError'>{changePassForm.formState.errors.new_password?.message}</p>
                                        </div>
                                        <label htmlFor="otp" className='formLabel'>Confirm Password</label>
                                        <div className='mb-4'>
                                            <div className='form-input prelative'>
                                                <input type={newCPass ? "text" : "password"} placeholder='Re-Enter new password' {...changePassForm.register('confirm_new_password', { validate: (value) => value === newpassword || 'Passwords do not match' })} />
                                                <Button className='viewPassIcon' onClick={() => setNewCPass(!newCPass)}>
                                                    {newCPass ? <i className="fa fa-eye-slash text-white" aria-hidden="true"></i> : <i className="fa fa-eye text-white"></i>}
                                                </Button>
                                            </div>
                                            <p className='validationError'>{changePassForm.formState.errors.confirm_new_password?.message}</p>
                                        </div>
                                        {/* <!-- Submit button --> */}
                                        <button className='formBtn signIn mt-4' type="submit" value="Sign Up">Submit</button>
                                    </form>
                                }
                                <div className='alredyAccount' style={{ width: '100%', textAlign: 'center' }}>
                                    <p className="mb-5 pb-lg-2" >Already Have an account? <NavLink to={"/login"}>Log In</NavLink></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );


}


export default ChangePassword;