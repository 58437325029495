import { CVEVALUATION_SUCCESS, CVEVALUATION_ERROR, CVEVALUATION_LOADING, SKILLSETEVALUATION_SUCCESS,SKILLSETEVALUATION_LOADING, SKILLSETEVALUATION_ERROR,CAREERPATH_ERROR, CAREERPATH_SUCCESS, CAREERPATH_LOADING, MCQS_ERROR, MCQS_LOADING, MCQS_SUCCESS, CASESTUDY_ERROR, CASESTUDY_LOADING, CASESTUDY_SUCCESS, CODINGSTIMULATION_ERROR, CODINGSTIMULATION_LOADING, CODINGSTIMULATION_SUCCESS, RESULTORIENTED_ERROR, RESULTORIENTED_LOADING, RESULTORIENTED_SUCCESS,FILETOTEXT_ERROR,FILETOTEXT_LOADING,FILETOTEXT_SUCCESS, INTERVIEW_PREPARATION_ERROR,INTERVIEW_PREPARATION_LOADING,INTERVIEW_PREPARATION_SUCCESS, GENERATE_EXAM_ERROR, GENERATE_EXAM_LOADING, GENERATE_EXAM_SUCCESS } from "./action";

const initialState = {
    cvEvaluationLoading: false,
    cvEvaluationSuccess: {
        "conversationHistory": [
            {
                "role": "assistant",
                "content": "Hello and welcome! I'm here to help you optimize your CV and make it stand out to potential employers. To get started, please submit your CV."
            }
        ],
        "promptName": "cv-bot-career-pal"
    },
    cvEvaluationError: null,

    skillsetEvalutionLoading: false,
    skillsetEvalutionSuccess: {
        "conversationHistory": [
            {
                "role": "assistant",
                "content": "Hello and welcome! I'm excited to help you evaluate your skills and identify areas for improvement. To begin, please submit your CV."
            }
        ],
        "promptName": "skillset-bot-career-pal"
    },
    skillsetEvalutionError: null,

    mcqsLoading: false,
    mcqsSuccess: {
        "conversationHistory": [
            {
                "role": "assistant",
                "content": "Hello and welcome! I'm here to help you evaluate your proficiency in a particular skill. Please tell me the skill you'd like to be assessed on and your preferred proficiency level: Beginner, Intermediate, or Advanced. Once we have that information, we'll proceed with a set of multiple-choice questions to gauge your knowledge and understanding. Let's get started!"
            },
            {
                "role": "assistant",
                "content": require("./dynamicFields.json").mcqsFields
            }
        ],
        "promptName": "mcqs-bot-career-pal"
    },
    mcqsError: null,

    caseStudyLoading: false,
    caseStudySuccess: {
        "conversationHistory": [
            {
                "role": "assistant",
                "content": "Please provide the concepts you would like to be tested on for the case study."
            }
        ],
        "promptName": "case-study-bot-career-pal"
    },
    caseStudyError: null,

    codingSimulationLoading: false,
    codingSimulationSuccess: {
        "conversationHistory": [
            {
                "role": "assistant",
                "content": "Hello and welcome! I am the job simulation bot. Please provide me with a job description so that I can create a role-playing scenario for you."
            }
        ],
        "promptName": "job-simulation-bot-career-pal"

    },
    codingSimulationError: null,

    interviewPreparationLoading: false,
    interviewPreparationSuccess: {
        "conversationHistory": [
            {
                "role": "assistant",
                "content": "Hello! I'm so glad you've decided to work with me as your interview coach. I'm here to support you and help you prepare for your upcoming job interview. To get started, can you please tell me about the position you're interviewing for and the company?"
            }
        ],
        "promptName": "interview-preparation-bot-career-pal"

    },
    interviewPreparationError: null,

    careerPathLoading: false,
    careerPathSuccess: {
        "conversationHistory": [
            {
                "role": "assistant",
                "content": "Hello! I'm here to help you explore new career options. To get started, can you please tell me what job title you are interested in?"
            }
        ],
        "promptName": "path-provider-bot-career-pal"
    },
    careerPathError: null,

    resultOrientedLoading: false,
    resultOrientedSuccess: {
        "conversationHistory": [
            {
                "role": "assistant",
                "content": "Hello and welcome! I'm GetResults, your upskilling bot. I'm here to help you achieve your learning objectives. Please tell me your end objective or learning outcome that you want to achieve."
            }
        ],
        "promptName": "result-oriented-upskilling-bot-career-pal"
    },
    resultOrientedError: null,

    filetotextSuccess : null,
    filetotextLoading : false,
    filetotextError : null,

    generateExamSuccess : null,
    generateExamLoading : false,
    generateExamError : null
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CVEVALUATION_SUCCESS: {
            return {
                ...state,
                cvEvaluationLoading: false,
                cvEvaluationSuccess: action.payload,
                cvEvaluationError: null
            }
        }
        case CVEVALUATION_LOADING: {
            return {
                ...state,
                cvEvaluationLoading: true,
                cvEvaluationError: null
            }
        }
        case CVEVALUATION_ERROR: {
            return {
                ...state,
                cvEvaluationLoading: false,
                cvEvaluationError: action.payload
            }
        }

        case SKILLSETEVALUATION_SUCCESS: {
            return {
                ...state,
                skillsetEvalutionLoading: false,
                skillsetEvalutionSuccess: action.payload,
                skillsetEvalutionError: null
            }
        }
        case SKILLSETEVALUATION_LOADING: {
            return {
                ...state,
                skillsetEvalutionLoading: true,
                skillsetEvalutionError: null
            }
        }
        case SKILLSETEVALUATION_ERROR: {
            return {
                ...state,
                skillsetEvalutionLoading: false,
                skillsetEvalutionError: action.payload
            }
        }

        case CAREERPATH_SUCCESS: {
            return {
                ...state,
                careerPathLoading: false,
                careerPathSuccess: action.payload,
                careerPathError: null
            }
        }
        case CAREERPATH_LOADING: {
            return {
                ...state,
                careerPathLoading: true,
                careerPathError: null
            }
        }
        case CAREERPATH_ERROR: {
            return {
                ...state,
                careerPathLoading: false,
                careerPathError: action.payload
            }
        }

        case MCQS_SUCCESS: {
            return {
                ...state,
                mcqsLoading: false,
                mcqsSuccess: action.payload,
                mcqsError: null
            }
        }
        case MCQS_LOADING: {
            return {
                ...state,
                mcqsLoading: true,
                mcqsError: null
            }
        }
        case MCQS_ERROR: {
            return {
                ...state,
                mcqsLoading: false,
                mcqsError: action.payload
            }
        }

        case CASESTUDY_SUCCESS: {
            return {
                ...state,
                caseStudyLoading: false,
                caseStudySuccess: action.payload,
                caseStudyError: null
            }
        }
        case CASESTUDY_LOADING: {
            return {
                ...state,
                caseStudyLoading: true,
                caseStudyError: null
            }
        }
        case CASESTUDY_ERROR: {
            return {
                ...state,
                caseStudyLoading: false,
                caseStudyError: action.payload
            }
        }

        case CODINGSTIMULATION_SUCCESS: {
            return {
                ...state,
                codingSimulationLoading: false,
                codingSimulationSuccess: action.payload,
                codingSimulationError: null
            }
        }
        case CODINGSTIMULATION_LOADING: {
            return {
                ...state,
                codingSimulationLoading: true,
                codingSimulationError: null
            }
        }
        case CODINGSTIMULATION_ERROR: {
            return {
                ...state,
                codingSimulationLoading: false,
                codingSimulationError: action.payload
            }
        }

        case INTERVIEW_PREPARATION_SUCCESS: {
            return {
                ...state,
                interviewPreparationLoading: false,
                interviewPreparationSuccess: action.payload,
                interviewPreparationError: null
            }
        }
        case INTERVIEW_PREPARATION_LOADING: {
            return {
                ...state,
                interviewPreparationLoading: true,
                interviewPreparationError: null
            }
        }
        case INTERVIEW_PREPARATION_ERROR: {
            return {
                ...state,
                interviewPreparationLoading: false,
                interviewPreparationError: action.payload
            }
        }
        
        case RESULTORIENTED_SUCCESS: {
            return {
                ...state,
                resultOrientedLoading: false,
                resultOrientedSuccess: action.payload,
                resultOrientedError: null
            }
        }
        case RESULTORIENTED_LOADING: {
            return {
                ...state,
                resultOrientedLoading: true,
                resultOrientedError: null
            }
        }
        case RESULTORIENTED_ERROR: {
            return {
                ...state,
                resultOrientedLoading: false,
                resultOrientedError: action.payload
            }
        }

        case FILETOTEXT_SUCCESS: {
            return {
                ...state,
                filetotextLoading: false,
                filetotextSuccess: action.payload,
                filetotextError: null
            }
        }
        case FILETOTEXT_LOADING: {
            return {
                ...state,
                filetotextLoading: true,
                filetotextError: null
            }
        }
        case FILETOTEXT_ERROR: {
            return {
                ...state,
                filetotextLoading: false,
                filetotextError: action.payload
            }
        }
        case GENERATE_EXAM_SUCCESS: {
            return {
                ...state,
                generateExamLoading: false,
                generateExamSuccess: action.payload,
                generateExamError: null
            }
        }
        case GENERATE_EXAM_LOADING: {
            return {
                ...state,
                generateExamLoading: true,
                generateExamError: null
            }
        }
        case GENERATE_EXAM_ERROR: {
            return {
                ...state,
                generateExamLoading: false,
                generateExamError: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default reducer;