import { Button } from '@mui/material';
import Images from '../../constants/Images';
import './pricing.scss';
import { useEffect, useState, } from 'react';
import { getPricingDetails, initiatePayment, updateTransaction } from './action';
import { connect, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { EASEBUZZKEY } from '../../utilities/constants';
import Loader from '../../components/Loader';

function Pricing(props) {
    const navigate = useNavigate();
    const { pricingDetails } = props;
    const { loading } = useSelector((state) => state.CurriculumReducer);
    const { initialPaymentSuccess, initialPaymentError, initialPaymentLoading, updatePaymentLoading } = useSelector((state) => state.pricingReducer);
    const { userDetails } = useSelector((state) => state.loginReducer);


    useEffect(() => {
        props.getPricingDetails()
    }, []);

    const initiatePayment = async (access_key, product_id) => {
        try {
            var options = {
                access_key, // access key received via Initiate Payment
                onResponse: (response) => {
                    if (typeof response != 'undefined' && response.status === 'success') {
                        props.updateTransaction({
                            transaction_id : response.txnid,
                            product_id
                        }).then(res => {
                            toast.success("Order placed Successfully");
                            navigate("/success-order");
                        }).catch(error => {
                            toast.error(error || "Error in processing payment");
                        });
                    } else {
                        toast.error("Error in processing payment");
                    }
                },
                theme: "#123456" // color hex
            }
            var easebuzzCheckout = new window.EasebuzzCheckout(EASEBUZZKEY, 'prod')
            easebuzzCheckout.initiatePayment(options);
        } catch (error) {
            // Handle any errors that occur during the API request
            toast.error("Error in processing payment");
        }
    };

    const handlePayment = async ({ id }) => {
        try {
            const res = await props.initiatePayment({
                product_id: id, success_url: "https://www.upskillr.ai/",
                failure_url: "https://www.upskillr.ai/"
            }).catch(error => {
                toast.error(error || "Error in initiate payment");
            });

            if(res?.data?.status === 1){
                initiatePayment(res.data?.data, id)
            }

        } catch (error) {
            toast.error(error || "Error to initiate payment")
        }
    };

    // console.log('pricingDetails',pricingDetails)
    return (
        <>
            {loading && <Loader />}
            <div className="container pricing">
                {(initialPaymentLoading || updatePaymentLoading) && <Loader />}
                <h1 className='title'>Pick your ideal plan</h1>
                <p className='pricin-para'>With our
                    credit system, you have the freedom to choose: redeem and use them across
                    any service that Upskillr.ai offers, ensuring you get the exact experience you seek.</p>
                <div className='row mb-5 mt-5 justify-content-center'>
                    {
                        pricingDetails && pricingDetails.length > 0 ? (
                            pricingDetails?.map((detail, index) => (
                                <>
                                    <div className='col-lg-3 align-items-center mb-3' key={index}>
                                        {/* {detail?.Type === 'Quick Boost' && 
                           <div className='topEnterprice'>
                                MOST POPULAR
                            </div>} */}
                                        {detail?.Type === 'Quick Boost'
                                            ? <div className='topEnterprice'>
                                                MOST POPULAR
                                            </div>
                                            : detail?.Type === 'Monthly Saver'
                                                ? <div className='topEnterprice'>
                                                    MOST VALUABLE
                                                </div>
                                                : null
                                        }
                                        <div className={detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `blueBackground` : `whiteBackground`}>
                                            <h3 style={{ color: detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `#fff` : `` }}>{detail?.Type}</h3>
                                            {
                                                detail?.Type === `Tailored` ? ' ' :
                                            <h1 style={{ color: detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `#fff` : `` }}>Rs {detail.Price}</h1>
                                            }
                                            <h4 dangerouslySetInnerHTML={{ __html: detail?.Description }} style={{ color: detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `#fff` : `` }}></h4>
                                            {detail?.Highlights?.split(",").map((text, i) => (
                                                <div className='d-flex align-item-center resourcePlan'>

                                                    <img className='tickimage' src={detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? Images.WhiteTick : Images.TickBlue} />
                                                    <p key={i} style={{ color: detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `#fff` : `` }}>{text}</p>

                                                </div>
                                            ))}

                                            {/* {
                                            userDetails ?
                                             <Button onClick={detail?.Type === `Tailored` ? null : () => handlePayment(detail) } className={detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver'  ? `btnStyle2` : `btnStyle`} >{detail?.Type === `Tailored`? null :'Pay Now'}</Button> 
                                             : 
                                            <Link to={'/register'} style={{display: "flex", justifyContent: "center"}} className={detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `btnStyle2` : `btnStyle`} >{detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `Get started` :`Sign up for free `}</Link>
                                        } */}

                                            {detail?.Type !== 'Tailored' && (
                                                userDetails ? (
                                                    <Button
                                                        onClick={() => handlePayment(detail)}
                                                        className={detail?.Type === 'Quick Boost' || detail?.Type === 'Monthly Saver' ? 'btnStyle2' : 'btnStyle'}
                                                    >
                                                        Pay Now
                                                    </Button>
                                                ) : (
                                                    <Link
                                                        to={'/register'}
                                                        style={{ display: 'flex', justifyContent: 'center' }}
                                                        className={detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `btnStyle2` : `btnStyle`}
                                                    >
                                                        {detail?.Type === `Quick Boost` || detail?.Type === 'Monthly Saver' ? `Get started` : `Sign up for free `}
                                                    </Link>
                                                )
                                            )}

                                            {
                                                detail?.Type === `Tailored` && <Link to={'/pricing/contact-sales'} style={{
                                                    display: "flex", justifyContent: "center"
                                                }} className={`btnStyle`}>Let's Chat</Link>
                                            }
                                        </div>
                                    </div>
                                </>
                            ))
                        ) : (
                            <div className="text-center mb-4">
                                <h2>No pricing data available</h2>
                            </div>
                        )
                    }

                </div>
            </div>
        </>
    );
}


const mapStateToProps = ({ pricingReducer }) => {
    const { pricingDetails } = pricingReducer;
    return { pricingDetails };
};

export default connect(mapStateToProps, {
    getPricingDetails,
    initiatePayment,
    updateTransaction
})(Pricing);

// export default Pricing;