import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
import { FAILUR, PROCCESSING, STOPPROCESSING } from "../../utilities/constants";
import { toast } from "react-toastify";
export const THINGS_WATCH = 'THINGS_WATCH';
export const THINGS_READ = 'THINGS_READ';
export const EMPTYSKILLMAP = 'EMPTYSKILLMAP';
export const THINGS_TODO = 'THINGS_TODO';
export const ACTIONS= "ACTIONS"

export const getThingstoWatch = (search, difficulty, length) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.skillMapWatch}?topic=${search}&difficulty=${difficulty}&length=${length}`,
    };
    
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({
                type: THINGS_WATCH,
                payload: response?.thingsToWatch
            })
            dispatch({ type: STOPPROCESSING });
        })
        .catch((error) => {
            dispatch({type: FAILUR, payload: error})
            dispatch({ type: STOPPROCESSING });
        });
};

export const getThingsToRead = (search, industry) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.skillMapRead}?input=${search}&input_2=${encodeURIComponent(industry)}`,
    };
    
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({
                type: THINGS_READ,
                payload: response?.thingsToRead
            })
            dispatch({ type: STOPPROCESSING });
        })
        .catch((error) => {
            dispatch({type: FAILUR, payload: error})
            dispatch({ type: STOPPROCESSING });
        });
};


export const getThingsToDo = (search, params) => async (dispatch) => {
    const queryParams = new URLSearchParams(params)
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.skillMapToDo}?skill=${search}&${queryParams}`,
    };
    
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({
                type: THINGS_TODO,
                payload: response
            })
            dispatch({ type: STOPPROCESSING });
        })
        .catch((error) => {
            dispatch({type: FAILUR, payload: error})
            dispatch({ type: STOPPROCESSING });
        });
};

export const skillMapActions = (postData) => async (dispatch) => {
    console.log('postData', postData)
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.skillMap_actions}`,
        data: postData
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
        })
        .catch((error) => {
            dispatch({ type: FAILUR, payload: error })
            dispatch({ type: STOPPROCESSING });
            throw error.message || 'Something went wrong';
        });
};


