import "../blogs/Blogs.scss"
import Images from "../../constants/Images";
import React, { useState, useEffect } from 'react';
import { getBlog } from './action';
import { connect, useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import Loader from '../../components/Loader';



function Blogs(props) {

  const { loading } = useSelector((state)=> state.CurriculumReducer);
  const [blog, setBlog] = useState([]);
  // const latestBlog = blog.reverse();
  const [initialBlogsToShow, setInitialBlogsToShow] = useState(6);
  // const [latestBlog, setLatestBlog] = useState([]);
  const [blogsPerLoad, setBlogsPerLoad] = useState(6); 
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    props.getBlog()
  }, []);

  useEffect(() => {

    if ((props.blogData).length > 0) {
      const latestBlog = props.blogData.reverse();
      setBlog(latestBlog);
    }

  }, [props.blogData]);

  function truncateString(string) {
    if (string.length <= 150) {
      return string;
    }
  
    let substring = string.slice(0, 150);
    let lastSpaceIndex = substring.lastIndexOf(' ');
    if (lastSpaceIndex !== -1) {
      if (substring.charAt(lastSpaceIndex - 1) === ',') {
        return substring.slice(0, lastSpaceIndex - 1);
      }
      return substring.slice(0, lastSpaceIndex)+'...';
    } else {
      // If there are no spaces within the substring,
      // return the substring as is.
      return substring;
    }
  }

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    
    const container = document.getElementById('blog-container');
    const section = document.getElementById('blog-section-6');
    
    if (container && section) {
      const containerTop = container.offsetTop;
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      
      const sectionCenter = containerTop + sectionTop + sectionHeight / 2;
      const windowHeight = window.innerHeight;
      
      if (position + windowHeight >= sectionCenter) {
        setInitialBlogsToShow(prevCount => prevCount + blogsPerLoad);
      }
  }
  };
  
  useEffect(() => {
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);  


  const handleClick = (item) => {
    console.log("item in handle",item);
    navigate(`/blog/details/`,{state:item});
  };

  return (
    <>
    {loading && <Loader /> }
    <div className="Blogs">
      <div className='homePageBannerSection mb-4'>
        <div className='bannerTitle'>Where Possibilities Begin</div>
        <div className='bannerSubTitle'>Empower your journey of discovery with Upskillr <br/> your hub for engaging, enriching online learning experiences.</div>
        <div className="aboutusbannerImages" style={{ position: 'relative' }}>
          <img src={Images.BlogBanner} className='homeBanner' style={{ width: '45%', marginTop: 50 }} />
        </div>
      </div>

      {blog?.length > 0 && (
      <div className="container" id="blog-container">
        <div className="row align-items-center justify-content-center d-flex my-5" onClick={() => handleClick(blog?.[0])}>
          <div className="col-md-6" style={{cursor:'pointer'}}>
            <img src={blog?.[0]?.image.media} style={{ width: '90%', height: 419, objectFit: 'fill', borderRadius: 25, marginRight: 20 }} />
          </div>
          <div className="col-md-6">
            <div className="h-100 bg-body-tertiary">
              <div className="blogsCard card-body">
                <h6>{truncateString(blog?.[0]?.heading || '')}</h6>
                <h3>{blog?.[0]?.title}</h3>
                <p className="card-text" dangerouslySetInnerHTML={{ __html: truncateString(blog && blog[0]?.description ||"") }}></p>
                <div className="d-flex align-items-center">
                  {/* <img src={blog?.[0]?.created_by?.image?.media} style={{ width: 40, height: 40, objectFit: 'contain', borderRadius: 20 }} /> */}
                  <div className="d-flex flex-column align-items-flex-start card-bottom-text ml-2">
                    {/* <p className="card-text">{blog?.[0]?.created_by?.username}</p> */}
                    <small className="text-body-secondary">{moment(blog?.[0]?.created_date).format('MMM DD, dddd')}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <h3 className="blogsTitle my-5 text-center">Latest Articles</h3>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" id="blog-section-6">
          {
            blog?.slice(0, initialBlogsToShow).map((item, index) => (
              <div className="col blogsCard" key={item.id}
              onClick={() => handleClick(item)}>
                {/* <a href="/blog/details" target="_blank" style={{textDecoration:'none'}}> */}
                <img src={item.image.media} style={{ width: '100%', height: 225, objectFit: 'fill', borderRadius: 25, cursor:'pointer' }} />
                <div className="card-body blogs-text-part"  style={{cursor:'pointer'}}>
                  <h6>{truncateString(item.heading)}</h6>
                  <h3>{item.title}</h3>
                  <p className="card-text" dangerouslySetInnerHTML={{ __html: truncateString(item?.description) }}></p>
                  <div className="d-flex align-items-center">
                    {/* <img src={item.created_by?.images?.media} style={{ width: 40, height: 40, objectFit: 'contain', borderRadius: 20 }} /> */}
                    <div className="d-flex flex-column align-items-flex-start card-bottom-text ml-2">
                      {/* <p className="card-text">{item?.created_by?.username}</p> */}
                      <small className="text-body-secondary">{moment(item?.created_date).format('MMM DD, dddd')}</small>
                    </div>
                  </div>
                </div>
                {/* </a> */}
              </div>
            ))
          }

          {/* <div className="col blogsCard">
            <img src={Images.CardImage} style={{ width: '100%', height: 225, objectFit: 'fill', borderRadius: 25 }} />
            <div className="card-body blogs-text-part">
              <h6>TECHNOLOGY</h6>
              <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              <p className="card-text">Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
              <div className="d-flex align-items-center">
                <img src={Images.AdityaSaxena} style={{ width: 40, height: 40, objectFit: 'contain', borderRadius: 20 }} />
                <div className="d-flex flex-column align-items-flex-start card-bottom-text ml-2">
                  <p className="card-text">Aditya Saxena</p>
                  <small className="text-body-secondary">06 Feb, 2023</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col blogsCard">
            <img src={Images.CardImage} style={{ width: '100%', height: 225, objectFit: 'fill', borderRadius: 25 }} />
            <div className="card-body blogs-text-part">
              <h6>TECHNOLOGY</h6>
              <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              <p className="card-text">Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
              <div className="d-flex align-items-center">
                <img src={Images.AdityaSaxena} style={{ width: 40, height: 40, objectFit: 'contain', borderRadius: 20 }} />
                <div className="d-flex flex-column align-items-flex-start card-bottom-text ml-2">
                  <p className="card-text">Aditya Saxena</p>
                  <small className="text-body-secondary">06 Feb, 2023</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col blogsCard">
            <img src={Images.CardImage} style={{ width: '100%', height: 225, objectFit: 'fill', borderRadius: 25 }} />
            <div className="card-body blogs-text-part">
              <h6>TECHNOLOGY</h6>
              <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              <p className="card-text">Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
              <div className="d-flex align-items-center">
                <img src={Images.AdityaSaxena} style={{ width: 40, height: 40, objectFit: 'contain', borderRadius: 20 }} />
                <div className="d-flex flex-column align-items-flex-start card-bottom-text ml-2">
                  <p className="card-text">Aditya Saxena</p>
                  <small className="text-body-secondary">06 Feb, 2023</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col blogsCard">
            <img src={Images.CardImage} style={{ width: '100%', height: 225, objectFit: 'fill', borderRadius: 25 }} />
            <div className="card-body blogs-text-part">
              <h6>TECHNOLOGY</h6>
              <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              <p className="card-text">Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
              <div className="d-flex align-items-center">
                <img src={Images.AdityaSaxena} style={{ width: 40, height: 40, objectFit: 'contain', borderRadius: 20 }} />
                <div className="d-flex flex-column align-items-flex-start card-bottom-text ml-2">
                  <p className="card-text">Aditya Saxena</p>
                  <small className="text-body-secondary">06 Feb, 2023</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col blogsCard">
            <img src={Images.CardImage} style={{ width: '100%', height: 225, objectFit: 'fill', borderRadius: 25 }} />
            <div className="card-body blogs-text-part">
              <h6>TECHNOLOGY</h6>
              <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              <p className="card-text">Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
              <div className="d-flex align-items-center">
                <img src={Images.AdityaSaxena} style={{ width: 40, height: 40, objectFit: 'contain', borderRadius: 20 }} />
                <div className="d-flex flex-column align-items-flex-start card-bottom-text ml-2">
                  <p className="card-text">Aditya Saxena</p>
                  <small className="text-body-secondary">06 Feb, 2023</small>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="d-flex align-items-center justify-content-center my-5" >
          <Button className='btnStyle'>Read More</Button>
        </div> */}
      </div>
      )}
    </div>
    </>
  );
}

const mapStateToProps = ({ blogsReducer }) => {
  const {  blogData } = blogsReducer;
  return {
    blogData
  };
}

export default connect(mapStateToProps, {
  getBlog
})(Blogs);
