import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { createMarketplace,updateMarketplace,getMarketplaceDetails } from "../action";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../utilities/constants";
import Sidebar from "../../../Components/Navigation/Sidebar";
import Topbar from "../../../Components/Navigation/Topbar";
import PageHeading from "../../../Components/PageHeading";
import { toast } from "react-toastify";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { useFieldArray, useForm } from "react-hook-form";
import Images from "../../../../constants/Images";
import { uploadImage } from "../../../action";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const UpdateMarketplace = (props) => {
  
  const [type, setType] = React.useState('active');
  const fileInputRef = useRef();
  const formRef = useRef(null);
  const history = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const { state } = useLocation();
  const [name, setName] = useState('');
  const [image_id, setImageId] = useState('');

    useEffect(() => {
    if (state?.id) {
      props.getMarketplaceDetails(state.id);
    }
  }, [state?.id]);

  useEffect(() => {
    if(props?.getDetails){
      setName(props.getDetails.name);
      setImageId(props.getDetails.image.id);
      setPreview(props.getDetails.image.media);
    }
  }, [props?.getDetails]);

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   control,
  //   setValue,
  //   formState: { errors, isDirty, isValid },
  // } = useForm();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataItem = {
      name,
    };
    if (!name || (!state?.id && !selectedFile )) {
      toast.error('All fields are required.');
      return;
    }
    if (state?.id) {
      let uploadedImage;
      if(selectedFile){
        let formData = new FormData();
        formData.append('media', selectedFile);
        uploadedImage = await props.uploadImage(formData)
      }
      
      props.updateMarketplace(formDataItem, state?.id, selectedFile ? uploadedImage.data.id : image_id)
          .then(res => {
            toast.success('MarketPlace updated successfully');
            history(`/admin/content/marketplace`);
          }).catch(error => {
            toast.error(error || "Error in add MarketPlace");
          });

    } else {
      // onAdd(newItem);
        let formData = new FormData();
        formData.append('media', selectedFile);
        props.uploadImage(formData)
          .then((res) => {
            const imageId = res.data.id;
            props.createMarketplace(formDataItem, imageId)
              .then(res => {
                toast.success('MarketPlace added successfully');
                history(`/admin/content/marketplace`);
              }).catch(error => {
                toast.error(error || "Error in add MarketPlace");
              });
          }).catch((error) => {
            console.log("Image Required");
          });

      } 
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  


  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content" className="addMarketPlace">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Add New"
                buttonName=""
                buttonLink=""
                buttonIcon=""
              />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form ref={formRef} method="post" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group">
                        <Form.Group >
                          <Form.Label className="text-capitalize">
                            Name
                          </Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                        <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Add Image
                          </Form.Label>
                        </Form.Group>
                        {selectedFile ? (
                          <img src={preview} className="imgthumb"style={{width:150,height:150,objectFit:'contain'}} />
                        ) : (
                          props?.getDetails && (
                            <img
                              src={`${props?.getDetails?.image?.media}`}
                              className="imgthumb"
                              style={{width:150,height:150,objectFit:'contain'}}
                            />
                          )
                        )}
                      </div>
                      <div className="">
                        <Form.Group className="mb-3">
                          <a
                            className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <i
                              className={`fas fa-plus fa-sm text-white-50 p-1`}
                            ></i>
                          </a>
                          <Form.Control
                            type="file"
                            className="form-control"
                            accept='image/*'
                            placeholder=""
                            onChange={onSelectFile}
                            ref={fileInputRef}
                            hidden
                          />
                        </Form.Group>
                      </div>
                      {/* <div className="form-group">
                        <Form.Group >
                          <Form.Label className="text-capitalize">Type</Form.Label>
                          <Select
                            displayEmpty
                            value={type}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length !== 0) {
                              //   return <em className="type_placeholder">Please select status...</em>;
                              return selected[0].toUpperCase() + selected.substring(1);
                            }
                            return selected;
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="form-control select-div"
                            {...register("status")}
                          >
                            <MenuItem value={'active'} className="text-capitalize">
                              active
                            </MenuItem>
                            <MenuItem value={'inactive'} className="text-capitalize">
                              inactive
                            </MenuItem>
                          </Select>
                        </Form.Group>
                      </div> */}
                      <div className=" mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminGetMarketDetails }) => {
  const { getDetails } = adminGetMarketDetails;
  console.log('getDetails',getDetails)
  return {
    getDetails,
  };
};

export default connect(mapStateToProps, {
  createMarketplace,
  uploadImage,
  updateMarketplace,
  getMarketplaceDetails,
})(UpdateMarketplace);

