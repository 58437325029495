import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Button } from '@mui/material';
import ListGroup from 'react-bootstrap/ListGroup';
import './index.scss'
import Images from "../../constants/Images";
import Modal from 'react-bootstrap/Modal';

function UploadFile(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='UploadFIle' style={{position:'relative'}}>
                <div className='tabContentHeaderBox'>
                    <h2 className='pageHeading'>Include your material to enhance the capabilities of the upskillr assistant.</h2>
                    <p className='tabContentSubHeading'>Provide prompt and intelligent solutions to prevent customers, employees, and followers from repeating inquiries and getting overwhelmed with extensive materials. </p>
                    <div className="uploadContainer searchBoxArea">
                        <div>
                        <TextField label="" placeholder='Input your Link here' className='assistantTextInput'  classes={{ root: 'placeholderSTyle', notchedOutline: 'oulineNone' }} InputProps={{
                            type: 'search',
                        }} value={props?.youtubeLink} onChange={props?.setYoutubeLink} />
                        </div>
                        <div className="form_OR">
                        -- OR --
                        </div>
                        <div>
                            <Button className='uploadButton' style={{ color: ' #FFAF0D', backgroundColor: '#FFF8E6', }} onClick={() => props.filePicker()}>{`Upload File`}</Button>
                        </div>
                        {/* <Button className='AddnewButton' style={{ color: ' #FFAF0D',backgroundColor: 'rgb(255, 248, 230)' }} onClick={() => console.log('button CLicked')}>{`+ Add new Link / File`}</Button> */}
                    </div>
                    <p className='tabContentSubHeadingUpload' onClick={handleShow}>What Content can I upload?</p>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button style={{ backgroundColor: '#004AAD', color: 'white', textTransform: 'capitalize',borderRadius:12 }} onClick={props?.handleClick} className='white'>Process</Button>
                    </div>
                </div>
                <img src={Images.leftLineVector} style={{ position: 'absolute', left: '-12%', height: 234, top: '-20%', zIndex: -10 }} />
                <img src={Images.RightLineVector} style={{ position: 'absolute', right: '-1%', height: 350, top: '-23%', zIndex: -10 }} />
            </div>

            <Modal aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose} animation>
                <ListGroup className="modalList">
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.UploadFileIcon} className='listIcons' />
                            <div className='infomationMoney' style={{fontWeight:'500'}}>{`Make sure your files are`}</div>  
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.TickCheckbox} className='listIcons' />
                            <div className='infomationMoney' style={{ fontWeight:'500'}}>{`.docx  /.pdf /.csv /.xlsx`} </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.TickCheckbox} className='listIcons' />
                            <div style={{ fontWeight:'500'}} className='infomationMoney'>{`Not more than 10MB`}</div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.WrongCheckBox} className='listIcons' />
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '500' }}>{`Not`} <div className='infomationMoney' style={{ marginLeft: 5, }} >handwritten</div> </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.WrongCheckBox} className='listIcons' />
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '500' }}>{`Not`} <div className='infomationMoney' style={{ marginLeft: 5, }} >scanned</div> </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.WrongCheckBox} className='listIcons' />
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '500' }}>{`Not`} <div className='infomationMoney' style={{ marginLeft: 5,fontWeight: '700' }} >password</div> <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500'}} >protected</div> </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.UploadLinkIcon} className='listIcons' />
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '700' }}>Make<div className='infomationMoney' style={{ marginLeft: 5,fontWeight: '500' }} > sure your </div><div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '700'}} >links</div><div className='infomationMoney' style={{ marginLeft: 5,fontWeight: '500' }} >are</div> </div>
                        </div>
                    </ListGroup.Item>                    
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.TickCheckbox} className='listIcons' />
                            <div className='infomationMoney' style={{ fontWeight:'500'}}>{`Youtube video links (English videos only)`} </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'flex-start', }}>
                            <img src={Images.TickCheckbox} className='listIcons' />
                            <div style={{ display: 'flex',flexDirection:'column',marginTop:-4}}>
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '500' }}>For a website with<div className='infomationMoney' style={{ marginLeft: 5,fontWeight: '700' }} >text</div> <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500'}} >content (HTML pages only)</div> </div>
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '500' }}>(Images and hosted files will be ignored)</div>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'flex-start', }}>
                            <img src={Images.TickCheckbox} className='listIcons' />
                            <div style={{ display: 'flex',flexDirection:'column',marginTop:-4}}>
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '500' }}>For the specific<div className='infomationMoney' style={{ marginLeft: 5,fontWeight: '700' }} >webpage</div> <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500'}} >you want content from</div> </div>
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '500' }}>(we won’t fetch all the links on a site, for example)</div>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <img src={Images.WrongCheckBox} className='listIcons' />
                            <div className='infomationMoney' style={{ display: 'flex', fontWeight: '500' }}>Does not require a<div className='infomationMoney' style={{ marginLeft: 5,fontWeight: '700' }} >login or password</div> <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500'}} >to access</div> </div>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
                <div className="button" style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:   32,marginTop:25}}>
                    <Button style={{ backgroundColor: '#004AAD', color: 'white', textTransform: 'capitalize' }} className='white' onClick={handleClose} >{`Got It`}</Button>
                </div>
                
            </Modal>

        </>

    );
}

export default UploadFile;