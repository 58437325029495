import {
  USERS_LIST,
  ADD_USERS,
  DELETE_USERS,
  UPDATE_USERS,
  GET_USER_DETAIL,
} from "./action";

const initialState = {
  allUsersList: null,
  createUser: null,
  deleteUser: null,
  updateUser: null,
  userDetail : null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_LIST: {
      return {
        ...state,
        allUsersList: action.payload,
      };
    }
    case ADD_USERS: {
      return {
        ...state,
        createUser: action.payload,
      };
    }
    case DELETE_USERS: {
      return {
        ...state,
        deleteUser: action.payload,
      };
    }
    case UPDATE_USERS: {
      return {
        ...state,
        updateUser: action.payload,
      };
    }
    case GET_USER_DETAIL: {
      return {
        ...state,
        userDetail: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
