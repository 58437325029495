import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
import { FAILUR, PROCCESSING } from "../../utilities/constants";
import { toast } from "react-toastify";
import UpskillrUtils from "../../utilities/UpskillrUtils";
export const CURRICULUM_DETAILS = 'CURRICULUM_DETAILS'
export const CURRICULUM_TOPIC_LIST = 'CURRICULUM_TOPIC_LIST'
export const SUCCESS_UPDATED_COURSE = 'SUCCESS_UPDATED_COURSE';
export const COURSE_DELETED_SUCCESS = 'COURSE_DELETED_SUCCESS';
export const THINGS_TO_WATCH_SUCCESS = 'THINGS_TO_WATCH_SUCCESS';
export const THINGS_TO_READ_SUCCESS = 'THINGS_TO_READ_SUCCESS';
export const GET_SUGGESTION_LIST_SUCCESS = 'GET_SUGGESTION_LIST_SUCCESS';
export const SEARCH_CURRICULAM_SUCCESS = 'SEARCH_CURRICULAM_SUCCESS';
export const GET_RECOMMENDED_LIST_SUCCESS = 'GET_RECOMMENDED_LIST_SUCCESS';
export const RECOMMENDED_COURSE_DELETED_SUCCESS = 'RECOMMENDED_COURSE_DELETED_SUCCESS';
export const SUCCESS_UPDATED_RECOMMENDED = 'SUCCESS_UPDATED_RECOMMENDED';
export const GENERATE_CURRICULAM_SUCCESS = 'GENERATE_CURRICULAM_SUCCESS';
export const GENERATE_MCQS_SUCCESS = 'GENERATE_MCQS_SUCCESS';
export const GENERATE_MCQS_ERROR = 'GENERATE_MCQS_ERROR';
export const GENERATE_MCQS_LOADING = 'GENERATE_MCQS_LOADING';
export const GENERATE_TOPICS_SUCCESS = 'GENERATE_TOPICS_SUCCESS';
export const GENERATE_TOPICS_ERROR = 'GENERATE_TOPICS_ERROR';
export const GENERATE_SUBTOPIC_SUCCESS = 'GENERATE_SUBTOPIC_SUCCESS';
export const GENERATE_SUBSUBTOPIC_SUCCESS = 'GENERATE_SUBSUBTOPIC_SUCCESS';
export const CURRICULAM_BY_USER_SUCCESS = 'CURRICULAM_BY_USER_SUCCESS';
export const CREATE_CURRICULUM_SUCCESS = 'CREATE_CURRICULUM_SUCCESS';
export const CREATE_CURRICULUM_ERROR = 'CREATE_CURRICULUM_ERROR';
export const CREATE_CURRICULUM_LOADING = 'CREATE_CURRICULUM_LOADING';
export const CHECK_USER_MCQ_SUCCESS = 'CHECK_USER_MCQ_SUCCESS';
export const CHECK_USER_MCQ_ERROR = 'CHECK_USER_MCQ_ERROR';
export const CHECK_USER_MCQ_LOADING = 'CHECK_USER_MCQ_LOADING';
export const SEE_RESULT_SUCCESS = 'SEE_RESULT_SUCCESS';
export const SEE_RESULT_ERROR = 'SEE_RESULT_ERROR';
export const SEE_RESULT_LOADING = 'SEE_RESULT_LOADING';
export const VIEW_DETAIL_SUCCESS = 'VIEW_DETAIL_SUCCESS';
export const VIEW_DETAIL_ERROR = 'VIEW_DETAIL_ERROR';
export const VIEW_DETAIL_LOADING = 'VIEW_DETAIL_LOADING';
export const SUBMIT_MCQ_SUCCESS = 'SUBMIT_MCQ_SUCCESS';
export const SUBMIT_MCQ_ERROR = 'SUBMIT_MCQ_ERROR';
export const SUBMIT_MCQ_LOADING = 'SUBMIT_MCQ_LOADING';
export const GENERATE_CURRICULUM_LOADING = 'GENERATE_CURRICULUM_LOADING';
export const GENERATE_CURRICULUM_TOC_LOADING = 'GENERATE_CURRICULUM_TOC_LOADING';
export const GENERATE_CURRICULUM_TOC_SUCCESS = 'GENERATE_CURRICULUM_TOC_SUCCESS';
export const ACTIVITY_MATRIX = 'ACTIVITY_MATRIX';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_ERROR = 'CREATE_COURSE_ERROR';
export const CREATE_COURSE_LOADING = 'CREATE_COURSE_LOADING';

export const getAllCurriculumBuilder = (params) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getAllCurriculum,
    };
    if(params.search){
        data.url +="?search="+params.search
    }
    if(params.limit){
        data.url +="?limit="+params.limit+'&offset='+(params.offset?params.offset:0)
    }
    if(params.next){
        data.url =params.next
    }
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {

                 dispatch({
                    type: CURRICULUM_DETAILS,
                    payload: response,
                });

            }
        })
        .catch((error) => {
            throw error?.response?.message;
        });
};

function sortHierarchyBySrNoAscending(array) {
    array.sort((a, b) => a.Sr_No - b.Sr_No);
    array.forEach((obj) => {
      if (obj.SubTopics) {
        sortHierarchyBySrNoAscending(obj.SubTopics);
      }
      if (obj.SubSubTopics) {
        sortHierarchyBySrNoAscending(obj.SubSubTopics);
      }
    });
  }

export const getCurriculamByUserId = (params) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.curriculum,
    };
    data.url = await UpskillrUtils.buildUrlWithParams(data.url, params);
    dispatch({ type: PROCCESSING });
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: CURRICULAM_BY_USER_SUCCESS,
                payload: response,
            });
        }
    }).catch((error) => {
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}

export const getCurriculumTopicByID = (id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.curriculum +id+'/',
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {
                sortHierarchyBySrNoAscending(response.Topics)
                 dispatch({
                    type: CURRICULUM_TOPIC_LIST,
                    payload: response,
                });

            }
        }).catch((error) => {
            throw error;
        });
};

export const updateCurriculumByID = (id, formData, setShowModal, setListArray, limit, offset) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.curriculum + id + '/',
        data:formData
    };
    dispatch({ type: PROCCESSING });
    return api(data).then(async (response) => {
        if (response) {
            if(setShowModal) setShowModal(false);
            toast.success("Data updated successfully");
            dispatch({
                type: SUCCESS_UPDATED_COURSE,
                payload: response,
            });
            // setListArray([]);
            // dispatch(getCurriculumBuilder({ limit: parseInt(limit), offset: parseInt(offset) }));
            // setTimeout(()=>{
            //     window.location.reload();
            // },500)
        }
    }).catch((error) => {
        toast.error(error.response.data.message || "Error in updating data");
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}
export const deleteCurriculumByID = (id, setListArray, limit, offset) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.delete,
        url: API_ENDPOINTS.curriculum + id + '/',
    };
    dispatch({ type: PROCCESSING });
    return api(data).then(async (response) => {
        if (response) {
            toast.success("Data deleted successfully");
            dispatch({
                type: COURSE_DELETED_SUCCESS,
                payload: response,
            });
            // setListArray([]);
            setTimeout(()=>{
                window.location.reload();
            },5000)
            // dispatch(getCurriculumBuilder({ limit: parseInt(limit), offset: parseInt(offset) }));
        }
    }).catch((error) => {
        toast.error(error.response.data.message || "Error in deleting data");
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}

export const getThingToWatchList = (params) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.things_to_watch((params?.topic)?params.topic:'', (params?.difficulty)?params.difficulty:'', (params?.length)?params.length:3),
    };
    dispatch({ type: PROCCESSING });
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: THINGS_TO_WATCH_SUCCESS,
                payload: response,
            });
        }
    }).catch((error) => {
        toast.error(error.response.data.message || "Getting error in fetching things to watch data");
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}
export const getThingToRead = (params) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.things_to_read,
    };
    if(params){
        if(params.input)data.url +='?input='+params.input;
        if(params.input && params.input_2)data.url+='&input_2='+params.input_2;
        if(!params.input && params.input_2)data.url+='?input_2='+params.input_2;
    }
    dispatch({ type: PROCCESSING });
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: THINGS_TO_READ_SUCCESS,
                payload: response,
            });
        }
    }).catch((error) => {
        toast.error(error.response.data.message || "Getting error in fetching things to watch data");
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}

export const searchCurriculam = (params) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.curriculum,
    };
    if(params){
            data.url +='?search='+params;
            dispatch({ type: PROCCESSING });
            return api(data).then(async (response) => {
            if (response) {
                dispatch({
                    type: SEARCH_CURRICULAM_SUCCESS,
                    payload: response,
                });
            }
        }).catch((error) => {
            dispatch({
                type: FAILUR,
                payload: error,
            });
        });
    }
}

export const getSuggestionList = (params) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.curriculum,
    };
    if(params){
        data.url +='?suggest='+params;
    }
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: GET_SUGGESTION_LIST_SUCCESS,
                payload: response,
            });
        }
    }).catch((error) => {
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}

export const getRecommendedList = (params) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.get_recommended,
    };
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: GET_RECOMMENDED_LIST_SUCCESS,
                payload: response,
            });
        }
    }).catch((error) => {
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}

export const deleteRecommendedByID = (id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.delete,
        url: API_ENDPOINTS.get_recommended + id + '/',
    };
    dispatch({ type: PROCCESSING });
    return api(data).then(async (response) => {
        if (response) {
            toast.success("Data deleted successfully");
            dispatch({
                type: RECOMMENDED_COURSE_DELETED_SUCCESS,
                payload: response,
            });
        }
    }).catch((error) => {
        toast.error(error.response.data.message || "Error in deleting data");
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}

export const updateRecommendedByID = (id, formData, setShowModal) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.get_recommended + id + '/',
        data:formData
    };
    dispatch({ type: PROCCESSING });
    return api(data).then(async (response) => {
        if (response) {
            if(setShowModal) setShowModal(false);
            toast.success("Data updated successfully");
            dispatch({
                type: SUCCESS_UPDATED_RECOMMENDED,
                payload: response,
            });
        }
    }).catch((error) => {
        toast.error(error.response.data.message || "Error in updating data");
        dispatch({
            type: FAILUR,
            payload: error,
        });
    });
}

export const generateNewCurriculam = (formData,setIsTextLoading) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.generate_new_curriculum,
        data:formData
    };
    dispatch({ type: GENERATE_CURRICULUM_LOADING });
return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: GENERATE_CURRICULAM_SUCCESS,
                payload: response,
            });
            if(setIsTextLoading) setIsTextLoading(false);
            return response.data
        }
    }).catch((error) => {
        dispatch({
            type: FAILUR,
            payload: error,
        });
        throw error;
    });
}

export const generateNewCurriculamtoc = (formData, setIsTextLoading) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.generate_new_curriculum_toc,
        data: formData
    };
    dispatch({ type: GENERATE_CURRICULUM_TOC_LOADING });
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: GENERATE_CURRICULUM_TOC_SUCCESS,
                payload: response,
            });
            if (setIsTextLoading) setIsTextLoading(false);
            return response.data
        }
    }).catch((error) => {
        dispatch({
            type: FAILUR,
            payload: error,
        });
        throw error;
    });
}

export const createCurriculam = (formData) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.create_new_curriculum,
        data:formData
    };
    dispatch({ type: CREATE_CURRICULUM_LOADING });
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: CREATE_CURRICULUM_SUCCESS,
                payload: response,
            });
            return response.data;
        }
    }).catch((error) => {
        dispatch({
            type: CREATE_CURRICULUM_ERROR,
            payload: error,
        });
        throw 'Error in generating Curriculum'
    });
}

export const generateMcQs = (payload) => async (dispatch) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.generate_mcq,
            data: payload
        };
        dispatch({
            type: GENERATE_MCQS_LOADING,
            payload: { generateMcqsLoading: true },
        });
        return api(data)
            .then(async (response) => {
                if (response) {
                    dispatch({
                        type: GENERATE_MCQS_SUCCESS,
                        payload: response.data,
                    });
                    return response.data;
                }
            }).catch((error) => {
                dispatch({
                    type: GENERATE_MCQS_ERROR,
                    payload: error?.message || 'Internal Server Error'
                });
                throw error.message;
            });
    } catch (err) {
        console.log("Error", err);
        dispatch({
            type: GENERATE_MCQS_ERROR,
            payload: err?.message || 'Internal Server Error'
        });
        throw err.message;
    }
}

export const generateTopics = (payload) => async (dispatch) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.generate_topics,
            data: payload
        };
        dispatch({ type: PROCCESSING });
        return api(data)
            .then(async (response) => {
                if (response) {
                    dispatch({
                        type: GENERATE_TOPICS_SUCCESS,
                        payload: response.data,
                    });
                    return response.data;
                }
            }).catch((error) => {
                dispatch({
                    type: GENERATE_TOPICS_ERROR,
                    payload: error?.message || 'Internal Server Error'
                });
                throw error.message;
            });
    } catch (err) {
        console.log("Error", err);
        dispatch({
            type: GENERATE_TOPICS_ERROR,
            payload: err?.message || 'Internal Server Error'
        });
        throw err.message;
    }
}

export const generateSubTopics = (payload, indices, setShowModal) => async (dispatch) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.generate_subTopics,
            data: payload
        };
        dispatch({ type: PROCCESSING });
        return api(data)
            .then(async (response) => {
                if (response) {
                    if(setShowModal) setShowModal(false);
                    toast.success("Sub topic generated successfully");
                    dispatch({
                        type: GENERATE_SUBTOPIC_SUCCESS,
                        payload: response.data,
                        indices: indices
                    });
                    return response.data;
                }
            }).catch((error) => {
                dispatch({
                    type: FAILUR,
                    payload: error?.message || 'Internal Server Error'
                });
                toast.error(error?.message || 'Error to generate sub topic');
                throw error.message;
            });
    } catch (err) {
        dispatch({
            type: FAILUR,
            payload: err?.message || 'Internal Server Error'
        });
        toast.error(err?.message || 'Internal Server Error');
        throw err.message;
    }
}

export const generateSubSubTopics = (payload, indices, setShowModal) => async (dispatch) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.generate_subSubTopics,
            data: payload
        };
        dispatch({ type: PROCCESSING });
        return api(data)
            .then(async (response) => {
                if (response) {
                    if(setShowModal) setShowModal(false);
                    toast.success("Sub sub topic generated successfully");
                    dispatch({
                        type: GENERATE_SUBSUBTOPIC_SUCCESS,
                        payload: response.data,
                        indices: indices
                    });
                    return response.data;
                }
            }).catch((error) => {
                dispatch({
                    type: FAILUR,
                    payload: error?.message || 'Internal Server Error'
                });
                toast.error(error?.message || 'Error to generate sub sub topic');
                throw error.message;
            });
    } catch (err) {
        dispatch({
            type: FAILUR,
            payload: err?.message || 'Internal Server Error'
        });
        toast.error(err?.message || 'Internal Server Error');
        throw err.message;
    }
}

export const getCheckMcq = (mcqId) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getCheckMcq + mcqId + '/',
    };
    dispatch({ type: CHECK_USER_MCQ_LOADING });
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: CHECK_USER_MCQ_SUCCESS,
                payload: response.data,
            });
            return response.data;
        }
    }).catch((error) => {
        dispatch({
            type: CHECK_USER_MCQ_ERROR,
            payload: error,
        });
        throw error;
    });
}

export const getSeeResult = (mcqId) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.getSeeResult}?mcq_id=${mcqId}`,
    };
    dispatch({ type: SEE_RESULT_LOADING });
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: SEE_RESULT_SUCCESS,
                payload: response.data,
            });
            return response.data;
        }
    }).catch((error) => {
        dispatch({
            type: SEE_RESULT_ERROR,
            payload: error,
        });
        throw error;
    });
}

export const getViewResult = (mcqId) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.getViewResult}${mcqId}/`,
    };
    dispatch({ type: VIEW_DETAIL_LOADING });
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: VIEW_DETAIL_SUCCESS,
                payload: response.data,
            });
            return response.data;
        }
    }).catch((error) => {
        dispatch({
            type: VIEW_DETAIL_ERROR,
            payload: error,
        });
        throw error;
    });
}

export const submitMcqTest = (payload) => async (dispatch) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.submitMcqTest,
            data: payload
        };
        dispatch({
            type: SUBMIT_MCQ_LOADING
        });
        return api(data)
            .then(async (response) => {
                if (response) {
                    dispatch({
                        type: SUBMIT_MCQ_SUCCESS,
                        payload: response.data,
                    });
                    return response.data;
                }
            }).catch((error) => {
                dispatch({
                    type: SUBMIT_MCQ_ERROR,
                    payload: error?.message || 'Internal Server Error'
                });
                throw error.message;
            });
    } catch (err) {
        console.log("Error", err);
        dispatch({
            type: SUBMIT_MCQ_ERROR,
            payload: err?.message || 'Internal Server Error'
        });
        throw err.message;
    }
}

export const activityMatrix = (search) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.activity_matrix}`,
        data: search
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({
                type: ACTIVITY_MATRIX,
                payload: response
            })
        })
        .catch((error) => {
            dispatch({ type: FAILUR, payload: error })
            throw error.message || 'Something went wrong';
        });
};

export const createCourse = (payload) => async (dispatch) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.createCourse,
            data: payload
        };
        dispatch({ type: CREATE_COURSE_LOADING });
        return api(data)
            .then(async (response) => {
                if (response) {
                    dispatch({
                        type: CREATE_COURSE_SUCCESS,
                        payload: response.data
                    });
                    return response.data;
                }
            }).catch((error) => {
                dispatch({
                    type: CREATE_COURSE_ERROR,
                    payload: error,
                });
                throw error;
            });
    } catch (err) {
        dispatch({
            type: CREATE_COURSE_ERROR,
            payload: err?.message || 'Internal Server Error'
        });
        throw err.message;
    }
}