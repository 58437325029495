import React from 'react';
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import "../cofounder/CoFounderCard.css"
import CoFounderCard from './CofounderCard';

function Cofounder(props) {

    
    // const { cofounder } = props;


    return (
        <>
            <section className='CofounderContainer' style={{ marginBottom: 50 }}>
                <div className='container'>
                    <h1 className='section_title' style={{ color: Colors.dark_blue, marginBottom: 60 }}>Meet the Founding Team</h1>
                    {/* {cofounder.map((el, i) =>(
                    <div className={el.id === 1? 'AdityaSaxena':'VikramPandyaConatiner'}>
                        <CoFounderCard key={i}
                            founderName={el.founderName}
                            description={el.description}
                            tagLine={el.tagLine}
                            founderImage={Images.AdityaSaxena}
                            LinkedInLink={el.LinkedInLink}
                        />
                    </div>
                    ))} */}
                    <div className='AdityaSaxena'>
                        <CoFounderCard
                            founderName={'Aditya Saxena'}
                            description={`Aditya has done his undergraduation in Computer Science from BITS Pilani'23. With 2 patents in Applied AI and expertise in Natural Language Processing, Aditya is poised to revolutionize the tech industry.`}
                            tagLine={'Founder'}
                            founderImage={Images.AdityaSaxena}
                            LinkedInLink={'https://www.linkedin.com/in/aditya-saxena-09a50719b/'}
                        />
                    </div>
                    <div className='VikramPandyaConatiner'>
                        <CoFounderCard
                            founderName={'Vikram Pandya'}
                            description={`Vikram is Director FinTech at SP Jain School of Global Management where he has designed Asia’s first interactive classroom led FinTech program featuring Blockchain, API Banking, AI/ML and IoT labs. He is also heading research arm of Fintech VC Fund Varanium.`}
                            tagLine={'Advisor'}
                            founderImage={Images.VikramPandya}
                            LinkedInLink={'https://www.linkedin.com/in/vikrampandya/'}
                        />
                        {/* <CoFounderCard
                         founderName={'Vikram Pandya'}
                         description={`Vikram is Director FinTech at SP Jain School of Global Management where he has designed Asia’s first interactive classroom led FinTech program featuring Blockchain, API Banking, AI/ML and IoT labs. He is also heading research arm of Fintech VC Fund Varanium.`}
                         tagLine={'Co-founder & Advisor'}
                         founderImage={Images.VikramPandya}
                    /> */}
                    </div>

                </div>
            </section>
        </>
    );
}

export default Cofounder;