import { GET_OURPARTNER,ADD_OURPARTNER,UPDATE_OURPARTNER,DELETE_OURPARTNER,GET_OURPARTNER_DETAILS } from "./action";

const initialState = {
  ourPartnerList: null,
  createOurPartner: null,
  updateOurPartner: null,
  deleteOurPartner: null,
  getPartnerDetials: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OURPARTNER: {
      return {
        ...state,
        ourPartnerList: action.payload,
      };
    }
    case ADD_OURPARTNER: {
      return {
        ...state,
        createRecognitions: action.payload,
      };
    }
    case UPDATE_OURPARTNER: {
      return {
        ...state,
        updateRecognitionsList: action.payload,
      };
    }
    case DELETE_OURPARTNER: {
      return {
        ...state,
        deleteOurPartner: action.payload,
      };
    }
    case GET_OURPARTNER_DETAILS: {
      return {
        ...state,
        getPartnerDetials: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
