import { ADD_SUBSCRIPTION, SUBSCRIPTION_LIST, DELETE_SUBSCRIPTION, UPDATE_SUBSCRIPTION, GET_SUBSCRIPTION_DETAIL } from "./action";

const initialState = {
  allSubscriptions: null,
  createSubscription: null,
  deleteSubscription: null,
  updateSubscription: null,
  subscriptionDetailData : null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_LIST: {
      return {
        ...state,
        allSubscriptions: action.payload,
      };
    }
    case ADD_SUBSCRIPTION: {
      return {
        ...state,
        createSubscription: action.payload,
      };
    }
    case DELETE_SUBSCRIPTION: {
      return {
        ...state,
        deleteSubscription: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTION: {
      return {
        ...state,
        updateSubscription: action.payload,
      };
    }
    case GET_SUBSCRIPTION_DETAIL: {
      return {
        ...state,
        subscriptionDetailData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
