import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import './index.css';
import Sidebar from '../Components/Navigation/Sidebar';
import Topbar from '../Components/Navigation/Topbar';
import PageHeading from '../Components/PageHeading';
import { getDashboardUsers,getDashboardRevenue,getDashboardOrders } from '../action';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";
import moment from 'moment';
import Loader from '../../components/Loader';

const Dashboard = (props) => {
    const { dashboard, dashboardRevenue, dashboardOrders } = props;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        props.getDashboardUsers()
        props.getDashboardRevenue()
        props.getDashboardOrders()
    }, [])

    useEffect(() => {
        props.getDashboardUsers(startDate, endDate);
        props.getDashboardOrders(startDate, endDate);
    }, [startDate, endDate])

    return (
        <div>
            {/* <!-- Page Wrapper --> */}
            <div id="wrapper" >

                {/* <!-- Sidebar --> */}
                < Sidebar />
                {/* <!-- End of Sidebar --> */}

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div>

                        {/* <!-- Topbar --> */}
                        <Topbar />
                        {/* <!-- End of Topbar --> */}

                        {/* <!-- Begin Page Content --> */}
                        <div className="container-fluid">
                            {/* <!-- Page Heading --> */}
                            <div className='row'>
                                <div className='col-md-6'>
                                <PageHeading title="Dashboard" />
                                </div>
                                <div className='col-md-6 mb-3'>
                                  <strong>Date Range: </strong> 
                                  <DateRangePicker 
                                  format={'yyyy-MM-dd'}
                                  onChange={(res)=>{
                                    if(res){
                                        setStartDate(moment(res[0]).format("yyyy-MM-DD"));
                                        setEndDate(moment(res[1]).format("yyyy-MM-DD"));
                                    }else{
                                        setStartDate(null);
                                        setEndDate(null);
                                    }
                                  }}
                                  onClean={(res)=>{
                                    setStartDate(null);
                                    setEndDate(null);
                                  }}
                                  />
                                </div>
                            </div>
                            

                            {/* <!-- Content Row --> */}
                            <div className="row">

                                {/* <!-- Total number of active users --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        Total number of active users</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard?.user_registered || 0}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-user fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Total number of orders --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        Total number of orders</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboardOrders?.subscription_counts || 0}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-download fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Total revenue generated --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        Total revenue generated</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboardRevenue?.Total_amounts || 0}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-rupee-sign fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ adminReducer }) => {
    const { dashboard,dashboardRevenue,dashboardOrders } = adminReducer;
    console.log('ssssssssssssssss',dashboardOrders)
    return { dashboard ,dashboardRevenue,dashboardOrders};
}

export default connect(mapStateToProps, {
    getDashboardUsers,getDashboardRevenue,getDashboardOrders
})(Dashboard);

