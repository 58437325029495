import React, { useState } from 'react';
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import "../getintouch/getInTouch.css";
import getInTouchForm from './getInTouchForm';
import { getInTouch } from '../../pages/aboutus/action';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';

function GetInTouch(props) {
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(yup.object().shape({
            name: yup.string().required("Fullname is required").matches(/^[^0-9]*$/, 'Numbers are not allowed'),
            phone: yup.string().required("Phone is required").matches(/^[^a-zA-Z]*$/, 'Alphabets are not allowed').matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
            email: yup.string().email('Invalid email format').required("Email is required"),
            message: yup.string().required("Message is required"),
        })),
    })

    const onSubmit = async (Formdata) => {
        try {
            const user = await dispatch(getInTouch(Formdata))
            if (user.status) {
                toast.success(user.result.success);
                reset();
            } else {
                toast.error(user.error || "Error in sending message");
            }

        } catch (error) {
            toast.error(error.message || "Error in form data not submited");
        }

    }

    return (
        <>
            <div id="getInTouchForm" className='getInTouch' style={{ marginBottom: 100 }}>
                <div className='getInTouchImage' style={{ backgroundColor: '#E6EDF7', width: '60%', alignItems: 'center' }}>
                    <div className='formContainer' style={{ backgroundColor: '#336EBD', width: '55%', borderRadius: 40, }}>
                        <h1 style={{ color: 'white', fontSize: 33, textAlign: 'center', marginTop: 0 }}>
                            Get in touch
                        </h1>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="fname" className='formLabel'>First Name</label>
                            <div className='form-input forTwoField'>
                                <input type="text" placeholder='First Name'
                                    {...register("name")} style={{marginBottom:errors.name? 5: 16,borderColor:errors.name?'#fd877b':'#fff'}}/>
                            </div>
                            <p className='validationError' style={{color:'#fd877b',marginBottom:errors.name? 12: 0}} >{errors.name?.message}</p>
                            <label htmlFor="fname" className='formLabel'>Phone Number</label>
                            <div className='form-input forTwoField'>
                                <input type="text" placeholder='+91' disabled="disabled" style={{ width: 60, marginRight: 12 ,marginBottom:errors.phone? 5: 16,borderColor:errors.phone?'#fd877b':'#fff'}} />
                                <input type="text" placeholder='123 - 245 - 4596'
                                    {...register("phone")} style={{marginBottom:errors.phone? 5: 16,borderColor:errors.phone?'#fd877b':'#fff'}}
                                />
                            </div>
                            <p className='validationError' style={{color:'#fd877b',marginBottom:errors.phone? 12: 0}} >{errors.phone?.message}</p>

                            <label htmlFor="fname" className='formLabel'>Email ID</label>
                            <div className='form-input forTwoField'>
                                <input type="text" placeholder='Email ID'
                                    {...register("email") }
                                    style={{marginBottom:errors.email? 5: 16,borderColor:errors.email?'#fd877b':'#fff'}}
                                />
                            </div>
                            <p className='validationError ' style={{color:'#fd877b',marginBottom:errors.email? 12: 0}}>{errors.email?.message}</p>
                            
                            <label htmlFor="fname" className='formLabel'>Message</label>
                            <div className='form-message forTwoField'>
                                <textarea type="text" placeholder='Message'
                                    {...register("message")}
                                    style={{marginBottom:errors.message? 5: 16,borderColor:errors.message?'#fd877b':'#fff'}}
                                />
                            </div>
                            <p className='validationError ' style={{color:'#fd877b',marginBottom:errors.message? 12: 0}} >{errors.message?.message  }</p>

                            <button className='formBtn' type="submit" value="Send Message">Send Message</button>
                        </form>

                    </div>
                    <img className='getInTouchImg' src={Images.GetInTouch} style={{ width: '55%' }} />
                </div>
            </div>

            
        </>
    );
}

export default GetInTouch;