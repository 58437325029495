import { BLOG } from "./action";

const initialState = {
  blogData: []
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case BLOG: {
        return {
          ...state,
          blogData: action.payload,
        };
      }
     
      default: {
        return state;
      }
    }
  };
  
  export default reducer;
  