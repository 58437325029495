import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss'
import Form from 'react-bootstrap/Form';
import Images from '../../constants/Images';
import { Button } from '@mui/material';
import ViewHeading from '../../components/ViewHeading';
import { getProfileDetails, updateProfileDetails, changePassword } from './action';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { PASSWORD_REGEX } from '../../utilities/constants';
import { uploadImage } from '../../admin/action';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import Loader from '../../components/Loader';
import { logout } from '../../admin/Components/Navigation/action';
import { useNavigate } from 'react-router-dom';

function MyAccount(props) {
    const dispatch = useDispatch();
    const [isInitialAppend, setIsInitialAppend] = useState(true);
    const imageInputRef = useRef();
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [newPass, setNewPass] = useState(false);
    const [newCPass, setNewCPass] = useState(false);
    const navigate = useNavigate();

    const { user_id } = useSelector((state) => (state.loginReducer));
    const { loading } = useSelector((state) => (state.accountReducer));

    const schema = yup.object().shape({
        fullname: yup.string().required('Fullname is required'),
        username: yup.string().required('Username is required'),
        email: yup.string().email('Invalid email format').required('Email is required'),
        phone: yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Phone number is required'),
        experiences: yup.array().of(
            yup.object().shape({
                Job_Title: yup.string().required('Job Title is required'),
                Company: yup.string().required('Company is required'),
                Date_Of_Joining: yup.string() // Expect a string
                    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)')
                    .required('Date of Joining is required')
                    .typeError('Invalid date format (YYYY-MM-DD)'), // Add custom error message
                Date_Of_Leaving: yup.string() // Expect a string
                    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)')
                    .required('Date of Joining is required')
                    .typeError('Invalid date format (YYYY-MM-DD)') // Add custom error message
                    .test('date-range', 'Leaving date must be after or equal to Joining date', function () {
                        const joiningDate = new Date(this.parent.Date_Of_Joining);
                        const leavingDate = new Date(this.parent.Date_Of_Leaving);

                        // Check if leaving date is greater than or equal to joining date
                        return leavingDate >= joiningDate;
                    }),
                Currently_Working: yup.bool().optional()
            })
        ),
    });

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        watch,
        formState: { errors, isDirty, isValid },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'experiences', // Name of the field array in the form data,
    });

    const experienceValue = watch("experiences")

    useEffect(() => {
        props.getProfileDetails(user_id);
    }, []);

    useEffect(() => {
        if (isInitialAppend) {
            // append();
             // Trigger append when the component mounts
            setIsInitialAppend(false); // Update the flag to prevent further appends
        }
    }, [append, isInitialAppend]);

    useEffect(() => {
        if (Object.keys(props.profileDetails).length > 0) {
            // reset(props.profileDetails); 

            setValue('fullname', props.profileDetails.fullname);
            setValue('username', props.profileDetails.username);
            setValue('email', props.profileDetails.email);
            setValue('phone', props.profileDetails.phone);
            // setValue('image', props.profileDetails.image.media);

            if (props.profileDetails.image && props.profileDetails.image.media) {
                setPreview(props.profileDetails.image.media);
            } else {
                setPreview(Images.UserIcon);
            }

            if (props.profileDetails.experiences.length > 0) {
                setValue('experiences', props.profileDetails.experiences);
            }

        }
    }, [props.profileDetails, setValue]);


    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);

    }, [selectedFile]);

    const appendNewEntry = () => {
        fields.forEach((entry, index) => {
            setValue(`experiences[${index}].Currently_Working`, false);
        });

        append({});
    };

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        const selectedFile = e.target.files[0];

        // Check if the selected file is an image
        const reader = new FileReader();

        reader.onloadend = () => {
            // Check if the file type is an image
            if (reader.result && reader.result.startsWith('data:image')) {
                // It's an image
                setSelectedFile(selectedFile);
            } else {
                // It's not an image, handle accordingly
                toast.error('Please select a valid image file.');
                setSelectedFile(undefined);
            }
        };

        reader.readAsDataURL(selectedFile);

    }
    const updateProfile = async (data) => {
        let error = null;
        if(typeof props.profileDetails.image != 'undefined' && props.profileDetails.image){
            data.image_id = props.profileDetails.image.id;
        }
        if (selectedFile) {
            let formData = new FormData();
            formData.append('media', selectedFile);
            await props.uploadImage(formData).then(res => {
                data.image_id = res.data.id;
            }).catch(err => {
                console.log('err', err)
                error = null;
                return null;
            });

            if (error) {
                toast.error(error || "Error in image upload");
                return;
            }
        }
        props.updateProfileDetails(data, user_id).then(res => {
            toast.success('Profile Updated Successfully');
        }).catch(error => {
            console.log("Error in update", error);
            toast.error(error || "Error in update");
        });
    }

    const changePassForm = useForm({
        resolver: yupResolver(yup.object().shape({
            current_password: yup.string().required("Current password is required"),
            new_password: yup.string().matches(PASSWORD_REGEX, 'Minimum eight characters, at least one letter, one number and one special character').required("Password is required"),
            confirm_new_password: yup.string().oneOf(
                [yup.ref("new_password"), null],
                "Passwords must match" // Error message for mismatched passwords
            ).required("Password is required"),
        })),
    });

    const handleOnUpdatePassword = async (formData) => {
        console.log("formData", formData);
        if (formData.new_password && formData.confirm_new_password) {
            if (formData.confirm_new_password !== formData.new_password) toast.error('Passwords do not match');
        }
        let submitFormData = {
            current_password: formData.current_password,
            new_password: formData.new_password
        }
        try {
            const changePassResp = await dispatch(changePassword(submitFormData));
            if (changePassResp.status) {
                toast.success(changePassResp.result.detail + ' Please relogin');
                await localStorage.clear();
                dispatch(logout());
                navigate('/admin/login');
            } else {
                toast.error(changePassResp.result.error || "Error in changing your password");
            }

        } catch (error) {

            toast.error(error.message || "Error while changing password");
        }
    }

    return (
        <>
            {loading && <Loader />}
            <form>
                <ViewHeading
                    buttonText={'Save Changes'}
                    onButtonClick={handleSubmit(updateProfile)}
                    containerStyles={{ backgroundColor: '#CCE2FF', paddingTop: 18, paddingBottom: 18, marginBottom: 0 }}
                    btnStyle2={{ color: '#004AAD', marginRight: 10, }}
                    btnStyle={{ backgroundColor: '#004AAD', opacity: (!isDirty || !isValid) ? 0.4 : 1 }}
                    disableButtonOne={!isDirty || !isValid}
                />
                <div className='container containers myAccount' >
                    <Row className='myAccount-row'>
                        <Col lg={6}>
                            <div className='left-text-col' style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: 20 }}>
                                    {/* {selectedFile ? (
                                    <img src={preview} style={{ width: 130, objectFit: 'cover',height: 130 , borderRadius: '50%' }} />
                                ) : (
                                    // image.media
                                    <img src={preview} style={{ width: 130, objectFit: 'cover',height: 130 , borderRadius: '50%' }} />
                                )} */}
                                    {/* <img src={Images.AdityaSaxenaNew} style={{ width: 130, objectFit: 'contain', borderRadius: '50%' }} /> */}

                                    <img
                                        src={preview}
                                        style={{ width: 130, objectFit: 'cover', height: 130, borderRadius: '50%' }}
                                    />

                                </div>
                                <div>
                                    <h3>Personal Information </h3>
                                    <p>{props.profileDetails.email}</p>
                                </div>
                            </div>
                        </Col >
                        <Col className='right-text-col'>
                            <div className="button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 32, marginTop: 25 }}>
                                <Button style={{ backgroundColor: '#004AAD', color: 'white', textTransform: 'capitalize' }} className='white' onClick={() => imageInputRef.current.click()} >{`Change Profile Picture`}</Button>
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={onSelectFile}
                                    ref={imageInputRef}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='myAccount-row'>
                        <Col lg={6}>
                            <div className='left-text-col'>
                                <h3>Personal Information</h3>
                                {/* <p>This Will Be displayed on your profile</p> */}
                            </div>
                        </Col >
                        <Col className='right-text-col'>
                            {/* <Form > */}
                            <Form.Group className="mb-3" controlId="fullname">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" placeholder="Aditya Saxena" {...register("fullname")} />
                                <p className='validationError'>{errors.fullname?.message}</p>
                            </Form.Group>
                            {/* </Form>
                        <Form > */}
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Linkedin ID</Form.Label>
                                <Form.Control type="text" placeholder="Aditya.Saxena.1234" {...register("username")} />
                                <p className='validationError'>{errors.username?.message}</p>
                            </Form.Group>
                            {/* </Form>
                        <Form > */}
                        {console.log("Error", errors)}
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" placeholder="Aditya.Saxena.@gmail.com" {...register("email")} />
                            </Form.Group>
                            <p className='validationError'>{errors.email?.message}</p>
                            {/* </Form> */}
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Mobile No.</Form.Label>
                                <Form.Control type="text" placeholder="0000000000" {...register("phone")} />
                                <p className='validationError'>{errors.phone?.message}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='myAccount-row'>
                        <Col lg={6} >
                            <div className='left-text-col'>
                                <h3>Professional Background</h3>
                                {/* <p>Lorem Ipsum is simply a dummy text</p> */}
                            </div>
                        </Col >
                        <Col lg={6} className='right-text-col'>
                            {fields.map((field, index) => (
                                <div key={field.id} >
                                    {
                                        // index !== 0 &&
                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', }}>
                                            <Button onClick={() => remove(index)} className='btnStyle' style={{ color: 'red' }}><img src={Images.RoundClose} style={{ width: 24, height: 24, objectFit: 'contain' }} /></Button>

                                        </div>}

                                    {/* <Form > */}
                                    <Form.Group className="mb-3" controlId={'Job_Title_' + index}>
                                        <Form.Label> Job Title</Form.Label>
                                        <Form.Control type="text" placeholder="E.g. Web Developer" defaultValue={field.name} {...register(`experiences.${index}.Job_Title`, {
                                            required: "Job Title is required"
                                        })} />
                                        {errors.experiences?.[index]?.Job_Title && (
                                            <p className="text-danger">{errors.experiences[index].Job_Title.message}</p>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId={'Company_' + index}>
                                        <Form.Label> Company</Form.Label>
                                        <Form.Control type="text" placeholder="E.g. Upskillr" {...register(`experiences.${index}.Company`)} />
                                        {errors.experiences?.[index]?.Company && (
                                            <p className="text-danger">{errors.experiences[index].Company.message}</p>
                                        )}
                                    </Form.Group>

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <Form.Group className="mb-3" controlId={'date_of_joining_' + index} style={{ marginRight: 20 }}>
                                            <Form.Label>Date Of Joining</Form.Label>
                                            <Form.Control type="date" placeholder="DD/MM/YYYY" {...register(`experiences.${index}.Date_Of_Joining`)} />
                                            {errors.experiences?.[index]?.Date_Of_Joining && (
                                                <p className="text-danger">{errors.experiences[index].Date_Of_Joining.message}</p>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId={'date_of_leaving_' + index} style={{ marginLeft: 20 }}>
                                            <Form.Label>Date Of Leaving</Form.Label>
                                            <Form.Control disabled={experienceValue[index].Currently_Working} type="date" placeholder="DD/MM/YYYY" {...register(`experiences.${index}.Date_Of_Leaving`)} />
                                            {errors.experiences?.[index]?.Date_Of_Leaving && (
                                                <p className="text-danger">{errors.experiences[index].Date_Of_Leaving.message}</p>
                                            )}
                                        </Form.Group>

                                    </div>
                                    {index === fields.length - 1 && (
                                        <Form.Group className="mb-3" controlId={'current_position' + index}>
                                            <Form.Check
                                                type="checkbox"
                                                label="Currently Working at this position"
                                                {...register(`experiences.${index}.Currently_Working`)}
                                            />
                                        </Form.Group>
                                    )}
                                    <hr />

                                </div>
                            ))}

                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', }}>
                                <img src={Images.Plus} style={{ width: 24, height: 24, objectFit: 'contain', marginRight: 3, }} />
                                <Button className='btnStyle' onClick={() => appendNewEntry()}>{'Add Experience'}</Button>
                            </div>
                        </Col>
                    </Row>


                </div>
            </form>
            <form onSubmit={changePassForm.handleSubmit(handleOnUpdatePassword)}>
                <div className='container containers myAccount' >
                    <Row className='myAccount-row'>
                        <Col lg={6}>
                            <div className='left-text-col'>
                                <h3>Security</h3>
                                <p><b>Note -</b> The password must contain atleast 1 Uppercase letter, 1 lowercase letter, 1 Number & 1 Special Character</p>
                            </div>
                        </Col >
                        <Col lg={6} className='right-text-col'>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Button style={{ backgroundColor: '#004AAD', alignSelf: 'flex-end' }} className='white changePasswordBtn text-right' type="submit" >{`Change Password`}</Button>
                            </div>


                            <Form.Group className="mb-3" controlId="currentPassword">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control type="password" placeholder="************"
                                    {...changePassForm.register('current_password')}
                                />
                                <p className='validationError'>{changePassForm.formState.errors.current_password?.message}</p>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="newPassword">
                                <Form.Label>New Password</Form.Label>
                                <div className='prelative'>
                                    <Form.Control type={newPass ? "text" : "password"} placeholder="************"
                                        {...changePassForm.register('new_password')}
                                    />
                                    <Button className='viewPassIcon' onClick={() => setNewPass(!newPass)}>
                                        {newPass ? <i className="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye"></i>}
                                    </Button>
                                </div>
                                <p className='validationError'>{changePassForm.formState.errors.new_password?.message}</p>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="confirmNewPassword">
                                <Form.Label>Confirm New Password</Form.Label>
                                <div className='prelative'>
                                    <Form.Control type={newCPass ? "text" : "password"} placeholder="*************"
                                        {...changePassForm.register('confirm_new_password')}
                                    />
                                    <Button className='viewPassIcon' onClick={() => setNewCPass(!newCPass)}>
                                        {newCPass ? <i className="fa fa-eye-slash text-white" aria-hidden="true"></i> : <i className="fa fa-eye text-white"></i>}
                                    </Button>
                                </div>
                                <p className='validationError'>{changePassForm.formState.errors.confirm_new_password?.message}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </form>
        </>

    );
}

const mapStateToProps = ({ accountReducer }) => {
    const { profileDetails } = accountReducer;
    return {
        profileDetails
    };
}

export default connect(mapStateToProps, {
    getProfileDetails, updateProfileDetails, uploadImage,
})(MyAccount);