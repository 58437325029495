import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from "redux-thunk";
import logger from 'redux-logger';
import combineReducer from "./client/global/combineReducer";
import { ResponseInterceptor } from './client/utilities/api';

const middlewares = [thunk,];
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger);
// }
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(combineReducer, composeEnhancers(applyMiddleware(...middlewares)))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
ResponseInterceptor(store.dispatch);
reportWebVitals();
