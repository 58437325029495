import React, { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import './index.scss'
import Images from '../../constants/Images';
import { Button, Menu, MenuItem } from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { toast } from 'react-toastify'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { Modal } from 'react-bootstrap';
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfmake = require("html-to-pdfmake");

const schema = yup.object().shape({
    query : yup.string().required('Topic is required'),
    links: yup.array().of(
         yup.string().url('Invalid URL format').required('Link is required'),      
    ),
    outline: yup.array().of(
        yup.string().required('Structure is required'),      
   ),
    attachments: yup.array().of(
        yup.mixed().test({
            name: 'is-valid-file',
            message: 'Attachment is required',
            test: function (value) {
              return value && value != '';
            },
          }).required('Attachment is required'),
    )
});

function MultiSearchLeftCard({handleSubmission, showGenerateBtn,editorHtml, resetEditor, download}) {
    const { handleSubmit, control, reset, formState: { errors }, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
    });
    const { fields: outlinesFields, append: appendOutline, remove: removeOutline } = useFieldArray({
        control,
        name: 'outline',
    });

    const { fields: linksFields, append: appendLink, remove: removeLink } = useFieldArray({
        control,
        name: 'links',
    });

    const { fields: attachmentFields, append: appendAttachment, remove: removeAttachment} = useFieldArray({
        control,
        name: 'attachments',
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [resetConfirmModal, setResetConfirmModal] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const fileInputRefs = useRef([]);

    useEffect(()=>{
        if(outlinesFields.length === 0){
            appendOutline('');
        }
        // if(linksFields.length === 0){
        //     appendLink('');
        // }
        // if(attachmentFields.length === 0){
        //     appendAttachment('');
        // }
    },[appendOutline, appendLink, appendAttachment])

    const onSubmit = (data) => {

        if (((typeof data['attachments'] != 'undefined') && data['attachments'].length === 0) && ((typeof data['links'] != 'undefined') && data['links'].length === 0)) {
            toast.error("Please mention at aleast links or attachments");
            return;
        }

        const formData = new FormData();
        if(data.links.length > 0){
            formData.append('links', data.links);
        }
        formData.append('outline', data.outline);
        formData.append('query', data.query);

        if(typeof data.attachments != 'undefined'){
            for (let i = 0; i < data.attachments.length; i++) {
                if(data.attachments[i]){
                    formData.append('attachments', data.attachments[i]);
                }
            }
        }        

        handleSubmission(formData);
    };

    const downloadDocument = (type) =>{
        download(type);
        handleClose();
    }  

    useEffect(() => {
        // Initialize tooltips for multiple buttons
        const buttons = document.querySelectorAll('.tooltip-button');
    
        buttons.forEach((button) => {
          const content = button.getAttribute('data-tooltip-content'); // Get content from data attribute
          tippy(button, {
            content,
            allowHTML: true,
            placement: 'right',
            arrow:false
          });
        });
      }, []);

    const handleReset = () => {       
        // const result = window.confirm('Are you sure you want to reset? The generated content will be deleted permanently. Download it to save it');
        // setResetConfirmModal(true);
        // if (result) {
            // Remove all appended fields
            for (let i = outlinesFields.length - 1; i > 0; i--) {
                removeOutline(i);
            }
            
            // Remove all appended fields
            for (let i = linksFields.length - 1; i >= 0; i--) {
                removeLink(i);
            }
            
            // Remove all appended fields
            for (let i = attachmentFields.length - 1; i >= 0; i--) {
                removeAttachment(i);
            }  

            fileInputRefs.current.forEach((fileInputRef) => {
                if (fileInputRef && fileInputRef.value) {
                    fileInputRef.value = '';
                }
            });

            reset(); // Reset the form values
            resetEditor();
            setResetConfirmModal(false);
        // }
    };
    
    return (
        <>
            <div className='MultiSearchLeftCard'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='MultiSearchForm'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                            <div className='d-flex multiSearchInfo'>
                                <p><Form.Label style={{ marginBottom: 0 }}>Topic</Form.Label></p>
                                <img className="multiSearchInfoIcon tooltip-button" data-tooltip-content='<p>For example: "Rise and Fall of Silicon Valley"</p>' src={Images.Info} />
                            </div>



                            <Controller
                                name="query"
                                control={control}
                                defaultValue=""
                                render={({ field }) =>
                                    <Form.Control type="text" placeholder="Enter Topic Here" {...field} />
                                }
                            />
                            {errors.query && <p>{errors.query.message}</p>}
                    </Form.Group>
                </div>

                <hr className='line' />

                <div className='MultiStructureForm' style={{ marginTop: 24 }}>

                    <div className='d-flex multiSearchInfo'>
                        <p><Form.Label style={{marginBottom : 0}}>Structure</Form.Label></p>
                        <img className="multiSearchInfoIcon tooltip-button" data-tooltip-content="<p><strong>Add the paragraphs topic name and a little context for it, one by one in order.</strong></p>

                        <p>For Example:</p>
    
        <p>Structure 1: Preamble - Covering what is Silicon Valley Bank</p>
        <p>Structure 2: Silence before the storm - Covering things that happened before the downfall of the bank</p>
        <p>Structure 3: Asset Liability & Interest rate risk Mismatch - Covering how banks invested short-term deposits in long-term bonds</p>
        <p>Structure 4: Self-fulfilling Prophecy - Covering how the bank failed due to depositors rushing to withdraw the money</p>
        <p>Structure 5: Impact - Covering impacted startups in specific and economic impact in general ['Indian startups impacted by SVB']</p>
        <p>Structure 6: The resolution provided by Fed - Covers the action taken by US federal reserve</p>
        <p>Structure 7: Conclusion - Focusing on how such things can be avoided</p>
    " src={Images.Info} />
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                            {outlinesFields.map((outline, index) => (
                                <div key={outline.id} className="mt-2">
                                    <Controller
                                        name={`outline[${index}]`}                                  
                                        control={control}
                                        rules={index === outlinesFields.length - 1 ? { required: 'Structure is required' } : {}}
                                        render={({ field }) => 

                                            <div style={{ display: 'flex', alignItems: 'center' }} >
                                                <Form.Control as="textarea" rows={1} placeholder={`Structure ${index + 1} here`} {...field} />
                                                {
                                                     index !== 0 && <img onClick={()=>removeOutline(index)} src={Images.FilledCloseIcon} style={{ width: 20, height: 20, cursor : 'pointer' }} />    
                                                }
                                            </div>
                                        }
                                    />
                                    {errors.outline?.[index] && (
                                        <p>{errors.outline[index].message}</p>
                                    )}
                                </div>
                            ))}
                    </Form.Group>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 24 }}>
                    <img src={Images.Plus} style={{ width: 24, height: 24, objectFit: 'contain', marginRight: 3, }} />
                    <Button onClick={() => appendOutline('')} className='btnStyle' >{'Add Structure'}</Button>
                </div>

               
                <hr className='line' style={{ marginTop: 24, marginBottom: 24 }} />

                <div className='MultiStructureForm' style={{ marginTop: 24 }}>
                    <div className='d-flex align-item-center multiSearchInfo'>
                        <p><Form.Label style={{marginBottom : 0}}>References</Form.Label></p>
                        <img className='multiSearchInfoIcon tooltip-button' data-tooltip-content="<p><strong>Please provide the specific Web URL links from which you'd like to extract content.</strong></p>
                        <p></p>
    <ul>
        <li>Enter each link individually.</li>
        <li>Note:</li>
        <ul>
            <li>We'll only fetch content from the provided URLs; links within those pages won't be (HTML pages only) retrieved.</li>
            <li>Images and hosted files will not be processed.</li>
            <li>Ensure the content is publicly accessible without a login or password.</li>
        </ul>
    </ul>"  src={Images.Info} />
                    </div>


                    {/* <Form.Select aria-label="Default select example" onChange={handleSelectChange}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </Form.Select> */}
                </div> 

                <div className='MultiSelectForm'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {linksFields.map((obj, index) => (
                            <div key={obj.id} className="mt-2">
                                <Controller
                                    name={`links[${index}]`}
                                    control={control}
                                    render={({ field }) =>
                                        <div style={{display : 'flex', alignItems : 'center'}} >
                                            <Form.Control type="text" placeholder={`Link ${index + 1} here`} {...field}
                                            />
                                            
                                            {<img onClick={() => removeLink(index)} src={Images.FilledCloseIcon} style={{ width: 20, height: 20, cursor : 'pointer' }} />
                                            }
                                        </div>
                                    }
                                />
                                {errors.links?.[index] && (
                                    <p>{errors.links[index].message}</p>
                                )}
                            </div>
                        ))}
                    </Form.Group>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 24 }}>
                    <img src={Images.Plus} style={{ width: 24, height: 24, objectFit: 'contain', marginRight: 3, }} />
                    <Button onClick={() => appendLink('')} className='btnStyle' >{'Add Links'}</Button>
                </div>

                <hr className='line' style={{ marginTop: 24, marginBottom: 24 }} />

                <div className='MultiStructureForm' style={{ marginTop: 24 }}>
                    <div className='d-flex align-item-center multiSearchInfo'>
                        <p><Form.Label style={{marginBottom : 0}}>Attachment</Form.Label></p>
                        <img className='multiSearchInfoIcon tooltip-button' data-tooltip-content="<strong>Make sure your files are</strong>
            <p/>
            <p></p>
            <ul>
            <li>.docx/.pdf only</li>
            <li>Not more than 10MB</li>
            <li>Not handwritten</li>
            <li>Not scanned</li>
            <li>Not password protected</li>
            </ul>
        " src={Images.Info} />
                    </div>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                            {attachmentFields.map((attachment, index) => (
                                <div key={attachment.id} className="mt-2">
                                    <Controller
                                        name={`attachments[${index}]`}                                  
                                        control={control}
                                        render={({ field: { value, onChange, ...field } }) => 

                                            <div style={{ display: 'flex', alignItems: 'center' }} >
                                                {/* <Form.Control onChange={(e) => console.log("ff")} type="file" {...field} /> */}
                                                <input
                                                    {...field}
                                                    type="file"
                                                    accept="application/pdf,.docx"
                                                    className='form-control'
                                                    onChange={(event) => {
                                                        const selectedFile = event.target.files[0];
                                                        // You can add additional validation here if needed
                                                        if (selectedFile && (selectedFile.type === 'application/pdf' || selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                                                            onChange(selectedFile);
                                                        } else {
                                                            fileInputRefs.current[index].value = '';
                                                            onChange(null);
                                                            // Handle invalid file type (e.g., show an error message)
                                                            toast.error("Only Pdf and docx are allowed")
                                                        }
                                                      }}
                                                      ref={(el) => (fileInputRefs.current[index] = el)}
                                                />
                                                {
                                                     <img onClick={()=>removeAttachment(index)} src={Images.FilledCloseIcon} style={{ width: 20, height: 20, cursor : 'pointer' }} />    
                                                }
                                            </div>
                                        }
                                    />
                                    {errors.attachments?.[index] && (
                                        <p>{errors.attachments[index].message}</p>
                                    )}
                                </div>
                            ))}
                    </Form.Group>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 24 }}>
                    <img src={Images.Plus} style={{ width: 24, height: 24, objectFit: 'contain', marginRight: 3, }} />
                    <Button onClick={() => appendAttachment('')} className='btnStyle' >{'Add Attachment'}</Button>
                </div>

                <div className='newButton' style={{ backgroundColor: '#FFF8E6', width: '100%', padding: 8, display: 'flex', justifyContent: 'center', marginTop: 35 }}>
                        {showGenerateBtn && <Button type="submit" className='GeneratenewButton' style={{ backgroundColor: '#FFAF0D', }}>{`Generate`}</Button> }

                        {
                            !showGenerateBtn && <Button type="button" onClick={()=>setResetConfirmModal(true)} className='GeneratenewButton' style={{ backgroundColor: '#FFAF0D', }}>{`Reset`}</Button>
                        }

                        {
                             !showGenerateBtn && <>
                                <Button className='GeneratenewButton  ml-2' style={{ backgroundColor: '#FFAF0D', }} aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}>{`Download`} </Button>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => downloadDocument('docx')}>
                                        <img src={Images.ReviewIcon} style={{ width: 16, height: 16, marginRight: 8 }} />DOCX
                                    </MenuItem>
                                    <MenuItem onClick={() => downloadDocument('pdf')}>
                                        <img src={Images.UploadFileIcon} style={{ width: 16, height: 16, marginRight: 8 }} />PDF
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                    

                </div>
                </form>

               
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={resetConfirmModal} onHide={() => setResetConfirmModal(!resetConfirmModal)} className='addTopicCardModal' backdrop="static" >
                    <Modal.Header closeButton={false} style={{ alignItems: 'center' }}>
                        <Modal.Title className='mdlTitle'>Confirm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to continue?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setResetConfirmModal(!resetConfirmModal)}>
                            No
                        </Button>
                        <Button variant="primary" onClick={handleReset}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>                
            </div>
        </>
    )
}
export default MultiSearchLeftCard;