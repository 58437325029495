import { api } from "../../utilities/api";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";

export const SIGNUPLOADER = "SIGNUPLOADER";
export const GENERATEURLLOADER = "GENERATEURLLOADER";

export const signup = (formData) => async (dispatch) => {
    dispatch({
      type: SIGNUPLOADER,
      payload: true
    });
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.signup,
      data: formData
    };
    return api(data)
    
      .then(async (response) => {
        return response.data.data;
      })
      .then(async (response) => {
        if (response) {
          dispatch({
            type: SIGNUPLOADER,
            payload: false
          });
         return response
        }
      })
      .catch((error) => {
        dispatch({
          type: SIGNUPLOADER,
          payload: false
        });
        throw error?.response?.data?.message;
      });
  };

export const generateURL = () => async (dispatch) => {
  dispatch({
    type: GENERATEURLLOADER,
    payload: true
  });
  let data = {
    method: METHOD_TYPE.get,
    url: API_ENDPOINTS.google
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: SIGNUPLOADER,
          payload: false
        });
        return response
      }
    }).catch((error) => {
      dispatch({
        type: SIGNUPLOADER,
        payload: false
      });
      throw error?.response?.data?.message;
    });
};