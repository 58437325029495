import React, { useState, useEffect } from 'react'
import Colors from '../../constants/Colors'
import { Button, MenuItem, Select } from '@mui/material'
import './index.scss'
import AddIcon from '../../assets/add_icon.png'
import EditIcon from '../../assets/edit_icon.png'
import DeleteIcon from '../../assets/delete_icon.png'
import CollapsableView from '../collapsable/CollapsableView'
import { toast } from 'react-toastify'

function BuilderLayers({searchListArray, handleBuilderInputChange, builderRender, editingItemIndex, setEditingItemIndex, goback, createCourse, setSelectedGPTValue, selectedGPTValue, undoTheChanges, undoEntries, searchValue}) {
    const [isFixed, setIsFixed] = useState(false);
    const [data]= searchListArray;
    const [inputTitle, setInputTitle] = useState('');

    const handleEditClick = (index, inputTitle) => {
        setInputTitle(inputTitle);
        setEditingItemIndex(index);
    };

    const handleDeleteClick = (index) => {
        handleBuilderInputChange('', index, 'delete');
    }
    
    const handleAddClick = (index) => {
        handleBuilderInputChange('', index, 'add');
    }
    
    const handleInputCrossClick = () => {
        setEditingItemIndex(-1);
    }

    const builderCheckRight = (newValue, oldValue, index) => {
        if (newValue !== '') {
            setEditingItemIndex(-1);
            if(newValue != oldValue){
                setInputTitle('');
                handleBuilderInputChange(newValue, index, 'change');
            }
        } else {
            toast.error("Please mention title");
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 293) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        setInputTitle(value);
        // handleBuilderInputChange(value, index, 'change');
    };

    const handleInputBlur = (newValue, oldValue, index) => {
        setInputTitle('');
        if (editingItemIndex !== -1 && newValue != '') {
            builderCheckRight(newValue, oldValue, index);
        }else{
            handleBuilderInputChange('', index, 'delete');
        }
    };

    // useEffect(() => {
    //     const [data] = searchListArray;
    //     if (typeof data != 'undefined') {
    //         setOutline(filterNestedData(data));
    //     }
    // }, [searchListArray]);

    // const filterNestedData = (data)=>{        
    //     if (typeof data === "object") {
    //         const filteredData = {};
    
    //         for (const key in data) {
    //             if (key === "Title" || data[key] === "Title") {
    //                 filteredData[key] = data[key];
    //             } else if (typeof data[key] === "object") {
    //                 const nestedFilteredData = filterNestedData(data[key]);
    //                 if (Object.keys(nestedFilteredData).length > 0) {
    //                     filteredData[key] = nestedFilteredData;
    //                 }
    //             }
    //         }
    
    //         return filteredData;
    //     }
    //     return data;
    // }

    const fixedStyle = {
        position: isFixed ? 'fixed' : 'relative',
        zIndex: 1000,
        width: isFixed ? '81%' : 'auto',
        top: isFixed ? 71 : 'auto'
    };
    
    return (
        <div className='builder-layer'>
            <div style={{ backgroundColor: Colors.lavender }}>
                <div className='container d-flex align-items-center justify-content-between flex-wrap py-4 mb-3'>
                    <h1 style={{ color: '#212121', fontSize: 35 }}>{searchValue}</h1>
                    <div className={`formControl activeInputs`} style={{ width: 300, display: "flex", flexDirection: 'column' }}>
                        <label className='inputLabel'>{`Select GPT`}</label>
                        <div style={{border:'2px solid #00439D',borderRadius:4}}>
                        <Select
                        value={selectedGPTValue}
                        onChange={(event)=>{
                            setSelectedGPTValue(event.target.value)
                        }}
                        style={{width:'100%'}}
                        >
                            {/* <MenuItem value={`GPT4`} key={0}>GPT-4 (Default)</MenuItem> */}
                            <MenuItem value={`GPT-3.5-turbo-16K`}>GPT-3.5-Turbo-16K (Default)</MenuItem>
                        </Select>
                        </div>
                        <p className='mb-0 mt-2' style={{ fontSize: 14, color: Colors.dark_blue, fontFamily: 'Poppins', fontWeight: '600' }}>{(selectedGPTValue === 'GPT-3.5-turbo-16K')?'Cost-Effective, Efficient Credit Use and Quality Assured':' Premium Performance, Increased Credit Usage, Higher Cost'} </p>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-sm-12 my-3'>
                        <div className='background-dash'>
                            <div className='d-flex align-items-center'>
                                <img src={AddIcon} style={{ width: 24 }} />
                                <h4 className='fw-bold' style={{ fontSize: 16, color: '#001F49', marginLeft: 10 }}>Add</h4>
                            </div>
                            <h4 className='fw-normal mt-2' style={{ fontSize: 16, color: '#000' }}>This icon enables users to insert subtopics or secondary subtopics within the designated section.</h4>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 my-3'>
                        <div className='background-dash'>
                            <div className='d-flex align-items-center'>
                                <img src={EditIcon} style={{ width: 24 }} />
                                <h4 className='fw-bold' style={{ fontSize: 16, color: '#FFAF0D', marginLeft: 10 }}>Edit</h4>
                            </div>
                            <h4 className='fw-normal mt-2' style={{ fontSize: 16, color: '#000' }}>This icon lets the user modify the topic title of that section.</h4>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 my-3'>
                        <div className='background-dash'>
                            <div className='d-flex align-items-center'>
                                <img src={DeleteIcon} style={{ width: 24 }} />
                                <h4 className='fw-bold' style={{ fontSize: 16, color: '#DC2626', marginLeft: 10 }}>Delete</h4>
                            </div>
                            <h4 className='fw-normal mt-2' style={{ fontSize: 16, color: '#000' }}>This icon enables the user to remove the topic level from that layer, along with its associated subtopics and sub-subtopics.</h4>
                        </div>
                    </div>
                </div>

                <div className={`d-flex align-items-center ${window.innerWidth < 500 ? 'justify-content-center' : 'justify-content-between'} flex-wrap mb-2 bg-white`}>
                    <Button className='AddTop my-2' onClick={()=>handleAddClick("")}>{`Add Top Level`} </Button>
                    {
                        undoEntries.length > 0 && <Button onClick={undoTheChanges} className='UndoChanges my-2'>{`Undo the Changes`} </Button>
                    }
                </div>
                <div className='mb-4'>
                    {
                        (typeof data != 'undefined') && (typeof data?.Topics != 'undefined') && data?.Topics.map((topic, topicIndex) => {
                            return (
                                <div className='mb-3'>
                                    <CollapsableView
                                        title={topic.Title}
                                        inputTitle={inputTitle}
                                        showDots={false}
                                        showButtons={true}
                                        currentIndex={`${topicIndex}`}
                                        handleEditClick={handleEditClick}
                                        handleDeleteClick={handleDeleteClick}
                                        handleAddClick={handleAddClick}
                                        editingItemIndex={editingItemIndex}
                                        titleStyles={styles.topicTitleStyle}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        handleInputCrossClick={handleInputCrossClick}
                                        builderCheckRight={builderCheckRight}
                                        children={
                                            (typeof topic?.SubTopics != 'undefined') ? topic?.SubTopics.map((subTopics, subTopicsIndex) => {
                                                return (
                                                    <CollapsableView
                                                        title={subTopics.Title}
                                                        inputTitle={inputTitle}
                                                        showDots={false}
                                                        showButtons={true}
                                                        containerStyles={styles.subTopicContainerStyle}
                                                        titleStyles={styles.topicTitleStyle}
                                                        currentIndex={`${topicIndex}_${subTopicsIndex}`}
                                                        handleEditClick={handleEditClick}
                                                        handleDeleteClick={handleDeleteClick}
                                                        handleAddClick={handleAddClick}
                                                        editingItemIndex={editingItemIndex}
                                                        handleInputChange={handleInputChange}
                                                        handleInputBlur={handleInputBlur}
                                                        handleInputCrossClick={handleInputCrossClick}
                                                        builderCheckRight={builderCheckRight}
                                                        children={
                                                            (typeof subTopics?.SubSubTopics != 'undefined')?subTopics?.SubSubTopics.map((subSubTopics, subSubTopicsIndex) => {
                                                                return (
                                                                    <CollapsableView
                                                                        title={subSubTopics.Title}
                                                                        inputTitle={inputTitle}
                                                                        showDots={false}
                                                                        showIcon={false}
                                                                        showButtons={true}
                                                                        showAddIcon={false}
                                                                        currentIndex={`${topicIndex}_${subTopicsIndex}_${subSubTopicsIndex}`}
                                                                        containerStyles={styles.subsubTopicContainerStyle}
                                                                        titleStyles={styles.topicTitleStyle}
                                                                        handleEditClick={handleEditClick}
                                                                        handleDeleteClick={handleDeleteClick}
                                                                        handleAddClick={handleAddClick}
                                                                        editingItemIndex={editingItemIndex}
                                                                        handleInputChange={handleInputChange}
                                                                        handleInputBlur={handleInputBlur}
                                                                        handleInputCrossClick={handleInputCrossClick}
                                                                        builderCheckRight={builderCheckRight}
                                                                    />
                                                                )
                                                            }): []
                                                        }
                                                    />
                                                )
                                            }) : []
                                        }
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div className={`d-flex align-items-center justify-content-center flex-wrap my-4`} style={{ gap: 30 }}>
                    <Button className='Goback' onClick={goback}>{`Go back`} </Button>
                    <Button className='AddTop' onClick={createCourse}>{`Create Course`} </Button>
                </div>
            </div>
        </div>
    )
}

export default BuilderLayers
const styles = {
    topicTitleStyle: { fontSize: window.innerWidth < 500 ? 14 : 16, fontWeight: '500', fontFamily: 'Poppins', color: '#001F49' },
    subTopicContainerStyle: { backgroundColor: '#D5E7FF', border: 0, paddingLeft: window.innerWidth < 500 ? 12 : 40 },
    subsubTopicContainerStyle: { backgroundColor: '#F1F7FF', border: 0, paddingLeft: window.innerWidth < 500 ? 12 : 70 },
}