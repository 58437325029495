import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";

export const GENERATE_ARTICLE_SUCCESS = 'GENERATE_ARTICLE_SUCCESS';
export const GENERATE_ARTICLE_ERROR = 'GENERATE_ARTICLE_ERROR';
export const GENERATE_ARTICLE_LOADING = 'GENERATE_ARTICLE_LOADING';

export const generateArticle = (payload) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.generateArticle}`,
        data: payload,
        headers: { "Content-Type": "multipart/form-data" }
    }
    
    dispatch({
        type: GENERATE_ARTICLE_LOADING
    })
    return api(data).then(async (response) => {
        if (response) {
            dispatch({
                type: GENERATE_ARTICLE_SUCCESS,
                payload: response.data.article,
            });
            return response.data;
        }
    }).catch((error) => {
        dispatch({
            type: GENERATE_ARTICLE_ERROR,
            payload: error,
        });
        throw error.response.data.message || error.message;
    });
}
