import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import LoginSlider from '../login/LoginSlider';
import '../login/login.scss'
import { useForm } from "react-hook-form";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateURL, signup } from './action';
import { toast } from 'react-toastify';
import { PASSWORD_REGEX } from '../../utilities/constants';
import { useNavigate, Link, NavLink, } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import Loader from '../../components/Loader';

function Register(props) {
    const dispatch = useDispatch();
    const history = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(yup.object().shape({
            fullname: yup.string().required("Fullname is required"),
            username: yup.string().required("Username is required"),
            institute: yup.string().required("Institute is required"),
            email: yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email address').required("Email is required"),
            phone: yup.string().required("Phone is required").matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
            password: yup.string().matches(PASSWORD_REGEX, 'Minimum eight characters, at least one letter, one number and one special character').required("Password is required"),
            terms: yup.boolean().oneOf([true], 'You must agree to the Terms and Conditions'),
        })),
      })
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { signupLoader } = useSelector((state) => state.registerReducer);

    useEffect(() => {
        if (userDetails) {
            history("/", { replace: true });
        }
    }, []);

    const onSubmit = async (Formdata) => {
        try{
            await dispatch(signup(Formdata))
            toast.success('Register Successfull! Please Login');
            history('/login')
        }
        catch (error) {
            toast.error(error || "signup error");
          }  
        
    }

    const googleLogin = async () => {
        await dispatch(generateURL()).then(url => {
            if (url) {
                window.open(url, "_self");
            }
        }).catch(error => toast.error(error));
    }

    return (
        <section className="vh-100">
            {signupLoader && <Loader/>}
            <div className="container-fluid signUpPage">
                <div className="row  ">
                    <div className="col-md-6 col-lg-7 col-xl-7 px-5 pt-5 leftSlider">
                        <div className='d-flex justify-content-center'>
                        <NavLink to={"/"}><img src={require('../../assets/logo.png')} className='loginLogo' /></NavLink>
                        </div>
                        <h1 className='FutureReady'>Learning has no limits, nor should you.</h1>
                        <div className='py-3 loginSilderClass'style={{marginTop:60}}>
                        <LoginSlider/>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-5">
                    <div className=' backgroundImage'>
                        <h1 className=''>Create new account</h1>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <button className='formBtn googleButton' onClick={googleLogin} type="button" value="Send Message" style={{ background: '#fff', border: 0, color: Colors.textBlack, }}><img src={Images.GoogleIcon} style={{ width: 24, marginRight: 10 }} />Sign in using Google</button>

                            <div className="divider d-flex align-items-center my-4 orText">
                                <div className='formLine' />
                                <p className="text-center fw-bold mx-3 mb-0 text-muted ">OR</p>
                                <div className='formLine' />
                            </div>


                            {/* <!-- Email input --> */}
                            <label htmlFor="fname" className='formLabel'>Full Name</label>
                            <div className='form-input forTwoField'>
                                <input 
                                type="text" 
                                placeholder='Full Name'
                                {...register("fullname")}
                                />
                            </div>
                            <p className='validationError'>{errors.fullname?.message}</p>
                            <label htmlFor="fname" className='formLabel'>User Name</label>
                            <div className='form-input forTwoField'>
                                <input 
                                type="text" 
                                placeholder='User Name'
                                {...register("username")}
                                />
                            </div>
                            <p className='validationError'>{errors.userName?.message}</p>
                            <label htmlFor="fname" className='formLabel'>Institute / Company Name</label>
                            <div className='form-input forTwoField'>
                                <input 
                                type="text" 
                                placeholder='Institute / Company Name'
                                {...register("institute")}
                                />
                            </div>
                                <p className='validationError'>{errors.institute?.message}</p>
                            <label htmlFor="fname" className='formLabel'>Email ID</label>
                            <div className='form-input forTwoField'>
                                <input 
                                type="email" 
                                placeholder='email@gmail.com'
                                {...register("email", {
                                    required: "Email Id is required"
                                })}
                                />
                            </div>
                                <p className='validationError'>{errors.email?.message}</p>
                            <label htmlFor="fname" className='formLabel'>Phone Number</label>
                            <div className='form-input forTwoField'>
                                <input type="text" placeholder='+91' disabled="disabled" style={{ width: 60, marginRight: 12 }} />
                                <input 
                                type="number" 
                                placeholder='123 - 245 - 4596'
                                {...register("phone", {
                                    required: "Phone number is required"
                                })}
                                />
                            </div>
                                <p className='validationError'>{errors.phone?.message}</p>
                            <label htmlFor="fname" className='formLabel'>Create password</label>
                            <div className='form-input forTwoField'>
                                <input 
                                type="password" 
                                placeholder='Create Password' 
                                {...register("password", {
                                    required: {  message: "Password is required" }
                                })}
                                />
                            </div>
                            <p className='validationError'>{errors.password?.message}</p>

                            <label htmlFor="terms" className='formLabel'></label>
                            <div className='checkbox'>
                                <input
                                    type="checkbox"
                                    id="terms"
                                    {...register("terms")}
                                    style={{ marginRight: '5px' }}
                                />
                                <p>I agree to the &nbsp;
                                <a href="/privacyPolicy" target='_blank'>Privacy</a>&nbsp; & &nbsp;
                                <a href="/termsOfService" target='_blank'>Terms Conditions</a>
                                </p>
                            </div>
                            {/* </label> */}
                            <p className='validationError'>{errors.terms?.message}</p>

                            {/* <!-- Submit button --> */}
                            <button className='formBtn signIn mt-4' type="submit" value="Sign Up">Sign Up</button>

                            <div className='alredyAccount' style={{ width: '100%', textAlign: 'center' }}>
                                <p className="mb-5 pb-lg-2" >Already Have an account? <NavLink to={"/login"}>Log In</NavLink></p>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default Register;