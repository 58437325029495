import { VISION, OURPARTNERLOADER, RECOGNITIONLOADER, COFOUNDER, OURTEAM } from "./action";


const initialState = {
    visionData:[],
    recognitionData: [],
    ourpartnerData: [],
    cofounderData: [],
    ourTeamData: [],
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      
      case VISION: {
        return {
          ...state,
          visionData: action.payload,
        };
      }
      case RECOGNITIONLOADER: {
        return {
          ...state,
          recognitionData: action.payload,
        };
      }
      case OURPARTNERLOADER: {
        return {
          ...state,
          ourpartnerData: action.payload,
        };
      }
      case COFOUNDER: {
        return {
          ...state,
          cofounderData: action.payload,
        };
      }
      case OURTEAM: {
        return {
          ...state,
          ourTeamData: action.payload,
        };
      }

      default: {
        return state;
      }
    }
  };
  
  export default reducer;
  