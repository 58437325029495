import { GET_OVERVIEW_LIST, ADD_OVERVIEW, UPDATE_OVERVIEW,DELETE_OVERVIEW,GET_OVERVIEW_DETAILS } from "./action";

const initialState = {
  arrayList: null,
  createData: null,
  updateData: null,
  deleteData: null,
  getDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OVERVIEW_LIST: {
      return {
        ...state,
        arrayList: action.payload,
      };
    }
    case ADD_OVERVIEW: {
      return {
        ...state,
        createData: action.payload,
      };
    }
    case UPDATE_OVERVIEW: {
      return {
        ...state,
        updateData: action.payload,
      };
    }
    case DELETE_OVERVIEW: {
      return {
        ...state,
        deleteData: action.payload,
      };
    }
    case GET_OVERVIEW_DETAILS: {
      return {
        ...state,
        getDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
