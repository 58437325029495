import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import LoginSlider from './LoginSlider';
import './login.scss';
import { useForm } from "react-hook-form";
import { userLogging } from './action'
import { useDispatch } from 'react-redux';
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import jwt_decode from 'jwt-decode';
import { generateURL } from '../register/action';

function Login(props) {
    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(yup.object().shape({
            email: yup.string().required("This is required"),
            password: yup.string().required("Password is required"),
        })),
    });

    const handleLogin = async (formData) => {
        try {
            setLoading(true);
            formData.email = btoa(formData.email);
            formData.password = btoa(formData.password);
            const user = await dispatch(userLogging(formData))
            // console.log("user",user);
            setLoading(false);
            //   if (user.status === true) {
            //     if (user?.result?.accountVerified === "approved") {
            localStorage.setItem('user', user.access)
            localStorage.setItem('refresh', user.refresh);
            localStorage.setItem('isAdmin', false)
            if (user?.access) {
                const decodedToken = jwt_decode(user.access);

                if (decodedToken) {
                    const { user_id } = decodedToken;
                    localStorage.setItem('user_id', user_id);
                } else {
                    toast.error('Invalid token');
                }
            } else {
                toast.error('No token found in response');
            }
            toast.success('Login Successfull');
            const fromPathname = location.state
                ? location.state.from
                : '/our-features';
            history(fromPathname);



            //     } else {
            //       history("/account-verification", { state: { verified: false, emailVerified: true } })
            //     }
            //   }
        } catch (error) {
            setLoading(false);
            toast.error(error || "login error");
        }
    }

    const googleLogin = async () => {
        await dispatch(generateURL()).then(url => {
            if (url) {
                window.open(url, "_self");
            }
        }).catch(error => toast.error(error));
    }

    return (
        <section className="vh-100">
            {loading && <Loader />}
            <div className="container-fluid loginPage">
                <div className="row  ">
                    <div className="col-md-6 col-lg-7 col-xl-7 px-5 pt-5 leftSlider">
                        <div className='d-flex justify-content-center'>
                            <NavLink to={"/"}><img src={require('../../assets/logo.png')} className='loginLogo' /></NavLink>
                        </div>
                        <h1 className='FutureReady'>Be Future Ready</h1>
                        <div className='py-3 loginSilderClass' style={{ marginTop: 60 }}>
                            <LoginSlider />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-5">
                        <div className='backgroundImage'>
                            <h1 className=''>Log into your account</h1>
                            <button className='formBtn googleButton' onClick={googleLogin} type="button" value="Send Message" style={{ background: '#fff', border: 0, color: Colors.textBlack, }}><img src={Images.GoogleIcon} style={{ width: 24, marginRight: 10 }} />Sign in using Google</button>
                            <form onSubmit={handleSubmit(handleLogin)}>

                                <div className="divider d-flex align-items-center my-4 orText">
                                    <div className='formLine' />
                                    <p className="text-center fw-bold mx-3 mb-0 text-muted ">OR</p>
                                    <div className='formLine' />
                                </div>


                                {/* <!-- Email input --> */}
                                <label htmlFor="username" className='formLabel'>Email</label>
                                <div className='form-input forTwoField'>
                                    <input type="text" placeholder='Enter your Email id here' autoComplete='off' {...register("email")} />
                                </div>
                                <p className='validationError'>{errors.email?.message}</p>
                                <label htmlFor="password" className='formLabel mt-3'>Password</label>
                                <div className='form-input forTwoField'>
                                    <input type="password" placeholder='Enter your password here' autoComplete='off' {...register("password")} />
                                </div>
                                <p className='validationError'>{errors.password?.message}</p>
                                <p className="small mb-3 pb-lg-2 text-right"><NavLink to={"/change-password"} className="forgetPassword">Forgot password?</NavLink></p>

                                {/* <!-- Submit button --> */}
                                <button className='formBtn signIn' type="submit" value="Sign Up">Login</button>

                                <div className='alredyAccount' style={{ width: '100%', textAlign: 'center' }}>
                                    <p className="mb-5 pb-lg-2" >Don't Have an account? <NavLink to={"/register"}>Sign up</NavLink></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default Login;