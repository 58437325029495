import {
    CURRICULUM_DETAIL,
    TOOLS_LIST,
} from './action';
import { FAILUR } from "../../utilities/constants";

const initialState = {
    curriculumDetail: {},
    toolDetail:[],
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CURRICULUM_DETAIL: {
            return {
                ...state,
                curriculumDetail: action.payload,
            };
        }
        case TOOLS_LIST: {
            return {
                ...state,
                toolDetail: action.payload,
            };
        }
        case FAILUR : {
            return {
                ...state,
                error: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
