import React, { useState, useEffect } from "react";
// import "./index.scss";
import Sidebar from "../../Components/Navigation/Sidebar";
import Topbar from "../../Components/Navigation/Topbar";
import PageHeading from "../../Components/PageHeading";
import Form from "react-bootstrap/Form";
import {
  createUser,
  getAllRoles,
  usersList,
  updateUser,
  getUserDetail,
} from "../Users/action";
import { useNavigate, useLocation } from "react-router-dom";
import CustomDialog from "../../Components/CustomDialog";

const ResetPassword = (props) => {
  const [formData, setFormData] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    // id:""
  });
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState(null);
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const history = useNavigate();
  const [isCta, setisCta] = useState(true);
  const { state } = useLocation();

  
  // useEffect(() => {
  //   if (state?._id) {
  //     props.getUserDetail(state._id);
  //   }
  // }, [state?._id]);
  
  React.useEffect(() => {
    // props.getAllRoles();
    if (props.userDetail) {
      setFormData({
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      });
    }
  }, [props.userDetail]);


  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const submitModal = async (e) => {
    e.preventDefault();
    if (formData.id && formData.id != null) {
      props
        .updateUser(formData)
        .then(async (response) => {
          onResponseRecieved(response);
        })
        .catch((error) => {
          onResponseFailed(error);
        });
    } else {
      props
        .createUser(formData)
        .then(async (response) => {
          onResponseRecieved(response);
        })
        .catch((error) => {
          onResponseFailed(error);
        });
    }
  };

  const onResponseRecieved = (response) => {
    setDialogSubmit(true);
    setMessage(response?.message);
    setOpenModal(true);
    setShowDialog(true);
    setisCta(false);
    props.usersList();
  };
  const onResponseFailed = (error) => {
    setDialogSubmit(false);
    setMessage(error);
    setOpenModal(false);
    setShowDialog(true);
  };

  const handleClose = () => {
    setDialogSubmit(false);
    setOpenModal(false);
    setShowDialog(false);
  };

  const handleDialogSubmit = () => {
    setFormData({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setDialogSubmit(false);
    setOpenModal(false);
    setShowDialog(false);
    history("/admin/users");
  };

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Reset Password"
                buttonName=""
                buttonLink=""
                buttonIcon=""
              />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                          Old Password
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="old password"
                          name="oldPassword"
                          value={formData.oldPassword}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                          New Password
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="New password"
                          name="newPassword"
                          value={formData.newPassword}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                         Confirm Password
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Confirm password"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group mt-5 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={submitModal}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        message={message}
        showDialog={showDialog}
        handleClose={handleClose}
        handleDialogSubmit={handleDialogSubmit}
        isSubmit={dialogSubmit}
        isCta={isCta}
      />
    </div>
  );
};
// const mapStateToProps = ({ adminUserReducer }) => {
//   const { allRoles, userDetail } = adminUserReducer;
//   return { allRoles, userDetail };
// };
// export default connect(mapStateToProps, {
//   createUser,
//   getAllRoles,
//   updateUser,
//   usersList,
//   getUserDetail,
// })(AddUpdateUser);
export default (ResetPassword);
