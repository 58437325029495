import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { getKeyFeatures,deleteKeyFeatures } from "./action";
import { DELETE_MESSAGE } from "../../../utilities/message";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from 'react-toastify'
import CustomDialog from "../../Components/CustomDialog";

//jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Sidebar from "../../Components/Navigation/Sidebar";
import Topbar from "../../Components/Navigation/Topbar";
import PageHeading from "../../Components/PageHeading";

const KeyFeatures = (props) => {
  $(document).ready(function () {
    setTimeout(function () {
      $("#dataTable").DataTable(); 
    }, 1000);
  });

  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState(null);
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const [userId, setUserId] = useState(null)
  const history = useNavigate();

  const handleDialogClose = () => {
    setId(null);
    setShowDialog(false);
  };

  const deleteKeyFeatures = async (id) => {
    setId(id);
    setDialogSubmit(true);
    setMessage(DELETE_MESSAGE);
    setShowDialog(true);
  };

  const handleDialogSubmit = () => {
    props
      .deleteKeyFeatures(id)
      .then(async (response) => {
        toast.success('Key Features Deleted Successfully');
        onResponseRecieved(response);
      })
      .catch((error) => {
        onResponseFailed(error);
      });
  };

  const onResponseRecieved = (response) => {
    props?.getKeyFeatures();
    setDialogSubmit(false);
    setMessage(response?.message);
    setOpenModal(false);
    setShowDialog(false);
  };

  const onResponseFailed = (error) => {
    setDialogSubmit(false);
    setMessage(error?.response?.message);
    setOpenModal(false);
    setShowDialog(true);
  };

  const handleEditData = (userData) => {
    history("/admin/content/keyFeature/addNew", {
      state: { id: userData?.id },
    });
  };

  useEffect(() => {
    props.getKeyFeatures();
  }, []);

  React.useEffect(() => {
  }, [props?.getKeyFeatures]);


  const handleChange = (e, id) => {
    setUserId(id)
    setShowDialog(true)
  }



  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="contents">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Key Features"
                buttonName="Add New"
                buttonLink={"/admin/content/keyFeature/addNew"}
                buttonIcon="fa-plus"
              />

              <div>

              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Key Features
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >

                      <>
                        <thead>
                          <tr>
                            <th className="text-capitalize">Id</th>
                            <th className="text-capitalize">Image</th>
                            <th className="text-capitalize">Title</th>
                            <th className="text-capitalize">Description</th>
                            <th className="text-capitalize">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props?.arrayList?.map((item, index) => (
                            <tr key={"tr" + index}>
                              <td>{item?.id}</td>
                              <td className="text-capitalize">
                                <img src={item?.image?.media} style={{ width: 80, height: 80, objectFit: 'contain' }} />
                              </td>
                              <td className="">{item?.title}</td>
                              <td className="text-capitalize txt-descriptions">
                                {item?.description}
                              </td>
                              <td className="action">
                                {
                                  <a className="text-primary">
                                    <i
                                      className="far fa-edit"
                                      onClick={() => handleEditData(item)}
                                      style={{ marginRight: 10 }}
                                    ></i>
                                  </a>
                                }
                                {
                                  <a
                                    className="deletePost delete text-danger"
                                    rowid="18"
                                    onClick={() => deleteKeyFeatures(item?.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        message={DELETE_MESSAGE}
        showDialog={showDialog}
        handleClose={() => handleDialogClose()}
        handleDialogSubmit={() => handleDialogSubmit()}
        isSubmit
      />
    </div>
  );
};

const mapStateToProps = ({ adminKeyFeaturesReducer }) => {
  const { arrayList } = adminKeyFeaturesReducer;
  return { arrayList };
};

export default connect(mapStateToProps, {
  getKeyFeatures,deleteKeyFeatures,
})(KeyFeatures);

// export default (KeyFeatures);
