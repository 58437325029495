export const API_ENDPOINTS = {
  signup: `/auth/signup/`,
  login: `/auth/token/`,
  refreshToken: `/auth/token/refresh/`,
  google: `/auth/google/`,
  googleToken: `/auth/google/callback/`,
  facebook: `/api/auth/facebook`,
  subscription: `/plan/subscription/`,
  keyfeatures: `cms/home/keyfeatures/`,
  blogs: `/cms/blogs/`,
  overview: `/cms/home/overview/`,
  marketplace: `/cms/home/marketplace/`,
  organizations: `/cms/home/organizations/`,
  backedby: `/cms/home/backed_by/`,
  ourPartners: `/cms/aboutus/partners/`,
  recognition: `/cms/aboutus/recognitions/`,
  vision: `/cms/aboutus/vision/`,
  ourTeam: `/cms/aboutus/team/`,
  curriculum: `/cur/curriculum/`,
  getAllCurriculum: `/cur/allcurriculum/`,
  getInTouch: `/auth/contactus/`,
  get_orders: `/plan/order/`,
  get_orders_history: `/plan/history/`,
  get_recognitions: `/cms/aboutus/recognitions/`,
  get_dashboard_users: `/dashboard/users/`,
  get_all_users: `/auth/users/`,
  get_ourpartner: `/cms/aboutus/partners/`,
  get_meetourteam: `/cms/aboutus/team/`,
  get_subscriptions: `/plan/subscription/`,
  profile_details : '/auth/profile/',
  update_profile : '/auth/profile/',
  change_password : '/auth/changepassword/',
  get_curriculum_details : '/cur/curriculum/6/',
  get_keyFeature : '/cms/home/keyfeatures/',
  get_overview : '/cms/home/overview/',
  your_uploads : '/files/uploaded/',
  get_marketing : '/cms/home/marketplace/',
  get_dashboard_revenue : '/dashboard/revenue/',
  get_dashboard_orders : '/dashboard/orders/',
  send_forgotpassword_OTP : "/auth/forgotpassword/otp/",
  verify_forgotpassword_OTP : "/auth/forgotpassword/verifyotp/",
  forgot_password : "/auth/forgotpassword/resetpass/",
  things_to_watch(topic, difficulty, length) {
    return `/skillmap/watch/?topic=${topic}&difficulty=${difficulty}&length=${length}`
  },
  initiate_payment : "/plan/payment/initiate",
  update_transaction : "/plan/payment/transaction",
  things_to_read : "/skillmap/read/",
  get_tools(keyword) {
    return `/tools/list/?industry=${keyword}`
  },
  skillMapWatch: "skillmap/watch/",
  skillMapRead: "/skillmap/read/",
  skillMapToDo: "/skillmap/do/",
  get_recommended : "/cur/suggested/",
  contact_sales : "/plan/payment/chat",
  generate_new_curriculum : "/cur/generate_curriculum/",
  generate_new_curriculum_toc : "/cur/generate_curriculum_toc/",
  create_new_curriculum : "/cur/curriculum/",
  generate_mcq : "/cur/generate_mcq/",
  generate_topics : "/cur/generate_curriculum_l1/",
  generate_subTopics : "/cur/generate_curriculum_l2/",
  generate_subSubTopics : "/cur/generate_curriculum_l3/",
  uploadEngineFile: "/engine/file-conversation/",
  uploadEngineLink: "/engine/youtube-conversation/",
  assistant: "/engine/gpt-query-by-conv-id/",
  carrerpal : "/carrerpal/get-response/",
  convertFileToText : "/carrerpal/file_to_text/",
  get_conversation: "/engine/get-conversations/",
  get_conversation_replies: "/engine/get-replies/",
  generateExam: "/carrerpal/generate-exam/",
  queryAssistance: "/engine/gpt-query-by-conv-id/",
  delete_conversation: "/engine/conversations/",
  search_conversation: "/engine/conversations/search/",
  update_conversation: "/engine/conversations/",
  skillMap_actions: "/skillmap/skillmapflags/",
  generateArticle: "/engine/article-generator/",
  getCheckMcq: "/cur/mcqcheck/",
  getSeeResult: "/cur/api/mcq/result/by_mcq_id/",
  getViewResult: "/cur/api/mcq/result/",
  submitMcqTest: "/cur/mcq-test/",
  getProfileLibrary : "/skillmap/skillmapflags/",
  activity_matrix : "/cur/activity_matrix/",
  getCreditDetails : "/credits/user-credit-details/",
  createCourse : "/cur/generate_curriculum_content_from_toc/"
};

export const METHOD_TYPE = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};
