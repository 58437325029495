import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
import { FAILUR, PROCCESSING, STOPPROCESSING } from "../../utilities/constants";
import { toast } from "react-toastify";

export const SAVED_THINGS_TO_WATCH_SUCCESS = 'SAVED_THINGS_TO_WATCH_SUCCESS';
export const SAVED_THINGS_TO_WATCH_ERROR = 'SAVED_THINGS_TO_WATCH_ERROR';
export const SAVED_THINGS_TO_WATCH_LOADING = 'SAVED_THINGS_TO_WATCH_LOADING';

export const SAVED_THINGS_TO_DO_SUCCESS = 'SAVED_THINGS_TO_DO_SUCCESS';
export const SAVED_THINGS_TO_DO_ERROR = 'SAVED_THINGS_TO_DO_ERROR';
export const SAVED_THINGS_TO_DO_LOADING = 'SAVED_THINGS_TO_DO_LOADING';

export const SAVED_THINGS_TO_READ_SUCCESS = 'SAVED_THINGS_TO_READ_SUCCESS';
export const SAVED_THINGS_TO_READ_ERROR = 'SAVED_THINGS_TO_READ_ERROR';
export const SAVED_THINGS_TO_READ_LOADING = 'SAVED_THINGS_TO_READ_LOADING';

export const getSavedThingstoWatch = () => async (dispatch) => {
    dispatch({ type: SAVED_THINGS_TO_WATCH_LOADING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.getProfileLibrary}?flag_type=Save&skillmap_type=thing_to_watch`,
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({
                type: SAVED_THINGS_TO_WATCH_SUCCESS,
                payload: response
            })
        })
        .catch((error) => {
            dispatch({
                type: SAVED_THINGS_TO_WATCH_ERROR,
                payload: error,
            });
            throw error.response.data.message || error.message;
        });
};

export const getSavedThingstoDo = () => async (dispatch) => {
    dispatch({ type: SAVED_THINGS_TO_DO_LOADING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.getProfileLibrary}?flag_type=Save&skillmap_type=thing_to_do`,
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({
                type: SAVED_THINGS_TO_DO_SUCCESS,
                payload: response
            })
        })
        .catch((error) => {
            dispatch({
                type: SAVED_THINGS_TO_DO_ERROR,
                payload: error,
            });
            throw error.response.data.message || error.message;
        });
};

export const getSavedThingstoRead = () => async (dispatch) => {
    dispatch({ type: SAVED_THINGS_TO_READ_LOADING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.getProfileLibrary}?flag_type=Save&skillmap_type=thing_to_read`,
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({
                type: SAVED_THINGS_TO_READ_SUCCESS,
                payload: response
            })
        }).catch((error) => {
            dispatch({
                type: SAVED_THINGS_TO_READ_ERROR,
                payload: error,
            });
            throw error.response.data.message || error.message;
        });
};