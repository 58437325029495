import React, { useState } from 'react';
import { Button, ButtonGroup, Menu } from '@mui/material';
import '../ourFeatures/index.scss';
import UpskillrUtils from '../../utilities/UpskillrUtils';
import MachineLearningCard from '../../components/ourFeatures/MachineLearningCard';
import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLikedThingstoDo, getLikedThingstoRead, getLikedThingstoWatch } from "./action";
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';

const LikedLibrary = (props) => {
    const [thingsToDo, setThingsToDo] = React.useState(true);
    const [thingsToWatch, setThingsToWatch] = React.useState(false);
    const [thingsToReads, setThingsToReads] = React.useState(false);

    const { likedThingsToDoSuccess, likedThingsToWatchSuccess, likedThingsToReadSuccess } = useSelector((state) => state.likedLibraryReducer);
    const { likedThingsToDoError, likedThingsToWatchError, likedThingsToReadError } = useSelector((state) => state.likedLibraryReducer);
    const { likedThingsToDoLoading, likedThingsToWatchLoading, likedThingsToReadLoading } = useSelector((state) => state.likedLibraryReducer);

    useEffect(() => {
        props.getLikedThingstoDo().catch(err => {
            toast.error(err || 'Something Went Wrong');
        })
    }, [])

    const handleTabChange = (tabName) => {
        if (tabName === 'thingsToDo') {
            setThingsToDo(true);
            setThingsToWatch(false);
            setThingsToReads(false);
            props.getLikedThingstoDo().catch(err => {
                toast.error(err || 'Something Went Wrong');
            })
        }
        if (tabName === 'thingsToWatch') {
            setThingsToDo(false);
            setThingsToWatch(true);
            setThingsToReads(false);
            props.getLikedThingstoWatch().catch(err => {
                toast.error(err || 'Something Went Wrong');
            })
        }
        if (tabName === 'thingsToReads') {
            setThingsToDo(false);
            setThingsToWatch(false);
            setThingsToReads(true);
            props.getLikedThingstoRead().catch(err => {
                toast.error(err || 'Something Went Wrong');
            })
        }
    }

    return (
        <>
            <>
                {likedThingsToDoLoading || likedThingsToWatchLoading || likedThingsToReadLoading && <Loader />}
                <div className='tabContentHeader'>
                    <div className='tabContentHeaderBox'>
                        <h2 className='pageHeading'>Liked Library</h2>
                    </div>
                </div>
                <div className='savedLibrary'>
                    <div className='innerTab' style={{ justifyContent: 'center', display: 'flex' }}>
                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button className={`${thingsToDo && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToDo')}>Things to Do</Button>
                            <Button className={`${thingsToWatch && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToWatch')}>Things to Watch</Button>
                            <Button className={`${thingsToReads && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToReads')}>Things to Read</Button>
                        </ButtonGroup>

                    </div>
                </div>
                <hr style={{ borderTop: '2px solid #FFF' }} />
                {thingsToDo &&
                    <>
                        <div className='containers'>
                            <div className='row'>
                                {
                                    likedThingsToDoSuccess && likedThingsToDoSuccess.map((obj, i) => {
                                        return <div className='col-md-4' key={i}>
                                            <MachineLearningCard type={obj?.skillmap?.display_name}
                                            title={obj?.skillmap?.title}
                                            courseType={obj?.skillmap?.productDifficultyLevel}
                                            imageUrl={obj?.skillmap?.image}
                                            // totalStudents={'10.1M'}
                                            // totalHours={27}
                                            courseFree={obj?.skillmap?.isCourseFree}
                                            coursePrize={(obj?.skillmap?.coursePrize)?UpskillrUtils.getIndianCurrencyFormatter(obj?.skillmap?.coursePrize):undefined}
                                            showButtons={false}
                                            floatingMenu={false}
                                            onCardClick={()=> window.open(obj?.skillmap?.url, "_blank")}
                                        />
                                        </div>
                                    }

                                    )

                                }
                            </div>
                        </div>
                    </>
                }
                {thingsToWatch && !thingsToDo && !thingsToReads && <>
                    <div className='containers'>
                        <div className='row'>
                            {
                                likedThingsToWatchSuccess && likedThingsToWatchSuccess.map((obj, i) => {
                                    return <div className='col-md-4' key={i}>
                                        <MachineLearningCard 
                                            // type={'Simplilearn'}
                                            title={obj?.skillmap?.title}
                                            // courseTime={'27:01'}
                                            showButtons={false}
                                            // timeFormat={'Minutes'}
                                            // date={'March 31'}
                                            imageUrl={obj?.skillmap?.image}
                                            onCardClick={()=> window.open(obj?.skillmap?.url, "_blank")}
                                            // URL={video?.video_url}
                                            floatingMenu={false}
                                        />
                                    </div>
                                }

                                )

                            }
                        </div>
                    </div>
                </>}
                {thingsToReads && !thingsToDo && !thingsToWatch && <>
                    <div className='containers'>
                        <div className='row'>
                            {
                                likedThingsToReadSuccess && likedThingsToReadSuccess.map((obj, i) => {
                                    return <div className='col-md-4' key={i}>
                                        <MachineLearningCard
                                            // type={'Simplilearn'}
                                            title={obj?.skillmap?.title || ''}
                                            // courseTime={'4'}
                                            showButtons={false}
                                            // timeFormat={'Min read'}
                                            // date={'March 31'}
                                            imageUrl={obj?.skillmap?.image}
                                            onCardClick={() => window.open(obj?.skillmap?.url, "_blank")}
                                            floatingMenu={false}
                                        />
                                    </div>
                                })

                            }
                        </div>
                    </div>
                </>}
            </>

        </>
    )


}

const mapStateToProps = ({ likedLibraryReducer }) => {
    const { likedThingsToDoSuccess, likedThingsToWatchSuccess, likedThingsToReadSuccess } = likedLibraryReducer;
    return { likedThingsToDoSuccess, likedThingsToWatchSuccess, likedThingsToReadSuccess };
}

export default connect(mapStateToProps, {
    getLikedThingstoWatch,
    getLikedThingstoDo,
    getLikedThingstoRead
})(LikedLibrary);