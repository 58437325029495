import * as React from 'react';
import { PropTypes } from "prop-types";
import Images from '../../constants/Images';

import './keyFeatureCard.scss';
import { Card } from 'react-bootstrap';

const KeyFeatureCard = (props) =>{
    const { cardWidth, cardHeight, title, description, image, cardType } = props;

    return(
        <Card style={{ maxWidth: cardWidth?cardWidth:502 }} variant="outlined" className={`featureCardStyle ` + cardType}>
            <Card.Img variant="top" src={image.media} className='cardImg' />
            <div className='cardContentStyle'>
                <div className='cardTitle'>{title}</div>
                <div className='cardSubTitle'>{description}</div>
            </div>
        </Card>
    )
}

export default KeyFeatureCard;

KeyFeatureCard.propTypes = {
    cardWidth:PropTypes.number,
    cardHeight: PropTypes.number,
    cardType: PropTypes.any,
  };