import { GET_BLOGS,ADD_BLOG,UPDATE_BLOG, GET_BLOG_DETAILS } from "./action";

const initialState = {
  blogList: null,
  createdBlog: null,
  updatedBlog: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOGS: {
      return {
        ...state,
        blogList: action.payload,
      };
    }
    case GET_BLOG_DETAILS: {
      return {
        ...state,
        blogDetails: action.payload,
      };
    }
    case ADD_BLOG: {
      return {
        ...state,
        createdBlog: action.payload,
      };
    }
    case UPDATE_BLOG: {
      return {
        ...state,
        updatedBlog: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
