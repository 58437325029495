import React, { useState } from 'react';
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import "../getintouch/getInTouch.css"

function getInTouchForm(props) {
    return (
        <>
        <section className='getInTouchForm'>
        <div className='formContainer' style={{backgroundColor:'#336EBD'}}>
            <h1 style={{color:'black'}}>
            Get in touch
            </h1>
        </div>
        </section>
        </>
    );
}

export default getInTouchForm;