import React, { useState } from 'react';
import Slider from 'react-slick';
import Images from '../../constants/Images';

import './home.scss';
import Colors from '../../constants/Colors';
import UpskillrUtils from '../../utilities/UpskillrUtils';

const PreviousBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.PreArrow} />
        </div>
    )
}
const NextBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.NextArrow} />
        </div>
    )
}

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrow:false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    className: "center",
    centerMode: true,
    centerPadding: "10px",
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: false

            }
        },
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 1,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
            }
        },
    ]
};


function HomeBackedBy(props) {
    const { sliderData } = props;
    const { width, height } = UpskillrUtils.useWindowDimensions();
    const sliderWidth = width / 2;

    return (
        <div className='backedByTestimonial'>
            <Slider
                // prevArrow={<PreviousBtn className={"prevBtn " + props.prevBtnStyle} />}
                // nextArrow={<NextBtn className={"prevBtn " + props.nextBtnStyle} />}
                {...settings}>
                {
                    sliderData.map((item, key) => (
                        <div className='logoSize' key={"sliderData" + key}>
                            <img src={item.image.media} style={{ width: '100%', height: '120px' }} />
                        </div>
                    ))
                }
            </Slider>
        </div>
    );
}

export default HomeBackedBy;