import '../../../../node_modules/react-quill/dist/quill.snow.css';
import "../blogs/Blogs.scss"
import Images from "../../constants/Images";
import React, { useState, useEffect } from 'react';
import { getBlog } from './action';
import { connect } from 'react-redux';
import MockData from '../../pages/homePage/data'
import BlogSlider from "../../components/blogsSlider/BlogSlider";
import { useLocation, useNavigate } from "react-router-dom";
import Blogs from "./Blogs";
import QuillTextEditor from '../../components/multiSearch/QuillTextEditor';

function BlogDetails(props) {

  const [blog, setBlog] = useState([]);
  let {state} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    props.getBlog()
  }, []);

  useEffect(() => {
  
    if ((props.blogData).length > 0) sortData(props.blogData);
  }, [props.blogData]);

  const sortData = (blogData) => {
    const sortedData = blogData.sort((a,b)=> b.id - a.id)
    setBlog(sortedData);
  }

  const handleClick = (e,item) => {
    e.preventDefault();
    
    navigate('.' , { state: item });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
 
  return (
    <div className="BlogDetails">
      <div className='tabContentHeader'>
                <div className='tabContentHeaderBox'>
                    <h2 className='tabContentSubHeading'>{state?.heading}</h2>
                    <h2 className='pageHeading'>{state?.title}</h2>
                </div>
            </div>
      <div className="container mt-4">
        <div className="blogImage mb-4">
          <img src={state?.image.media } style={{ width: '100%', height: 558, objectFit: 'cover' ,borderRadius:24}} />
        </div>
        <div className="blog-text">
        <QuillTextEditor
          placeholder={""}
          editorValue={state?.description}
          disableEditor={true}
          // handleChange={(html) => setDescription(html)}
        />
          {/* <p>{state?.description }</p> */}
        </div>
      </div>
      <div className='homeTestimonialArea'>
        <h2 className='cardHeading'>Recent in Blogs</h2>
        <div className='slide-container'>
          <BlogSlider sliderData={blog} handleClick={handleClick}/>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = ({ blogsReducer }) => {
  const {  blogData } = blogsReducer;
  return {
    blogData
  };
}

export default connect(mapStateToProps, {
  getBlog
})(BlogDetails);

