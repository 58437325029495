import { useEffect, useState } from "react";
import Colors from "../../constants/Colors";
import Images from "../../constants/Images";
import UpskillrUtils from "../../utilities/UpskillrUtils";
import './index.scss';
import { useNavigate } from "react-router";

const SuccessOrder = (props) => {
    const { height, width } = UpskillrUtils.useWindowDimensions();
    const navigate = useNavigate();
    

    return (
        <div className="successOrderPage">
           <img src={Images.SuccessOrder} className="imageStyle"/>
           <h2 className="orderTitle">Order Successful !</h2>
           <p className="subTitle">Your order has been placed successfully, get ready for an amazing experience!</p>
            <button className="btn btnStyle" onClick={()=>navigate('/pricing')}>
                <img src={Images.Compass} className="btyImgStyle" />
                Explore more Plans
            </button>
        </div>
    );
}
export default SuccessOrder;