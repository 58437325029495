import React from 'react'
import './CurriculumBuilder.scss'
import MachineLearningCard from '../ourFeatures/MachineLearningCard';
import Colors from '../../constants/Colors';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { useState } from 'react';
import CardSlider from '../CardSlider';
import Accordion from 'react-bootstrap/Accordion';
import QuillTextEditor from '../multiSearch/QuillTextEditor';

const Information = ({control, contents, setValue, dispatch, width, thingsToWatch, thingsToRead, thingsToDo, activitymatrix, fetchActivityMatrix, activeKeys, handleAccordionSelect, disableEditor}) => {
    const [displayCounts, setDisplayCounts]= useState(3);
    
    useEffect(() => {
        if(width){
            if(width < 768) setDisplayCounts(1);
        }
    }, [width]);

    useEffect(() => {
        setValue('editedContent',contents)
    }, [control, contents, setValue]);


    return (
        <>
        <div style={{width: '100%' }} className='informationSection'>
            <form>
                <Controller
                    control={control}
                    name="editedContent" // Specify the name attribute
                    defaultValue={contents} // Provide a default value if needed
                    render={({ field }) => (
                        // <CKEditor
                        //     editor={ClassicEditor}
                        //     config={{
                        //         toolbar: {
                        //             items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'undo', 'redo'],
                        //         },
                        //     }}
                        //     data={field.value}
                        //     onChange={(event, editor) => {
                        //         const data = editor.getData();
                        //         field.onChange(data);
                        //     }}
                        // />
                        <QuillTextEditor
                            placeholder={""}
                            editorValue={field.value}
                            handleChange={(html)=>field.onChange(html)}
                            disableEditor={disableEditor}
                        />
                    )}
                />
            </form>
            </div>

            <Accordion className='builder-accorion' activeKey={activeKeys} onSelect={handleAccordionSelect}>

                <div style={{ backgroundColor: Colors.background_light_blue, marginBottom:10}} onClick={()=>{
                    if(!activitymatrix?.thing_to_read || activitymatrix?.thing_to_read?.length === 0){
                        fetchActivityMatrix()
                    }
                }}>
                    <Accordion.Item eventKey="2" style={{ backgroundColor: Colors.background_light_blue, marginBottom:20}}>
                     <Accordion.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <h3 style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: '700' }}>
                            Things to read
                        </h3>
                    </div>
                    </Accordion.Header>
                    <Accordion.Body>
                    {activitymatrix?.thing_to_read &&  activitymatrix?.thing_to_read?.length > 0 ?
                    <CardSlider>
                        {  activitymatrix?.thing_to_read?.map((item, i)=>(
                            <MachineLearningCard
                                    key={'read'+i}
                                    title={item.title}
                                    imageUrl={item.picture_url}
                                    machineLearningCardStyle={{ borderBottom: '3px solid #E7EAEE', margin: 10,borderRadius:0}}
                                    imageStyles={{ height: 160, backgroundColor:'#E6EDF7',borderRadius:0 }}
                                    cardContentStyle={{padding:10,paddingBottom:0}}
                                    showButtons={false}
                                    showBottomLine={false}
                                    floatingMenu={false}
                                    onCardClick={()=>window.open(item.url_link, "_blank")}
                                    />
                                    ))
                                }
                    </CardSlider> : <div>No Record Found</div>
                }
                    </Accordion.Body>
                    </Accordion.Item>
                </div>
                <div style={{ backgroundColor: Colors.background_light2_yellow, marginBottom:10}} onClick={()=>{
                    if(!activitymatrix?.thing_to_watch || activitymatrix?.thing_to_watch?.length === 0){
                        fetchActivityMatrix()                 
                    }
                }}>
                    <Accordion.Item eventKey="1" style={{ backgroundColor: Colors.background_light_blue,marginBottom:20}}>
                    <Accordion.Header>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                        <h3 style={{fontSize:18,fontFamily:'Poppins',fontWeight:'700'}}>
                        Things to watch
                        </h3>
                    </div>
                    </Accordion.Header>
                    <Accordion.Body>
                    <div className='row thingsToWatch'>
                        {activitymatrix?.thing_to_watch &&  activitymatrix?.thing_to_watch?.length > 0 ? (           
                    <CardSlider>
                        {activitymatrix?.thing_to_watch?.map((item, i) => (
                            <MachineLearningCard
                                key={"watch" + i}
                                type={''}
                                imageUrl={item.picture_url}
                                title={item.title}
                                machineLearningCardStyle={{ borderBottom: '3px solid #E7EAEE', margin: 10, borderRadius: 0 }}
                                imageStyles={{ height: 160, backgroundColor: '#E6EDF7', borderRadius: 0 }}
                                cardContentStyle={{ padding: 10, paddingBottom: 0 }}
                                showButtons={false}
                                showBottomLine={false}
                                floatingMenu={false}
                                onCardClick={() => window.open(item.url_link, "_blank")}
                                />
                        ))}
                    </CardSlider>
                    ) : <div>No Record Found</div>}
                        
                    </div>
                    </Accordion.Body>
                    </Accordion.Item>
                </div>
                 <div style={{ backgroundColor: Colors.background_light_blue,marginTop:16, marginBottom:10 }} onClick={()=>{
                    if(!activitymatrix?.thing_to_do || activitymatrix?.thing_to_do?.length === 0){
                        fetchActivityMatrix()                 
                    }
                    }}>
                     <Accordion.Item eventKey="0" style={{ backgroundColor: Colors.background_light_blue, marginBottom:20}}>
                         <Accordion.Header>
                     <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                         <h3 style={{fontSize:18,fontFamily:'Poppins',fontWeight:'700'}}>
                         Things to Do
                         </h3>
                     </div>
                         </Accordion.Header>
                     <Accordion.Body>
                     {activitymatrix?.thing_to_do?.length > 0 ?
                     <CardSlider>
                         {activitymatrix?.thing_to_do?.map((item, i) => (
                             <MachineLearningCard
                                 key={'things_to_do' + i}
                                 title={item.title}
                                 imageUrl={item.picture_url}
                                 machineLearningCardStyle={{ borderBottom: '3px solid #E7EAEE', margin: 10,borderRadius:0 }}
                                 imageStyles={{ height: 160,backgroundColor:'#E6EDF7', borderRadius:0}}
                                 cardContentStyle={{ padding:10, paddingBottom:0 }}
                                 showButtons={false}
                                 showBottomLine={false}
                                 floatingMenu={false}
                                 onCardClick={()=>window.open(item.url_link, "_blank")}
                             />
                         ))
                         }
                     </CardSlider>
                       : <div>No Record Found</div>} 
                     </Accordion.Body>
                    </Accordion.Item>
                 </div>
             </Accordion>
        </>
    )
}
export default Information;