import React, { useState, useEffect } from "react";
import "./index.scss";
import Sidebar from "../../Components/Navigation/Sidebar";
import Topbar from "../../Components/Navigation/Topbar";
import PageHeading from "../../Components/PageHeading";
import Form from "react-bootstrap/Form";
import {
  createUser,
  usersList,
  updateUser,
  getUserDetail,
} from "../Users/action";
import { useNavigate, useLocation } from "react-router-dom";
import CustomDialog from "../../Components/CustomDialog";
import { connect } from "react-redux";
import { toast } from 'react-toastify'
import Loader from "../../../components/Loader";

const AddUpdateUser = (props) => {
  const [formData, setFormData] = React.useState({
    fullname: "",
    username:"",
    email: "",
    institute:"",  
    phone:"",  
    password: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState(null);
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const history = useNavigate();
  const [isCta, setisCta] = useState(true);
  const { state } = useLocation();
  const [isLoading, setisLoading] = useState(false);


  
  useEffect(() => {
    if (state?.id) {
      props.getUserDetail(state.id);
    }
  }, [state?.id]);
  
  useEffect(() => {
    if (props.userDetail) {
      setFormData({
        fullname: props.userDetail.fullname,
        username: props.userDetail.username,
        email: props.userDetail.email,
        institute: props.userDetail.institute,
        phone: props.userDetail.phone,
        password: undefined,
      });
    }
  }, [props.userDetail]);



  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const submitModal = async (e) => {
    setisLoading(true)
    e.preventDefault();
    if (state?.id) {
      props
        .updateUser(formData, state?.id)
        .then(async (response) => {
          onResponseRecieved(response);
          setisLoading(false)
        })
        .catch((error) => {
          onResponseFailed(error);
          setisLoading(false)
        });
    } else {
      props
        .createUser(formData)
        .then(async (response) => {
          onResponseRecieved(response);
          setisLoading(false)
          
        })
        .catch((error) => {
          onResponseFailed(error);
          setisLoading(false)
        });
    }
  };

  const onResponseRecieved = (response) => {
    setDialogSubmit(true);
    setMessage(response?.message);
    setOpenModal(true);
    setShowDialog(true);
    setisCta(false);
    // props.usersList();
    toast.success(response?.message)
    history("/admin/users");
    
  };
  const onResponseFailed = (error) => {
    setDialogSubmit(false);
    setMessage(error?.message);
    setOpenModal(false);
    setShowDialog(true);
  };

  // const handleClose = () => {
  //   setDialogSubmit(false);
  //   setOpenModal(false);
  //   setShowDialog(false);
  // };

  // const handleDialogSubmit = () => {
  //   setFormData({
  //     fullname: "",
  //     username: "",
  //     email: "",
  //     institute: "",
  //     phone: "",
  //     password: "",
  //   });
  //   setDialogSubmit(false);
  //   setOpenModal(false);
  //   setShowDialog(false);
  //   history("/admin/users");
  // };

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        {isLoading && <Loader/>}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Add new user"
                buttonName=""
                buttonLink=""
                buttonIcon=""
              />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                          Full Name
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter Full Name"
                          name="fullname"
                          value={formData.fullname}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                          User Name
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter User Name"
                          name="username"
                          value={formData.username}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                          Email
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                        Institute
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter Institute"
                          name="institute"
                          value={formData.institute}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                        Phone Number
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter Phone Number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                          Password
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div> */}
                    <div className="form-group mt-5 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={submitModal}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CustomDialog
        message={message}
        showDialog={showDialog}
        handleClose={handleClose}
        handleDialogSubmit={handleDialogSubmit}
        isSubmit={dialogSubmit}
        isCta={isCta}
      /> */}
    </div>
  );
};
const mapStateToProps = ({ adminUserReducer }) => {
  const { userDetail } = adminUserReducer;
  return { userDetail };
};
export default connect(mapStateToProps, {
  createUser,
  updateUser,
  usersList,
  getUserDetail,
})(AddUpdateUser);
