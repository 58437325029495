import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenuOpen } from '../action';
import logo from '../../../../assets/logo.png';
import { useLocation } from 'react-router-dom';
import '../index.scss';
import useWindowDimensions from '../../WindowDimensions';

const Sidebar = (props) => {
  const location = useLocation()
  const pathArray = window.location.href.split('/');
  const parentMenu = pathArray[4];
  const { clickMenuOpen, toggled } = props;
  const { height, width } = useWindowDimensions();

  return (
    <ul className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'} id="accordionSidebar">

      {/* <!-- Sidebar - Brand --> */}
      <a className="sidebar-brand d-flex align-items-center justify-content-center bg-white">
        <div className="sidebar-brand-text mx-3">
          <img src={logo} className='w-100'/>
        </div>
      </a>

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Nav Item - Dashboard --> */}
      <li className={location.pathname === '/admin/dashboard' ? "nav-item active" : "nav-item"}>           
        <Link className="nav-link active" to="/admin/dashboard">
          <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span></Link>
      </li>
      <li className={parentMenu === 'subscriptions' || parentMenu === 'subscription' ? "nav-item active" : "nav-item"}>
        <a className='nav-link collapsed' href="#" data-toggle="collapse" data-target="#masterMenuSubscription" aria-controls="masterMenuSubscription">
          <i className="fas fa-fw fa-cog"></i>
          <span>Subscriptions</span>
        </a>
        <div id="masterMenuSubscription" className={(parentMenu === 'subscriptions' && width > 768 && !toggled) || (parentMenu === 'subscription' && width > 768 && !toggled) ? "collapse show" : "collapse"} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <Link className={location.pathname === '/admin/subscriptions' ? "collapse-item active" : "collapse-item"} to="/admin/subscriptions">Subscriptions</Link>
            <Link className={location.pathname === '/admin/subscription/addNew' ? "collapse-item active" : "collapse-item"} to="/admin/subscription/addNew">Add New</Link>
          </div>
        </div>
      </li>
      <li className={(parentMenu === 'users') || parentMenu === 'user' ? "nav-item active" : "nav-item"}>
            <a className='nav-link collapsed' href="#" data-toggle="collapse" data-target="#masterMenu" aria-controls="masterMenu">
              <i className="fas fa-fw fa-cog"></i>
              <span>User Managements</span>
            </a>
            <div id="masterMenu" className={(parentMenu === 'users' && width > 768 && !toggled)|| (parentMenu === 'user' && width > 768 && !toggled) ? 'collapse show' : "collapse"} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <Link className={location.pathname === '/admin/users' ? "collapse-item active" : "collapse-item"} to="/admin/users">Users</Link>
                {/* <Link className={location.pathname === '/admin/user/addNew' ? "collapse-item active" : "collapse-item"} to="/admin/user/addNew">Add New</Link> */}
              </div>
            </div>
          </li>
      <li className={parentMenu === 'contents' || parentMenu === 'content' ? "nav-item active" : "nav-item"}>
            <a className='nav-link collapsed' href="#" data-toggle="collapse" data-target="#contentMenu" aria-controls="contentMenu">
              <i className="fa fa-folder"></i>
              <span>Content Managements</span>
            </a>
            <div id="contentMenu" className={(parentMenu === 'contents' && width > 768 && !toggled) || (parentMenu === 'content' && width > 768 && !toggled) ? "collapse show" : "collapse"} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <Link className={(location.pathname === '/admin/content/recogniations' || location.pathname === '/admin/content/recogniation/addNew') ? "collapse-item active" : "collapse-item"} to="/admin/content/recogniations">Recognizations</Link>
                <Link className={(location.pathname === '/admin/content/ourpartners' || location.pathname === '/admin/content/ourpartner/addNew') ? "collapse-item active" : "collapse-item"} to="/admin/content/ourpartners">Our Partners</Link>
                <Link className={(location.pathname === '/admin/content/meetourteam' || location.pathname === '/admin/content/meetourteam/addNew') ? "collapse-item active" : "collapse-item"} to="/admin/content/meetourteam">Meet Our Team</Link>
                {/* <Link className={(location.pathname === '/admin/content/meetourcofounders' || location.pathname === '/admin/content/meetourcofounder/addNew') ? "collapse-item active" : "collapse-item"} to="/admin/content/meetourcofounders">Meet Our Co - Founders</Link> */}
                <Link className={(location.pathname === '/admin/content/keyFeatures' || location.pathname === '/admin/content/keyFeature/addNew') ? "collapse-item active" : "collapse-item"} to="/admin/content/keyFeatures">Key Features</Link>
                <Link className={(location.pathname === '/admin/content/homeOverviews' || location.pathname === '/admin/content/homeOverview/addNew') ? "collapse-item active" : "collapse-item"} to="/admin/content/homeOverviews">Overviews</Link>
                <Link className={(location.pathname === '/admin/content/marketplace' || location.pathname === '/admin/content/marketplace/addNew') ? "collapse-item active" : "collapse-item"} to="/admin/content/marketplace">Marketplace</Link>
                <Link className={(location.pathname === '/admin/content/organizations' || location.pathname === '/admin/content/organization/addNew') ? "collapse-item active" : "collapse-item"} to="/admin/content/organizations">Organizations</Link>
              </div>
            </div>
          </li>
      {/* <!-- Nav Item - Orders --> */}
      <li className={parentMenu === 'blogs' || parentMenu === 'blog' ? "nav-item active" : "nav-item"}>
        <a className='nav-link collapsed' href="#" data-toggle="collapse" data-target="#masterMenuBlogs" aria-controls="masterMenuBlogs">
          <i className="fas fa-fw fa-cog"></i>
          <span>Blogs</span>
        </a>
        <div id="masterMenuBlogs" className={(parentMenu === 'blogs' && width > 768 && !toggled) || (parentMenu === 'blog' && width > 768 && !toggled) ? "collapse show" : "collapse"} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <Link className={location.pathname === '/admin/blogs' ? "collapse-item active" : "collapse-item"} to="/admin/blogs">Blogs</Link>
            <Link className={location.pathname === '/admin/blog/addNew' ? "collapse-item active" : "collapse-item"} to="/admin/blog/addNew">Add New</Link>
          </div>
        </div>
      </li>
      {/* <!-- Nav Item - Orders --> */}
      <li className={parentMenu === 'orders' || parentMenu === 'order' ? "nav-item active" : "nav-item"}>
        <a className='nav-link collapsed' href="#" data-toggle="collapse" data-target="#masterMenuOrders" aria-controls="masterMenuBlogs">
          <i className="fas fa-fw fa-cog"></i>
          <span>Orders</span>
        </a>
        <div id="masterMenuOrders" className={(parentMenu === 'orders' && width > 768 && !toggled) || (parentMenu === 'order' && width > 768 && !toggled) ? "collapse show" : "collapse"} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <Link className={location.pathname === '/admin/orders' ? "collapse-item active" : "collapse-item"} to="/admin/orders">Orders</Link>
          </div>
        </div>
      </li>
      {/* <!-- Sidebar Toggler (Sidebar) --> */}
      <div className="text-center d-none d-md-inline">
        <button onClick={() => clickMenuOpen()} className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>

    </ul>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = store => ({
  toggled: store.menuState.menuOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);