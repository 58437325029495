import { API_ENDPOINTS,METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";
import Images from "../../../constants/Images";


export const GET_OURPARTNER = "GET_OURPARTNER";
export const ADD_OURPARTNER = "ADD_OURPARTNER";
export const UPDATE_OURPARTNER = "UPDATE_OURPARTNER";
export const DELETE_OURPARTNER = "DELETE_OURPARTNER";
export const GET_OURPARTNER_DETAILS = "GET_OURPARTNER_DETAILS";

export const getOurPartners = (value) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: `${API_ENDPOINTS.get_ourpartner}`,
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: GET_OURPARTNER,
          payload: response,
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const deleteOurPartners = (id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.delete,
      url: API_ENDPOINTS.get_ourpartner+id+"/",
    };
    return api(data)
    .then(async (response) => {
        return response.data;
      })
      .then(async (response) => {
        if (response) {
          dispatch({
            type: DELETE_OURPARTNER,
            payload: response,
          });
          getOurPartners();
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
};

export const createOurPartners = (partenrsData,id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.post,
      url: `${API_ENDPOINTS.get_ourpartner}`,
      data: {...partenrsData,image_id:id},
    };
    return api(data)
    .then(async (response) => {
        return response.data;
      })
      .then(async (response) => {
          if (response) {
          dispatch({
            type: ADD_OURPARTNER,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };
  export const updateOurPartners = (partnerDetails,mainId, id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.put,
      url: API_ENDPOINTS.get_ourpartner+mainId+"/",
      data: {...partnerDetails,image_id:id},
    };
    return api(data)
      .then(async (response) => {
        return response.data;
      })
      .then(async (response) => {
        if (response) {
          dispatch({
            type: UPDATE_OURPARTNER,
            payload: response.data,
          });
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };
  
  export const getOurPartnersDetail = (id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.get_ourpartner+id+"/",
    };
    return api(data)
      .then(async (response) => {
          return response;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: GET_OURPARTNER_DETAILS,
                    payload: response.data,
                });
            }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };

