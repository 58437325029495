import React, { useState } from 'react';
import Slider from 'react-slick';
import Images from '../../constants/Images';
import Card from '@mui/material/Card';
import './home.scss';
import Colors from '../../constants/Colors';
import UpskillrUtils from '../../utilities/UpskillrUtils';

const PreviousBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.PreArrow} />
        </div>
    )
}
const NextBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.NextArrow} />
        </div>
    )
}

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    className: "center",
    centerMode: true,
    centerPadding: "10px",
    variableWidth: true
};


function HomeTestimonial(props) {
    const { sliderData } = props;
    const { width, height } = UpskillrUtils.useWindowDimensions();
    const sliderWidth = width / 2;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => { setIsReadMore(!isReadMore) };

    return (
        <div className='homeTestimonial'>
            <Slider
                prevArrow={<PreviousBtn className={"prevBtn " + props.prevBtnStyle} />}
                nextArrow={<NextBtn className={"prevBtn " + props.nextBtnStyle} />}
                {...settings}>
                {
                    sliderData.map((item, i) => {
                        return (
                            <Card elevation={0} style={{ width: sliderWidth }} className='cardBox' key={'homeTestimonial' + i}>
                                <div className='cardBoxContents'>
                                    <h4 className='cardHeadingText'>{item.title}</h4>
                                    <div className='cardDetailText'>
                                        {/* {item.subTitle} */}
                                        {item?.subTitle}
                                        {/* {isReadMore ? item.subTitle.slice(0, 230) : item.subTitle} */}
                                        {/* {item.subTitle.length > 230 &&
                                        <span onClick={toggleReadMore} className='show-more-less'>
                                            {isReadMore ? '...read more' : ' ...show less'}
                                        </span>
                                    } */}
                                    </div>
                                </div>
                                <div className='profileArea'>
                                    <img src='https://propreturns-strapi-bucket.s3.ap-south-1.amazonaws.com/avatar_1_811f30cb41.svg' style={{ width: 56, height: 56, borderRadius: 40, backgroundColor: Colors.lightCobaltBlue, marginRight: 12 }} />
                                    <div>
                                        <h5>{item.profileName}</h5>
                                        <p className='profileDetailText'>{item.location}</p>
                                    </div>
                                </div>
                            </Card>
                        )
                    })
                }

            </Slider>
        </div>
    );
}

export default HomeTestimonial;