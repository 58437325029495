import { GET_KEY_FEATURE_LIST, ADD_KEY_FEATURE, UPDATE_KEY_FEATURE,DELETE_KEY_FEATURE,GET_KEY_FEATURE_DETAILS } from "./action";

const initialState = {
  arrayList: null,
  createData: null,
  updateData: null,
  deleteData: null,
  getFeatureDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KEY_FEATURE_LIST: {
      return {
        ...state,
        arrayList: action.payload,
      };
    }
    case ADD_KEY_FEATURE: {
      return {
        ...state,
        createData: action.payload,
      };
    }
    case UPDATE_KEY_FEATURE: {
      return {
        ...state,
        updateData: action.payload,
      };
    }
    case DELETE_KEY_FEATURE: {
      return {
        ...state,
        deleteData: action.payload,
      };
    }
    case GET_KEY_FEATURE_DETAILS: {
      return {
        ...state,
        getFeatureDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
