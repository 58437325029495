import React, { useState } from 'react'
// import './index.scss'
import { useCollapse } from 'react-collapsed'
import { Button } from '@mui/material';
import Images from '../../constants/Images';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import UploadFile from '../upSkillrAssistant/UploadFile';
import Chat from './Chat';
import LeftCard from '../upSkillrAssistant/LeftCard';
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { careerPalResponse, generateExam, type } from './action';
import { toast } from 'react-toastify';
import { MCQS_ERROR, MCQS_LOADING, MCQS_SUCCESS } from "./action";

function CareerPal() {
    const [isExpanded, setExpanded] = useState(false);
    const dispatch = useDispatch();

    const { cvEvaluationLoading,cvEvaluationSuccess,cvEvaluationError,skillsetEvalutionLoading,skillsetEvalutionSuccess,skillsetEvalutionError, mcqsLoading,mcqsSuccess,mcqsError,caseStudyLoading,caseStudySuccess,caseStudyError,codingSimulationLoading,codingSimulationSuccess, codingSimulationError, careerPathLoading, careerPathSuccess, careerPathError, resultOrientedLoading, resultOrientedSuccess, resultOrientedError,filetotextLoading, interviewPreparationLoading, interviewPreparationError, interviewPreparationSuccess, generateExamLoading } = useSelector((state)=> state.careerPalReducer);

    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubmission = (data) => {       
        dispatch(careerPalResponse(data)).catch(err=>{
            console.log("Error", err);
            toast.error(err);
        });
    }

    const generateExamFunction = (data, updatedMessage) => {
        dispatch(generateExam(data)).then((response)=>{
            updatedMessage.conversationHistory.splice(1, 1);
            dispatch({
                type: MCQS_SUCCESS,
                payload: updatedMessage,
            });
            const updatedSuccess = {
                ...updatedMessage,
                conversationHistory: [...updatedMessage.conversationHistory, {
                    role: 'assistant',
                    content: { choices : response.Questions.choices}
                }],
              };
              dispatch({
                type: MCQS_SUCCESS,
                payload: updatedSuccess,
            });
        }).catch(err=>{
            console.log("Error", err);
            toast.error(err);
        });
    }

    return (
        <>
            <div className='container-fluid leftViewMcq'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={3} className='leftViewMcq' style={{ backgroundColor: '#F7F8F9', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div className='upSkillrAssistant'>
                                <div className='linksLeftTab tabView'>
                                    <Nav variant="pills" className="flex-column">
                                        <Accordion defaultActiveKey="0" style={{ width: '100%', }}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header >
                                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                        <img src={Images.Evaluation} style={{ width: 24, height: 24, marginRight: 4, }} />
                                                        <div className='title' style={{fontSize:16,fontWeight:'500',color: '#001F49'}}>Evaluation</div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Nav.Item style={{textIndent:20}}>
                                                        <Nav.Link eventKey="first"> <LeftCard
                                                            title={'CV Evaluation'}
                                                            imageUrl={false}
                                                            showDots={false}
                                                        /></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item style={{textIndent:20}}>
                                                        <Nav.Link eventKey="second"> <LeftCard
                                                            title={'Skillset Evaluation'}
                                                            imageUrl={false}
                                                            showDots={false}
                                                        /></Nav.Link>
                                                    </Nav.Item>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item>
                                                <Accordion.Header >
                                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                        <img src={Images.SkillValidator} style={{ width: 24, height: 24, marginRight: 4, }} />
                                                        <div className='title' style={{fontSize:16,fontWeight:'500',color: '#001F49'}}>Skill Validator</div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Nav.Item style={{textIndent:20}}>
                                                        <Nav.Link eventKey="third"> <LeftCard
                                                            title={'MCQ'}
                                                            imageUrl={false}
                                                            showDots={false}
                                                        /></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item style={{textIndent:20}}>
                                                        <Nav.Link eventKey="four"> <LeftCard
                                                            title={'Case Study'}
                                                            imageUrl={false}
                                                            showDots={false}
                                                        /></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item style={{textIndent:20}}>
                                                        <Nav.Link eventKey="five"> <LeftCard
                                                            title={'Simulation'}
                                                            imageUrl={false}
                                                            showDots={false}
                                                        /></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item style={{textIndent:20}}>
                                                        <Nav.Link eventKey="six"> <LeftCard
                                                            title={'Interview Preparation'}
                                                            imageUrl={false}
                                                            showDots={false}
                                                        /></Nav.Link>
                                                    </Nav.Item>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <Nav.Item>
                                            <Nav.Link eventKey="CareerPath"> <LeftCard
                                                title={'Career path'}
                                                imageUrl={Images.CareerPath}
                                                showDots={false}
                                            /></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ResultOriented"> <LeftCard
                                                title={'Result oriented upskilling'}
                                                imageUrl={Images.ResultOriented}
                                                showDots={false}
                                            /></Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Chat key="1" dispatch={dispatch} data={cvEvaluationSuccess} type={"cv-bot-career-pal"} loading={cvEvaluationLoading} filetotextLoading={filetotextLoading} handleSubmission={handleSubmission}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Chat key="2" dispatch={dispatch} data={skillsetEvalutionSuccess} loading={skillsetEvalutionLoading}  type={"skillset-bot-career-pal"} filetotextLoading={filetotextLoading} handleSubmission={handleSubmission}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <Chat key="3" data={mcqsSuccess} dispatch={dispatch} loading={generateExamLoading} type={"mcqs-bot-career-pal"} handleSubmission={generateExamFunction}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="four">
                                    <Chat key="4" data={caseStudySuccess} loading={caseStudyLoading}  type={"case-study-bot-career-pal"} handleSubmission={handleSubmission}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="five">
                                    <Chat key="5" data={codingSimulationSuccess} loading={codingSimulationLoading}  type={"job-simulation-bot-career-pal"} handleSubmission={handleSubmission}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="six">
                                    <Chat key="6" data={interviewPreparationSuccess} loading={interviewPreparationLoading}  type={"interview-preparation-bot-career-pal"} handleSubmission={handleSubmission}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="CareerPath">
                                    <Chat key="7" data={careerPathSuccess} loading={careerPathLoading}  type={"path-provider-bot-career-pal"} handleSubmission={handleSubmission}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ResultOriented">
                                    <Chat key="8" data={resultOrientedSuccess} loading={resultOrientedLoading}  type={"result-oriented-upskilling-bot-career-pal"} handleSubmission={handleSubmission}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}
export default CareerPal;