import React, { useState } from 'react';
import Slider from 'react-slick';
import Images from '../../constants/Images';
import Card from '@mui/material/Card';
import './login.scss';
import Colors from '../../constants/Colors';
import UpskillrUtils from '../../utilities/UpskillrUtils';


const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "center",
    centerMode: true,
    centerPadding: "10px",
    variableWidth: true
};


function LoginSlider(props) {
    const { sliderData } = props;
    const { width, height } = UpskillrUtils.useWindowDimensions();
    const sliderWidth = width / 2;

    return (
        <div className='LoginSlider'>
            <Slider
            {...settings}>
            <div className='yellow-backround boxbackground'>
                <h1>
                    Multi-search Writing
                </h1>
                <img src={Images.MultiSearchWritingIcon} style={{ width: 160, objectFit: 'contain' }} />
            </div>
            <div className='blue-backround boxbackground'>
                <h1>
                Curriculum Builder
                </h1>
                <img src={Images.CurriculumBuilderIcon} style={{ width: 160, objectFit: 'contain' }} />
            </div>
            <div className='yellow-backround boxbackground'>
                <h1>
                Tools Advisory
                </h1>
                <img src={Images.ToolsAdvisoryIcon} style={{ width: 160, objectFit: 'contain' }} />
            </div>
            <div className='blue-backround boxbackground'>
                <h1>
                Career Pal
                </h1>
                <img src={Images.CareerPalIcon} style={{ width: 160, objectFit: 'contain' }} />
            </div>
            <div className='yellow-backround boxbackground'>
                <h1>
                Upskillr Assistant
                </h1>
                <img src={Images.UpskillrAssistantIcon} style={{ width: 160, objectFit: 'contain' }} />
            </div>
            <div className='yellow-backround boxbackground'>
                <h1>
                Learn,discover,curate
                </h1>
                <img src={Images.LearnDiscoverCurateIcon} style={{ width: 160, objectFit: 'contain' }} />
            </div>

            </Slider>
        </div>
    );
}

export default LoginSlider;