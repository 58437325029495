import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { addContent, getContentDetailsById } from "../action";
import { uploadImage, updateImageById } from "../../../action";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL } from "../../../../utilities/constants";
import Sidebar from "../../../Components/Navigation/Sidebar";
import Topbar from "../../../Components/Navigation/Topbar";
import PageHeading from "../../../Components/PageHeading";
import { useFieldArray, useForm } from "react-hook-form";
import Images from "../../../../constants/Images";
import { toast } from "react-toastify";

const label = { inputProps: { 'aria-label': 'controlled' } };

const UpadteOrganization = (props) => {
  const fileInputRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [organizationId, setOrganizationId] = useState(null);
  const [pageTitle, setPageTitle] = useState('Add New');
  const [imagePreviews, setImagePreviews] = useState([]);
  const { state } = useLocation();
  const [isInitialAppend, setIsInitialAppend] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [preview, setPreview] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'upadteOrganization',
  });


  useEffect(() => {
    let id = searchParams.get("update_id")
    if (id) {
      setOrganizationId(id);
      setPageTitle('Update Organization');
      props.getContentDetailsById(id);
    }
  }, []);

  useEffect(() => {
    if (isInitialAppend) {
      append(); // Trigger append when the component mounts
      setIsInitialAppend(false); // Update the flag to prevent further appends
    }
  }, [append, isInitialAppend]);

  useEffect(() => {
    if (props?.contentDetails) {
      console.log(props?.contentDetails);
    }
  }, [props?.contentDetails]);


  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setSelectedFiles((prevArray) => [...prevArray, file]);
    reader.onload = () => {
      const newImagePreviews = [...imagePreviews];
      newImagePreviews[index] = reader.result;
      setImagePreviews(newImagePreviews);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleReset = () => {
    formRef.current.reset();
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);

  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);


  const onSubmit = async (data) => {
    if (state && state.id) {
      if (selectedFile) {
        let formData = new FormData();
        formData.append('media', selectedFile);
        const uploadResp = await props.updateImageById(props?.contentDetails?.image?.id, formData);
        if (uploadResp.status === 200) {
          toast.success('Data uploaded');
          navigate(`/admin/content/organizations`);
        }
      }
    } else {
      if (selectedFiles.length > 0) {
        let promiseArray = [];
        for (const element of selectedFiles) {
          promiseArray.push(uploadImage(element));
        }
        Promise.all(promiseArray)
          .then(result => {
            toast.success('Data added');
            navigate(`/admin/content/organizations`);
          });

      } else {
        toast.error('Please select image')
      }
    }
  };

  async function uploadImage(element) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('media', element);
      props.uploadImage(formData).then(async (res) => {
        const imageId = res.data.id;
        await props.addContent({ image_id: imageId }).then(res => {
          setUploadedImage((prevArray) => [...prevArray, { imageId: imageId, contentId: res.id }]);
          // arrray.push({ imageId: imageId, contentId: res.id });
          resolve({ imageId: imageId, contentId: res.id });
        }).catch(error => {
          console.log("Error insert data", error)
          reject(error);
        });
      }).catch((error) => {
        console.log("Error on image upload", error)
        reject(error);
      });
    });
  }

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading title={pageTitle} />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Add organization details
                          </Form.Label>
                        </Form.Group>
                      </div>
                      {props?.contentDetails &&
                        <>
                          <div className="form-group">
                            <label htmlFor="fileInput" style={{ backgroundColor: 'lightgray' }}>
                              {selectedFile ? (
                                <img src={preview} className="imgthumb" style={{ width: 80, height: 80, objectFit: 'contain' }} />
                              ) : (
                                props.contentDetails && (
                                  <img
                                    src={`${props?.contentDetails?.image?.media}`}
                                    className="imgthumb"
                                    style={{ width: 80, height: 80, objectFit: 'contain' }}
                                  />
                                )
                              )}
                            </label>
                          </div>
                          <div className="">
                            <Form.Group className="mb-3">
                              <a className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                                onClick={() => fileInputRef.current.click()}>Change Image</a>
                              <Form.Control
                                id="fileInput"
                                type="file"
                                className="form-control"
                                accept='image/*'
                                placeholder=""
                                onChange={onSelectFile}
                                ref={fileInputRef}
                                hidden
                              />
                            </Form.Group>
                          </div>
                        </>
                      }
                      {!props?.contentDetails &&
                        <>
                          {fields.map((field, index) => (
                            <div key={field.id}>
                              <div className="row mb-3 align-items-center">
                                <div className="col-md-2">
                                  <label htmlFor={`fileInput-${index}`}>
                                    <img src={imagePreviews[index] || require('../../../../assets/team_blue_card.png')} alt="Preview" style={{ width: 80, height: 80, objectFit: 'contain' }} />
                                  </label>
                                  <input
                                    id={`fileInput-${index}`}
                                    type="file"
                                    {...register(`upadteOrganization[${index}].file`, {
                                      required: 'Please select an image file'
                                    })}
                                    onChange={(e) => handleFileChange(e, index)}
                                    style={{ display: 'none' }}
                                  />
                                  {errors.upadteOrganization && errors.upadteOrganization[index] && (
                                    <p className='validationError'>{errors.upadteOrganization[index].file?.message}</p>
                                  )}
                                </div>
                                {/* <Form.Group className="col-md-8" controlId={'title_' + index}>
                                <Form.Control placeholder="Organization title" defaultValue={field.name} {...register(`upadteOrganization.${index}.title`,{
                                    required: 'Please enter title'})}/>
                                {errors.upadteOrganization && errors.upadteOrganization[index] && (
                                  <p className='validationError'>{errors.upadteOrganization[index].title?.message}</p>
                                )}
                              </Form.Group> */}
                                <div className="col-md-2 d-flex text-align-center justify-content-center">
                                  <a onClick={() => remove({})} style={{ cursor: 'pointer' }}>
                                    <img src={Images.RemoveComprasion} style={{ width: 40, height: 40 }} />
                                  </a>
                                </div>
                              </div>
                              <hr />

                            </div>
                          ))}
                          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', }}>
                            <button type="button" onClick={() => append({})} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Add blank row"><i className="fa fa-plus"></i></button>
                          </div>
                        </>
                      }
                      <div className=" mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminOrganizationsReducer }) => {
  const { contentDetails } = adminOrganizationsReducer;
  return { contentDetails };
};

export default connect(mapStateToProps, {
  addContent,
  uploadImage,
  getContentDetailsById,
  updateImageById
})(UpadteOrganization);

