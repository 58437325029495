import { API_ENDPOINTS, METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";
import Images from "../../../constants/Images";


export const GET_OVERVIEW_LIST = "GET_OVERVIEW_LIST";
export const ADD_OVERVIEW = "ADD_OVERVIEW";
export const UPDATE_OVERVIEW = "UPDATE_OVERVIEW";
export const DELETE_OVERVIEW = "DELETE_OVERVIEW";
export const GET_OVERVIEW_DETAILS = "GET_OVERVIEW_DETAILS";

export const getHomeOverviews = (value) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.get_overview}`,
    };
    return api(data)
      .then(async (response) => {
        console.log('getHomeOverviews',response)
        return response;
      })
      .then(async (response) => {
        if (response) {
    dispatch({
        type: GET_OVERVIEW_LIST,
        payload: response.data,
    });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const deleteHomeOverviews = (id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.delete,
        url: `${API_ENDPOINTS.get_overview+id+'/'}`,
    };
    return api(data)
      .then(async (response) => {
        console.log('getHomeOverviews',response)
        return response;
      })
      .then(async (response) => {
        if (response) {        
    dispatch({
        type: DELETE_OVERVIEW,
        payload: response.data.results,
    });
    getHomeOverviews()
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const createHomeOverviews = (overviewsData,id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.post,
    url: `${API_ENDPOINTS.get_overview}`,
    data: {...overviewsData,video_id:id},
  };
  return api(data)
  .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
        if (response) {
        dispatch({
          type: ADD_OVERVIEW,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const updateHomeOverviews = (overviewsData,mainId, id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.put,
    url: API_ENDPOINTS.get_overview+mainId+"/",
    data: {...overviewsData,video_id:id},
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: UPDATE_OVERVIEW,
          payload: response.data,
        });
      }
      return response;
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const getHomeOverviewsDetail = (id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: API_ENDPOINTS.get_overview+id+"/",
  };
  return api(data)
    .then(async (response) => {
        return response;
      })
      .then(async (response) => {
          if (response) {
              dispatch({
                  type: GET_OVERVIEW_DETAILS,
                  payload: response.data,
              });
          }
      return response;
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};


