import React, { useState } from 'react'
import { useCollapse } from 'react-collapsed'
import { ChevronIcon } from './ChevronIcon'
import './CurriculumCollapsableView.css'
import './Table.scss'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem, Button } from '@mui/material';
import Images from '../../../constants/Images';
import Colors from '../../../constants/Colors';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { useCallback } from 'react'


function CurriculumCollapsableView(props) {
	const { children, title, childStyles, titleStyles, containerStyles, showIcon, showDots, collapseLevel, handleAction, contents, data} = props
	const [isExpanded, setExpanded] = useState(false)
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget);
	});
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [show, setShow] = useState(false);

	const handleCloseResult = () => setShow(false);
	const handleShowResult = () => setShow(true);

	const handleClickMenu=useCallback((leve, id, action, data= null)=>{
		handleAction(leve, id, action, data);
		handleClose();
	});


	return (
		<>
			<div className='Collapsablecard' style={{ padding: 12, backgroundColor: '#B5D2F9', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', ...containerStyles }} >
				<div style={{width: '100%'}} {...getToggleProps({ onClick: () => setExpanded((prevExpanded) => !prevExpanded)})}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}} onClick={props.onClick}>
					{showIcon !== false && <span className={`ChevronButton ${isExpanded ? 'ActiveChevron' : ''}`}>
						<ChevronIcon />
					</span>}
					<h2 style={{ width: '100%', fontSize: 16, marginLeft: 12, marginBottom: 0, ...titleStyles }}>{title}</h2>
					</div>
				</div>
				{showDots !== false &&
					<div>
						<div className='floatingBtn'>
							<Button
								// id="basic-button"
								aria-controls={open ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
								style={{ color: Colors.dark_blue }}
							>
								<MoreHorizIcon />
							</Button>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
							>
								<MenuItem onClick={()=>handleClickMenu(props.data.level,props.data.title,"rename")}>
									<img src={Images.Rename} style={{ width: 16, height: 16, marginRight: 8 }} />Rename
								</MenuItem>
								{collapseLevel && collapseLevel ===1 &&
								<MenuItem onClick={()=>handleClickMenu(props.data.level,props.data.title,"addNewSubTopic")}>
									<img src={Images.CirclePlus} style={{ width: 16, height: 16, marginRight: 8 }} />New Sub-topic
								</MenuItem>
								}
								{collapseLevel && collapseLevel ===2 &&
								<MenuItem onClick={()=>handleClickMenu(props.data.level,props.data.title,"addNewSubSubTopic")}>
									<img src={Images.CirclePlus} style={{ width: 16, height: 16, marginRight: 8 }} />New Subsub-topic
								</MenuItem>
								}
								{collapseLevel && collapseLevel === 3 && !props.data.mcqs  &&
										<MenuItem onClick={()=>handleClickMenu(props.data.level,props.data.title,'addMcqs', contents)}>
											<img src={Images.GenerateMcqs} style={{ width: 16, height: 16, marginRight: 8 }} /> Generate MCQ’s
										</MenuItem>
								}
								<MenuItem onClick={()=>handleClickMenu(props.data.level,props.data.title,'delete')}>
									<img src={Images.Trash} style={{ width: 16, height: 16, marginRight: 8 }} /> Delete
								</MenuItem>
							</Menu>
						</div>
					</div>
				}
			</div>
			<section {...getCollapseProps()}>
				{
					(typeof children != 'undefined') && (children.length > 0) && <div style={{ backgroundColor: '#E9F3FF', cursor: 'pointer', ...childStyles }}>
					{children}
				</div>
				}
				
			</section>
			<Modal size="lg" className='Tables'
				aria-labelledby="contained-modal-title-vcenter"
				centered show={show} onHide={handleCloseResult}>
				{/* <Modal.Header closeButton style={{marginTop:-50}} /> */}
				<Button onClick={handleCloseResult} style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
					<img src={require('../../../assets/table_close_icon.png')} style={{width:32,height:32,marginTop:-50}}/>
				</Button>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th className='Headtable'>Sr. No</th>
							<th className='Headtable'>Name</th>
							<th className='Headtable'>Email</th>
							<th className='Headtable'>Score</th>
							<th className='Headtable'>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>01</td>
							<td>Arnav Chakraborty</td>
							<td>arnavchakraborty@gmail.com</td>
							<td>09 / 10</td>
							<td className='ViewDetails'>View Details</td>
						</tr>
						<tr>
							<td>02</td>
							<td>Aditi Gupta</td>
							<td>aditi.gupta@gmail.com</td>
							<td>06 / 10</td>
							<td className='ViewDetails'>View Details</td>
						</tr>
						<tr>
							<td>03</td>
							<td>Arnav Chakraborty</td>
							<td>arnavchakraborty@gmail.com</td>
							<td>06 / 10</td>
							<td className='ViewDetails'>View Details</td>
						</tr>
					</tbody>
				</Table>
			</Modal>			
		</>
	)
}
export default CurriculumCollapsableView;