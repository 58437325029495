import { API_ENDPOINTS, METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";

export const USERS_LIST = "USERS_LIST";
export const ADD_USERS = "ADD_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const GET_USER_DETAIL = "GET_USER_DETAIL";

export const usersList = (value) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: `${API_ENDPOINTS.get_all_users}?type=${value}`,
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: USERS_LIST,
          payload: response,
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const createUser = (userData) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.post,
    url: API_ENDPOINTS.get_all_users,
    data: userData,
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: ADD_USERS,
          payload: response,
        });
      }
      return response;
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const updateUser = (userData, id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.put,
    url: API_ENDPOINTS.get_all_users+id+"/",
    data: userData,
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: UPDATE_USERS,
          payload: response,
        });
      }
      return response;
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const deleteUser = (id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.delete,
    url: API_ENDPOINTS.get_all_users+id+"/",
    // data: { id: id},
  };
  return api(data)
  .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: DELETE_USERS,
          payload: response,
        });
        usersList();
      }
      return response;
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const getUserDetail = (id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: API_ENDPOINTS.get_all_users  + id+"/",
  };
  return api(data)
    .then(async (response) => {
      return response?.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: GET_USER_DETAIL,
          payload: response,
        });
      }
      return response;
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};
