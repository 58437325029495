import { API_ENDPOINTS,METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
import Images from "../../constants/Images";


export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOG_DETAILS = "GET_BLOG_DETAILS";
export const ADD_BLOG = "ADD_BLOG";
export const UPDATE_BLOG = "UPDATE_BLOG";

export const getAllBlogs = () => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.blogs}`,
    };
    return api(data)
        .then(async (response) => {
            if (response.status) {
                dispatch({
                    type: GET_BLOGS,
                    payload: response.data,
                });
            }
        }).catch((error) => {
            throw error?.response?.data?.message;
        });
};
export const getBlogById = (blogId) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.blogs + blogId}/`,
    };
    return api(data)
        .then(async (response) => {
            if (response.status) {
                dispatch({
                    type: GET_BLOG_DETAILS,
                    payload: response.data,
                });
            }
        }).catch((error) => {
            throw error?.response?.data?.message;
        });
};

export const addContent = (content) => async () => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.blogs,
        data: content
    };
    return api(data)
        .then(async(response) => {
            return response.data;
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};

export const updateContent = (blogDetails, id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.put,
      url: API_ENDPOINTS.blogs+id+"/",
      data: blogDetails,
    };
    return api(data)
      .then(async (response) => {
        return response.data;
      })
      .then(async (response) => {
        if (response) {
          dispatch({
            type: UPDATE_BLOG,
            payload: response.data,
          });
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };
  

export const deleteBlogById = (blogId) => async () => {
    let data = {
        method: METHOD_TYPE.delete,
        url: `${API_ENDPOINTS.blogs}${blogId}/`,
    };
    return api(data)
        .then(async (response) => {
            return ({
                status: true,
                message: response.data.message
            });
        }).catch((error) => {
            return ({
                status: false,
                message: error.response.statusText
            })
        });
};



