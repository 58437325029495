import { api } from "../../utilities/api";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";

export const VISION = "VISION";
export const RECOGNITIONLOADER = "RECOGNITIONLOADER";
export const OURPARTNERLOADER = "OURPARTNERLOADER";
export const COFOUNDER = "COFOUNDER";
export const OURTEAM = "OURTEAM";
export const GETINTOUCH = 'GETINTOUCH'

export const getVision = () => async (dispatch) => {
 
  let data = {
    method: METHOD_TYPE.get,
    url: API_ENDPOINTS.vision,
};
return api(data)
    .then(async (response) => {
        return await response.data
    }).then(async (response) => {
        if (response) {
            
             dispatch({
                type: VISION,
                payload: response,
            });

        }
    })
    .catch((error) => {
        throw error?.response?.message;
    });
};

export const getRecognition = () => async (dispatch) => {
    
      let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.recognition,
    };
    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            if (response) {
                
                 dispatch({
                    type: RECOGNITIONLOADER,
                    payload: response,
                });

            }
        })
        .catch((error) => {
            throw error?.response?.message;
        });
  };

  export const getOurPartner = () => async (dispatch) => {
    
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.ourPartners,
  };
  return api(data)
      .then(async (response) => {
          return await response.data
      }).then(async (response) => {
          if (response) {
              
               dispatch({
                  type: OURPARTNERLOADER,
                  payload: response,
              });

          }
      })
      .catch((error) => {
          throw error?.response?.message;
      });
  };


  export const getCoFounder = () => async (dispatch) => {
    // dispatch({
    //   type: COFOUNDER,
    // //   payload: true
    // payload: [
    //   {
    //     id:1,
    //     founderName : "Aditya Saxena",
    //     description : "Aditya has done his undergraduation in Computer Science from BITS Pilani'23. With 2 patents in Applied AI and expertise in Natural Language Processing, Aditya is poised to revolutionize the tech industry.",
    //     tagLine : "Co-founder & CEO",
    //     founderImage : "",
    //     LinkedInLink : "https://www.linkedin.com/in/aditya-saxena-09a50719b/"
    //   },
    //   {
    //     id:2,
    //     founderName : "Vikram Pandya",
    //     description : "Vikram is Director FinTech at SP Jain School of Global Management where he has designed Asia’s first interactive classroom led FinTech program featuring Blockchain, API Banking, AI/ML and IoT labs. He is also heading research arm of Fintech VC Fund Varanium.",
    //     tagLine : "Co-founder & Advisor",
    //     founderImage : "",
    //     LinkedInLink : "https://www.linkedin.com/in/vikrampandya/"
    //   },
    // ]
    // });
    // let data = {
    //   method: METHOD_TYPE.post,
    //   url: API_ENDPOINTS.register,
    //   data: formData
    // };
    // return api(data)
    //   .then(async (response) => {
    //     return response.data;
    //   })
    //   .then(async (response) => {
    //     if (response.status) {
    //       dispatch({
    //         type: OURPARTNERLOADER,
    //         payload: false
    //       });
    //      return response.status
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch({
    //       type: OURPARTNERLOADER,
    //       payload: false
    //     });
    //     throw error?.response?.data?.message;
    //   });
  };

  export const getOurTeam = () => async (dispatch) => {

    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.ourTeam,
  };
  return api(data)
      .then(async (response) => {
          return await response.data
      }).then(async (response) => {
          if (response) {
               dispatch({
                  type: OURTEAM,
                  payload: response,
              });

          }
      })
      .catch((error) => {
          throw error?.response?.message;
      });
  };

  
export const getInTouch = (formdata) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.getInTouch,
        data: formdata
    };
    return await api(data)
    .then(async (response) => {
        if (response.status) {
            return {
                status: true,
                result: response.data,
            };
        }
    }).catch((error) => {
        // console.log("error",error);
        return {
            status: false,
            result: error?.response?.data?.detail
        };
    });
};