import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenuOpen } from '../action';
import logo from '../../../../assets/logo.png';
import '../index.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../action';

const Topbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clickMenuOpen } = props;

  const handleLogout = async () => {
    await localStorage.removeItem('user')
    await localStorage.removeItem('isAdmin')
    dispatch(logout());
    navigate('/admin/login');
  }

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

      {/* <!-- Sidebar Toggle (Topbar) --> */}
      <button onClick={() => clickMenuOpen()} id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="fa fa-bars"></i>
      </button>
      {/* <!-- Topbar Navbar --> */}
      <ul className="navbar-nav ml-auto">
        {/* <li className="d-flex align-items-center justify-content-center">
                <a  href="index.html">
                  <div className="sidebar-brand-text mx-3">
                    <img src={logo} />
                  </div>
                </a>
              </li> */}
<li className='d-none d-sm-block mt-3 pt-2 text-primary font-weight-bold'>Admin Panel</li>

        <div className="topbar-divider d-none d-sm-block"></div>
        {/* <!-- Nav Item - User Information --> */}
        <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {/* <span className="mr-2 d-none d-lg-inline text-gray-600 small">Admin Name</span> */}
            <img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" />
          </a>
          {/* <!-- Dropdown - User Information --> */}
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={handleLogout}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </a>
          </div>
        </li>

      </ul>

    </nav>

  )
}



const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = store => ({
  toggled: store.menuState.menuOpen
});

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
// export default (Topbar);