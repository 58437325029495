import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {
  createMeetOurTeam,getMeetOurTeamDetail,updateMeetOurTeam
} from "../../MeetOurTeam/action";
import { uploadImage } from "../../../action";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../utilities/constants";
import Sidebar from "../../../Components/Navigation/Sidebar";
import Topbar from "../../../Components/Navigation/Topbar";
import PageHeading from "../../../Components/PageHeading";
import { toast } from "react-toastify";

const label = { inputProps: { 'aria-label': 'controlled' } };

const MeetOurTeamUpload = (props) => {
  const fileInputRef = useRef();
  const formRef = useRef(null);
  const history = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [formData, setFormData] = useState({sensitiveContent: false});
  const { state } = useLocation();
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [image_id, setImageId] = useState('');
  
  
  useEffect(() => {
    if (state?.id) {
      props.getMeetOurTeamDetail(state.id);
      console.log('updateMeetOurTeam',state?.id)
    }
  }, [state?.id]);

  useEffect(() => {
    if(props.getTeamDetails){
      setName(props.getTeamDetails.name);
      setDesignation(props.getTeamDetails.designation);
      setImageId(props.getTeamDetails.image.id);
      setPreview(props.getTeamDetails.image.media);
    }
  }, [props.getTeamDetails]);


  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataItem = {
      name,
      designation,
    };
    if (!name|| !designation || (!state?.id && !selectedFile )) {
      toast.error('All fields are required.');
      return;
    }
    if (state?.id) {
      let uploadedImage;
      if(selectedFile){
        let formData = new FormData();
        formData.append('media', selectedFile);
        uploadedImage = await props.uploadImage(formData)
      }
      
      props.updateMeetOurTeam(formDataItem, state?.id, selectedFile ? uploadedImage.data.id : image_id)
          .then(res => {
            toast.success('Team updated successfully');
            history(`/admin/content/meetourteam`);
          }).catch(error => {
            toast.error(error || "Error in add Team");
          });

    } else {
      // onAdd(newItem);
        let formData = new FormData();
        formData.append('media', selectedFile);
        props.uploadImage(formData)
          .then((res) => {
            const imageId = res.data.id;
            props.createMeetOurTeam(formDataItem, imageId)
              .then(res => {
                toast.success('Team added successfully');
                history(`/admin/content/meetourteam`);
              }).catch(error => {
                toast.error(error || "Error in add Team");
              });
          }).catch((error) => {
            console.log("Image Required");
          });

      } 
  };



  const onResponseRecieved = (response) => {
    setDialogSubmit(true);
    setMessage(response?.message);
    setOpenModal(true);
    setShowDialog(true);
    toast.success(response?.message)
    history("/admin/content/meetourteam");
    
  };
  const onResponseFailed = (error) => {
    setDialogSubmit(false);
    setMessage(error?.message);
    setOpenModal(false);
    setShowDialog(true);
  };

  return (
    <div>      
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Add New"
                buttonName="" 
                buttonLink=""
                buttonIcon=""
              />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form ref={formRef} method="post" onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                        Name
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                        Designation
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Designation"
                          name="designation"
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Add Image
                          </Form.Label>
                        </Form.Group>
                        {selectedFile ? (
                          <img src={preview} className="imgthumb"style={{width:150,height:150,objectFit:'contain'}} />
                        ) : (
                          props.getTeamDetails && (
                            <img
                              src={`${props?.getTeamDetails?.image?.media}`}
                              className="imgthumb"
                              style={{width:150,height:150,objectFit:'contain'}}
                            />
                          )
                        )}
                      </div>
                      <div className="">
                        <Form.Group className="mb-3">
                          <a
                            className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <i
                              className={`fas fa-plus fa-sm text-white-50 p-1`}
                            ></i>
                          </a>
                          <Form.Control
                            type="file"
                            className="form-control"
                            accept='image/*'
                            placeholder=""
                            onChange={onSelectFile}
                            ref={fileInputRef}
                            hidden
                          />
                        </Form.Group>
                      </div>
                      <div className=" mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminUpdateMeetOurTeamReducer }) => {
  const { getTeamDetails } = adminUpdateMeetOurTeamReducer;
  return { getTeamDetails };
};
export default connect(mapStateToProps, {
  createMeetOurTeam,getMeetOurTeamDetail,updateMeetOurTeam,uploadImage,
})(MeetOurTeamUpload);

