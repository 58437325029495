import React, { useEffect, useState } from 'react'
import './index.scss'
import { useCollapse } from 'react-collapsed'
import LeftCard from './LeftCard';
import UploadFile from './UploadFile';
import Chat from './Chat';
import { Button } from '@mui/material';
import Images from '../../constants/Images';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { uploadEngineFile, uploadEngineLink, getConversations, deleteConversation, searchConversation, updateConversation } from './action';
import { toast } from 'react-toastify';
import useDidUpdateEffect from '../customHooks/useDidUpdateEffect';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

function UpSkillrAssistant() {
    const dispatch = useDispatch()
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [activeTab, setActiveTab] = useState("upload");
    const [youtubeLink, setYoutubeLink] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [activeChat, setActiveChat] = useState(null)
    const [history, setHistory] = useState('');
    const [showModal, setShowModal] = useState(false)

    const {
        register,
        handleSubmit,
        setValue,
        formState:{errors}
    } =  useForm({
        resolver: yupResolver(yup.object().shape({
            name: yup.string().required("Name is required"),
            id: yup.number(),
            conv_type: yup.string()
        }))
    });

    const { conversations } = useSelector((state) => state.assistantReducer);

    useEffect(() => {
        dispatch(getConversations())
            .catch((error) => {
                console.log("Error", error);
                toast.error(error?.response?.data?.message || "Something went wrong, please try again.")
            }
        )
    }, [])

    useDidUpdateEffect(() => {
        const debounceSearch = setTimeout(() => {
            dispatch(searchConversation(history)).catch((error) => {
                console.log("Error", error);
                toast.error(error?.response?.data?.message || "Something went wrong, please try again.")
            })
        }, 500);

        return () => {
            clearTimeout(debounceSearch);
        };
    }, [history]);


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleYoutubeLink = (event) => {
        setYoutubeLink(event.target.value)
        setSelectedFile(null)
    }

    const handleFileSelect = (event) => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.csv, .pdf, .docx'; // You can change this to specify different file types (e.g., 'image/*, .pdf, .doc')
        fileInput.multiple = false;
        fileInput.onchange = (e) => {
            const selectedFile = e.target.files[0];
            // Do something with the selected file, e.g., display its name or upload it to a server.
            console.log('Selected file:', selectedFile);
            setSelectedFile(selectedFile)
            toast.success("Kindly click on the Process button to proceed forward")
            setYoutubeLink("")
        };
        fileInput.click();
    };

    const handleAskClick = async () => {
        if (youtubeLink) {
            dispatch(uploadEngineLink(youtubeLink))
                .then(() =>{
                    getConversationLists()
                    setActiveTab("chat")
                    setYoutubeLink("")
                })
                .catch((error) =>
                    toast.error( error?.response?.data?.message || "Something went wrong, please try again.")
                )
        } else if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile)
            dispatch(uploadEngineFile(formData))
                .then(() => {
                    getConversationLists()
                    setActiveTab("chat")
                    setSelectedFile(null)
                })
                .catch((error) =>
                    toast.error( error?.response?.data?.message || "Something went wrong, please try again.")
                )
        } else {
            toast.error('Please enter youtube link or upload file.');
        }

    }

    const getConversationLists = (deleteId) => {
        dispatch(getConversations())
            .then((response) => {
                if (response?.length > 0 && !deleteId)
                    setActiveChat(response[response.length - 1])
                if(deleteId === activeChat?.id)
                    setActiveChat(response[response.length - 1])
            }
            )
    }

    const handleCoversationClick = (chat) => {
        setActiveTab("chat")
        setActiveChat(chat)
    }

    const handleDelete = (id) => {
        dispatch(deleteConversation(id)).then(async(res) => {
            getConversationLists(id)
        }).catch((error) => {
            toast.error( error?.response?.data?.message || "Something went wrong, please try again.")
        })
    }

    const handleRename = (chat) => {
        console.log('chat', chat)
        setValue('name', chat?.name)
        setValue('id', chat?.id)
        setValue('conv_type', chat?.conv_type)
        setShowModal(true)
    }

    const handleUpdateTopic = (data) => {
        console.log('data', data)
        dispatch(updateConversation(data, data?.id))
        .then(res => {
            setShowModal(false);
            getConversationLists();
        })
    }


    return (
        <>
            <div className='container-fluid leftViewMcq'>

                <Tab.Container id="left-tabs-example" defaultActiveKey="upload" activeKey={activeTab}>
                    <Row>
                        <Col sm={3} className='leftViewMcq' style={{ backgroundColor: '#F7F8F9', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',overflow:'hidden' }}>
                            <div className='upSkillrAssistant'>
                                <div class="input-group" style={{ marginTop: 20 }}>
                                    <div class="form-outline">
                                        <input type="search" placeholder='Search History' value={history} onChange={(e) => setHistory(e.target.value)} id="form1" class="form-control" />
                                    </div>
                                </div>

                                <div className='linksLeftTab'>
                                    <Nav variant="pills" className="flex-column" >
                                        {
                                            conversations?.map((chat, index) =>

                                                <Nav.Item >
                                                    <Nav.Link eventKey="first">
                                                        <LeftCard
                                                            onCardClick={() => handleCoversationClick(chat)}
                                                            title={chat?.name.length > 25 ? chat?.name.substring(0, 25) + '...' : chat?.name}
                                                            imageUrl={chat?.conv_type === "youtube" ? Images.UploadLinkIcon : ""}
                                                            deleteChat={() => handleDelete(chat?.id)}
                                                            renameChat={()=>handleRename(chat)}
                                                        />
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )
                                        }
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="second"  > <LeftCard
                                                title={'Previous File'}
                                                imageUrl={Images.UploadLinkIcon}
                                            /></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third"> <LeftCard
                                                title={'Previous File'}
                                                imageUrl={Images.UploadLinkIcon}
                                            /></Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </div>

                            </div>

                            <div className='newButton' style={{ backgroundColor: '#FFF8E6', width: '100%', padding: 8, display: 'flex', justifyContent: 'center' }}>
                                <Button className='AddnewButton' style={{ color: ' #FFAF0D', }} onClick={() => setActiveTab("upload")}>{`+ Add New Link/File`}</Button>
                            </div>
                        </Col>
                        <Col sm={9} style={{paddingRight:0}}>
                            <Tab.Content>
                                {/* <Tab.Pane eventKey="chat">
                                    <UploadFile />
                                </Tab.Pane> */}
                                <Tab.Pane eventKey="chat">
                                    <Chat chatId={activeChat?.id} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="upload">
                                    <UploadFile filePicker={handleFileSelect} youtubeLink={youtubeLink} setYoutubeLink={handleYoutubeLink} selectedFile={selectedFile} handleClick={handleAskClick} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={()=>setShowModal(!showModal)} className='addTopicCardModal'>
                <Button onClick={()=>setShowModal(!showModal)} style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end", padding:0 }}>
                    <img src={require('../../assets/table_close_icon.png')} style={{ width: 32, height: 32, marginTop: -50 }} />
                </Button>
                <Modal.Header closeButton={false} style={{alignItems:'center'}}>
                    <Modal.Title className='mdlTitle'>Update name</Modal.Title>
                </Modal.Header>
                    <form onSubmit={handleSubmit(handleUpdateTopic)} className='addTopicForm'>
                        <Modal.Body>
                            <div className='mb-2'>
                                <label>Name</label>
                                <input type="text" placeholder='Title' className="form-control" {...register("name")} />
                                <p className='validationError'>{errors?.name?.message}</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-primary' type="submit" style={{ paddingLeft: 30, paddingRight: 30 }}>{`Save`}</button>
                        </Modal.Footer>
                    </form>
                
			</Modal>
        </>
    )
}
export default UpSkillrAssistant;