import React, { useState } from 'react';
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import "../meetourteam/TeamCard.css"
import "./index.scss";


function OurPartnerBox(props) {
    const { description, partnerlogo, backgroundColor, borderColor } = props;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {setIsReadMore(!isReadMore)};

    const renderHtml = (htmlContent)=>{
        return { __html: htmlContent };
    }

    return (
        <><div className='OurPartners OurPartnersSlide'>
            <div className='OurPartnerBoxContainer' style={{ backgroundColor: 'transparent', borderColor: borderColor,width:397,borderRadius: 24, alignItems: 'center', justifyContent: 'center',padding:16 }}>
                <div style={{backgroundColor: backgroundColor,alignItems:'center',justifyContent:'center',borderRadius:16}}>
                    <img src={partnerlogo} style={{width:'100%', height:196,objectFit:'contain'}} />
                </div>
                <div className='TextDetails'>
                    {/* <p style={{ color: Colors.textBlack, fontSize: 20, fontFamily: "Poppins", fontWeight: '500', marginTop: 16,marginBottom:8,textAlign:'left',wordBreak:'break-all' }} dangerouslySetInnerHTML={renderHtml(description)}>
                    </p> */}
                    <p style={{ color: Colors.textBlack, fontSize: 20, fontFamily: "Poppins", fontWeight: '500', marginTop: 16,marginBottom:8,textAlign:'left',wordBreak:'break-all' }} dangerouslySetInnerHTML={renderHtml(description)}></p>
                </div>
            </div>
            </div>
        </>
    );
}

export default OurPartnerBox;