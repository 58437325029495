import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {
  addContent,
  updateAdminContent,
  getTagsByUploadImage
} from "../../Recogniations/action";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../utilities/constants";
import Sidebar from "../../../Components/Navigation/Sidebar";
import Topbar from "../../../Components/Navigation/Topbar";
import PageHeading from "../../../Components/PageHeading";

const label = { inputProps: { 'aria-label': 'controlled' } };

const MeetOurCoFounderUpload = (props) => {
  const [formDataDesc, setFormDataDesc] = React.useState({
    name: "",
    designation: "",
    link: "",
  });
  const fileInputRef = useRef();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [startDate, setStartDate] = useState();
  const [dropDownData, setDropDownData] = useState([]);
  const [formData, setFormData] = useState({sensitiveContent: false});
  const { state } = useLocation();
  const [isEffectComplete, setIsEffectComplete] = useState(false);

  React.useEffect(() => {
    // props.getAllRoles();
    if (props.userDetail) {
      setFormDataDesc({
        name: props.userDetail.name,
        designation: props.userDetail.designation,
      });
    }
  }, [props.userDetail]);
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    // Need to fetch the google vision tags
    // Calling Api
    setSelectedFile(e.target.files[0]);
    props?.getTagsByUploadImage(e.target.files[0]).then((res) => {
      setTag(res.data.tags);
      setFormData({ ...formData, tags: res.data.tags || "", colour : res.data.colors?.map((el) => el._id) || [] });
    }).catch((err) => {
      console.log("Error in fetching tags", err);
    //   toast.error("Error in fetching tags");
    });
  };
  const [tag, setTag] = useState([]);
  const handleChange = (tags) => {
    setTag(tags);

  };
  const handleChangeData = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleReset = () => {
    formRef.current.reset();
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const requestParam = { ...formData };

      // validate form
      // if (
      //   UPLOAD_FORM.top.some(
      //     (el) => el.required && formData[el.key] === undefined
      //   )
      // ) {
      //   toast.error("All Fields are Mandatory");
      //   return;
      // }
      // validate image file
      if (
        !props.selectedShotDetails &&
        (!selectedFile)
      ) {
        // toast.error("Image missing");
        return;
      }

    //   UPLOAD_FORM.top.forEach((el) => {
    //     if (el.type === "multi" && !requestParam[el.key]) {
    //       requestParam[el.key] = [];
    //     }
    //   });

      if (props.selectedShotDetails && state?.contentId) {
        requestParam.contentId = props?.selectedShotDetails?._id;
        requestParam.contentUrl = selectedFile
          ? selectedFile?.name
          : props?.selectedShotDetails?.contentUrl;
        requestParam.isAdminUpload = true;
        await props.updateAdminContent(requestParam).then(async (res) => {
          if (selectedFile) {
            await props.getUploadURL(selectedFile).then(async (res) => {
              await props.updateUploadURL(res, selectedFile);
            });
          }
          setSelectedFile(null);
          setPreview(null);
          setFormData({});
          setTag([]);
          handleReset();
          navigate("/admin/files");
        });
      } else {
        props.getUploadURL(selectedFile).then((res) => {
          props.updateUploadURL(res, selectedFile).then((res) => {
            requestParam.contentUrl = selectedFile?.name;
            requestParam.isAdminUpload = true;
            props.addContent(requestParam).then((res) => {
              setSelectedFile(null);
              setPreview(null);
              setFormData({});
              setTag([]);
              handleReset();
            });
          });
        });
      }
    } catch (error) {
    //   toast.error(error || "Request failed !");
    }
  };

//   if (!isEffectComplete) {
//     return <div id="wrapper">
//       <Sidebar />
//       <div id="content-wrapper" className="d-flex flex-column">
//         <div id="content">
//           <Topbar />
//           <div className="container-fluid">
//             <div className="card shadow mb-4" id="addNewMaster">
//               <div className="card-body">
//                   <Loader visible={true}></Loader>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   }

  return (
    <div>      
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Add New"
                buttonName="" 
                buttonLink=""
                buttonIcon=""
              />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form ref={formRef} method="post" onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                        Name
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Name"
                          name="name"
                          value={formDataDesc.name}
                          onChange={handleChangeData}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                        Designation
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Designation"
                          name="designation"
                          value={formDataDesc.name}
                          onChange={handleChangeData}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="text-capitalize">
                        Linkedin Link
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Linkedin Link"
                          name="link"
                          value={formDataDesc.name}
                          onChange={handleChangeData}
                        />
                      </Form.Group>
                    </div>
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Add Image
                          </Form.Label>
                        </Form.Group>
                        {selectedFile ? (
                          <img src={preview} className="imgthumb"style={{width:150,height:150,objectFit:'contain'}} />
                        ) : (
                          props.selectedShotDetails && (
                            <img
                              src={`${BASE_URL}api/getImage/${props?.selectedShotDetails?.contentUrl}?height=202&width=350`}
                              className="imgthumb"
                              style={{width:150,height:150,objectFit:'contain'}}
                            />
                          )
                        )}
                      </div>
                      <div className="">
                        <Form.Group className="mb-3">
                          <a
                            className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <i
                              className={`fas fa-plus fa-sm text-white-50 p-1`}
                            ></i>
                          </a>
                          <Form.Control
                            type="file"
                            className="form-control"
                            accept='image/*'
                            placeholder=""
                            onChange={onSelectFile}
                            ref={fileInputRef}
                            hidden
                          />
                        </Form.Group>
                      </div>
                      <div className=" mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ listingReducer }) => {
  // const { selectedShotDetails } = listingReducer;
  return {
    // selectedShotDetails,
  };
};

export default connect(mapStateToProps, {
  updateAdminContent,
  getTagsByUploadImage
})(MeetOurCoFounderUpload);

