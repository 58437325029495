import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import cancel from "../../assets/icons/cancel_icon.svg";
import "./css/dialog.css";

const CustomDialog = ({
  showDialog,
  message,
  handleClose,
  isCta,
  ctaText,
  children,
  isCancelIcon,
  isSubmit,
  submitText,
  handleDialogSubmit
}) => {
  return (
    <Dialog open={showDialog} onClose={() => handleClose()}>
      <div
        id="createCollection"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              {isCancelIcon && (
                <div className="dailog_cancel" onClick={handleClose}>
                  <img src={cancel} alt="dialog cancel" />
                </div>
              )}
              {children && children}
              {message && (
                <div className="modal-text dailog_text">{message}</div>
              )}


              <div className="d-flex gap-3">
                {isCta && (<button
                  type="button"
                  className="btn btn__gray3 w-50"
                  onClick={() => handleClose()}
                >
                  {ctaText}
                </button>
                )}
                {isSubmit && (<button
                  type="button"
                  className="btn btn__gray3 w-50"
                  onClick={() => handleDialogSubmit()}
                >
                  {submitText}
                </button>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CustomDialog;

CustomDialog.propTypes = {
  message: PropTypes.string,
  isCta: PropTypes.bool,
  showDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  children: PropTypes.element,
  ctaText: PropTypes.string,
  isCancelIcon: PropTypes.bool,
};

CustomDialog.defaultProps = {
  isCta: true,
  isSubmit: false,
  ctaText: "CLOSE",
  submitText: "OK",
  isCancelIcon: false,
};
