import React, { useState } from 'react';
import { Button, ButtonGroup, Menu } from '@mui/material';
import '../ourFeatures/index.scss';
import UpskillrUtils from '../../utilities/UpskillrUtils';
import MachineLearningCard from '../../components/ourFeatures/MachineLearningCard';
import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getSavedThingstoDo, getSavedThingstoRead, getSavedThingstoWatch } from "./action";
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';

const SavedLibrary = (props) => {
    const [thingsToDo, setThingsToDo] = React.useState(true);
    const [thingsToWatch, setThingsToWatch] = React.useState(false);
    const [thingsToReads, setThingsToReads] = React.useState(false);

    const { savedThingsToDoSuccess, savedThingsToWatchSuccess, savedThingsToReadSuccess } = useSelector((state)=> state.savedLibraryReducer);
    const { savedThingsToDoError, savedThingsToWatchError, savedThingsToReadError } = useSelector((state)=> state.savedLibraryReducer);
    const { savedThingsToDoLoading, savedThingsToWatchLoading, savedThingsToReadLoading } = useSelector((state)=> state.savedLibraryReducer);

    useEffect(()=>{
        props.getSavedThingstoDo().catch(err => {
            toast.error(err || 'Something Went Wrong');
        })
    },[])

    const handleTabChange = (tabName) => {
        if (tabName === 'thingsToDo') {
            setThingsToDo(true);
            setThingsToWatch(false);
            setThingsToReads(false);
            props.getSavedThingstoDo().catch(err => {
                toast.error(err || 'Something Went Wrong');
            })
        }
        if (tabName === 'thingsToWatch') {
            setThingsToDo(false);
            setThingsToWatch(true);
            setThingsToReads(false);
            props.getSavedThingstoWatch().catch(err => {
                toast.error(err || 'Something Went Wrong');
            })
        }
        if (tabName === 'thingsToReads') {
            setThingsToDo(false);
            setThingsToWatch(false);
            setThingsToReads(true);
            props.getSavedThingstoRead().catch(err => {
                toast.error(err || 'Something Went Wrong');
            })
        }
    }

    return (
        <>
            <>
            {savedThingsToDoLoading || savedThingsToWatchLoading || savedThingsToReadLoading && <Loader/>}
            <div className='tabContentHeader'>
                <div className='tabContentHeaderBox'>
                    <h2 className='pageHeading'>Saved Library</h2>
                </div>
            </div>
            <div className='savedLibrary'>
                <div className='innerTab' style={{justifyContent:'center',display:'flex'}}>
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button className={`${thingsToDo && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToDo')}>Things to Do</Button>
                        <Button className={`${thingsToWatch && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToWatch')}>Things to Watch</Button>
                        <Button className={`${thingsToReads && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToReads')}>Things to Read</Button>
                    </ButtonGroup>

                </div>
            </div>
                <hr style={{ borderTop: '2px solid #FFF' }} />
                {thingsToDo &&
                    <>
                        <div className='containers'>
                            <div className='row'>
                                {
                                savedThingsToDoSuccess && savedThingsToDoSuccess.map((obj, i) => {
                                    return <div className='col-md-4' key={i}>
                                        <MachineLearningCard type={obj?.skillmap?.display_name}
                                            title={obj?.skillmap?.title}
                                            courseType={obj?.skillmap?.productDifficultyLevel}
                                            imageUrl={obj?.skillmap?.image}
                                            // totalStudents={'10.1M'}
                                            // totalHours={27}
                                            courseFree={obj?.skillmap?.isCourseFree}
                                            coursePrize={(obj?.skillmap?.coursePrize)?UpskillrUtils.getIndianCurrencyFormatter(obj?.skillmap?.coursePrize):undefined}
                                            showButtons={false}
                                            floatingMenu={false}
                                            onCardClick={()=> window.open(obj?.skillmap?.url, "_blank")}
                                        />
                                    </div>
                                }

                                )
                                    
                                }
                            </div>
                        </div>
                    </>
                }
                {thingsToWatch && !thingsToDo && !thingsToReads && <>
                        <div className='containers'>
                            <div className='row'>
                                {
                                savedThingsToWatchSuccess && savedThingsToWatchSuccess.map((obj, i) => {
                                    return <div className='col-md-4' key={i}>
                                        <MachineLearningCard 
                                            // type={'Simplilearn'}
                                            title={obj?.skillmap?.title}
                                            // courseTime={'27:01'}
                                            showButtons={false}
                                            // timeFormat={'Minutes'}
                                            // date={'March 31'}
                                            imageUrl={obj?.skillmap?.image}
                                            onCardClick={()=> window.open(obj?.skillmap?.url, "_blank")}
                                            // URL={video?.video_url}
                                            floatingMenu={false}
                                        />
                                    </div>
                                }

                                )
                                    
                                }
                            </div>
                        </div>
                    </>}
                {thingsToReads && !thingsToDo && !thingsToWatch && <>
                        <div className='containers'>
                            <div className='row'>
                                {
                                savedThingsToReadSuccess && savedThingsToReadSuccess.map((obj, i) => {
                                    return <div className='col-md-4' key={i}>
                                        <MachineLearningCard
                                            // type={'Simplilearn'}
                                            title={obj?.skillmap?.title || ''}
                                            // courseTime={'4'}
                                            showButtons={false}
                                            // timeFormat={'Min read'}
                                            // date={'March 31'}
                                            imageUrl={obj?.skillmap?.image}
                                            onCardClick={() => window.open(obj?.skillmap?.url, "_blank")}
                                            floatingMenu={false}
                                        />
                                    </div>
                                })
                                    
                                }
                            </div>
                        </div>
                    </>}
            </>

        </>
    )


}

const mapStateToProps = ({ savedLibraryReducer }) => {
    const { savedThingsToDoSuccess, savedThingsToWatchSuccess, savedThingsToReadSuccess } = savedLibraryReducer;
    return { savedThingsToDoSuccess, savedThingsToWatchSuccess, savedThingsToReadSuccess } ;
}

export default connect(mapStateToProps, {
    getSavedThingstoWatch,
    getSavedThingstoDo,
    getSavedThingstoRead
})(SavedLibrary);