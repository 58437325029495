import { GET_RECOGNITIONS, ADD_RECOGNITIONS, UPDATE_RECOGNITIONS, DELETE_RECOGNITIONS, GET_RECOGNITIONS_DETAILS } from "./action";

const initialState = {
  recognitionsList: null,
  newRecognitions: null,
  updateRecognitionsList: null,
  deleteRecognitions: null,
  getDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECOGNITIONS: {
      return {
        ...state,
        recognitionsList: action.payload,
      };
    }
    case ADD_RECOGNITIONS: {
      return {
        ...state,
        newRecognitions: action.payload,
      };
    }
    case UPDATE_RECOGNITIONS: {
      return {
        ...state,
        updateRecognitionsList: action.payload,
      };
    }
    case DELETE_RECOGNITIONS: {
      return {
        ...state,
        updateRecognitionsList: action.payload,
      };
    }
    case GET_RECOGNITIONS_DETAILS: {
      return {
        ...state,
        getDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
