import React, { useState, useEffect } from 'react';
import '../../components/ourFeatures/index.scss'
import ViewHeading from '../ViewHeading';
import { Autocomplete, TextField } from '@mui/material';
import UpskillrUtils from '../../utilities/UpskillrUtils';
import Images from '../../constants/Images';
import MachineLearningCard from '../ourFeatures/MachineLearningCard';
import ComparisonTable from '../ourFeatures/ComparisonTable';
import { useDispatch, useSelector } from 'react-redux';
import { TOOLS_LIST, getTools } from '../../pages/ourFeatures/action';
import Loader from '../Loader';
import { toast } from 'react-toastify';

const Tools = (props) => {

    const [showChexkBox, setShowChexkBox] = React.useState(false);
    const [continueCompare, setContinueCompare] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [title, setTitle] = useState(null);
    const dispatch = useDispatch();
    const { toolsData, handleClick=()=>{} } = props
    const isLoading = useSelector((state) => state.CurriculumReducer.loading);

    useEffect(() => {
        // dispatch(getTools());
        dispatch({
            type: TOOLS_LIST,
            payload: []
        });
    }, []);

    const onCompareClick = () => {
        if (showChexkBox === false) {
            setShowChexkBox(!showChexkBox);
        } else {
            setContinueCompare(!continueCompare)
        }
    }


    const onClickRemoveAll = () => {
        setShowChexkBox(!showChexkBox);
    }

    return (
        <>
            {continueCompare && <ComparisonTable />}
            <div className='tabContentHeader'>
                <div className='tabContentHeaderBox'>
                    <h2 className='pageHeading'>Toolkit Architect</h2>
                    <p className='tabContentSubHeading'>Your guide to exploring industry-relevant software tools.</p>
                    <div className='searchBoxArea'>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            id="seachBox"
                            options={[]}
                            renderInput={(params) => (
                                <div style={{ position: 'relative', backgroundColor: '#FFFFFF', borderRadius: 16 }}>
                                    <img src={Images.SearchIcon} className='searchImg' />
                                    <TextField {...params} label="" placeholder='Input your domain name here. Eg: Email Marketing' classes={{ root: 'placeholderSTyle', notchedOutline: 'oulineNone' }} InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }} />
                                </div>
                            )}
                            value={searchValue}
                            onInputChange={(event, newValue) => {
                                setSearchValue(newValue);
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    dispatch(getTools(searchValue)).catch(err => {
                                        toast.error(err || 'Something Went Wrong');
                                    });
                                    setTitle(searchValue)
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            {!continueCompare && <div className='containers'>
                {
                    isLoading && <Loader />
                }
                {/* <ViewHeading title={'Email Marketing'} buttonText={showChexkBox ? 'Continue' : 'Compare Course'} onButtonClick={onCompareClick} buttonText2={showChexkBox ? 'Remove All' : ''} btnStyle2={{ color: showChexkBox ? '#DC2626' : '', border: '1px solid #DC2626' }} onButton2Click={onClickRemoveAll} */}
                <ViewHeading title={isLoading  ? '' : title}
                    containerStyles={{ marginTop: 40 }}/>
                <div className='row'>
                    {toolsData?.map(tools => {
                        return (
                            <div className='col-md-3'>
                                <MachineLearningCard
                                    onCardClick={()=>handleClick(`${tools?.name}${searchValue ? ' ('+ searchValue+')': '' }`)}
                                    centerTitle={tools?.name}
                                    // showChexkBox={showChexkBox}
                                    showButtons={false}
                                    imageUrl={tools?.logo_url}
                                    imageStyles={{ paddingRight: 50, paddingLeft: 50, objectFit: 'contain', paddingTop: 30, paddingBottom: 30 }}
                                    dotImageUrl={Images.DotImage}
                                    floatingMenu={false}
                                />
                            </div>
                        )
                    })}
                    {/* <div className='col-md-3'>
                    <MachineLearningCard
                            centerTitle={'Buffer'}
                            // showChexkBox={showChexkBox}
                            showButtons={false}
                            imageUrl={Images.Mailchimp}
                            imageStyles={{paddingRight:50,paddingLeft:50,objectFit:'contain',paddingTop:30,paddingBottom:30}}
                            dotImageUrl={Images.DotImage}
                        />
                    </div>
                    <div className='col-md-3'>
                    <MachineLearningCard
                            centerTitle={'Buffer'}
                            // showChexkBox={showChexkBox}
                            showButtons={false}
                            imageUrl={Images.Hubspot}
                            imageStyles={{paddingRight:50,paddingLeft:50,objectFit:'contain',paddingTop:30,paddingBottom:30}}
                            dotImageUrl={Images.DotImage}
                        />
                    </div>
                    <div className='col-md-3'>
                    <MachineLearningCard
                            centerTitle={'Buffer'}
                            // showChexkBox={showChexkBox}
                            showButtons={false}
                            imageUrl={Images.ActiveCampaign}
                            imageStyles={{paddingRight:50,paddingLeft:50,objectFit:'contain',paddingTop:30,paddingBottom:30}}
                            dotImageUrl={Images.DotImage}
                        />
                    </div> */}
                </div>
            </div>}
        </>
    )
}

export default Tools;