import React, { useState, useEffect } from 'react';
import { Autocomplete, Button, ButtonGroup, Menu, MenuItem, Select, TextField } from '@mui/material';
import { MultiSelect } from "react-multi-select-component";
import Form from 'react-bootstrap/Form';
import './index.scss';
import MockData from './data.json'
import MachineLearningCard from './MachineLearningCard';
import ViewHeading from '../ViewHeading';
import UpskillrUtils from '../../utilities/UpskillrUtils';
import Images from '../../constants/Images';
import ThingsToWatch from './ThingsToWatch';
import ThingsToRead from './ThingsToRead';
import ComparisonTable from './ComparisonTable';
import MarketPlace from './MarketPlace';
import { useDispatch, useSelector } from 'react-redux';
import { EMPTYSKILLMAP, getThingsToDo, getThingsToRead, getThingstoWatch, skillMapActions, THINGS_READ, THINGS_WATCH, THINGS_TODO } from './action'
import Loader from '../Loader';
import { getSuggestionList } from '../curriculumBuilder/action';
import { toast } from 'react-toastify';

const SkillMap = (props) => {
    const tabs = {
        thingsToDo: "thingsToDo",
        thingsToWatch: "thingsToWatch",
        thingsToReads: "thingsToReads"
    }
    const dispatch = useDispatch();

    const {search} = props

    const [minValue, setMinValue] = React.useState(0);
    const [maxValue, setMaxValue] = React.useState(0);
    const [openTab, setOpenTab] = React.useState("thingsToDo");
    const [openTabInclude, setOpenTabInclude] = useState([])
    const [newSearch, setNewSearch] = useState(false)
    const [titleValue, setTitleValue] = useState(null)

    const [showChexkBox, setShowChexkBox] = React.useState(false);
    const [continueCompare, setContinueCompare] = useState(false);
   
    const [searchValue, setSearchValue] = React.useState(null);
    const [industry, setIndustry] = useState('')
    const [videoLevel, setvideoLevel] = useState('');
    const [videoDuration, setvideoDuration] = useState('');
    const [suggestionListArray, setSuggestionListArray] = useState([]);
    const [params, setParams] = useState({})

    const thingsToWatch = useSelector((state)=> state.skillMapReducer.thingsToWatch);
    const thingsToRead = useSelector((state)=> state.skillMapReducer.thingsToRead);
    const {thingsToDo} = useSelector((state)=> state.skillMapReducer);
    const isLoading = useSelector((state)=> state.CurriculumReducer.loading);
    const { suggestionList } = useSelector((state)=> state.CurriculumReducer);

    useEffect(() => {
      return () => {
        dispatch({type: EMPTYSKILLMAP})
      }
    }, [])

    useEffect(() => {
      if(search){
        setSearchValue(search)
        handleSearch(search)
        const tabToOpen = ["thingsToDo", "thingsToWatch", "thingsToReads"].filter(tab => tab !== openTab)
        setOpenTabInclude(tabToOpen)
      }
    }, [search])
    
    
    useEffect(() => {
        if(newSearch) {
            if(openTabInclude?.length > 0){
                if(openTabInclude.includes(openTab)){
                    handleSearch()
                    const tabs = openTabInclude.filter(tab => tab !== openTab)
                    setOpenTabInclude(tabs)
                }
            }
            else
            setNewSearch(false)
        }
    }, [openTab])

    useEffect(() => {
        const debounceSearch = setTimeout(() => {
            dispatch(getSuggestionList(searchValue));
        }, 500); 
        
        return () => {
          clearTimeout(debounceSearch);
        };
      }, [searchValue]);

      useEffect(() => {
        if (suggestionList.data) {
          const arr = suggestionList.data.reduce((uniqueArr, response) => {
            const label = response?.Name;
            if (!uniqueArr.some((e) => e.label === label)) {
              uniqueArr.push({ label });
            }
            return uniqueArr;
          }, []);
          setSuggestionListArray(arr);
        }
      }, [suggestionList]);

      useEffect(() => {
        if(Object.keys(params).length > 0)
            dispatch(getThingsToDo( searchValue, params ))
      }, [params])
      
    
    const onCompareClick = () =>{
        if(showChexkBox=== false){
            setShowChexkBox(!showChexkBox);
        }else{
            setContinueCompare(!continueCompare)
        }
    }
    const onClickRemoveAll = () =>{
        setShowChexkBox(!showChexkBox);
    }

    const handleSearch = (value) => {
        setParams({})
        if(value)
            setSearchValue(value)
        if(openTab === tabs.thingsToWatch)
        dispatch(getThingstoWatch(value ?? searchValue, "", ""))
        if(openTab === tabs.thingsToReads)
        dispatch(getThingsToRead(value ?? searchValue, industry))
        if(openTab === tabs.thingsToDo)
        dispatch(getThingsToDo(value ?? searchValue))
        setNewSearch(true)
        setTitleValue(searchValue)
    }

    const handleTabChange=(tabName)=>{
        setOpenTab(tabName)
    }

    const changeIndustry = (event) => {
        setIndustry(event.target.value)
        dispatch(getThingsToRead(searchValue, event.target.value))

    }

    const handleChangeWatch = (event, type) => {
        if(type === "level") {
            setvideoLevel(event.target.value) 
            dispatch(getThingstoWatch( searchValue, event.target.value, videoDuration ))
        } else {
            setvideoDuration(event.target.value)
            dispatch(getThingstoWatch(searchValue, videoLevel, event.target.value))
        }
    }

    const handleMarketPlace = (value) => {
        const tabToOpen = ["thingsToDo", "thingsToWatch", "thingsToReads"].filter(tab => tab !== openTab)
        setOpenTabInclude(tabToOpen)
        handleSearch(value)
    }
    const renderCompareView = () => {

        return (
            <div className='container pt-4'>
                <ViewHeading title={'Comparison table'} />
                <div>renderCompareView</div>
            </div>
        )
    }

    const handleOptions = (event) => {
        if(event.target.name === 'provider')
        setParams({[event.target.name]: event.target.value})
        else
        setParams(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const handleActions = (action, payload, type) => {
        const postData = {
            flag_type: action,
            skillmap: payload
        };
        dispatch(skillMapActions(postData)).then(()=>{
            if (action === 'Hide') {
                if (payload['type'] == 'thing_to_do') {
                    if (type === 'coursera') {
                        thingsToDo.coursera.courseraCourses = thingsToDo.coursera.courseraCourses.filter(item => item.objectUrl !== payload.id);
                    } else if (type === 'udemy') {
                        thingsToDo.udemy.udemyCourses = thingsToDo.udemy.udemyCourses.filter(item => item.course_id !== payload.id);
                    }
                    dispatch({
                        type: THINGS_TODO,
                        payload: thingsToDo
                    })
                } else if (payload['type'] == 'thing_to_watch') {
                    if (type === 'youtubeVideos') {
                        thingsToWatch.youtubeVideos = thingsToWatch.youtubeVideos.filter(item => item.videoId !== payload.id);
                    } else if (type === 'youtubeChannels') {
                        thingsToWatch.youtubeChannels = thingsToWatch.youtubeChannels.filter(item => item.channel_id !== payload.id);
                    } else if (type === 'youtubePlaylists') {
                        thingsToWatch.youtubePlaylists = thingsToWatch.youtubePlaylists.filter(item => item.playlist_id !== payload.id);
                    }
                    dispatch({
                        type: THINGS_WATCH,
                        payload: thingsToWatch
                    })
                } else if (payload['type'] === 'thing_to_read') {
                    thingsToRead[type] = thingsToRead[type].filter(item => item.Picture !== payload.image);
                    dispatch({
                        type: THINGS_READ,
                        payload: thingsToRead
                    })
                }
            }
            toast.success("Success");
        }).catch(err=>{
            toast.error(err);
        })
    }

    return (
        <>
            <div className='tabContentHeader'>
                {
                    isLoading && <Loader />
                }
                <div className='tabContentHeaderBox'>
                    <h2 className='pageHeading'>Meet your knowledge navigator</h2>
                    <p className='tabContentSubHeading'>Explore, categorize, and exchange online resources with perpetual learners.</p>
                    <div className='searchBoxArea'>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            id="seachBox"
                            options={suggestionListArray}
                            renderInput={(params) => (
                                <div style={{ position: 'relative', backgroundColor: '#FFFFFF', borderRadius: 16 }}>
                                    <img src={Images.SearchIcon} className='searchImg' />
                                    <TextField {...params} label="" placeholder='Input your skill here. Eg: Graphic Design' classes={{ root: 'placeholderSTyle', notchedOutline: 'oulineNone' }} InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }} />
                                </div>
                            )}
                            value={searchValue}
                            onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.defaultMuiPrevented = true;
                                    handleSearch()
                                    const tabToOpen = ["thingsToDo", "thingsToWatch", "thingsToReads"].filter(tab => tab !== openTab)
                                    setOpenTabInclude(tabToOpen)
                                }
                              }}
                        />
                    </div>
                </div>
            </div>
        {!thingsToWatch && !thingsToRead && !thingsToDo && <MarketPlace handleClick={handleMarketPlace} /> }
        {/* {continueCompare && <ComparisonTable/>} */}
        {(thingsToWatch || thingsToRead || thingsToDo) && !continueCompare && <>
            <div className='innerTab'>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button className={`${openTab === tabs.thingsToDo && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToDo')}>Things to Do</Button>
                    <Button className={`${openTab === tabs.thingsToWatch && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToWatch')}>Things to Watch</Button>
                    <Button className={`${openTab === tabs.thingsToReads && 'innerTabActiveTab'}`} onClick={() => handleTabChange('thingsToReads')}>Things to Read</Button>
                </ButtonGroup>

            </div>
            <hr style={{borderTop: '2px solid #64748B'}}/>
            {openTab === tabs.thingsToDo &&
                <>
                    <div className='filterArea row m-0'>
                        {/* <div className={`formControl ${(minValue || maxValue) ? 'activeInputs' : ''}`}>
                            <label className='inputLabel'>{MockData.budget.label}</label>
                            <Button
                                className="budgetDropdown"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                {minValue || maxValue ? (
                                    <span className='spanVal'>
                                        {minValue && (minValue)} - {maxValue && maxValue}
                                    </span>
                                ) : '0 - 5000'
                                }
                                <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray"><path d="M6 9L12 15 18 9"></path></svg>
                            </Button>
                            <Menu
                                className='budgetClass'
                                anchorEl={budget}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <div className='p-3'>
                                    <Form className='d-flex align-items-center'>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Min Price.</Form.Label>
                                            <div className='parendDiv'>
                                                <img src={Images.INRIcon} className='inrIcon' />
                                                <Form.Control type="number" placeholder="0" onChange={(e) => setMinValue(e.target.value)} value={minValue ? minValue : ''} />
                                            </div>
                                        </Form.Group>
                                        <span className='seperators'></span>
                                        <Form.Group className="mb-3" controlId="formBasicPassword" >
                                            <Form.Label>Max Price</Form.Label>
                                            <div className='parendDiv'>
                                                <img src={Images.INRIcon} className='inrIcon' />
                                                <Form.Control type="number" placeholder="5000" onChange={(e) => setMaxValue(e.target.value)} value={maxValue ? maxValue : ''} />
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Menu>
                        </div> */}
                        <div className={`formControl activeInputs`} style={{width:150,marginRight:40, display: "flex", flexDirection: 'column'}}>
                            <label className='inputLabel'>{MockData.provider.label}</label>
                            <Select
                                value={params?.provider}
                                name={"provider"}
                                onChange={handleOptions}
                                style={{border:params?.provider?.length > 0 ? '2px solid #004aad' : '2px solid #A0abbb'}}
                            >
                                {
                                    MockData.provider.options?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                                }
                            </Select>
                        </div>
                        <div className={`formControl ${params?.provider?.length > 0 ? 'activeInputs' : ''}`} style={{width:150,marginRight:40, display: "flex", flexDirection: 'column'}}>
                            <label className='inputLabel'>{MockData.budget.label}</label>
                            <Select
                                disabled={!params?.provider?.length > 0}
                                name={params?.provider === 'udemy' ? 'price' : 'isCourseFree'}
                                value={params?.provider === 'udemy' ? params['price'] : params['isCourseFree']}
                                onChange={handleOptions}
                                style={{border:params?.provider?.length > 0 ? '2px solid #004aad' : '2px solid #A0abbb'}}
                            >
                                {
                                    MockData.budget[[params?.provider]]?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                                }
                            </Select>
                        </div>
                        <div className={`formControl ${params?.provider?.length > 0 ? 'activeInputs' : ''}`} style={{width:150,marginRight:40, display: "flex", flexDirection: 'column'}}>
                            <label className='inputLabel'>{MockData.level.label}</label>
                            <Select
                                disabled={!params?.provider?.length > 0}
                                name={params?.provider === 'udemy' ? 'instructionalLevel' : 'productDifficultyLevel'}
                                value={params?.provider === 'udemy' ? params['instructionalLevel'] : params['productDifficultyLevel']}
                                onChange={handleOptions}
                                style={{border:params?.provider?.length > 0 ? '2px solid #004aad' : '2px solid #A0abbb'}}
                            >
                                {
                                    MockData.level[params?.provider]?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                                }
                            </Select>
                        </div>
                        <div className={`formControl ${params?.provider?.length > 0 ? 'activeInputs' : ''}`} style={{width:150,marginRight:40, display: "flex", flexDirection: 'column'}}>
                            <label className='inputLabel'>{MockData.length.label}</label>
                            <Select
                                disabled={!params?.provider?.length > 0}
                                name={params?.provider === 'udemy' ? 'duration' : 'productDurationEnum'}
                                value={params?.provider === 'udemy' ? params['duration'] : params['productDurationEnum']}
                                onChange={handleOptions}
                                style={{border:params?.provider?.length > 0 ? '2px solid #004aad' : '2px solid #A0abbb'}}
                            >
                                {
                                    MockData.length[params?.provider]?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                                }
                            </Select>
                        </div>
                        <div className={`formControl ${params?.provider?.length > 0 ? 'activeInputs' : ''}`} style={{width:150,marginRight:40, display: "flex", flexDirection: 'column'}}>
                            <label className='inputLabel'>{MockData.sortBy.label}</label>
                            <Select
                                disabled={!params?.provider?.length > 0}
                                name={params?.provider === 'udemy' ? 'ordering' : 'entityType'}
                                value={params?.provider === 'udemy' ? params['ordering'] : params['entityType']}
                                onChange={handleOptions}
                                style={{border:params?.provider?.length > 0 ? '2px solid #004aad' : '2px solid #A0abbb'}}
                            >
                                {
                                    MockData.sortBy[params?.provider]?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                                }
                            </Select>
                        </div>
                        {
                            params?.provider === "udemy" &&
                            <div className={`formControl ${params?.provider?.length > 0 ? 'activeInputs' : ''}`} style={{ width: 150, marginRight: 40, display: "flex", flexDirection: 'column' }}>
                                <label className='inputLabel'>{MockData.rating.label}</label>
                                <Select
                                    disabled={!params?.provider?.length > 0}
                                    name={'ratings'}
                                    value={params?.ratings}
                                    onChange={handleOptions}
                                >
                                    {
                                        MockData.rating.options?.map(data => <MenuItem value={data.value}>{data.label}</MenuItem>)
                                    }
                                </Select>
                            </div>
                        }
                    </div>
                    <div className='containers'>
                        <ViewHeading title={titleValue} containerStyles={{ textTransform: 'capitalize' }} />
                        {/* <ViewHeading title={'Machine Learning'} buttonText={showChexkBox ? 'Continue' : 'Compare Course'} onButtonClick={onCompareClick} buttonText2={showChexkBox ? 'Remove All' : ''} btnStyle2={{ color: showChexkBox ? '#DC2626' : '', border: '1px solid #DC2626' }} onButton2Click={onClickRemoveAll} /> */}

                        <div className='row'>
                            {
                                thingsToDo?.udemy?.udemyCourses?.map(course =>
                                    <div className='col-md-4'>
                                        <MachineLearningCard 
                                            type={course?.instructor?.display_name}
                                            title={course?.title}
                                            // courseType={course?.productDifficultyLevel}
                                            imageUrl={course?.image_480x270}
                                            // totalStudents={'10.1M'}
                                            // totalHours={27}
                                            coursePrize={UpskillrUtils.getIndianCurrencyFormatter(course?.course_price)}
                                            showChexkBox={showChexkBox}
                                            showButtons={false}
                                            quickView={() => alert('sss')}
                                            onCardClick={()=> window.open(course?.url, "_blank")}
                                            section='udemy'
                                            clickAction={handleActions}
                                            payloadAction={{
                                                "id": course.course_id,
                                                "title": course.title,
                                                "type": "thing_to_do",
                                                "image": course.image_480x270,
                                                "display_name" : course?.instructor?.display_name,
                                                "coursePrize" : course?.course_price,
                                                "url" : course?.url
                                            }}
                                            URL={course?.url}
                                        />
                                    </div>
                                )
                            }
                           {!thingsToDo?.udemy?.udemyCourses?.length > 0  && <ViewHeading title={" No results found"} containerStyles={{ textTransform: 'capitalize' }} />}
                            {
                                thingsToDo?.coursera?.courseraCourses?.map(course =>
                                    <div className='col-md-4'>
                                        <MachineLearningCard 
                                            type={course?.tagline}
                                            title={course?.name}
                                            courseType={course?.productDifficultyLevel}
                                            imageUrl={course?.imageUrl}
                                            courseFree={course?.isCourseFree ? 'Free': 'Paid'}
                                            clickAction={handleActions}
                                            payloadAction={{
                                                "id": course?.objectUrl,
                                                "title": course?.name,
                                                "type": "thing_to_do",
                                                "image": course?.imageUrl,
                                                "url" : course?.objectUrl,
                                                "isCourseFree" : course?.isCourseFree ? 'Free': 'Paid',
                                                "productDifficultyLevel" : course?.productDifficultyLevel,
                                                "display_name" : course?.tagline
                                            }}
                                            section='coursera'
                                            // totalStudents={'10.1M'}
                                            // totalHours={27}
                                            // coursePrize={UpskillrUtils.getIndianCurrencyFormatter(4451)}
                                            showChexkBox={showChexkBox}
                                            showButtons={false}
                                            quickView={() => alert('sss')}
                                            onCardClick={()=> window.open(course?.objectUrl, "_blank")}
                                            URL={course?.objectUrl}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </>
            }
            {openTab === tabs.thingsToWatch && <ThingsToWatch clickAction={handleActions} videoLevel={videoLevel} videoDuration={videoDuration} onChange={handleChangeWatch} title={titleValue} />}
            {openTab === tabs.thingsToReads && <ThingsToRead clickAction={handleActions} industry={industry} changeIndustry={changeIndustry} title={titleValue} />}
        </>
        }
       
    </>
    )


}


export default SkillMap;