import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import './contact-sales.scss';
import { useForm, Controller  } from "react-hook-form";
import { contactSales } from './action';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import { MenuItem, Select } from '@mui/material';
import { toast } from 'react-toastify';
import Images from '../../constants/Images';
import { useEffect } from 'react';

const ContactSales = () => {

    const dispatch = useDispatch();
    const { contactSalesLoading, contactSalesSuccess, contactSalesError } = useSelector((state) => state.pricingReducer);
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(yup.object().shape({
            firstname: yup.string().required("First Name is required"),
            lastname: yup.string().required("Last Name is required"),
            email: yup.string().email('Invalid email format').required("Work Email is required"),
            company: yup.string().required("Company Name is required"),
            industry: yup.string().required("Industry is required"),
            country: yup.string().required("Country is required"),
            message : yup.string().required("How can we help you is required"),
        })),
      });

    const handleSubmitForm = async (formData) => {
        try{
            dispatch(contactSales(formData));
            reset();
        }catch(err){
            console.log("Error", err);
        }
    }

    useEffect(()=>{
        if(contactSalesSuccess){
            toast.success('Data Submitted Successfully')
        }

        if(contactSalesError){
            toast.error(contactSalesError || 'Something went wrong')
        }
    },[contactSalesSuccess, contactSalesError])

    return (        
        <div className="container contact-sales-screen">
            {contactSalesLoading && <Loader /> }
            <div className='row'>
                <div className="col-md-6">
                    <h2 className='heading'>Get Started</h2>
                    <p className='headingInfo'>We are ready and willing to address any queries you may have and provide you with an in-depth understanding of our platform. Here's what we offer:</p>
                    <div className='boxArea'>
                        <div className='imageBox arrangeBox'>
                            <img src={Images.Arrange} />
                        </div>
                        <p>Arrange a customized demonstration</p>
                    </div>
                    <div className='boxArea'>
                        <div className='imageBox cashBox'>
                            <img src={Images.CashPlan}/>
                        </div>
                        <p>Receive a pricing plan specific to your needs</p>
                    </div>
                    <div className='boxArea'>
                        <div className='imageBox settingBox'>
                            <img src={Images.Setting} />
                        </div>
                        <p>Discover potential applications for your educational institution or professional team</p>
                    </div>
                </div>
                <div className="col-md-6 backgroundImage">
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className='row'>
                            <div className='col-md-6 form-group'>
                                <label htmlFor="firstname" className='formLabel'>First Name *</label>
                                <div className='form-input'>
                                    <input type="text" {...register("firstname")}  id='firstname'/>
                                </div>
                                <p className='validationError'>{errors.firstname?.message}</p>
                            </div>
                            <div className='col-md-6 form-group'>
                                <label htmlFor="lastname" className='formLabel'>Last Name *</label>
                                <div className='form-input' >
                                    <input type="text" id='lastname' {...register("lastname")} />
                                </div>
                                <p className='validationError'>{errors.lastname?.message}</p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="email" className='formLabel'>Work Email *</label>
                                <div className='form-input' >
                                    <input type="text" id='email' {...register("email")} />
                                </div>
                                <p className='validationError'>{errors.email?.message}</p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="company" className='formLabel'>Company Name *</label>
                                <div className='form-input' >
                                    <input type="text" id='company' {...register("company")} />
                                </div>
                                <p className='validationError'>{errors.company?.message}</p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="industry" className='formLabel'>Industry *</label>
                                <div className='form-input' >
                                    <input type="text" id='industry' {...register("industry")} />
                                </div>
                                <p className='validationError'>{errors.industry?.message}</p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="country" className='formLabel'>Select your country *</label>
                                <div className='form-input' >
                                    <Controller
                                        name="country"
                                        control={control} // Set the initial value here
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select {...field} className='country-select w-100'>
                                                <MenuItem value=""> <em>Please select</em> </MenuItem>
                                                <MenuItem value={'india'}>India</MenuItem>
                                                <MenuItem value={'us'}>US</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </div>
                                <p className='validationError'>{errors.country?.message}</p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="message" className='formLabel'>How can we help you? *</label>
                                <div className='form-input' >
                                    <textarea id='message' {...register("message")}>

                                    </textarea>
                                </div>
                                <p className='validationError'>{errors.message?.message}</p>
                            </div>
                        </div>
                        <p className='inpfoText'>By clicking “Submit,” I acknowledge receipt of the Upskillr.ai Privacy Policy.</p>
                        <div className='btnArea'>
                            <button className='submitBtn' type="submit">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactSales;