import React, { useState } from 'react'
import { Menu, MenuItem, Button } from '@mui/material';
import Images from '../../constants/Images';
import Colors from '../../constants/Colors';
import './index.scss'
import { useCollapse } from 'react-collapsed'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function LeftCard(props) {
    const { 
        imageUrl,
        title,
        showDots,
        onCardClick=()=>{},
        deleteChat=()=>{},
        renameChat=()=>{}
    } = props;
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (type) => {
        setAnchorEl(null);
        if( type === 'delete')
        deleteChat();
        else if(type === 'rename')
        renameChat();

    };



    return (
        <>
            <div className='LeftCard' style={{display:'flex',alignItems:'center',justifyContent:'space-between',background: 'rgba(233, 243, 255, 0.5)'}}>
                <div className='leftViewLink' onClick={onCardClick}>
                    {imageUrl !==false && <img src={imageUrl ? imageUrl : Images.UploadFileIcon} style={{ width: 24, height: 24, marginRight: 4 }} />}
                    <div className='title'>{title}</div>
                </div>
                {showDots !==false && <div className='floatingBtn'>
                    <Button
                        // id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        style={{ color: Colors.dark_blue}}
                    >
                        <MoreHorizIcon />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={()=>handleClose("rename")}>
                            <img src={Images.Rename} style={{ width: 16, height: 16, marginRight: 8 }} />Rename
                        </MenuItem>
                        <MenuItem onClick={()=>handleClose("delete")} style={{ color: '#C82323' }}>
                            <img src={Images.Trash} style={{ width: 16, height: 16, marginRight: 8 }} />Delete
                        </MenuItem>
                    </Menu>
                </div>}
            </div>
        </>
    )
}
export default LeftCard;