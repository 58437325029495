import React, { useState } from 'react';
import Slider from 'react-slick';
import Images from '../../constants/Images';
import Colors from '../../constants/Colors';

const PreviousBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.PreArrow} />
        </div>
    )
}
const NextBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.NextArrow} />
        </div>
    )
}

const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrow: false,
    autoplay: false,
    speed: 2000,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false

            }
        },
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            }
        },
    ]
};

function UpskillrSlider(props) {
    return (
            <div className='upskillrSlider'>
                <Slider
                    prevArrow={<PreviousBtn className={"prevBtn " + props.prevBtnStyle}/>}
                    nextArrow={<NextBtn className={"prevBtn "+ props.nextBtnStyle }/>}
                    {...settings}>
                    {props.children}
                </Slider>
        </div>
    );
}

export default UpskillrSlider;