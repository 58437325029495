export const BASE_URL = 'https://upskillr.ai/api';

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const GOOGLE_CLIENT_ID = "509406331467-vlqop6vbft1v3pedcihd4r9aranot00f.apps.googleusercontent.com";
export const FACEBOOKID = "960029204695485";
export const RAZORPAYKEY = "rzp_test_d983sK9lIR0Wu8";
export const EASEBUZZKEY = "TF7CF1D9CF";
export const SMS_API_KEY = "3772b91b-00f8-11ed-9c12-0200cd936042";

export const SUCCESS = 'SUCCESS';
export const PROCCESSING = 'PROCCESSING';
export const FAILUR = 'FAILUR';
export const STOPPROCESSING = 'STOPPROCESSING'

export const sensitiveValue = 'cryptoSecretValue';
export const secretKey = 'cryptoSecretKey';
