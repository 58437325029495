// home page
import CurriculumBuilder from '../assets/curriculumBuilder.png';
import UpskillrAssitant from '../assets/upskillrAssitant.png';
import MultiSearchWriting from '../assets/MultiSearchWriting.png';
import LearnDiscoverCurate  from '../assets/Learn-Discover-Curate.png';
import CareerPal  from '../assets/CareerPal.png';
import ToolsAdvisory  from '../assets/ToolsAdvisory.png';
import CardBGLeftBlue  from '../assets/key-feature-card-gb.png';
import PeachCardBGRightBlue  from '../assets/key-feature-card-gb-right-yellow.png';
import YellowMultilineBg  from '../assets/yellow-multiline-bg.png';
import VideoPlayIcon  from '../assets/play-icon.png';
import BlueMultilineBg  from '../assets/blue-multiline-bg.png';
import Business   from '../assets/business.png';
import ItIcon   from '../assets/it-icon.png';
import FinanceAccounting   from '../assets/Finance-Accounting.png';
import SmartLibrary   from '../assets/smart-library.png';
import Comma   from '../assets/comma.png';

// ABoutUs Images
import GoolgeLogoImage from '../assets/logos/Google.png'
import CourseraLogoImage from '../assets/logos/Coursera.png'
import ExpertronLogoImage from '../assets/logos/Expertron.png'
import EDxLogoImage from '../assets/logos/EDx.png'
import IimJobsLogoImage from '../assets/logos/iimjobs.png'
import MediumLogoImage from '../assets/logos/Medium.png'
import NaukriLogoImage from '../assets/logos/Naukri.png'
import RelevelLogoImage from '../assets/logos/Relevel.png'
import SimplyHiredLogoImage from '../assets/logos/Simply Hired.png'
import SkillShareLogoImage from '../assets/logos/Skillshare.png'
import UdemyLogoImage from '../assets/logos/Udemy.png'
import WizeLogoImage from '../assets/logos/Wize.png'
import YoutubeLogoImage from '../assets/logos/Youtube.png'
import TeamBlueCard from '../assets/team_blue_card.png'
import TeamYellowCard from '../assets/team_yellow_card.png'
import AaravPatel from '../assets/Aarav_Patel.png'
import AnayaSharma from '../assets/Anaya_Sharma.png'
import RohanDesai from '../assets/Rohan_Desai.png'
import MapMyIndia from '../assets/mapmyindia.png'
import PIEDS from '../assets/PIEDS.png'
import BIRAC_1_Transformed from '../assets/BIRAC_1_transformed.png'
import NextArrow from '../assets/next_arrow.png'
import PreArrow from '../assets/prev_arrow.png'
import AdityaSaxena from '../assets/AdityaSaxena.png'
import VikramPandya from '../assets/VikramPandya.png'
import LinkendLogo from '../assets/linkend_logo.png'
import GetInTouch from '../assets/Get_in_touch.png'
import LinkedInVikram from '../assets/LinkedInVikram.png'
import AboutusBannerImage from '../assets/abooutus_banner_image.png'
import AdityaSaxenaNew from '../assets/AdityaSaxenaBackround.png'
import MarketingBlue from '../assets/makering_blue_background.png'
import MarketingYellow from '../assets/yellow_blue_background.png'
import Puspendra from '../assets/pushpendra-removebg-preview.png'

//Footer Icons
import FacebookLogo from '../assets/Facebook.png'
import InstagramLogo from '../assets/Instagram.png'
import YouTubeLogo from '../assets/YouTube.png'
import TwitterLogo from '../assets/Twitter.png'
import LinkedInLogo from '../assets/LinkedIn.png'

//
import SearchIcon from '../assets/search-icon.png'
import CardImage from '../assets/card-img.png'
import INRIcon from '../assets/inr-icon.png'
import viewIcon from '../assets/view-icon.png'
import arrowIcon from '../assets/arrow-icon.png'
import DropdownArrow from '../assets/dropdown-arrow-icon.png'
import DropdownArrowGray from '../assets/down-arrow-icon.png'
import FloatingMenu from '../assets/floating-menu-icon.png'

//
import CheckBoxes from '../assets/checkboxes.png'
import RadioButton from '../assets/RadioButton.png'
import Plus from '../assets/plus.png'
import Trash from '../assets/trash.png'
import Rename from '../assets/Rename.png'
import CirclePlus from '../assets/circle-plus.png'
import GenerateMcqs from '../assets/GenerateMcqs.png'
import CircleCheck from '../assets/circle-check.png'
import ReviewIcon from '../assets/ReviewIcon.png'
import RoundClose from '../assets/round-close.png'
import FilledCloseIcon from '../assets/table_close_icon.png'

//
import Buffer from '../assets/buffer.png'
import Hubspot from '../assets/hubspot.png'
import Mailchimp from '../assets/mailchimp.png'
import ActiveCampaign from '../assets/activeCampaign.png'
import DotImage from '../assets/dot_image.png'

//
import Rupees from '../assets/ruppess.png'
import GraducationCap from '../assets/graducation_cap.png'
import Clock from '../assets/clock.png'
import IntermediateLevel from '../assets/IntermediateLevel.png'
import Globe from '../assets/globe.png'
import RemoveComprasion from '../assets/remove_comprasion.png'
import Coursera from '../assets/coursera.png'


//
import UploadFileIcon from '../assets/upload_file_icon.png'
import UploadLinkIcon from '../assets/upload_link.png'
import leftLineVector from '../assets/leftLinevector.png'
import RightLineVector from '../assets/rightLineVector.png'
import ItSoftware from '../assets/it-software.png';
import OfficeProductivity from '../assets/office-productivity.png';
import PersonalDevelopment from '../assets/personal-development.png';
import Design from '../assets/design.png';
import Marketing from '../assets/marketing.png';
import Lifestyle from '../assets/lifestyle.png';
import PhotographyVideo from '../assets/photography-video.png';
import HealthFitness from '../assets/health-fitness.png';
import Music from '../assets/music.png';
import TeachingAcademics from '../assets/teaching-academics.png';
import TickCheckbox from '../assets/tickCheckbox.png';
import WrongCheckBox from '../assets/WrongCheckBox.png';

//
import UserIcon from '../assets/userIcon.png';
import TutorialIcon from '../assets/tutorialIcon.png';
import SavedLibrary from '../assets/SavedLibrary.png';
import LikedLibrary from '../assets/LikedLibrary.png';
import HiddenLibrary from '../assets/HiddenLibrary.png';
import CreditIcon from '../assets/CreditIcon.png';
import LogoutIcon from '../assets/logoutIcon.png';
import GoogleIcon from '../assets/googleIcon.png';
import TickBlue from '../assets/mdi_tick-circle.png';
import WhiteTick from '../assets/white-Tick.png';
import ChatPDF from '../assets/chatPdfIcon.png';
import CrossChat from '../assets/crossChat.png';
import Evaluation from '../assets/Evaluation.png';
import SkillValidator from '../assets/SkillValidator.png';
import CareerPath from '../assets/CareerPath.png';
import ResultOriented from '../assets/ResultOriented.png';
import CurriculumBuilderIcon from '../assets/CurriculumBuilderIcon.png';
import UpskillrAssistantIcon from '../assets/UpskillrAssistantIcon.png';
import MultiSearchWritingIcon from '../assets/MultiSearchWritingIcon.png';
import CareerPalIcon from '../assets/CareerPalIcon.png';
import ToolsAdvisoryIcon from '../assets/ToolsAdvisoryIcon.png';
import LearnDiscoverCurateIcon from '../assets/Learndiscovercurate.png';
import BlogBanner from '../assets/blog_banner_image.png';
import BlogImage from '../assets/Nature_blog.png';
import TechnologyNewLog from '../assets/Technology_newslog.png';

import CartIcon from '../assets/cart-icon.png';
import SuccessOrder from '../assets/success-order.png';
import Compass from '../assets/compass.png';
import CashPlan from '../assets/cash_plan.png';
import Setting from '../assets/setting.png';
import Arrange from '../assets/arrange.png';
import Assistant from '../assets/AssistantLogo.png'
import Info from '../assets/info.png'
import Clipboard from '../assets/clipboard.png'
import AdityaSaxenaLinkedIn from '../assets/adityasaxsena.png'


const Images = {
    // home page 
    CurriculumBuilder,UpskillrAssitant,MultiSearchWriting,LearnDiscoverCurate,CareerPal,ToolsAdvisory,CardBGLeftBlue,PeachCardBGRightBlue,YellowMultilineBg,VideoPlayIcon,BlueMultilineBg,Business,ItIcon,FinanceAccounting,SmartLibrary,Comma,

    // ABoutUs Images
    GoolgeLogoImage,CourseraLogoImage,ExpertronLogoImage,EDxLogoImage,IimJobsLogoImage,MediumLogoImage,NaukriLogoImage,RelevelLogoImage,SimplyHiredLogoImage,SkillShareLogoImage,UdemyLogoImage,WizeLogoImage,YoutubeLogoImage,TeamBlueCard,TeamYellowCard,AaravPatel,AnayaSharma,RohanDesai,MapMyIndia,PIEDS,BIRAC_1_Transformed,NextArrow,PreArrow,AdityaSaxena,VikramPandya,LinkendLogo,GetInTouch,LinkedInVikram,AboutusBannerImage,AdityaSaxenaNew,MarketingBlue,MarketingYellow,AdityaSaxenaLinkedIn,

    //Footer Icons
    FacebookLogo,InstagramLogo,YouTubeLogo,TwitterLogo,LinkedInLogo,

    ///
    SearchIcon, CardImage,INRIcon,viewIcon,arrowIcon,DropdownArrow,FloatingMenu,DropdownArrowGray, Assistant, 

    //
    CheckBoxes,RadioButton,Plus,Trash,Rename,CirclePlus,GenerateMcqs,CircleCheck,ReviewIcon,RoundClose,FilledCloseIcon,

    //
    Buffer,DotImage,ActiveCampaign,Mailchimp,Hubspot,

    //
    Rupees,GraducationCap,Clock,IntermediateLevel,Globe,RemoveComprasion,Coursera,

    //
    UploadFileIcon,UploadLinkIcon,leftLineVector,RightLineVector,
    ItSoftware,OfficeProductivity,PersonalDevelopment,Design,Marketing,Lifestyle,PhotographyVideo, HealthFitness,Music,TeachingAcademics,Puspendra,
    TickCheckbox,WrongCheckBox,

    //
    UserIcon,TutorialIcon,SavedLibrary,LikedLibrary,HiddenLibrary,CreditIcon,LogoutIcon,GoogleIcon,TickBlue,WhiteTick,ChatPDF,CrossChat,Evaluation,SkillValidator,CareerPath,ResultOriented,CurriculumBuilderIcon,UpskillrAssistantIcon,MultiSearchWritingIcon,CareerPalIcon,ToolsAdvisoryIcon,LearnDiscoverCurateIcon,BlogBanner,BlogImage,TechnologyNewLog,CartIcon,SuccessOrder,Compass,CashPlan,Setting,Arrange,Info, Clipboard


};

export default Images;
