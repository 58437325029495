import {
    ORDER_LIST,
} from './action';


const initialState = {
    orderList: [],
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case ORDER_LIST: {
            return {
                ...state,
                orderList: action.payload,
            };
        }
        
        default: {
            return state;
        }
    }
};

export default reducer;
