import Images from "../../constants/Images";

const KeyFeatureData = [
    {
        "title": "Curriculum Builder",
        "subTitle": "Instant pre-learning material for your curriculum of degree programs and job topics of all levels in every field.",
        "imageUrl": Images.CurriculumBuilder,
        "cardType":'lavenderBlueRighBgCard'
    },
    {
        "title": "Upskillr Assitant",
        "subTitle": "Provide quick, smart, and well-organized responses to avoid repetitive inquiries and overwhelm users with excessively complex documents, URLs, and videos.",
        "imageUrl": Images.UpskillrAssitant,
        "cardType":'peachRightCard'
    },
    {
        "title": "Multi-search Writing",
        "subTitle": "Compose educational or professional articles that can determine, based on the user's inquiry, which data source should be employed to provide a response.",
        "imageUrl": Images.MultiSearchWriting,
        "cardType":'peachLeftCard'
    },
    {
        "title": "Learn, discover,curate ",
        "subTitle": "Daily collections of the most popular blog posts, videos, lectures, and articles from all the major content platforms.",
        "imageUrl": Images.LearnDiscoverCurate,
        "cardType":'lavenderBlueLeftBgCard'
    },
    {
        "title": "CareerPal",
        "subTitle": "Unlock your potential with an all-in-one AI-powered companion that provides personalized support for tutoring, skill building, interview prep, and beyond.",
        "imageUrl": Images.CareerPal,
        "cardType":'lavenderBlueLeftBgCard'
    },
    {
        "title": "Tools Advisory",
        "subTitle": "Must-known and industry-level used software tools for your domain",
        "imageUrl": Images.ToolsAdvisory,
        "cardType":'peachRightCard'
    }

]

const OrganizationsLogo = [
    {
        data:[ Images.GoolgeLogoImage, Images.YoutubeLogoImage, Images.MediumLogoImage, Images.CourseraLogoImage]
    },
    {
        data:[ Images.SkillShareLogoImage, Images.RelevelLogoImage, Images.UdemyLogoImage, Images.EDxLogoImage, Images.IimJobsLogoImage]
    },
    {
        data:[ Images.WizeLogoImage, Images.SimplyHiredLogoImage, Images.NaukriLogoImage, Images.ExpertronLogoImage]
    }
]

const WhyChooseUpskillr = [
    {
        title:"Flexible and Intuitive",
        subTitle:"I was struggling with my Economics course until I discovered Upskillr.ai's Curriculum Builder. I could create my study plan, follow interactive videos, and even take quizzes. A few kinks to iron out but it turned a difficult subject into a fun learning journey!",
        profileImage:'',
        profileName:'Aarav Sharma',
        location:'BITS Pilani'
    },
    {
        title:"Unparalleled Skill Enhancement",
        subTitle:"Incorporating Career Pal's job simulations in our curriculum has empowered our students to practice real-world scenarios. Even in its early stages, I've witnessed remarkable growth in their technical and analytical skills. Career Pal is showing great promise as a classroom essential.",
        profileImage:'',
        profileName:'Sunita Joshi',
        location:'Placement Academy'
    },
    {
        title:"Business Amplified",
        subTitle:"Preparing market analysis reports used to take days. With Upskillr, I can already create insightful and data-driven reports in hours, referencing multiple market studies and Excel files. Though there are improvements to be made, my productivity has soared. Its potential is undeniable.",
        profileImage:'',
        profileName:'Nitin Agarwal',
        location:'TechWave Innovations Pvt. Ltd.'
    },
    {
        title:"Productivity Boosted",
        subTitle:"Dealing with numerous project documents used to be daunting. Upskillr Assistant provides quick summaries and specific details without going through my documents. A few glitches here and there, but my project delivery times have halved! It's exciting to see where it goes.",
        profileImage:'',
        profileName:'Rohan Desai',
        location:'Neotech Solutions Pvt. Ltd.'
    },
    {
        title:"Education Reimagined",
        subTitle:"Despite Upskillr.ai being fresh out the gate, its Curriculum Builder shows immense promise. It’s allowed me to tailor unique learning paths. Recently, a module on Renewable Energy was a hit, with curated resources making my class sessions more engaging.",
        profileImage:'',
        profileName:'Dr. Kavita Mehta',
        location:'Mumbai Institute of Engineering and Technology'
    },
    {
        title:"Instant Clarifications",
        subTitle:"For my project on ancient Indian history, Upskillr Assistant was a lifesaver. Instead of sifting through long documentaries and surfing online, I simply pasted YouTube links, Web URLs and got specific details in seconds. A true time saver!",
        profileImage:'',
        profileName:'Bhavika Malhotra',
        location:'BITS Pilani'
    },
    {
        title:"Pathway Unveiled!",
        subTitle:"Initially uncertain about a newer platform like Career Pal, I was taken by surprise! The career path insights and skill map were top-notch, outlining my journey to become a consultant, post graduation. It’s evolving, but the guidance is unparalleled.",
        profileImage:'',
        profileName:'Akshay Patel',
        location:'SP Jain School of Global Management'
    },
    {
        title:"Research Simplified",
        subTitle:"Working on a psychology assignment was a breeze with Upskillr. I just fed in three vital URLs, and the tool curated an accurate essay on child development theories. It saved me hours of manual work.",
        profileImage:'',
        profileName:'Priya Nair',
        location:'BITS Pilani'
    },
]

const  VideoLevel={
    label:"Level",
    options:[
        { "value": "Beginner", "label": "Beginner" },
        { "value": "Intermediate", "label": "Intermediate" },
        { "value": "Advanced", "label": "Advanced" },
    ]
}
const   VideoDuration= {
    label:"Duration",
    options:[
        { "value": "Short", "label": "Short" },
        { "value": "Medium", "label": "Medium" },
        { "value": "Long", "label": "Long" },
    ]
}
const   Industry= {
    label:"Industry",
    options:[
        { "value": "Development", "label": "Development" },
        { "value": "IT & Software", "label": "IT & Software" },
        { "value": "Finance & Marketing", "label": "Finance & Marketing" },
        { "value": "Business ", "label": "Business " },
        { "value": "Office Productivity ", "label": "Office Productivity " },
        { "value": "Personal Development ", "label": "Personal Development " },
        { "value": "Design ", "label": "Design " },
        { "value": "Marketing ", "label": "Marketing " },
        { "value": "Lifestyle", "label": "Lifestyle" },
        { "value": "Photography & Video ", "label": "Photography & Video " },
        { "value": "Health & Fitness", "label": "Health & Fitness" },
        { "value": "Music", "label": "Music" },
        { "value": "Teaching & Academics", "label": "Teaching & Academics" },
    ]
}

export default {KeyFeatureData, OrganizationsLogo,WhyChooseUpskillr,VideoLevel,VideoDuration,Industry};