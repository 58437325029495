import React, { useState } from 'react';
import { PropTypes } from "prop-types";
import Modal from 'react-bootstrap/Modal';
import { Menu, MenuItem, Button, Checkbox, Box, Tabs, Tab } from '@mui/material';
import { Card } from 'react-bootstrap';

import './index.scss';
import Images from '../../constants/Images';
import MockData from './data.json';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    TwitterIcon,
    WhatsappIcon,
  } from 'react-share';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`quickView-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const MachineLearningCard = (props) => {
    const { cardWidth, cardHeight, title, subTitle, imageUrl, type, courseType, totalStudents, totalHours, coursePrize, courseFree, machineLearningCardStyle, cardContentStyle, showBottomLine, cardImageStyles, showButtons, showChexkBox, centerTitle, imageStyles, quickView, floatingMenu = true, clickAction, payloadAction, section, URL} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [checked, setChecked] = React.useState(false);
    const [key, setKey] = useState('home');
    const [openShare, setOpenShare] = useState(false)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheckBoxChecked = () => {
        setChecked(!checked);
    }
    const [modalShow, setModalShow] = useState(false);

    const handleCloseModal = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderShare = () => {
        return(
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap'}} onMouseLeave={(event) => setOpenShare(!openShare)}>
        <EmailShareButton style={{margin:6}} url={URL|| ''}>
          <EmailIcon size={30} round={true} />
        </EmailShareButton>
        <FacebookShareButton  style={{margin:6}} url={URL|| ''}>
          <FacebookIcon size={30} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton style={{margin:6}} url={URL|| ''}>
          <LinkedinIcon size={30} round={true} />
        </LinkedinShareButton>
        {/* <PinterestShareButton url={URL}>
          <PinterestIcon size={30} round={true} />
        </PinterestShareButton> */}
        <TwitterShareButton style={{margin:6}} url={URL|| ''}>
          <TwitterIcon size={30} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton style={{margin:6}} url={URL|| ''}>
          <WhatsappIcon size={30} round={true} />
        </WhatsappShareButton>
      </div>

        )
    }

    const renderQuickViewModal = () => {

        return (
            <Modal
                show={modalShow} onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='quickViewModal'
            >
                <div style={styles.modalHeaderBg}>
                    <Button onClick={handleCloseModal} style={styles.modalCloseButton}>
                        <img src={Images.FilledCloseIcon} style={{ width: 36, height: 36 }} />
                    </Button>
                    <div className='d-flex' style={{ width: '80%' }}>
                        <img src={Images.AboutusBannerImage} style={styles.thumImg} />
                        <div style={{ paddingLeft: 24 }}>
                            <Modal.Title style={styles.modalTitle}>Convolution Neural Networks & Structuring Machine Learning</Modal.Title>
                            <div className='courseDetails'>
                                <span className='courseType'>{courseType}</span>
                                <div className='dottStyle' />
                                <span className='courseType'>{totalStudents} Students</span>
                                <div className='dottStyle' />
                                <span className='courseType'>{totalHours} Hours</span>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal.Body id="quickViewSummary">
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={tabValue}
                            onChange={handleTabChange}
                            indicatorColor="#FCC52"
                            aria-label="basic tabs example"
                            className="summaryTabs"
                            variant="scrollable"
                            scrollButtons="auto">
                            {
                                MockData.quickViewTabs.map((item, i) => (
                                    <Tab label={item.tabName} key={'tab' + i} />
                                ))
                            }
                        </Tabs>
                        {
                            MockData.quickViewTabs.map((item, i) => (
                                <TabPanel value={tabValue} index={i} key={'TabPanel' + i} className="tabContents">
                                    {item.data}
                                </TabPanel>
                            ))
                        }
                    </Box>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-center'>
                    <Button style={styles.goToCourseModalBtn}>Go to course <img src={Images.arrowIcon} style={styles.rightBtnImg} /></Button>
                </Modal.Footer>
            </Modal>
        )
    }



    return (
        <>
            <Card className='machineLearningCard' style={{ ...machineLearningCardStyle }} >
                {openShare && renderShare()}
                {floatingMenu && <div className='floatingBtn'>
                    <Button
                        // id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <img src={Images.FloatingMenu} />
                    </Button>
                    <Menu
                        className="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={()=>{handleClose(); clickAction('Save', payloadAction, section)}}>
                            <img src={require('./../../assets/save-icon.png')} style={styles.menuIconStyle} />Save
                        </MenuItem>
                        <MenuItem onClick={()=>{handleClose(); clickAction('Like', payloadAction, section)}}>
                            <img src={require('./../../assets/heart-icon.png')} style={styles.menuIconStyle} />Like
                        </MenuItem>
                        <MenuItem onClick={()=>{handleClose();setOpenShare(!openShare)}}>
                            <img src={require('./../../assets/share-icon.png')} style={styles.menuIconStyle} /> Share
                        </MenuItem>
                        <MenuItem onClick={()=>{handleClose(); clickAction('Hide', payloadAction, section)}}>
                            <img src={require('./../../assets/hide-icon.png')} style={styles.menuIconStyle} /> Hide
                        </MenuItem>
                    </Menu>
                </div>}
                {showChexkBox &&
                    <div className={`floatingCheckBox ${checked ? 'active' : ''}`} style={{ color: checked ? '#FFAA29' : '#64748B' }}>
                        <Checkbox size="small" sx={{ padding: 0, color: '#64748B', '&.Mui-checked': { color: '#FFAA29' }, marginRight: 0.3 }} onChange={handleCheckBoxChecked} />Compare
                    </div>
                }
                <div onClick={props.onCardClick}>
                    <Card.Img variant="top" src={imageUrl ? imageUrl : Images.CardImage} onError={(e) => {
                            e.target.src = Images.CardImage;
                        }} className='cardImg' style={{ height: 136, ...imageStyles }} />
                    <div className='cardContentStyle' style={{ ...cardContentStyle }}>
                        {type && <div className='type'>{type}</div>}
                        {title && <div className='cardTitle'>{title}</div>}
                        {centerTitle && <div className='cardCenterTitle'>{centerTitle}</div>}
                        {subTitle && <div className='cardSubTitle'>{subTitle}</div>}
                        {(courseType || totalStudents || totalHours) &&
                            <div className='courseDetails'>
                                {
                                    courseType &&
                                    <span className='courseType'>{courseType}</span>
                                }
                                {
                                    (courseType && totalStudents) &&
                                    <div className='dottStyle' />
                                }
                                {
                                    totalStudents &&
                                    <span className='courseType'><b>{totalStudents}</b> Students</span>
                                }
                                {
                                    (totalHours && totalStudents) &&
                                    <div className='dottStyle' />
                                }
                                {
                                    totalHours &&
                                    <span className='courseType'><b>{totalHours}</b> Hours</span>
                                }
                            </div>
                        }
                        {
                            props.courseTime && props.timeFormat && props.date && <div className='courseDetails'>
                                <span className='courseType'><b>{props.courseTime}</b> {props.timeFormat}</span>
                                <div className='dottStyle' />
                                <span className='courseType'><b>{props.date}</b></span>
                            </div>
                        }
                        {courseFree &&
                            <div className='prize'>
                                {/* <img src={Images.INRIcon} className='inrIcon' /> */}
                                {courseFree}
                            </div>
                        }
                        {coursePrize &&
                            <div className='prize'>
                                {/* <img src={Images.INRIcon} className='inrIcon' /> */}
                                {coursePrize}
                            </div>
                        }
                        {showButtons !== false && <div className='cardFooter'>
                            <Button variant="white" onClick={handleModalShow}>
                                <img src={Images.viewIcon} style={{ marginRight: 8 }} /> Quick View
                            </Button>
                            <Button style={{ backgroundColor: '#004AAD' }} className='white'>Go to course <img src={Images.arrowIcon} style={{ marginLeft: 8 }} /></Button>
                        </div>}
                        {showBottomLine && <hr style={styles.hrLine} />}
                    </div>
                </div>

            </Card>
            {renderQuickViewModal()}
        </>
    )
}

export default MachineLearningCard;

MachineLearningCard.propTypes = {
    cardWidth: PropTypes.number,
    cardHeight: PropTypes.number,
    type: PropTypes.any,
    showChexkBox: PropTypes.bool,
    quickView: PropTypes.func,
    floatingMenu: PropTypes.bool,
};

const styles = {
    thumImg: { width: 150, height: 150, borderRadius: 16, objectFit: 'cover' },
    modalTitle: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 23,
        lineHeight: '28px',
        letterSpacing: '-0.02em',
        color: '#00295F',
        marginTop: 10
    },
    modalCloseButton: { position: 'absolute', right: 10 },
    goToCourseModalBtn: { backgroundColor: '#004AAD', color: 'white', fontFamily: 'Poppins', fontSize: 14, fontWeight: '700', padding: '10px 31px', },
    rightBtnImg: { width: 14, height: 12, marginLeft: 8 },
    menuIconStyle: { width: 16, height: 16, marginRight: 8 },
    hrLine: { backgroundColor: '#E7EAEE', marginTop: 16, height: 2, border: 0, marginBottom: 0 },
    modalHeaderBg: { background: '#E5F1FF', padding: 24, borderTopLeftRadius: 7, borderTopRightRadius: 7 }
}