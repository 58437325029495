import React, { useState } from 'react';
import { Button, ButtonGroup, Menu } from '@mui/material';
import { MultiSelect } from "react-multi-select-component";
import Form from 'react-bootstrap/Form';

import './index.scss';
import MockData from './data.json'
import MachineLearningCard from './MachineLearningCard';
import ViewHeading from '../ViewHeading';
import UpskillrUtils from '../../utilities/UpskillrUtils';
import Images from '../../constants/Images';
import ThingsToWatch from './ThingsToWatch';
import ThingsToRead from './ThingsToRead';
import ComparisonCard from './ComparisonCard';

const ComparisonTable = () => {

    

    return (
        <>
            <div className='containers'>
                <ViewHeading title={'Machine Learning'} buttonText={'Compare Course'} containerStyles={{marginTop:40}}/>

                <div className='row'>
                    <div className='col-md-3'>
                        <ComparisonCard type={'Coursera'}
                            title={'Convolution Neural Networks & Structuring Machine Learning'}
                            courseType={'Beginner'}
                            money={'Free'}
                            students={'10.1M'}
                            hours={'24'}
                            level={'Intermediate Level'}
                            language={'English'}
                        />
                    </div>
                    <div className='col-md-3'>
                        <ComparisonCard type={'Coursera'}
                            title={'Convolution Neural Networks & Structuring Machine Learning'}
                            courseType={'Beginner'}
                            money={'Free'}
                            students={'10.1M'}
                            hours={'24'}
                            level={'Intermediate Level'}
                            language={'English'}
                        />
                    </div>
                    <div className='col-md-3'>
                        <ComparisonCard type={'Coursera'}
                            title={'Convolution Neural Networks & Structuring Machine Learning'}
                            courseType={'Beginner'}
                            money={'Free'}
                            students={'10.1M'}
                            hours={'24'}
                            level={'Intermediate Level'}
                            language={'English'}
                        />
                    </div>
                    <div className='col-md-3'>
                        <ComparisonCard type={'Coursera'}
                            title={'Convolution Neural Networks & Structuring Machine Learning'}
                            courseType={'Beginner'}
                            money={'Free'}
                            students={'10.1M'}
                            hours={'24'}
                            level={'Intermediate Level'}
                            language={'English'}
                        />
                    </div>
                </div>
            </div>
        </>
    )


}


export default ComparisonTable;