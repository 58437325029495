import React, { useState, useEffect, useRef, useCallback } from 'react'
import './CurriculumBuilder.scss'
import { NavLink } from 'react-router-dom';
import ViewHeading from '../ViewHeading';
import Information from './Infomation';
import Mcqs from './Mcqs';
import McqsEdit from './McqsEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem, Button, Autocomplete, TextField } from '@mui/material';
import Images from '../../constants/Images';
import McqsQA from './McqsQA';
import McqsQAEdit from './McqQAEdit';
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useEffect  } from 'react';
import { deleteCurriculumByID, getAllCurriculumBuilder, getCurriculumTopicByID, getThingToRead, getThingToWatchList, updateCurriculumByID,getSuggestionList, searchCurriculam, getRecommendedList, deleteRecommendedByID, generateMcQs,generateNewCurriculam, generateTopics, generateSubTopics, generateSubSubTopics, getCurriculamByUserId, createCurriculam, getCheckMcq, getViewResult, submitMcqTest, getSeeResult, activityMatrix, generateNewCurriculamtoc, createCourse} from './action';
import { Content, SelectPicker } from 'rsuite';
import CurriculamListCard from './CurriculamListCard';
import Loader from '../Loader';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Dropdown, ListGroup, Table } from 'react-bootstrap';
import CurriculumCollapsableView from './collapsable/CurriculumCollapsableView';
import Colors from '../../constants/Colors';
import Slider from 'react-slick';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import SeeResults from './SeeResults';
import McqReviewScreen from './McqReviewScreen';
import { getThingsToDo } from '../ourFeatures/action';
import { getProfileDetails } from '../../pages/myAccount/action';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import McqsUserQA from './McqsUserQA';
import BuilderLayers from './BuilderLayers';
import { encryptQueryParam, decryptQueryParam} from "../../utilities/EncryptionUtils";
import { ACTIVITY_MATRIX } from "./action";

function CurriculumBuilder(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pageData = useSelector((state)=> state.CurriculumReducer.curriculumDetails);
    let topicListData = useSelector((state)=> state.CurriculumReducer.topicListData);
    const totalCount = useSelector((state)=> state.CurriculumReducer.totalCurriculumCount);
    const { loading, recommendedList, error, suggestionList, searchList, listByUser } = useSelector((state)=> state.CurriculumReducer);
    const thingsToWatch = useSelector((state)=> state.CurriculumReducer.thingsToWatchList);
    const thingsToRead = useSelector((state)=> state.CurriculumReducer.thingsToReadList);
    const {generateMcqsLoading, generateMcqsSuccess,generateCurriculumLoading, generateCurriculumtocLoading} = useSelector((state)=> state.CurriculumReducer);
    const thingsToDo = useSelector((state)=> state.skillMapReducer.thingsToDo);
    const {user_id} = useSelector((state) => (state.loginReducer));
    const {checkMcqUserSubmissionSuccess, seeResultSuccess, seeResultLoading, viewDetailSuccess, viewDetailLoading, submitMcqLoading, createCourseLoading} = useSelector((state) => (state.CurriculumReducer));
    const activitymatrix = useSelector((state)=> state.CurriculumReducer.activitymatrix); 
    const [ mcqQA, setmcqQA] = useState();
    const [disabledBtn, setDisabledBtn]= useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [courseId, setCourseId] = React.useState(null);
    const [pageLoading, setPageLoading] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState(null);
    const [contents, setContents] = React.useState(null);
    const [listArray, setListArray] = React.useState([]);
    const [limit, setLimit] = React.useState(6);
    const [offset, setOffset] = React.useState(0);
    const [userCurrOffset, setuserCurrOffset] = React.useState(0);
    const [formObject, setFormObject] = React.useState(null);
    const [mcqQus, setMcqQus] = useState(null)
    const [topicListTempData, setTopicListTempData] = React.useState(null);
    const [options, setOptions] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [isTextLoading, setIsTextLoading]= useState(false);
    const [inputValues, setInputValues] = useState({
        Title : '',
        Is_Correct : false,
        questionTitle : ''
    });
    const [editQuestion, setEditQuestion] = useState({
        questionId : '',
        key : '',
        multipleChoice : null
    });

    const [addQuestion, setAddQuestion] = useState({
        mcqTitle: '',
        key: '',
        questionTitle: ''
    });

    const [undoEntries, setUndoEntries] = useState([]);    
    const [deleteUrlParams, setDeleteUrlParams] = useState([{paramName:'mcqId'},{paramName:'attempUser'}]);
    const [isMcqReviewScreen, setIsMcqReviewScreen] = useState(false);
    const [mcqId, setMcqId] = useState(null);
    const [searchListArray, setSearchListArray] = useState([]);
    const [userCurrListArray, setUserCurrListArray] = useState([]);
    const [openShare, setOpenShare] = useState(false);
    const [showBuilder, setShowBuilder] = useState(false);
    const [builderRender, setBuilderRender] = useState(0);
    const [editingItemIndex, setEditingItemIndex] = useState(-1);  
    const [level, setLevel] = useState(null); 
    const [activeKeys, setActiveKeys] = useState([]);

    const handleAccordionSelect = (eventKey) => {
        // Update the activeKeys state to keep track of open accordions
        setActiveKeys((prevKeys) =>
            prevKeys.includes(eventKey)
                ? prevKeys.filter((key) => key !== eventKey)
                : [...prevKeys, eventKey]
        );
    };

    const closeAllAccordions = () => {
        // Set the activeKeys state to an empty array to close all accordions
        setActiveKeys([]);
    };

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrow: true,
        autoplay: true,
        speed: 2000,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
    
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,
                    speed: 3000
                }
            },
        ]
    };
    useEffect(()=>{
        async function fetchData() {
            let courseId = searchParams.get("courseId");
            let error = null;
            if (courseId) {
                setCourseId(courseId);
                courseId = await decryptQueryParam(courseId).catch(err => {
                    return '';
                });
                if (courseId === '') {
                    toast.error("Invalid URL");
                    setCourseId(null);
                    navigate('/');
                    return;
                }

                await dispatch(getCurriculumTopicByID(courseId)).catch(err => {
                    toast.error(err.message || 'Something Went Wrong');
                    if(err.response.status === 403){
                        error = err;
                    }
                    return null;
                });

                if (error) {
                    searchParams.delete('courseId');
                    window.location.href = '/our-features?' + searchParams.toString();
                    return;
                }

                setContents(topicListData?.Topics[0]?.Content);
            }
            // dispatch(getRecommendedList()).catch(err => {
            //     console.log("Error", err);
            // });
        }
        fetchData();
    },[]);

    useEffect(()=>{
        const fetchData = async () => {
            if(courseId){
                let decryptCourseId = await decryptQueryParam(courseId).catch(err => {
                    return '';
                });
                if (decryptCourseId === '') {
                    toast.error("Invalid URL");                    
                    setCourseId(null);
                    navigate('/');                 
                    return;
                } else {
                    dispatch(getCurriculumTopicByID(decryptCourseId));
                    setContents(topicListData?.Topics[0]?.Content);
                }            
            }
        }

        fetchData();
    },[courseId]);
    
    useEffect(() => {
        const fetchData = async () => {
            let courseIdParam = searchParams.get("courseId");
            if (courseIdParam && courseId) {
                // We will decrypt and check if it is valid or not
                courseIdParam = await decryptQueryParam(courseIdParam).catch(err => {
                    return '';
                });
                if (courseIdParam === '') {
                    toast.error("Invalid URL");                    
                    setCourseId(null);
                    navigate('/');                    
                    return;
                } else {
                    dispatch(getCurriculumTopicByID(courseIdParam));
                }
            } else {
                if (listByUser.length < 1) dispatch(getCurriculamByUserId());
                if (totalCount >= parseInt(offset)) {
                    dispatch(getAllCurriculumBuilder({ limit: limit, offset: parseInt(offset) }));
                }
            }
        };
        fetchData();
    }, [offset]);
    
        
    useEffect(()=>{
        if(pageData){
            setListArray((prevData) => {
                const uniqueData = [...prevData, ...pageData];
                const uniqueSet = new Set(uniqueData.map(JSON.stringify));
                const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
                return uniqueArray;
            });
        }
    }, [pageData]);

    useEffect(()=>{
        if(topicListData){
            if (!(topicListData?.isPublic) && (topicListData?.Created_By !== null && user_id != topicListData?.Created_By.id) && !(topicListData?.isShare)) {
                console.log("Ready to remove")
            }


            setContents(topicListData?.Topics[0]?.Content);
            // dispatch(getThingToWatchList({topic:topicListData?.Name,}));
            // dispatch(getThingToRead({input:topicListData?.Name}));
        }
    }, [topicListData]);

    useEffect(()=>{
        if(formObject){
            let formData = topicListData;
            let getThingToReadObject = {input:formData?.Name};
            let getThingToWatchObject = {topic:formData?.Name};
            let thingToDo = formData?.Name;
            if(formObject){
                if(formObject.topicLevel === null){
                    getThingToReadObject.input_2=  formData.Topics[formObject.index].Title;
                    getThingToWatchObject.difficulty=  formData.Topics[formObject.index].Title;
                }
                if(formObject.topicLevel === 'SubTopics'){
                    getThingToReadObject.input_2=  formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].Title;
                    getThingToWatchObject.difficulty=  formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].Title;
                    thingToDo=formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].Title
                }
                if(formObject.topicLevel === 'SubSubTopics'){
                    getThingToReadObject.input_2= formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].SubSubTopics[formObject.subSubTopicIndex].Title;
                    getThingToWatchObject.difficulty= formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].SubSubTopics[formObject.subSubTopicIndex].Title;
                    thingToDo=formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].SubSubTopics[formObject.subSubTopicIndex].Title;
                }
            }
            // dispatch(getThingToWatchList(getThingToWatchObject));
            // dispatch(getThingToRead(getThingToReadObject));
            // dispatch(getThingsToDo(thingToDo));
            // if(thingToDo){
            //     dispatch(activityMatrix({ topic:thingToDo }))
            // }

        }
    }, [formObject]);

    useEffect(() => {
        if(searchValue && searchValue !==''){
            dispatch(getSuggestionList(searchValue));
            const attempUser =  searchParams.get('attempUser');
            const mcqId =  searchParams.get('mcqId');
            if(attempUser && mcqId){
                setIsMcqReviewScreen(true)
            }else{
                setIsMcqReviewScreen(false);
            }
            if(listByUser && listByUser.length > 0){
                let searchUserCurrList = listByUser.filter((res)=>res.Name === searchValue)
                setUserCurrListArray(searchUserCurrList);
            }else{
                setUserCurrListArray([]);
            }
        }else{
            if(listByUser && listByUser.length > 0) setUserCurrListArray(listByUser); 
        }

    }, [searchValue]);

    const fetchActivityMatrix = () => {
        if(formObject){
            let formData = topicListData;
            let getThingToReadObject = {input:formData?.Name};
            let getThingToWatchObject = {topic:formData?.Name};
            let thingToDo = formData?.Name;
            if(formObject){
                if(formObject.topicLevel === null){
                    getThingToReadObject.input_2=  formData.Topics[formObject.index].Title;
                    getThingToWatchObject.difficulty=  formData.Topics[formObject.index].Title;
                }
                if(formObject.topicLevel === 'SubTopics'){
                    getThingToReadObject.input_2=  formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].Title;
                    getThingToWatchObject.difficulty=  formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].Title;
                    thingToDo=formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].Title + ` (${thingToDo})`
                }
                if(formObject.topicLevel === 'SubSubTopics'){
                    getThingToReadObject.input_2= formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].SubSubTopics[formObject.subSubTopicIndex].Title;
                    getThingToWatchObject.difficulty= formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].SubSubTopics[formObject.subSubTopicIndex].Title;
                    thingToDo=formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].SubSubTopics[formObject.subSubTopicIndex].Title + ` (${thingToDo})`;
                }
            }
            if(thingToDo){
                dispatch(activityMatrix({ topic:thingToDo }))
            }
        }
    }

    const addItemToArray = () => {
        const { Title, Is_Correct } = inputValues;
        if (Title.trim() === '' || typeof Is_Correct === 'undefined') {
            // Empty input, do not add to the array
            return;
        }
        if (editIndex !== -1) {
            // Editing existing item
            setOptions(prevArray =>
                prevArray.map((item, index) =>
                    index === editIndex ? { Title,Is_Correct} : item
                )
            );
            setEditIndex(-1); // Reset the edit index
        } else {
            if (options.some(item => item.Title === Title && item.Is_Correct === Is_Correct)) {
                // Duplicate values, do not add to the array
                return;
            }
            setOptions(prevArray => [...prevArray, { Title,Is_Correct }]);
        }
    
        setInputValues(prevState => ({
            ...prevState,
            Title: '', Is_Correct: false
          }));
      };

      const editItem = index => {
        const itemToEdit = options[index];
        setInputValues(prevState => ({
            ...prevState,
            Title : itemToEdit.Title,
            Is_Correct : itemToEdit.Is_Correct
          }));
        setEditIndex(index);
      };
    
      const removeItem = index => {
        setOptions(prevArray => prevArray.filter((_, i) => i !== index));
      };

    const handleOnClick = (mcqs) => {
        setContents(null);
        setmcqQA(mcqs);
        setTopicListTempData(topicListData);
        setMcqId(mcqs.id);
        removeParamFromUrl(deleteUrlParams);
    };

    useEffect(()=>{
        if(mcqId){
            dispatch(getCheckMcq(mcqId)).catch(err=>{
                toast.error(err.message || 'Unable to check user submitted mcq')
            })
        }        
    },[mcqId])

    const handleChange = event => {
        const { name, value, type, checked} = event.target;
        const newValue = type === "checkbox" ? checked : value;
        setInputValues(prevValues => ({ ...prevValues, [name]: newValue}));
    };

    const onCurriculamListCardCliked = (item) => {
        let courseId = searchParams.get("courseId");
        const encryptedId = encryptQueryParam(item?.id);
        if(courseId){
            searchParams.set("courseId", encryptedId);
        }else{
            searchParams.append("courseId", encryptedId);
        }
        setSearchParams(searchParams);
        setCourseId(encryptedId);
        window.scrollTo(0, 0);
        // dispatch(getThingToWatchList({topic:item?.Name,}));
        // dispatch(getThingToRead({input:item?.Name}));
        // dispatch(getThingsToDo(item?.Name));
        dispatch(activityMatrix({ topic: item?.Name }))
    }
    const ref = useRef(null);
    const [height, setHeight] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        
        const container = document.getElementById('list-container');
        const section = document.getElementById('list-section');
        
        if (container && section) {
          const containerTop = container.offsetTop;
          const sectionTop = section.offsetTop;
          const sectionHeight = section.offsetHeight;
          const sectionCenter = containerTop + sectionTop + sectionHeight;
          const windowHeight = window.innerHeight;
          if (position + windowHeight + ref.current.clientHeight >= sectionCenter && totalCount >= parseInt(offset)) {
            setOffset(prevCount => prevCount + (limit))
          }
      }
      };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleOnDeleteCourse = async (selectedCourseId, type=null)=>{
        if(type===null && selectedCourseId){
            await dispatch(deleteCurriculumByID(selectedCourseId, setListArray, limit, offset));
        }
        if(selectedCourseId && type!==null){
            await dispatch(deleteRecommendedByID(selectedCourseId));
            // await dispatch(getRecommendedList());
        }
    }

    const removeIds = async(obj)=>{
        if (typeof obj === 'object') {
            if (Array.isArray(obj)) {
                obj.forEach(item => removeIds(item));
            } else {
                for (const key in obj) {
                    if (key === 'id') {
                        delete obj[key];
                    } else {
                        removeIds(obj[key]);
                    }
                }
            }
        }
    }

    const [copied, setCopied] = useState(false);
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the "copied" state after 2 seconds
      };

    const renderShare = () => {
        return(
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap'}} onClick={(event) => setOpenShare(!openShare)}>
        <CopyToClipboard text={window.location.href || ''} onCopy={handleCopy}>
            <button style={{backgroundColor : 'transparent', padding: '0px', font : 'inherit', color : 'inherit', margin : '6px'}}>
                {copied ? 'Copied!' : <img style={{width : 30, height : 30, cursor: 'pointer'}} src={Images.Clipboard} />}
            </button>
        </CopyToClipboard>
        <EmailShareButton style={{margin:6}} url={window.location.href || ''}>
          <EmailIcon size={30} round={true} />
        </EmailShareButton>
        <FacebookShareButton  style={{margin:6}} url={window.location.href || ''}>
          <FacebookIcon size={30} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton style={{margin:6}} url={window.location.href || ''}>
          <LinkedinIcon size={30} round={true} />
        </LinkedinShareButton>
        {/* <PinterestShareButton url={URL}>
          <PinterestIcon size={30} round={true} />
        </PinterestShareButton> */}
        <TwitterShareButton style={{margin:6}} url={window.location.href || ''}>
          <TwitterIcon size={30} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton style={{margin:6}} url={window.location.href || ''}>
          <WhatsappIcon size={30} round={true} />
        </WhatsappShareButton>
      </div>

        )
    }

    const cloneCurriculum = (data)=>{
        dispatch(getProfileDetails(user_id)).then(async res => {
            let formData = Object.assign({},data);
            formData.Created_By = {
                id: user_id,
                fullname: res.data.fullname
            }
            formData.isPublic = false;
            if(formData?.image) formData.image_id= formData?.image?.id;
            await removeIds(formData);
            dispatch(createCurriculam(formData)).then(response => {
                onCurriculamListCardCliked(response);
            }).catch(err => {
                toast.error(err);
            });
        }).catch(err => {
            toast.error(err);
        });
    }

    const { handleSubmit, control, setValue, formState: { isDirty } } = useForm();

    const handleOnAccordionClick = useCallback((contents, index, topicLevel=null, subTopicIndex=null, subSubTopicIndex=null) =>{
        setContents(contents?.Content); 
        removeParamFromUrl(deleteUrlParams);
        if(contents?.Mcqs) setmcqQA(contents?.Mcqs[0]);
        dispatch({
            type: ACTIVITY_MATRIX,
            payload: {}
        });
        closeAllAccordions();
        setFormObject({index: index ,topicLevel:topicLevel, subTopicIndex:subTopicIndex, subSubTopicIndex:subSubTopicIndex});
    })

    const saveContents = async(data) =>{
        handleSubmit(onSubmit)();
    }
    
    const onSubmit = data => {
        let formData = topicListData;
        if(formObject && formObject?.index){
            if(formObject.topicLevel){
                if(formObject.topicLevel === 'SubTopics' && formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex]){
                    formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].Content = data.editedContent;
                }
                if(formObject.topicLevel === 'SubSubTopics' && formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].SubSubTopics[formObject.subSubTopicIndex]){
                    formData.Topics[formObject.index].SubTopics[formObject.subTopicIndex].SubSubTopics[formObject.subSubTopicIndex].Content = data.editedContent;
                }
            }else{
                if(formData.Topics[formObject.index]){
                    formData.Topics[formObject.index].Content = data.editedContent;
                }
            }
        }
        if(formData?.image) formData.image_id= formData?.image?.id;
        if(data){
           dispatch(updateCurriculumByID(formData.id, formData)); 
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [showAddTopicModal, setShowAddTopicModal] = useState(false);
    const [showAddOptionModal, setshowAddOptionModal] = useState(false);
    const handleCloseAddTopicModal = () => setShowAddTopicModal(false);
    const handleCloseAddOptionModal = () => setshowAddOptionModal(false);
    const handleCloseModal = () => setShowModal(false);
    
    const addTopicForm =  useForm({
        resolver: yupResolver(yup.object().shape({
            Title: yup.string().required("Topic name is required"),
        }))
    });

    const [suggestionListArray, setSuggestionListArray] =useState([]);
    useEffect(()=>{
        if(suggestionList.data){
            let arr = [];
            suggestionList.data.map((response)=>{
                if (arr.filter(function(e) { return e.label === response?.Name }).length < 1) {
                    arr.push({label:response?.Name});
                }
            })
            setSuggestionListArray(arr)
        }
    },[suggestionList]);

    useEffect(()=>{
        if(searchList){
            setSearchListArray(searchList);
        }
    },[searchList]);

    useEffect(()=>{
        if(listByUser){
            setUserCurrListArray(listByUser);
        }
    },[listByUser]);

    const searchCurriculamBuilder=()=>{
        const filterData = (typeof suggestionList.data != 'undefined')?suggestionList.data.filter(res=>((res.Name).toLowerCase()) === (searchValue.toLowerCase())):[];
        if(searchValue && filterData && filterData.length > 0){
            dispatch(searchCurriculam(searchValue));
        }else{
            setIsTextLoading(true);
            dispatch(generateNewCurriculamtoc({Title:searchValue},setIsTextLoading)).then(res => {
                setShowBuilder(true);
            }).catch(err => {
                toast.error(err.response.data.message || "Something Went Wrong in generating Curriculum");
            });
        }
    }

    const renameTopicForm =  useForm({
        resolver: yupResolver(yup.object().shape({
            Title: yup.string().required("Topic is required"),
            indices:null,
            oldTitle:null
        }))
    });
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showMcqForm, setShowMcqForm] = React.useState(false);
    const [subTopicModalContent, setSubTopicModalContent] = React.useState(false);
    const [topicType, setTopicType] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget);
	});
	const handleClose = () => {
		setAnchorEl(null);
	};
    const findArrayAndObjectAction = (obj, key, objectId, action,data=null) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (Array.isArray(obj[prop]) && prop === key) {
                    if(action === 'delete'){
                        obj[prop] = obj[prop].filter((item) => (item.Title).toLowerCase() !== objectId.toLowerCase()); // Remove the object with the specified Title
                        return obj[prop];
                    }
                    if(action === 'rename'){
                        obj[prop].filter((item) => {
                            if ((item.Title).toLowerCase() === objectId.toLowerCase()) {
                                renameTopicForm.setValue('Title', item.Title);
                                renameTopicForm.setValue('key', key);
                                renameTopicForm.setValue('objectId', objectId);
                            }
                        })
                    }
                    if(action === 'changeQuestionType'){
                        obj[prop] = obj[prop].map((item) => {
                            if ((item.Title).toLowerCase() === objectId.toLowerCase()) {
                                item ={...item, multiple : data.type}
                                return item;
                            } else {
                                return item;
                            }
                        });
                    }
                    if(action === 'replace_answers'){
                        obj[prop] = obj[prop].map((item) => {
                            if ((item.Title).toLowerCase() === objectId.toLowerCase()) {
                                item['Mcq_Answers'] = data.options
                                item['Title'] = data.title
                                item.multiple = (data['options'].filter((obj)=>obj.Is_Correct).length > 1)
                                return item;
                            } else {
                                return item;
                            }
                        });
                    }
                    if(action === 'add_questions'){
                        obj[prop] = obj[prop].map((item) => {
                            if ((item.Title).toLowerCase() === objectId.toLowerCase()) {
                                item['Mcq_Questions'].push({
                                    Title : data.title,
                                    multiple : (data['options'].filter((obj)=>obj.Is_Correct).length > 1),
                                    Mcq_Answers : data.options
                                });
                                return item;
                            } else {
                                return item;
                            }
                        })
                    }

                    if (action === 'update_options') {
                        obj[prop] = obj[prop].map((item) => {
                            if ((item.Title).toLowerCase() === objectId.toLowerCase()) {
                                item['Mcq_Answers'] = item['Mcq_Answers'].map((element, index) => {
                                    if (index === data.index) {
                                      return {...element, Is_Correct : data.value}; // Change the value at the specified index
                                    } else {
                                        if(!item?.multiple){
                                            return {...element, Is_Correct : false}; // Keep the element unchanged for other indices
                                        }else{
                                            return element;
                                        }
                                    }
                                  });
                                return item;
                            }else{
                                return item;
                            }
                        })
                    }

                    if (action === 'addMcqs') {
                        obj[prop] = obj[prop].map((item) => {
                            if ((item.Title).toLowerCase() === objectId.toLowerCase()) {
                                item['Mcqs'] = [{
                                    Title : 'MCQS',
                                    Mcq_Questions : data['Mcq_Questions'].map((element)=>{
                                        return {
                                            ...element, multiple: (element['Mcq_Answers'].filter((obj) => {
                                                return obj.Is_Correct
                                            }).length > 1)
                                        }
                                    })
                                }];
                                return item;
                            } else {
                                return item;
                            }
                        });
                    }

                    if(action === 'addNewTopics'){
                        // Filter out objects from newArrayToAdd that already exist in the unicArray
                        const uniqueNewArray = data['Topics'].filter((newObj) =>
                            obj[prop].every((oldObj) => (oldObj.Title).toLowerCase() !== (newObj.Title).toLowerCase())
                        );
                        obj[prop] = obj[prop].concat(uniqueNewArray);
                        setIsTextLoading(false);
                        return obj[prop];
                    }
                    if (action === 'addNewSubTopics') {
                        obj[prop] = obj[prop].map((item) => {
                            if ((item.Title).toLowerCase() === objectId.toLowerCase()) {
                                item['SubTopics'] =  item['SubTopics'].concat(data.SubTopics);
                            }
                            return item;
                        })
                    }
                    if (action === 'addNewSubSubTopics') {
                        obj[prop] = obj[prop].map((item) => {
                            if ((item.Title).toLowerCase() === objectId.toLowerCase()) {
                                item['SubSubTopics'] = item['SubSubTopics'].concat(data.SubSubTopics);
                            }
                            return item;
                        })
                    }

                    return; // Exit the function once the object is removed
                } else if (typeof obj[prop] === 'object') {
                    findArrayAndObjectAction(obj[prop], key, objectId, action, data); // Recursively call the function for nested objects
                }
            }
        }
    }

    const handleUpdateTopic=async(formRequestData)=>{
        let requestFormData = topicListData;
        setIsTextLoading(true);
        formRequestData.Title  = `${formRequestData.Title} (${topicListData.Name})`
        dispatch(generateTopics(formRequestData)).then((resp) => {
            setShowAddTopicModal(false);
            findArrayAndObjectAction(requestFormData, 'Topics', null, 'addNewTopics',resp);
            toast.success("Topic Generated Successfully");
            dispatch({
                type: "CURRICULUM_TOPIC_LIST",
                payload: requestFormData,
            });
            setIsTextLoading(false);
        }).catch((err)=>{
            console.log('Error', err);
            toast.error(err || 'Internal Server Error');
            setIsTextLoading(false);
            return;
        });
    }

    const addMcqForm = useForm({
        resolver: yupResolver(yup.object().shape({
            Title: yup.string().required("Mcq title is required"),
            key: null,
            objectId: null
        }))
    });

    const handleBuilderInputChange = (newTitle, index, action) => {
        let payload = searchListArray;
        let indexArr = (index != '') ? index.split("_") : [];
        let oldData = {};
        let activity = '';

        if (action === 'change') {
            activity = 'change';
            if (indexArr.length === 3) {
                oldData = Object.assign({},payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics[indexArr[2]]);
                payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics[indexArr[2]].Title = newTitle;

                // For Add title blank means it is new added field and its previous value was empty and change means value is entered in new field
                if (oldData.Title === '') {
                    activity = 'add';
                    oldData = payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics[indexArr[2]]
                }

            } else if (indexArr.length === 2) {
                oldData = Object.assign({},payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]]);
                payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].Title = newTitle;

                // For Add title blank means it is new added field and its previous value was empty and change means value is entered in new field
                if (oldData.Title === '') {
                    activity = 'add';
                    oldData = payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]];
                }
            } else if (indexArr.length === 1) {
                oldData = Object.assign({},payload[0].Topics[indexArr[0]]);
                payload[0].Topics[indexArr[0]].Title = newTitle;

                // For Add title blank means it is new added field and its previous value was empty and change means value is entered in new field
                if (oldData.Title === '') {
                    activity = 'add';
                    oldData = payload[0].Topics[indexArr[0]];
                }
            }
        }

        if (action === 'delete') {
            if (indexArr.length === 3) {
                oldData = payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics[indexArr[2]];
                // delete payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics[indexArr[2]]
                payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics.splice(indexArr[2], 1);
            } else if (indexArr.length === 2) {
                oldData = payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]];
                // delete payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]];
                payload[0].Topics[indexArr[0]].SubTopics.splice(indexArr[1], 1);
            } else if (indexArr.length === 1) {
                oldData = payload[0].Topics[indexArr[0]];
                // delete payload[0].Topics[indexArr[0]];
                payload[0].Topics.splice(indexArr[0], 1);
            }
            activity = 'delete';
        }

        if (action === 'add') {
            if (indexArr.length === 2) {
                let position = payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics;
                oldData = Object.assign({}, { Title: '' });
                position.push({ Title: '' });
                setEditingItemIndex(`${indexArr[0]}_${indexArr[1]}_${position.length - 1}`);
            } else if (indexArr.length === 1) {
                let position = payload[0].Topics[indexArr[0]].SubTopics;
                oldData = Object.assign({}, { Title: '', SubSubTopics: [] });
                position.push({ Title: '', SubSubTopics: [] });
                setEditingItemIndex(`${indexArr[0]}_${position.length - 1}`);
            } else {
                let position = payload[0].Topics;
                oldData = Object.assign({}, { Title: '', SubTopics: [] });
                position.push({ Title: '', SubTopics: [] });
                setEditingItemIndex(`${position.length - 1}`);
            }
            activity = 'add';
        }

        if (Object.keys(oldData).length > 0 && activity !== '' && index != '' && oldData.Title != '') {
            setUndoEntries(oldArray => [...oldArray, {
                index,
                activity,
                oldData
            }]);           
        }

        setBuilderRender(prev => prev + 1);
        setSearchListArray(searchListArray);
    }

    const undoTheChanges = () => {
        if(undoEntries.length > 0){
            let done = false;
            const {activity, index, oldData} = undoEntries[undoEntries.length - 1];
            let payload = searchListArray;
            let indexArr = (index != '') ? index.split("_") : [];
            if(typeof activity != 'undefined' && typeof index != 'undefined' && typeof oldData != 'undefined'){
                
                if(activity === 'change' || activity === 'delete'){
                    if(oldData.Title != ''){
                        if (indexArr.length === 3) {                            
                            payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics[indexArr[2]] = oldData;
                            done = true;
                        } else if (indexArr.length === 2) {                            
                            payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]] = oldData;
                            done = true;
                        } else if (indexArr.length === 1) {
                            payload[0].Topics[indexArr[0]] = oldData;
                            done = true;
                        }
                    }
                }

                if(activity === 'add'){
                    if (indexArr.length === 3) {
                        // delete payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics[indexArr[2]]
                        payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]].SubSubTopics.splice(indexArr[2], 1);
                        done = true;
                    } else if (indexArr.length === 2) {
                        payload[0].Topics[indexArr[0]].SubTopics.splice(indexArr[1], 1);
                        // delete payload[0].Topics[indexArr[0]].SubTopics[indexArr[1]];
                        done = true;
                    } else if (indexArr.length === 1) {
                        payload[0].Topics.splice(indexArr[0], 1);
                        // delete payload[0].Topics[indexArr[0]];
                        done = true;
                    }
                }

            }

            if(done){
                setUndoEntries(undoEntries.slice(0, -1));
                setSearchListArray(searchListArray);
            }
        }
    }
    
    useEffect(() => {
        if (!showBuilder) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [showBuilder])

    const goback = () => {
        const result = window.confirm('Are you sure you want to go back? The generated outline will be erased');
        if (result) {
            setShowBuilder(false);
        }
    }

    const addOrCorrectSrNoToPayload = (array, parentSrNo = 1) => {
        let srNo = parentSrNo;

        array.forEach((obj) => {
            if (obj.Sr_No === undefined || obj.Sr_No !== srNo) {
                obj.Sr_No = srNo; // Correct or add Sr_No if it's missing or wrong
            }

            srNo++;

            if (obj.SubTopics) {
                addOrCorrectSrNoToPayload(obj.SubTopics, 1); // Reset srNo for SubTopics
            }
            if (obj.SubSubTopics) {
                addOrCorrectSrNoToPayload(obj.SubSubTopics, 1); // Reset srNo for SubSubTopics
            }
        });
    }; 

    const createCourseFunction = () => {
        if (selectedGPTValue) {
            const [Topics] = searchListArray;
            addOrCorrectSrNoToPayload(Topics.Topics);
            if (typeof Topics !== 'undefined') {
                dispatch(createCourse({
                    gpt_model: selectedGPTValue,
                    toc: {
                        ...Topics
                    },
                    content: searchValue
                })).then((resp) => {
                    toast.success("Create Created Successfully");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }).catch(err => {
                    console.log("Error", err);
                    toast.error(err?.response?.data?.message || 'Something Went Wrong');
                });
            }
        } else {
            toast.error("Please select from GPT dropdown");
        }
    }

    const [showMcqResults, setShowMcqResults] = useState(false);
    const handleCloseResult = () => setShowMcqResults(false);
    const [selectedGPTValue, setSelectedGPTValue] = useState('GPT-3.5-turbo-16K');

    // const findArrayAndObjectActions = (dataArray, indices, action, data) => {
    //     let updatedArray = {...dataArray};
    //     let currentLevel = updatedArray;
    //     if (action === 'delete') {
    //         if (indices.length === 1) {
    //             updatedArray['Topics'].splice(indices[0], 1);
    //         } else if (indices.length === 2) {
    //             updatedArray['Topics'][indices[0]]['SubTopics'].splice(indices[1], 1);
    //         } else if (indices.length === 3) {
    //             updatedArray['Topics'][indices[0]]['SubTopics'][indices[1]]['SubSubTopics'].splice(indices[2], 1);
    //         } else if (indices.length === 4) {
    //             updatedArray['Topics'][indices[0]]['SubTopics'][indices[1]]['SubSubTopics'][indices[2]]['Mcqs'] = [];
    //         } else if (indices.length === 5) {
    //             updatedArray['Topics'][indices[0]]['SubTopics'][indices[1]]['SubSubTopics'][indices[2]]['Mcqs'][indices[3]]['Mcq_Questions'].splice(indices[4], 1);
    //         } else if (indices.length === 6) {
    //             updatedArray['Topics'][indices[0]]['SubTopics'][indices[1]]['SubSubTopics'][indices[2]]['Mcqs'][indices[3]]['Mcq_Questions'][indices[4]]['Mcq_Answers'].splice(indices[5], 1);
    //         }
    //     } else {
    //         if (indices.length === 1) {
    //             currentLevel = currentLevel['Topics'][indices[0]];
    //         }
    //         if (indices.length === 2) {
    //             currentLevel = currentLevel['Topics'][indices[0]]['SubTopics'][indices[1]];
    //         }
    //         if (indices.length === 3) {
    //             currentLevel = currentLevel['Topics'][indices[0]]['SubTopics'][indices[1]]['SubSubTopics'][indices[2]];
    //         }
    //         if (indices.length === 4) {
    //             currentLevel = currentLevel['Topics'][indices[0]]['SubTopics'][indices[1]]['SubSubTopics'][indices[2]]['Mcqs'][indices[3]];
    //         }
    //         if (indices.length === 5) {
    //             currentLevel = currentLevel['Topics'][indices[0]]['SubTopics'][indices[1]]['SubSubTopics'][indices[2]]['Mcqs'][indices[3]]['Mcq_Questions'][indices[4]];
    //         }
    //         if (indices.length === 6) {
    //             currentLevel = currentLevel['Topics'][indices[0]]['SubTopics'][indices[1]]['SubSubTopics'][indices[2]]['Mcqs'][indices[3]]['Mcq_Questions'][indices[4]]['Mcq_Answers'][indices[5]];
    //         }
    //         if (action === 'rename') {
    //             renameTopicForm.setValue('Title', currentLevel.Title);
    //             renameTopicForm.setValue('indices', indices);
    //         }
    //         if (action === 'submitRename') {
    //             currentLevel.Title = data.Title
    //         }
    //         if(action === 'addNewSubTopics'){
    //             currentLevel['SubTopics'] = currentLevel['SubTopics'].concat(data.SubTopics);
    //         }
    //         if(action === 'addNewSubSubTopics'){
    //             currentLevel['SubSubTopics'] = currentLevel['SubSubTopics'].concat(data.SubSubTopics);
    //         }
    //         if (action === 'addMcqs') {
    //             currentLevel['Mcqs'] = [{
    //                 Title: 'MCQS',
    //                 Mcq_Questions: data['Mcq_Questions'].map((element) => {
    //                     return {
    //                         ...element, multiple: (element['Mcq_Answers'].filter((obj) => {
    //                             return obj.Is_Correct
    //                         }).length > 1)
    //                     }
    //                 })
    //             }];
    //         }
    //     }

    //     return(updatedArray);
    // };

    const handleActions = useCallback(async (level, title, action, data = null)=>{
        removeParamFromUrl(deleteUrlParams);
        let formData = {...topicListData};
        if (action === 'rename') {
            handleClose();
            setSubTopicModalContent(false);
            setShowModal(true);
            setTopicType(null);
            renameTopicForm.setValue('oldTitle', title);
            findArrayAndObjectAction(formData, level, title,'rename');
            setLevel(level)
        }
        if (action === 'addNewSubTopic') {
            handleClose();
            setShowModal(true);
            setSubTopicModalContent(true);
            setTopicType('l2');
            renameTopicForm.setValue('Title', '');
            renameTopicForm.setValue('id', title);
            renameTopicForm.setValue('oldTitle', title);
            renameTopicForm.setValue('action', 'addNewSubTopic');
            renameTopicForm.setValue('key', level);
        }
        if (action === 'addNewSubSubTopic') {
            handleClose();
            setShowModal(true);
            setSubTopicModalContent(true);
            setTopicType('l3');
            renameTopicForm.setValue('Title', '');
            renameTopicForm.setValue('id', title);
            renameTopicForm.setValue('oldTitle', title);
            renameTopicForm.setValue('action', 'addNewSubSubTopic');
            renameTopicForm.setValue('key', level);
        }
        if(action === 'delete'){ 
            handleClose();
            const result = window.confirm('Are you sure you want to proceed?');
            if(result){    
                if(formData?.image) formData.image_id = formData?.image.id;
                await findArrayAndObjectAction(formData, level, title, action);
                // await dispatch(updateCurriculumByID(formData.id, formData));
                // dispatch(getCurriculumTopicByID(courseId));
            }
        }
        if(action === 'addMcqs'){
            addMcqForm.setValue('key', level);
            addMcqForm.setValue('objectId', title);
            if(data){
                dispatch(generateMcQs({paragraph : data.Content})).then((resp) => {
                    if(formData?.image) if(formData?.image) formData.image_id = formData?.image.id;
                    findArrayAndObjectAction(formData, level, title, action, resp);
                    toast.success("Mcq Generated Successfully");
                    dispatch({
                        type: "CURRICULUM_TOPIC_LIST",
                        payload: formData,
                    });
                }).catch((err)=>{
                    console.log('Error', err);
                    toast.error(err || 'Internal Server Error')
                    return;
                });

            }else{  
                toast.error("No Data found to generate Mcqs");
            }
            // setShowMcqForm(true);
            // setShowModal(true);
        }
        if(action === 'mcqresults'){
            handleClose();
            setShowMcqResults(true);
            setmcqQA(title)
            // setIsMcqReviewScreen(true);  
        }
        dispatch({
            type: "CURRICULUM_TOPIC_LIST",
            payload: formData,
        });
    }, [topicListData])

    const removeParamFromUrl = (params)=>{
        if (params) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            // remove a parameter named "exampleParam"
            params.filter((item)=>urlSearchParams.delete(item.paramName));
            // Get the updated query string after removing the parameter
            const updatedQueryString = urlSearchParams.toString();
            // Update the browser's address bar without a page refresh
            const newUrl = window.location.pathname + '?' + updatedQueryString;
            window.history.replaceState(null, '', newUrl);
            setIsMcqReviewScreen(false);
        } 
    }

    const findDuplicateTitle = (obj, key, oldTitle, newValue) => {

        const recursiveChangeTitle = (obj) => {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if (Array.isArray(obj[prop]) && prop === key) {
                        obj[prop].forEach((item) => {
                            if ((item.Title).trim() === newValue.trim()) {                               
                                // If there are multiple occurrences of the title, raise an error
                                throw('Already a title exist with same name.');                                
                            }
                        });
                    } else if (typeof obj[prop] === 'object') {
                        recursiveChangeTitle(obj[prop]); // Recursively call the function for nested objects
                    }
                }
            }
        };

        recursiveChangeTitle(obj); 
    };

    const findArrayAndChangeTitle = (obj, key, oldTitle, newValue) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (Array.isArray(obj[prop]) && prop === key) {
                    obj[prop].filter((item) => {
                        if (item.Title === oldTitle) {
                            item.Title = newValue;
                            return;
                        }
                    });// Exit the function once the title set
                } else if (typeof obj[prop] === 'object') {
                    findArrayAndChangeTitle(obj[prop], key, oldTitle, newValue); // Recursively call the function for nested objects
                }
            }
        }
    }

    const handleOnUpdateRenameTopic = async(data) =>{
        try{
            let formData = topicListData;
            findDuplicateTitle(formData, data.key, data.oldTitle, data.Title);
            findArrayAndChangeTitle(formData, data.key, data.oldTitle, data.Title);
            if (formData?.image) formData.image_id = formData?.image.id;
            if (data.Title) {
                setShowModal(false);
            }
        }catch(err){
            toast.error(err || 'Something went wrong')
        }        
    }
    
    const handleOnGenerateSubTopic = async(data) =>{
        if(topicType === 'l2'){
            const respData = await dispatch(generateSubTopics({Title:`${data.Title} (${data.oldTitle})`}, null ,setShowModal));
             if(respData){
                let requestFormData = topicListData;
                findArrayAndObjectAction(requestFormData, data.key, data.oldTitle ,'addNewSubTopics',respData);
                dispatch({
                    type: "CURRICULUM_TOPIC_LIST",
                    payload: requestFormData,
                });
             }
        }
        if(topicType === 'l3'){
            const respData= await dispatch(generateSubSubTopics({Title:`${data.Title} (${data.oldTitle})`},data.id ,setShowModal)); 
            if(respData){
                let requestFormData = topicListData;
                findArrayAndObjectAction(requestFormData, data.key, data.id ,'addNewSubSubTopics',respData);
                dispatch({
                    type: "CURRICULUM_TOPIC_LIST",
                    payload: requestFormData,
                });
             }
        }
    }

    const handleOnAddMcq=async(requestObject)=>{
        let requestFormData = topicListData;
        findArrayAndObjectAction(requestFormData, requestObject.key, requestObject.objectId, 'addMcqs');
        requestFormData.image_id = requestFormData.image.id;
        await dispatch(updateCurriculumByID(requestFormData.id, requestFormData));
    }

    const handleOnDeleteMcqQuestion = async (key, objectId, action) => {
        try {
            let formData =  {...topicListData}
            const result = window.confirm('Are you sure you want to proceed?');
            if(result){
                if(formData?.image) formData.image_id = formData?.image.id;
                findArrayAndObjectAction(formData, key, objectId, action);
                setTopicListTempData(formData);
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    const handleOnMcqAddChanges =  useCallback((key, title, action, value) =>{
        const newTopicListData = {...topicListData}
        findArrayAndObjectAction(newTopicListData, key, title, action,value);
        if(newTopicListData?.image) newTopicListData.image_id = newTopicListData.image.id;
        setTopicListTempData(newTopicListData);
    })

    const handleOnSubmitMcq = useCallback((payload)=>{
        dispatch(submitMcqTest(payload)).then((res)=>{
            toast.success("Submitted Successfully");
            getCheckMcqFunction(payload['mcq_id']);
        }).catch(err => {
            console.log("error", err);
            toast.error(err.message || 'Unable to submit answers')
        });
    })

    const addAnswerOption = useCallback((key, objectId, question, multipleChoice)=>{
        setshowAddOptionModal(true);
        setEditQuestion({
            questionId : objectId,
            questionTitle : question.Title,
            key,
            multipleChoice
        });
        if(question?.Mcq_Answers.length > 0){
            setOptions(
                question?.Mcq_Answers.map((elem)=> {
                return {
                    Title : elem.Title,
                    Is_Correct : elem.Is_Correct,
                    id: elem.id,
                    Created_Date: elem.Created_Date,
                    Updated_Date: elem.Updated_Date,
                }
            }));
        }

        setInputValues(prevState => ({
            ...prevState,
            questionTitle : question.Title,
          }));
    });

    const handleSelectionInput = (questionTitle, optionIndex, value) => {
        let formData = { ...topicListData };
        findArrayAndObjectAction(formData, 'Mcq_Questions', questionTitle, 'update_options', { index: optionIndex, value });
        setTopicListTempData(formData);
    }

    const saveOptionData = async()=>{
        if(options.length > 0){
            let formData = topicListData;
            if(formData?.image) formData.image_id = formData.image.id;
            if(editQuestion.questionId != ''){
                findArrayAndObjectAction(formData, editQuestion.key, editQuestion.questionId,'replace_answers',{title : inputValues.questionTitle, options})
                dispatch({
                    type: "CURRICULUM_TOPIC_LIST",
                    payload: formData,
                });
            }else{
                findArrayAndObjectAction(formData, addQuestion.key, addQuestion.mcqTitle,'add_questions',{title : inputValues.questionTitle, options})
                dispatch({
                    type: "CURRICULUM_TOPIC_LIST",
                    payload: formData,
                });
            }
            setEditQuestion({
                questionId : '',
                key : '',
                multipleChoice : null
            })
            setOptions([])
            // dispatch(getCurriculumTopicByID(courseId));
            setshowAddOptionModal(false);
        }else{
            toast.error("Please mention at least one option");
        }
    }

    const addQuestionFunc = async(key, title)=>{
        setshowAddOptionModal(true);
        setAddQuestion({
            mcqTitle : title,
            key
        });
        setOptions([]);
        setInputValues({
            Is_Correct : false,
            questionTitle : '',
            Title : ''
        })
    }

    const handleOnMCQDetailClick = useCallback((mcqId) =>{
        handleCloseResult();
        dispatch(getViewResult(mcqId)).then((obj)=>{
            setContents(null);
            setIsMcqReviewScreen(true);
        }).catch(err=>{
            toast.error(err.message || "Something Went Wrong in View Details");
        });
    })

    const getCheckMcqFunction = useCallback((mcqId) =>{
        handleCloseResult();
        dispatch(getCheckMcq(mcqId)).catch(err=>{
            toast.error(err.message || "Something Went Wrong");
        });
    })

    const handleOptionChange = (event, newValue) => {
        if (newValue) {
            dispatch(searchCurriculam(newValue.label));
        }
    };

    const clickShare = () => {
        if(!topicListData?.isPublic && !topicListData.isShare){
            // Call the update Curriculum api to set the isShare key true
            let formData = topicListData;
            formData.isShare = true;
            if (formData?.image) formData.image_id = formData?.image?.id;
            if (formData) {
                dispatch(updateCurriculumByID(formData.id, formData));
            }
        }
        handleClose();
        setOpenShare(!openShare)
    }
    if(courseId){
        return(
            <div className='curriculumListView'>
                {(pageLoading || generateMcqsLoading || loading || viewDetailLoading || submitMcqLoading) && <Loader textLoading={isTextLoading} /> }
                <div className='container-fluid' style={{ backgroundColor: '#CCE2FF', padding: 24 }}>
                    <div className='row'>
                        <h3 className='col-md-6 viewHeadingText'>{topicListData?.Name}</h3>
                        <div className='col-md-6 rightSection text-right'>
                            {openShare && renderShare()}
                            <Button className='btnStyle2' onClick={clickShare}>Share</Button>
                            {!(topicListData?.isPublic) && (topicListData?.Created_By !==null && user_id == topicListData?.Created_By.id) && <Button className='btnStyle' onClick={saveContents}>Save Changes</Button> }
                            {topicListData?.isPublic && <Button className='btnStyle' onClick={()=>cloneCurriculum(topicListData)}>Edit</Button> }
                        </div>
                    </div>
                </div>

            <div className='container-fluid leftViewMcq'>
                <div className='row '>
                    <div className='col-md-3 leftViewMcq'>
                        <div className='leftSideCollapseView'>
                        {topicListData?.Topics?.map((detail, index) => (
                            <CurriculumCollapsableView
                                collapseLevel={1}
                                key={"topics"+index}
                                title={detail.Title}
                                showDots={!(topicListData?.isPublic) && (topicListData?.Created_By !==null && user_id == topicListData?.Created_By.id)}
                                titleStyles={styles.topicTitleStyle}
                                data={{level:`Topics`,id:detail.id, indices:[index], title:detail.Title}}
                                onClick={()=>handleOnAccordionClick(detail, index)}
                                handleAction={handleActions}
                                children={
                                    detail?.SubTopics?.map((subTopics, subIndex) => (
                                        <CurriculumCollapsableView
                                            collapseLevel={2}
                                            key={"subtopic"+subIndex}
                                            showDots={!(topicListData?.isPublic) && (topicListData?.Created_By !==null && user_id == topicListData?.Created_By.id)}
                                            title={subTopics.Title}
                                            containerStyles={styles.subTopicContainerStyle}
                                            data={{level:`SubTopics`,id:subTopics.id, indices:[index,subIndex],title:subTopics.Title}}
                                            titleStyles={styles.subTopicTitleStyle}
                                            onClick={()=>handleOnAccordionClick(subTopics, index, 'SubTopics', subIndex) }
                                            handleAction={handleActions}
                                            children={
                                                subTopics?.SubSubTopics?.map((item,subSubIndex) => (
                                                    <div style={{ backgroundColor: '#E9F3FF' }} key={"SubSubTopics"+subSubIndex}>
                                                        <CurriculumCollapsableView
                                                            collapseLevel={3}
                                                            showDots={!(topicListData?.isPublic) && (topicListData?.Created_By !==null && user_id == topicListData?.Created_By.id)}
                                                            data={{level:`SubSubTopics`,id:item.id,mcqs:item?.Mcqs?.length, indices:[index,subIndex,subSubIndex],title:item.Title}}
                                                            title={item.Title}
                                                            titleStyles={styles.subSubTopicTitleStyle}
                                                            onClick={() => handleOnAccordionClick(item, index, 'SubSubTopics', subIndex, subSubIndex)}
                                                            handleAction={handleActions}
                                                            contents={item}
                                                            showIcon={(typeof (item?.Mcqs) != 'undefined' && item?.Mcqs.length > 0)? true : false}
                                                            children={item.Mcqs?.map((mcqs, i) => (
                                                                <div className='d-flex justify-content-between align-items-center' key={"mcq"+i}>
                                                                    <a onClick={() => handleOnClick(mcqs)}  style={{width:'100%',...styles.subSubTopicTitleStyle}}>{mcqs.Title}</a>
                                                                    
                                                                    {
                                                                        !(topicListData?.isPublic) && (topicListData?.Created_By !==null && user_id == topicListData?.Created_By.id) &&
                                                                        <Dropdown className="custom-dropdown-toggle">
                                                                            <Dropdown.Toggle variant="light" id="custom-dropdown-toggle" size="small"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => handleActions('Mcqs', mcqs.Title, "rename")}>
                                                                                    <img src={Images.Rename} style={{ width: 16, height: 16, marginRight: 8 }} />Rename
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => handleActions('Mcqs', mcqs, "mcqresults")}><img src={Images.CircleCheck} style={{ width: 16, height: 16, marginRight: 8 }} /> See Results</Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => handleActions('Mcqs', mcqs.Title, 'delete')}><img src={Images.Trash} style={{ width: 16, height: 16, marginRight: 8 }} /> Delete</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    }
                                                                </div>
                                                            ))}
                                                            containerStyles={styles.subSubTopicContainerStyle}
                                                            childStyles={styles.subSubTopicChildContainerStyle}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        />
                                    ))
                                }
                                containerStyles={{borderBottom:'1px solid #99BCEA'}}
                            />
                        ))}
                        </div>                        
                            {!(topicListData?.isPublic) && (topicListData?.Created_By !==null && user_id == topicListData?.Created_By.id) &&  <div className='newButton'><Button className='AddnewButton'  onClick={() => setShowAddTopicModal(true)}>{`+ Add New`}</Button> </div>}                       
                    </div>

                    <div className='col-md-8 mcqRightView'>
                        {contents &&
                            <Information contents={contents} control={control} setValue={setValue} dispatch={dispatch} width={props.width} topicListTitle={topicListData?.Name} thingsToWatch={thingsToWatch} thingsToRead={thingsToRead} thingsToDo={thingsToDo} activitymatrix={activitymatrix} fetchActivityMatrix={fetchActivityMatrix} activeKeys={activeKeys} handleAccordionSelect={handleAccordionSelect} disableEditor={!(!(topicListData?.isPublic) && (topicListData?.Created_By !==null && user_id == topicListData?.Created_By.id))}/>
                        }
                        {!contents && !isMcqReviewScreen && !(topicListData?.isPublic) && topicListData?.Created_By !==null && user_id == topicListData?.Created_By.id &&
                            
                            <McqsEdit 
                                originalData={topicListTempData}
                                key={'Mcqs'}
                                mcqId={mcqId}
                                handleOnDeleteMcqQuestion={handleOnDeleteMcqQuestion}
                                handleOnMcqAddChanges={handleOnMcqAddChanges}
                                addAnswerOption={addAnswerOption}
                                addQuestion={addQuestionFunc}
                                handleSelectionInput={handleSelectionInput}
                            />
                         }

                         {
                            !contents && !isMcqReviewScreen && !(topicListData?.isPublic) && topicListData?.Created_By !==null && user_id != topicListData?.Created_By.id && checkMcqUserSubmissionSuccess && !checkMcqUserSubmissionSuccess.attempted &&
                            <McqsUserQA 
                            originalData={topicListTempData}
                            handleOnSubmitMcq={handleOnSubmitMcq}
                            key={'Mcqs'} 
                            mcqId={mcqId}/>
                         }

                         {
                            !contents && !isMcqReviewScreen && !(topicListData?.isPublic) && topicListData?.Created_By !==null && user_id != topicListData?.Created_By.id && checkMcqUserSubmissionSuccess && checkMcqUserSubmissionSuccess.attempted &&
                            <p>Your Answers has been submitted</p>
                         }

                        {!contents && isMcqReviewScreen && <McqReviewScreen mcqQA={viewDetailSuccess}/> }
                    </div>
                </div>
            </div>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={handleCloseModal} className='addTopicCardModal'>
                <Button onClick={handleCloseModal} style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end", padding:0 }}>
                    <img src={require('../../assets/table_close_icon.png')} style={{ width: 32, height: 32, marginTop: -50 }} />
                </Button>
                <Modal.Header closeButton={false} style={{ alignItems: 'center' }}>
                    <Modal.Title className='mdlTitle'>
                        {showMcqForm && 'MCQ Title'}
                        {(topicType && topicType === 'l2') && 'Sub Topic Name'}
                        {(topicType && topicType === 'l3') && 'Sub Sub Topic Name'}
                        {!showMcqForm && !topicType && 'Topic Name'}</Modal.Title>
                </Modal.Header>
                {!showMcqForm && !subTopicModalContent &&
                    <form onSubmit={renameTopicForm.handleSubmit(handleOnUpdateRenameTopic)} className='renameForm'>
                    <Modal.Body>
                            <div className='mb-2'>
                                <label>
                                    {level === 'Topics'
                                    ? 'Enter your desire Topic Name'
                                    : level === 'SubTopics'
                                    ? 'Enter your desire Sub Topic Name'
                                    : level === 'SubSubTopics'
                                    ? 'Enter your desire Sub-Sub-Topic Name'
                                    : level === 'Mcqs'
                                    ? 'Enter your desire Mcqs Name'
                                    : ''}
                                </label>
                                
                                <input type="text" placeholder='Title' className="form-control" {...renameTopicForm.register("Title")} />
                                <p className='validationError'>{renameTopicForm.formState.errors.Title?.message}</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-primary' type="submit" style={{ paddingLeft: 30, paddingRight: 30 }}>{`Save`}</button>
                        </Modal.Footer>
                    </form>
                }
                {showMcqForm && !subTopicModalContent &&
                    <form onSubmit={addMcqForm.handleSubmit(handleOnAddMcq)} className='renameForm'>
                        <Modal.Body>
                            <div className='mb-2'>
                                <label>Mcq Name</label>
                                <input type="text" placeholder="MCQ's" className="form-control" {...addMcqForm.register("Title")} />
                                <p className='validationError'>{addMcqForm.formState.errors.Title?.message}</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-primary' type="submit" style={{ paddingLeft: 30, paddingRight: 30 }}>{`Save`}</button>
                        </Modal.Footer>
                    </form>
                }
                {subTopicModalContent &&
                    <form onSubmit={renameTopicForm.handleSubmit(handleOnGenerateSubTopic)} className='renameForm'>
                    <Modal.Body>
                            <div className='mb-2'>
                                <label>
                                    {(topicType && topicType === 'l2') && 'Enter your desire Sub Topic Name'}
                                    {(topicType && topicType === 'l3') && 'Enter your desire Sub-Sub-Topic Name'}
                                    {!showMcqForm && !topicType && 'Topic Name'}
                                </label>
                                <input type="text" placeholder='Title' className="form-control" {...renameTopicForm.register("Title")} />
                                <p className='validationError'>{renameTopicForm.formState.errors.Title?.message}</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-primary' type="submit" style={{ paddingLeft: 30, paddingRight: 30 }}>
                                {`Generate`} {(topicType && topicType === 'l2') && 'Sub Topic'}
                                {(topicType && topicType === 'l3') && 'Sub Sub Topic'}
                            </button>
                        </Modal.Footer>
                    </form>
                }
			</Modal>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showAddTopicModal} onHide={handleCloseAddTopicModal} className='addTopicCardModal'>
                <Button onClick={handleCloseAddTopicModal} style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end", padding:0 }}>
                    <img src={require('../../assets/table_close_icon.png')} style={{ width: 32, height: 32, marginTop: -50 }} />
                </Button>
                <Modal.Header closeButton={false} style={{alignItems:'center'}}>
                    <Modal.Title className='mdlTitle'>Update title</Modal.Title>
                </Modal.Header>
                    <form onSubmit={addTopicForm.handleSubmit(handleUpdateTopic)} className='addTopicForm'>
                        <Modal.Body>
                            <div className='mb-2'>
                                <label>Topic Name</label>
                                <input type="text" placeholder='Title' className="form-control" {...addTopicForm.register("Title")} />
                                <p className='validationError'>{addTopicForm.formState.errors.Title?.message}</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-primary' type="submit" style={{ paddingLeft: 30, paddingRight: 30 }}>{`Generate Topic and Save`}</button>
                        </Modal.Footer>
                    </form>
                
			</Modal>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showAddOptionModal} onHide={handleCloseAddOptionModal} className='addTopicCardModal'>
				
                <Modal.Header closeButton={false} style={{alignItems:'center'}}>
                    <Modal.Title className='mdlTitle'>Manage Question</Modal.Title>
                    <Button onClick={handleCloseAddOptionModal} style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                        <img src={require('../../assets/table_close_icon.png')} style={{width:32,height:32}}/>
                    </Button>
                </Modal.Header>
                   
                        <Modal.Body>
                        <label>Question</label>
                            <div className="form-group" style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
                                <input type="text" name="questionTitle" placeholder='Title' value={inputValues.questionTitle} className="form-control" onChange={handleChange} />
                                {/* <div style={{width : 70, marginLeft: 10}}>
                                    <a className="p-2" ><i className="fa fa-edit"></i></a>
                                    <a className="p-2" ><i className="fa fa-trash"></i></a>
                                </div>                                 */}
                            </div>
                            <div className="form-group">
                                <label>Options</label>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Is Correct</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        options.map((item, index) => (
                                            <tr key={'option'+index}>
                                                <td>{item.Title}</td>
                                                <td style={{ width: 50 }}>{((item.Is_Correct)?'true':'false')}</td>
                                                <td style={{ width: 100 }}>
                                                    <a  className="p-2" onClick={() => editItem(index)}><i className="fa fa-edit"></i></a>
                                                    <a className="p-2" onClick={() => removeItem(index)}><i className="fa fa-trash"></i></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <div className=''>
                                    <div className='row'>
                                        <div className='col-md-8 mb-2'>
                                            <input type="text" name="Title" placeholder='Title' value={inputValues.Title} className="form-control"  onChange={handleChange}/>
                                        </div>
                                        <div className='col-md-4 mb-2'>
                                            <Form.Check
                                                label={'Is Correct'}
                                                name='Is_Correct'
                                                type='checkbox'
                                                // id={`${multipleChoice}-${i}`}
                                                checked={inputValues.Is_Correct}
                                                onChange={handleChange}
                                                className="custom-checkbox"
                                            />
                                        </div>
                                        <div className='col-md-12 mb-2'>
                                            
                                        </div>

                                    </div>

                                    <button type="button" onClick={addItemToArray} className='btn btn-primary'><i className="fa fa-plus"></i></button>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-primary' onClick={saveOptionData} type="submit" style={{ paddingLeft: 30, paddingRight: 30 }}>{`Save`}</button>
                        </Modal.Footer>
                
			</Modal>
            
            <SeeResults dispatch={dispatch} show={showMcqResults} closeModal={handleCloseResult} seeResultSuccess={seeResultSuccess} mcqQA={mcqQA} handleOnDetailClick={handleOnMCQDetailClick}/>
        </div>
        )
    } else if (showBuilder) {
        return (
            <>
                {
                    createCourseLoading && <Loader textLoading={isTextLoading} loaderText={"Crafting your course with AI. ⏳ Please wait up to 10 minutes. Do not close the tab or log out."}/>
                }
            <BuilderLayers builderRender={builderRender} searchListArray={searchListArray} editingItemIndex={editingItemIndex} setEditingItemIndex={setEditingItemIndex} handleBuilderInputChange={handleBuilderInputChange} goback={goback} createCourse={createCourseFunction} setSelectedGPTValue={setSelectedGPTValue} selectedGPTValue={selectedGPTValue} undoEntries={undoEntries} undoTheChanges={undoTheChanges} searchValue={searchValue}/>
            </>
        )
    } else {
        return (
            <>
            {(loading || generateCurriculumtocLoading)&& <Loader textLoading={isTextLoading} loaderText={(generateCurriculumtocLoading)?'Crafting your course with AI. ⏳ Please wait up to 5 minutes. Do not close the tab or log out.':'Please wait, it will take some time'}/> }

                <div className='tabContentHeader'>
                    <div className='tabContentHeaderBox'>
                        <h2 className='pageHeading'>Your AGI-accelerated LMS</h2>
                        <p className='tabContentSubHeading'>Design, customize, and share your learning curriculum with passionate learners worldwide.</p>
                        <div className='searchBoxArea'>
                            <Autocomplete
                                freeSolo
                                disableClearable
                                id="seachBox"
                                options={suggestionListArray}
                                renderInput={(params) => (
                                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', borderRadius: 16 }}>
                                        <img src={Images.SearchIcon} className='searchImg' onClick={searchCurriculamBuilder} />
                                        <TextField {...params} label="" placeholder='Input your course name here. Eg: Quantum computing' classes={{ root: 'placeholderSTyle', notchedOutline: 'oulineNone' }} InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }} />
                                    </div>
                                )}
                                value={searchValue}
                                onChange={handleOptionChange}
                                onInputChange={(event, newValue) => {
                                   setSearchValue(newValue);
                                   if(newValue === '')  setSearchListArray([]);
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                      event.defaultMuiPrevented = true;
                                      searchCurriculamBuilder()
                                    }
                                  }}
                            />
                        </div>
                    </div>
                </div>
                <div className='container courseListViewSection' id="user-list-container" ref={ref}>
                    <div className='row filter-area'>
                        <h2 className='col-8 content-heading'>Your Curriculum Library</h2>
                    </div>
                    {(userCurrListArray && userCurrListArray.length > 0) ?
                        <div className='row searchi-list-section' id="user-search-list-section">
                            {userCurrListArray.map((item, i) => (
                                <div className='col-md-4' key={"userlistView" + i}>
                                    <CurriculamListCard
                                        dispatch={dispatch}
                                        by={item?.Created_By?.fullname}
                                        title={item?.Name}
                                        date={moment(item?.Created_Date).format('MMM DD')}
                                        onCardClick={() => onCurriculamListCardCliked(item)}
                                        imageUrl={item?.image}
                                        cardData={item}
                                        limit={limit}
                                        offset={offset}
                                        handleOnDeleteClick={() => handleOnDeleteCourse(item?.id,)}
                                        setListArray={setListArray}
                                    />
                                </div>
                            ))
                            }
                        </div>
                    :
                        <div className='nodata'>
                            Curriculum not found
                        </div>
                    }
                </div>
                {/* <div className='container courseListViewSection' id="list-container">
                    <div className='row filter-area'>
                        <h2 className='col-8 content-heading'>All {searchList && searchListArray.length > 0 && ('Search')} Curriculum Library</h2>
                    </div>
                    {searchListArray &&
                        <div className='row searchi-list-section' id="searchi-list-section">
                            {searchListArray.map((item, i) => (
                                <div className='col-md-4' key={"listView" + i}>
                                    <CurriculamListCard
                                        dispatch={dispatch}
                                        by={item?.Created_By?.fullname}
                                        title={item?.Name}
                                        date={moment(item?.Created_Date).format('MMM DD')}
                                        onCardClick={() => onCurriculamListCardCliked(item)}
                                        imageUrl={item?.image}
                                        cardData={item}
                                        limit={limit}
                                        offset={offset}
                                        handleOnDeleteClick={() => handleOnDeleteCourse(item?.id,)}
                                        setListArray={setListArray}
                                    />
                                </div>
                            ))
                            }
                        </div>
                    }
                    {searchListArray && searchListArray.length < 1 &&
                    <div className='row list-section' id="list-section">
                        {listArray && listArray.length > 0 &&
                        //    listArray.sort((a, b) => a.id > b.id ? 1 : -1).map((item,i)=>(
                           listArray.map((item,i)=>(
                                <div className='col-md-4' key={"listView"+i}>
                                <CurriculamListCard
                                    dispatch={dispatch}
                                    by={item?.Created_By?.fullname}
                                    title={item?.Name}
                                    date={moment(item?.Created_Date).format('MMM DD')}
                                    onCardClick={()=>onCurriculamListCardCliked(item)}
                                    imageUrl={item?.image}
                                    cardData={item}
                                    limit={limit}
                                    offset={offset}
                                    handleOnDeleteClick = {()=>handleOnDeleteCourse(item?.id,)}
                                    setListArray={setListArray}                                    
                                    isPublic={(item?.isPublic)}                                    
                                    cloneCurriculum={()=>cloneCurriculum(item)}
                                    />
                            </div>
                            ))
                        }
                        {listArray.length < 1 && !loading &&
                            <div className='nodata'>
                                Curriculum not found
                            </div>
                        }
                    </div>
                    }
                </div> */}
                {/* {recommendedList && recommendedList.length > 0 &&
                <div className='container courseListViewSection'>
                    <div className='row filter-area'>
                        <h2 className='col-8 content-heading'>Recommended for you</h2>
                    </div>
                    <div className='row recommended-section' id="recommended-list-section">
                        {
                            <Slider
                                prevArrow={recommendedList.length > 3 && <PreviousBtn />}
                                nextArrow={recommendedList.length > 3 && <NextBtn />}
                                {...settings}>
                                {recommendedList.map((item, i) => {
                                    const even = i % 2 === 0 ? true : false;
                                    return (
                                        <CurriculamListCard
                                            cardStyle={{marginRight:10, marginLeft:10}}
                                            dispatch={dispatch}
                                            key={'recom'+i}
                                            by={item?.Created_By?.fullname}
                                            title={item?.Name}
                                            date={moment(item?.Created_Date).format('MMM DD')}
                                            onCardClick={()=>onCurriculamListCardCliked(item?.id)}
                                            imageUrl={item?.image}
                                            cardData={item}
                                            cardType={'recommend'}
                                            handleOnDeleteClick={()=>handleOnDeleteCourse(item?.id,'recommend')}
                                        />
                                    )
                                })}

                            </Slider>
                        }
                    </div>
                </div>
                } */}
            </>
        )
    }
}

export default CurriculumBuilder;

const PreviousBtn = (props) => {
    const { className, onClick } = props
    return (
        <div className={className} onClick={onClick}>
            <img src={Images.DropdownArrow} className="prevArrowImg" />
        </div>
    )
}
const NextBtn = (props) => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick}>
            <img src={Images.DropdownArrow} className="nexArrowImg" />
        </div>
    )
}


// const mapStateToProps = (state) => {
//     const { curriculumDetails, topicListData} = state.CurriculumReducer;
//     return { curriculumDetails, topicListData };
// };

// export default connect(mapStateToProps, {
//     getCurriculumBuilder,
//     getCurriculumTopicByID
// })(CurriculumBuilder);

const styles ={
    topicTitleStyle:{ fontSize: 16, fontWeight: '500', fontFamily: 'Poppins', color: '#001F49' },
    subTopicTitleStyle:{ fontSize: 14, fontWeight: '500', fontFamily: 'Poppins', color: '#001F49', width: '80%', marginLeft: 10 },
    subTopicContainerStyle:{ backgroundColor: '#D5E7FF', border: 0, paddingLeft: 20 },
    subSubTopicTitleStyle:{ fontSize: 12, fontWeight: '500', fontFamily: 'Poppins', color: '#001F49', width: '80%', marginLeft: 10 },
    subSubTopicContainerStyle:{ backgroundColor: '#E9F3FFF', border: 0, paddingLeft: 30 },
    subSubTopicChildContainerStyle:{ padding: 12, backgroundColor: '#FFFFFF', cursor: 'pointer', color: '#001F49', paddingLeft: 60, }
}