import React, { useState } from 'react';
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import OurPartnerBox from './OurPartnerBox';
import "../meetourteam/TeamCard.css"
import Slider from 'react-slick';

const PreviousBtn =(props) => {
    const {className,onClick}=props
    return(
        <div className={className} onClick={onClick}>
        <img  src={Images.PreArrow} />
        </div>
    )
}
const NextBtn =(props) => {
    const {className,onClick}=props

    return(
        <div className={className} onClick={onClick}>
        <img  src={Images.NextArrow} />
        </div>
    )
}

var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrow: false,
    autoplay: true,
    speed: 2000,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false

            }
        },
        {
            breakpoint: 780,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                autoplay: true,
                speed:3000
            }
        },
    ]
};

function OurPartner(props) {


    const { partner } = props;

    return (
        <>
        {
        partner && partner.length > 0 ? (
            <section style={{marginTop:50,marginBottom:100}}>
                <div className='container'>
                    <h1 className='section_title' style={{ color: Colors.dark_blue }}>Our Partners</h1>


                    <Slider
                        prevArrow={<PreviousBtn />}
                        nextArrow={<NextBtn />}
                        {...settings}>
                            {partner.map((el, i) =>{
                                const even = i % 2 === 0?true:false;
                                return(
                                <OurPartnerBox key={i}
                                description={el.desc}
                                backgroundColor={even?Colors.background_light_blue:Colors.background_light_yellow}
                                partnerlogo={el.image.media}
                                borderColor={even?'rgb(204, 226, 255)':Colors.border_light_yellow}
                            />
                                )
                            })}
                    
                    </Slider>
                </div>
            </section>
        ) : null
        }
        </>
    );
}

export default OurPartner;