const UpskillrTypes = {
    parseInt(value, defaultValue) {
        if (value != null)
            return parseInt(value, 10);
        return defaultValue ? defaultValue : 0;
    },
    getIntFloorValue(value, defaultValue) {
        if (value != null)
            return Math.floor(value);
        return Math.floor(defaultValue || 0);
    },
    getIntCeilValue(value, defaultValue) {
        if (value != null)
            return Math.ceil(value);
        return Math.ceil(defaultValue || 0);
    },
    getIntValue(value, defaultValue) {
        if (value != null)
            return Math.round(value);
        return Math.round(defaultValue || 0);
    },
    getDoubleValue(value, defaultValue) {
        if (value != null)
            return value;
        return defaultValue ? defaultValue : 0;
    },
    getBooleanValue(value, defaultValue) {
        if (value != null)
            return value;
        return (defaultValue != null) ? defaultValue : false;
    },
    isIntValue(value) {
        return (value == Math.floor(value)) && isFinite(value);
    },
    round(value, places) {
        return Number(Number(value).toFixed(places));
    },
    getDateValue(value, defaultValue) {
        if (value != null)
            return value;
        return (defaultValue != null) ? defaultValue : Date.now();
    },
    getStringValue(value, defaultValue) {
        if (value != null)
            return value;
        return (defaultValue != null && true) ? defaultValue : null;
    },
    //To get fixed value without round up
    getFixedValue(str, val) {
        str = str.toString();
        str = str.slice(0, (str.indexOf(".")) + val + 1);
        return Number(str);
    }
};
export default UpskillrTypes;
