import { GENERATE_ARTICLE_SUCCESS, GENERATE_ARTICLE_ERROR, GENERATE_ARTICLE_LOADING } from "./action";

const initialState = {
    generateArticleSuccess : null,
    generateArticleLoading : false,
    generateArticleError : null
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GENERATE_ARTICLE_SUCCESS: {
            return {
                ...state,
                generateArticleLoading: false,
                generateArticleSuccess: action.payload,
                generateArticleError: null
            }
        }
        case GENERATE_ARTICLE_LOADING: {
            return {
                ...state,
                generateArticleLoading: true,
                generateArticleError: null
            }
        }
        case GENERATE_ARTICLE_ERROR: {
            return {
                ...state,
                generateArticleLoading: false,
                generateArticleError: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default reducer;