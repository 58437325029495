import React, { useEffect, useState } from 'react';
import './header.scss';
import { NavLink, useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Dropdown } from 'react-bootstrap';
import Images from '../../constants/Images';
import { useDispatch, useSelector, connect } from 'react-redux';
import { logout } from '../../pages/login/action';
import { getProfileDetails } from '../../pages/myAccount/action';


const navItems = [
  {
    name: 'About',
    routeLink: '/aboutus'
  },
  {
    name: 'Pricing',
    routeLink: '/pricing'
  },
  {
    name: 'Blog',
    routeLink: '/blogs'
  },
  {
    name: 'Our features',
    routeLink: '/our-features'
  }
];

const CustomToggle = React.forwardRef(({ children, onClick, profileImage }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <img src={profileImage? profileImage : Images.UserIcon } style={{width:48, height:48, marginRight:5, backgroundColor:'#00439D', borderRadius:'50%'}}/>
    {children}
    <img src={Images.DropdownArrow} style={{width:15, height:8, marginLeft:5}}/>
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);
const Header = (props)=>{
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const {user_id} = useSelector((state) => state.loginReducer);


  useEffect(() => {
    if (userDetails) {
      setIsLogin(true);
    }
  }, []);

  const handleLogout = async () => {
    await localStorage.clear();
    dispatch(logout());
    history('/login');
}

  useEffect(() => {
    if(user_id){
    props.getProfileDetails(user_id);
    }
  }, [user_id]);

  return (
    <Navbar bg="white" expand='md' sticky='top' onSelect className='navHeader' collapseOnSelect>
      <Container>
        <Navbar.Brand>
          <NavLink to="" >
            <img src={require('../../assets/logo.png')} style={{ maxWidth: 126.49, objectFit: 'contain' }} />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} onClick={handleShow} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
          show={show}
        >
          <Offcanvas.Header closeButton onClick={handleClose}>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              <img src={require('../../assets/logo.png')} style={{ maxWidth: 126.49, objectFit: 'contain' }} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-center flex-grow-1 pe-3" activeKey={'our-features'} >
              {/* {navItems.map((item, i) => ( */}
                <NavLink to='/aboutus' className='menuLink' key={'menuList1'} onClick={handleClose} >About</NavLink >
                <NavLink to='/pricing' className='menuLink' key={'menuList2'} onClick={handleClose} >Pricing</NavLink >
                <NavLink to='/blogs' className='menuLink' key={'menuList3'} onClick={handleClose} >Blog</NavLink >
                {
                  isLogin && <NavLink to='/our-features' className='menuLink' key={'menuList4'} onClick={handleClose} >Features</NavLink >
                }
              {/* ))} */}
            </Nav>
            <Nav className='headerBtn'>
              {isLogin ?
                (

                  <Dropdown className='profileDropdown'>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" profileImage={props.profileDetails?.image?.media}>
                    {props.profileDetails.fullname}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu} style={{width:200}}>
                      <Dropdown.Item as="button">
                      <NavLink to={'/my-account'}>
                          <div className='my-2 ' style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                            <img src={Images.UserIcon} style={{width:20,marginRight:8}} />
                            <p className='mb-0'>My profile</p>
                          </div>
                        </NavLink ></Dropdown.Item>
                      <Dropdown.Divider className='m-0'/>
                      <Dropdown.Item as="button">
                      <NavLink to={'/order-history'}>
                          <div className='my-2 ' style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                            <img src={Images.ReviewIcon} style={{width:20,marginRight:8}} />
                            <p className='mb-0'>Order History</p>
                          </div>
                        </NavLink ></Dropdown.Item>
                      <Dropdown.Divider className='m-0'/>
                      <Dropdown.Item as="button">
                      <NavLink to={'/tutorial'}>
                          <div className='my-2 ' style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                            <img src={Images.TutorialIcon} style={{width:20,marginRight:8}} />
                            <p className='mb-0'>Tutorial</p>
                          </div>
                        </NavLink ></Dropdown.Item>
                      <Dropdown.Divider className='m-0'/>
                      <Dropdown.Item as="button">
                      <NavLink to={'saved-library'}>
                          <div className='my-2 ' style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                            <img src={Images.SavedLibrary} style={{width:20,marginRight:8}} />
                            <p className='mb-0'>Saved Library</p>
                          </div>
                        </NavLink ></Dropdown.Item>
                      <Dropdown.Divider className='m-0'/>
                      <Dropdown.Item as="button">
                      <NavLink to={'liked-library'}>
                          <div className='my-2 ' style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                            <img src={Images.LikedLibrary} style={{width:20,marginRight:8}} />
                            <p className='mb-0'>Liked Library</p>
                          </div>
                        </NavLink ></Dropdown.Item>
                      <Dropdown.Divider className='m-0'/>
                      <Dropdown.Item as="button">
                      <NavLink to={'hidden-library'}>
                          <div className='my-2 ' style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                            <img src={Images.HiddenLibrary} style={{width:20,marginRight:8}} />
                            <p className='mb-0'>Hidden Library</p>
                          </div>
                        </NavLink ></Dropdown.Item>
                      <Dropdown.Divider className='m-0'/>
                      <Dropdown.Item as="button">
                      <NavLink to={'/credit'}>
                          <div className='my-2 ' style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                            <img src={Images.CreditIcon} style={{width:20,marginRight:8}} />
                            <p className='mb-0'>Credit</p>
                          </div>
                        </NavLink ></Dropdown.Item>
                      <Dropdown.Divider className='m-0'/>
                      <Dropdown.Item as="button">
                      <a onClick={handleLogout}>
                          <div className='my-2 ' style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                            <img src={Images.LogoutIcon} style={{width:20,marginRight:8}} />
                            <p className='mb-0'>Logout</p>
                          </div>
                        </a ></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ):(
                  <>
                    <NavLink to={"/login"} className='headerBtn' onClick={handleClose}>Log In</NavLink>
                    <NavLink to={"/register"} className='headerBtn signup' onClick={handleClose}>Sign Up</NavLink>
                  </>
                )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string
};

// export default Header;

const mapStateToProps = ({ accountReducer }) => {
  const { profileDetails } = accountReducer;
  return {
      profileDetails
   };
}

export default connect(mapStateToProps, {
  getProfileDetails
})(Header);