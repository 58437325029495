import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import './McqsEdit.scss'
import Images from '../../constants/Images';
import Colors from '../../constants/Colors';
import { Button, FormControl, MenuItem, Select,Menu, } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

const McqsUserQA= (props)=> {
    const { handleOnSubmitMcq, originalData, mcqId } = props;

    const { handleSubmit, register,control, formState: { errors } } = useForm();

    const findKeyAndValue = (obj, targetKey, targetValue) => {
        for (const key in obj) {
            if (key === targetKey) {
                return obj[key].find((item) => {
                    if (item.id === targetValue) {
                        return item;
                    }
                }); // Return the key-value pair if both key and value match
            }
            if (typeof obj[key] === 'object') {
                const foundPair = findKeyAndValue(obj[key], targetKey, targetValue); // Recur into nested objects
                if (foundPair) {
                    return foundPair; // If a pair is found, return it
                }
            }
        }
        return undefined; // Return undefined if the pair is not found
    };

    const memoizedData = useMemo(() => {
        const filterData = findKeyAndValue(originalData, 'Mcqs', mcqId);
        return filterData;
    }, [mcqId, originalData]); 

    const onSubmit = (inputPayload) => {
        const outputArray = [];
        for (const question_id in inputPayload) {
            const answers = inputPayload[question_id];
            if (Array.isArray(answers)) {
                for (const answer of answers) {
                    outputArray.push({
                        question_id: parseInt(question_id),
                        answer_id: answer
                    });
                }
            } else {
                outputArray.push({
                    question_id: parseInt(question_id),
                    answer_id: answers
                });
            }
        }
        handleOnSubmitMcq({mcq_id : mcqId, data : outputArray});
    }

    return (
        <section className='McqsEdit'>
            <form onSubmit={handleSubmit(onSubmit)}>
            {
                memoizedData?.Mcq_Questions?.map((element, i) => {
                    return (<Accordion defaultActiveKey="0" style={{ marginBottom: 12 }} key={'McqsEdit'+i}>
                        <Accordion.Item eventKey="0" className='mcq'>
                            <Accordion.Header>
                                {i + 1}. {element.Title}
                            </Accordion.Header>
                            <Accordion.Body> 
                                <div style={{ width: '100%', height: 3, backgroundColor: "#FFAF0D", marginBottom: 20 }} />
                                    {
                                        element?.Mcq_Answers.map((item, index) => {
                                            if (element?.multiple) {
                                                return (
                                                    <Controller
                                                        name={`${(element?.id).toString()}`}
                                                        control={control}
                                                        defaultValue={[]}
                                                        key={'qq' + index}
                                                        value={`${item?.id}`}
                                                        rules={{
                                                            validate: (value) => value.length > 0 || 'At least one checkbox must be selected',
                                                        }}
                                                        render={({ field }) => (
                                                            <Form.Check
                                                                label={`${item.Title}`}
                                                                type={'checkbox'}
                                                                value={item?.id}
                                                                className="custom-checkbox"
                                                                onChange={(e) => {
                                                                    const value = parseInt(e.target.value);
                                                                    const updated = field.value.includes(value)
                                                                        ? field.value.filter((x) => x !== value)
                                                                        : [...field.value, value];
                                                                    field.onChange(updated);
                                                                }}
                                                                checked={field.value.includes(item?.id)}
                                                            />
                                                        )}
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        key={'qq' + index}
                                                        name={`${(element?.id).toString()}`}
                                                        value={`${item?.id}`}
                                                        rules={{
                                                            required: 'Please select an option', // Adjust the error message as needed
                                                          }}
                                                        render={({ field }) => (                                                            
                                                            <Form.Check
                                                                label={item.Title}
                                                                name={field.name}
                                                                value={`${item?.id}`}
                                                                type={'radio'}                                                                
                                                                className="custom-checkbox"
                                                                onChange={() => field.onChange(item?.id)}
                                                                checked={field.value === item?.id}
                                                            />
                                                        )}
                                                    />
                                                )
                                            }
                                        })
                                    }
                                    {errors[element?.id] && <p>{errors[element?.id].message}</p>}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>)
                })
            }

            <div className='newButtonSection'>
                <Button className='addnewButton' type="submit">{`Submit Response`} </Button>
            </div>
            </form>
        </section>
    );
}

export default McqsUserQA;