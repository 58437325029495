import PrivateRoute from "../utilities/privateRoute";
import Home from "../pages/homePage";
import AboutUs from "../pages/aboutus/AboutUs";
import OurFeatures from "../pages/ourFeatures/OurFeatures";
import MyAccount from "../pages/myAccount/MyAccount";
import Credit from "../pages/credit/Credit";
import Tutorial from "../pages/tutorial/Tutorial";
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import Pricing from "../pages/pricing/Pricing";
import SavedLibrary from "../pages/savedLibrary/SavedLibrary";
import HiddenLibrary from "../pages/hiddenLibrary/HiddenLibrary";
import LikedLibrary from "../pages/likedLibrary/LikedLibrary";
import PrivacyPolicy from "../pages/privacyConditions/PrivacyPolicy";
import TermsOfService from "../pages/privacyConditions/TermsOfService";

import AdminLogin from "../admin/Login/AdminLogin";
import Dashboard from "../admin/Dashboard";
import Users from "../admin/UserManagement/Users";
import AddUpdateUser from "../admin/UserManagement/AddUser";
import ResetPassword from "../admin/UserManagement/Reset";
import Recognitions from "../admin/ContentManagments/Recogniations";
import ImageFileUpload from "../admin/ContentManagments/Recogniations/AddNew/AddNew";
import Orders from "../admin/Orders";
import OurPartners from "../admin/ContentManagments/OurPartners";
import OurPartnerUpload from "../admin/ContentManagments/OurPartners/AddNew/AddNew";
import MeetOurTeam from "../admin/ContentManagments/MeetOurTeam";
import MeetOurTeamUpload from "../admin/ContentManagments/MeetOurTeam/AddNew/AddNew";
import MeetOurCoFounder from "../admin/ContentManagments/MeetOurCoFounder";
import MeetOurCoFounderUpload from "../admin/ContentManagments/MeetOurCoFounder/AddNew/AddNew";
import Subscriptions from "../admin/Subscriptions";
import AddSubscription from "../admin/Subscriptions/AddSubscription";
import KeyFeatures from "../admin/ContentManagments/KeyFeatures";
import UpdateKeyFeature from "../admin/ContentManagments/KeyFeatures/AddNew/UpdateKeyFeature";
import Blogs from "../pages/blogs/Blogs";
import BlogDetails from "../pages/blogs/BlogDetails";
import HomeOverviews from "../admin/ContentManagments/HomeOverviews";
import UpdateHomeOverview from "../admin/ContentManagments/HomeOverviews/AddNew/UpdateHomeOverview";
import HomeMarketplace from "../admin/ContentManagments/Marketplace/HomeMarketplace";
import UpdateMarketplace from "../admin/ContentManagments/Marketplace/AddNew/UpdateMarketplace";
import Organizations from "../admin/ContentManagments/Organizations/Organizations";
import UpadteOrganization from "../admin/ContentManagments/Organizations/AddNew/UpadteOrganization";
import OrderHistory from "../pages/orderHistory/OrderHistory";
import SuccessOrder from "../pages/orderSuccessfull/SuccessOrder";
import BlogList from "../admin/Blogs/BlogList";
import UpadteBlog from "../admin/Blogs/AddNew/UpadteBlog";
import ChangePassword from "../pages/login/ChangePassword";
import CurriculumBuilder from "../components/curriculumBuilder/CurriculumBuilder";
import ContactSales from "../pages/pricing/ContactSales";

export const routes = [
  {
    path: "/",
    exact: true,
    element: <Home />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/our-features",
    element: <PrivateRoute component={OurFeatures} />,
  },
  {
    path: "/my-account",
    element:<PrivateRoute component={MyAccount} />,
  },
  {
    path: "/credit",
    element: <Credit />,
  },
  {
    path: "/tutorial",
    element: <Tutorial />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/pricing/contact-sales",
    element: <ContactSales />,
  },
  {
    path: "/saved-library",
    element: <SavedLibrary />,
  },
  {
    path: "/hidden-library",
    element: <HiddenLibrary />,
  },
  {
    path: "/liked-library",
    element: <LikedLibrary />,
  },
  {
    path: "*",
    element: <Home />,
  },
  {
    path: "/admin/login",
    exact: true,
    element: <AdminLogin />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/blog/details",
    element: <BlogDetails />,
  },
  {
    path: "/order-history",
    element: <OrderHistory />,
  },
  {
    path: "/success-order",
    element: <SuccessOrder />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/our-features/curriculum-builder",
    element: <CurriculumBuilder />,
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/termsOfService",
    element: <TermsOfService />,
  },
];

export const adminRoutes = [
  {
    path: "/admin/dashboard",
    exact: true,
    element: <PrivateRoute component={Dashboard} />,
  },
  {
    path: "/admin/users",
    exact: true,
    element: <PrivateRoute component={Users} />,
  },
  {
    path: "admin/user/addNew",
    exact: true,
    element: <PrivateRoute component={AddUpdateUser} />,
  },
  {
    path: "/admin/user/resetpassword",
    exact: true,
    element: <PrivateRoute component={ResetPassword} />,
  },
  {
    path: "/admin/contents",
    exact: true,
    element: <PrivateRoute component={ResetPassword} />,
  },
  {
    path: "/admin/content/recogniations",
    exact: true,
    element: <PrivateRoute component={Recognitions} />,
  },
  {
    path: "/admin/content/recogniation/addNew",
    exact: true,
    element: <PrivateRoute component={ImageFileUpload} />,
  },
  {
    path: "/admin/orders",
    exact: true,
    element: <PrivateRoute component={Orders} />,
  },
  {
    path: "/admin/content/ourpartners",
    exact: true,
    element: <PrivateRoute component={OurPartners} />,
  },
  {
    path: "/admin/content/ourpartner/addNew",
    exact: true,
    element: <PrivateRoute component={OurPartnerUpload} />,
  },
  {
    path: "/admin/content/meetourteam",
    exact: true,
    element: <PrivateRoute component={MeetOurTeam} />,
  },
  {
    path: "/admin/content/meetourteam/addNew",
    exact: true,
    element: <PrivateRoute component={MeetOurTeamUpload} />,
  },
  {
    path: "/admin/content/meetourcofounders",
    exact: true,
    element: <PrivateRoute component={MeetOurCoFounder} />,
  },
  {
    path: "/admin/content/meetourcofounder/addNew",
    exact: true,
    element: <PrivateRoute component={MeetOurCoFounderUpload} />,
  },
  {
    path: "/admin/content/keyFeatures",
    exact: true,
    element: <PrivateRoute component={KeyFeatures} />,
  },
  {
    path: "/admin/content/keyFeature/addNew",
    exact: true,
    element: <PrivateRoute component={UpdateKeyFeature} />,
  },
  {
    path: "/admin/content/homeOverviews",
    exact: true,
    element: <PrivateRoute component={HomeOverviews} />,
  },
  {
    path: "/admin/content/homeOverview/addNew",
    exact: true,
    element: <PrivateRoute component={UpdateHomeOverview} />,
  },
  {
    path: "/admin/content/marketplace",
    exact: true,
    element: <PrivateRoute component={HomeMarketplace} />,
  },
  {
    path: "/admin/content/marketplace/addNew",
    exact: true,
    element: <PrivateRoute component={UpdateMarketplace} />,
  },
  {
    path: "/admin/content/organizations",
    exact: true,
    element: <PrivateRoute component={Organizations} />,
  },
  {
    path: "/admin/content/organization/addNew",
    exact: true,
    element: <PrivateRoute component={UpadteOrganization} />,
  },
  {
    path: "/admin/subscriptions",
    exact: true,
    element: <PrivateRoute component={Subscriptions} />,
  },
  {
    path: "/admin/subscription/addNew",
    exact: true,
    element: <PrivateRoute component={AddSubscription} />,
  },
  {
    path: "/admin/blogs",
    exact: true,
    element: <PrivateRoute component={BlogList} />,
  },
  {
    path: "/admin/blog/addNew",
    exact: true,
    element: <PrivateRoute component={UpadteBlog} />,
  },
]
