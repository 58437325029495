import React, { useState } from 'react';
import { PropTypes } from "prop-types";
import { Button } from '@mui/material';
import { TextStyles } from '../constants/TextStyles';
import UpskillrUtils from '../utilities/UpskillrUtils';

const ViewHeading = (props) =>{
    const {title, buttonText, onButtonClick, titleStyle, btnStyle,buttonText2,containerStyles,btnStyle2,onButton2Click, disableButtonOne} = props;

    return(
        <div className='space-between viewHeadingSection' style={containerStyles}>
            <h3 className='viewHeadingText' style={titleStyle}>{title}</h3>
            {buttonText &&
            <div className='rightSection'>
                {buttonText2 && <Button className='btnStyle2' style={btnStyle2} onClick={onButton2Click}>{buttonText2}</Button>}
                <Button className='btnStyle' style={btnStyle} disabled={disableButtonOne} onClick={onButtonClick}>{buttonText}</Button>
            </div>
            
            }
        </div>
    )
}

export default ViewHeading;

ViewHeading.propTypes = {
    title:PropTypes.string,
    buttonText: PropTypes.string,
    rightText: PropTypes.string,
    rightIcon: PropTypes.string,
    onButtonClick: PropTypes.func,
    onButton2Click: PropTypes.func,
    titleStyle:PropTypes.any,
    btnStyle:PropTypes.any
  };
