import { PRICING_DETAILS, INITIATE_PAYMENT_SUCCESS, INITIATE_PAYMENT_ERROR, INITIATE_PAYMENT_LOADING, UPDATE_PAYMENT_ERROR, UPDATE_PAYMENT_LOADING, UPDATE_PAYMENT_SUCCESS, CONTACT_SALES_ERROR, CONTACT_SALES_LOADING, CONTACT_SALES_SUCCESS} from "./action";

const initialState = {
    pricingDetails: [],
    initialPaymentLoading : false,
    initialPaymentSuccess : null,
    initialPaymentError : null,
    updatePaymentLoading : false,
    updatePaymentSuccess : null,
    updatePaymentError : null,
    contactSalesLoading : false,
    contactSalesSuccess : null,
    contactSalesError : null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PRICING_DETAILS : {
            return {
                ...state,
                loading: false,
                pricingDetails: action.payload,
            };
        }
        case INITIATE_PAYMENT_SUCCESS : {
            return {
                ...state,
                initialPaymentLoading : false,
                initialPaymentSuccess : action.payload,
                initialPaymentError : null
            }
        }
        case INITIATE_PAYMENT_LOADING : {
            return {
                ...state,
                initialPaymentLoading : true,
                initialPaymentError : null,
                initialPaymentSuccess : null
            }
        }
        case INITIATE_PAYMENT_ERROR : {
            return {
                ...state,
                initialPaymentLoading : false,
                initialPaymentError : action.payload,
                initialPaymentSuccess : null
            }
        }
        case UPDATE_PAYMENT_SUCCESS : {
            return {
                ...state,
                updatePaymentLoading : false,
                updatePaymentSuccess : action.payload,
                updatePaymentError : null
            }
        }
        case UPDATE_PAYMENT_LOADING : {
            return {
                ...state,
                updatePaymentLoading : true,
                updatePaymentError : null,
                updatePaymentSuccess : null
            }
        }
        case UPDATE_PAYMENT_ERROR : {
            return {
                ...state,
                updatePaymentLoading : false,
                updatePaymentError : action.payload,
                updatePaymentSuccess : null
            }
        }
        case CONTACT_SALES_SUCCESS : {
            return {
                ...state,
                contactSalesLoading : false,
                contactSalesSuccess : action.payload,
                contactSalesError : null
            }
        }
        case CONTACT_SALES_LOADING : {
            return {
                ...state,
                contactSalesLoading : true,
                contactSalesError : null,
                contactSalesSuccess : null
            }
        }
        case CONTACT_SALES_ERROR : {
            return {
                ...state,
                contactSalesLoading : false,
                contactSalesError : action.payload,
                contactSalesSuccess : null
            }
        }
        
        default: {
            return state;
        }
    }
};

export default reducer;