import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
import { FAILUR, PROCCESSING, STOPPROCESSING } from "../../utilities/constants";
import { toast } from "react-toastify";
export const UPLOAD = 'UPLOAD';
export const CHATS = 'CHATS';
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const GET_REPLIES = 'GET_REPLIES';
export const QUERY_ASSISTANCE ='QUERY_ASSISTANCE';

export const uploadEngineLink = (link) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.uploadEngineLink}`,
        data: { youtube_link: link }
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
            dispatch({
                type: UPLOAD,
                payload: response
            })
            return response
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error
        });
};

export const uploadEngineFile = (file) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.uploadEngineFile}`,
        data: file,
        headers: {
            'Content-Type': 'text/csv', // Set the Content-Type header to indicate the CSV file
        },
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
            dispatch({
                type: UPLOAD,
                payload: response
            })
            return response
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error;
        });
};

export const getConversations = () => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.get_conversation}`,
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
            dispatch({
                type: GET_CONVERSATION,
                payload: response
            })
            return response
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error;
        });
}

export const getConversationReplies = (id) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.get_conversation_replies}?conversation_id=${id}`,
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
            dispatch({
                type: GET_REPLIES,
                payload: response
            })
            return response
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error;
        });
}

export const queryAssistance = (query) => async (dispatch) => {
    dispatch({
        type: QUERY_ASSISTANCE,
        payload: [{
            from_gpt: false,
            text: query.text
        }]
    })
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.queryAssistance}`,
        data: query
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
            dispatch({
                type: QUERY_ASSISTANCE,
                payload: [response]
            })
            return response
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error;
        });
}

export const deleteConversation = (Id) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.delete,
        url: `${API_ENDPOINTS.delete_conversation}${Id}/`,
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
            return response
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error;
        });
}

export const searchConversation = (search) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.search_conversation}?name=${search}`,
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
            dispatch({
                type: GET_CONVERSATION,
                payload: response
            })
            return response
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error;
        });
}

export const updateConversation = (updateData, id) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.put,
        url: `${API_ENDPOINTS.update_conversation}${id}/`,
        data: updateData
    };

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({ type: STOPPROCESSING });
            return response
        })
        .catch((error) => {
            dispatch({ type: STOPPROCESSING });
            throw error;
        });
}




