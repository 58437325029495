import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import {  getAllOrganizations, deleteRecordById } from "./action";
import { DELETE_MESSAGE } from "../../../utilities/message";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from 'react-toastify'
import CustomDialog from "../../Components/CustomDialog";

//jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Sidebar from "../../Components/Navigation/Sidebar";
import Topbar from "../../Components/Navigation/Topbar";
import PageHeading from "../../Components/PageHeading";

const Organizations = (props) => {
  $(document).ready(function () {
    setTimeout(function () {
      $("#dataTable").DataTable();
    }, 1000);
  });

  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState(null);
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const [userListArr, setUserListArr] = useState([]);
  const [type, setType] = useState('')
  const [check, setCheck] = useState(Boolean)
  const [userId, setUserId] = useState(null)
  const history = useNavigate();

  const handleDialogClose = () => {
    setId(null);
    setShowDialog(false);
  };

  const deleteRecord = async (id) => {
    setId(id);
    setDialogSubmit(true);
    setMessage(DELETE_MESSAGE);
    setShowDialog(true);
  };

  const handleEditData = (userData) => {
    history(`/admin/content/organization/addNew?update_id=${userData.id}`, {
      state: { id: userData?.id },
    });
  };

  useEffect(() => {
    props.getAllOrganizations();
}, []);

const deleteOrganizationDialog = () => {
  props?.deleteRecordById(id).then(response => {
    if(response.status){
      props.getAllOrganizations();
      toast.success('Data deleted successfully')
    }else{
      toast.error(response?.message)
    }
    setShowDialog(false)
  }).catch(error =>{
    setShowDialog(false)
    toast.error('Getting error in delete record')
  }
  )
}


  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper" className="organizations">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="contents">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Organizations"
                buttonName="Add New"
                buttonLink={"/admin/content/organization/addNew"}
                buttonIcon="fa-plus"
              />

              <div>

              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                  Organization list
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >
                        
                        <>
                          <thead>
                            <tr>
                            <th className="text-capitalize heading-section">Id</th>
                              <th className="text-capitalize">Image</th>
                              <th className="text-capitalize action-section">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props?.organizationsList?.map((item, index) => (
                              <tr key={"tr" + index}>
                                <td>{item?.id}</td>
                                <td className="text-capitalize"><img src={item?.image?.media} style={{width:80,height:80,objectFit:'contain'}}/></td>
                                <td className="action">
                                  {
                                    <a className="text-primary">
                                      <i
                                        className="far fa-edit"
                                        onClick={() => handleEditData(item)}
                                        style={{marginRight:10}}
                                      ></i>
                                    </a>
                                  }
                                  {
                                    <a
                                      className="deletePost delete text-danger"
                                      rowid="18"
                                      onClick={() => deleteRecord(item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        message={DELETE_MESSAGE}
        showDialog={showDialog}
        handleClose={() => handleDialogClose()}
        handleDialogSubmit={() => deleteOrganizationDialog()}
        isSubmit
      />
    </div>
  );
};

const mapStateToProps = ({adminOrganizationsReducer}) => {
  const { organizationsList } = adminOrganizationsReducer;
  return { organizationsList };
};

export default connect(mapStateToProps, {
  getAllOrganizations,
  deleteRecordById
})(Organizations);

// export default (Organizations);
