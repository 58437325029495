import {
    UPLOAD_FILES,
    ALL_ORDERS,
    DASHBOARD_USERS,
    GET_FILES,
    DASHBOARD_REVENUE,
    DASHBOARD_ORDERS
} from './action';

const initialState = {
    fileList: [],
    orderList: [],
    dashboard: {},
    dashboardRevenue: {},
    dashboardOrders: {},
    getFile: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_FILES: {
            return {
                ...state,
                fileList: action.payload,
            };
        }
        case ALL_ORDERS: {
            return {
                ...state,
                orderList: action.payload,
            }
        }
        case DASHBOARD_USERS: {
            return {
                ...state,
                dashboard: action.payload,
            };
        }
        case DASHBOARD_REVENUE: {
            return {
                ...state,
                dashboardRevenue: action.payload,
            };
        }
        case DASHBOARD_ORDERS: {
            return {
                ...state,
                dashboardOrders: action.payload,
            };
        }
        case GET_FILES: {
            return {
                ...state,
                getFile: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
