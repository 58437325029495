import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import './McqsEdit.scss'
import Images from '../../constants/Images';
import Colors from '../../constants/Colors';
import { Button, FormControl, MenuItem, Select,Menu, } from '@mui/material';
import { useMemo } from 'react';

const McqsEdit= (props)=> {
    const { handleOnDeleteMcqQuestion, handleOnMcqAddChanges, addAnswerOption, addQuestion, originalData, mcqId, handleSelectionInput } = props;

    const handleChange = (event, key, title, questionType) => {
        event.stopPropagation();
        handleOnMcqAddChanges(key,title, questionType,{type:event.target.value});
    };
    const findKeyAndValue = (obj, targetKey, targetValue) => {
        for (const key in obj) {
            if (key === targetKey) {
                return obj[key].find((item) => {
                    if (item.id === targetValue) {
                        return item;
                    }
                }); // Return the key-value pair if both key and value match
            }
            if (typeof obj[key] === 'object') {
                const foundPair = findKeyAndValue(obj[key], targetKey, targetValue); // Recur into nested objects
                if (foundPair) {
                    return foundPair; // If a pair is found, return it
                }
            }
        }
        return undefined; // Return undefined if the pair is not found
    };

    const memoizedData = useMemo(() => {
        const filterData = findKeyAndValue(originalData, 'Mcqs', mcqId);
        return filterData;
    }, [mcqId, originalData]); 

    return (
        <section className='McqsEdit'>

            {
                memoizedData?.Mcq_Questions?.map((element, i) => {
                    return (<Accordion defaultActiveKey="0" style={{ marginBottom: 12 }} key={'McqsEdit'+i}>
                        <Accordion.Item eventKey="0" className='accordion-item2'>
                            <Accordion.Header>
                                {i + 1}. {element.Title}
                            </Accordion.Header>
                            <div className='selectType'>
                                {/* <FormControl sx={{ m: 1, minWidth: 190 }} size="small">
                                    <Select value={element?.multiple} onChange={(event)=>handleChange(event,'Mcq_Questions',element.Title, 'changeQuestionType')}>
                                        <MenuItem value=""> <em>Please select</em> </MenuItem>
                                        <MenuItem value={true}>Multiple Choice</MenuItem>
                                        <MenuItem value={false}>Single Choice</MenuItem>
                                    </Select>
                                </FormControl> */}
                            </div>
                            <div style={{ width: '100%', height: 1, backgroundColor: '#B8C0CC', marginBottom: 20, marginTop:10 }} />
                            <Accordion.Body>
                                <Form>  
                                    {
                                        element?.Mcq_Answers.map((item, index) => {
                                            if (element?.multiple) {
                                                return (<Form.Check
                                                    label={item.Title}
                                                    type={'checkbox'}
                                                    id={`${element?.multiple}-${index + index}`}
                                                    defaultChecked={item.Is_Correct ?? false}
                                                    className="custom-checkbox"
                                                    key={'qq' + index}
                                                    onChange={(event)=>handleSelectionInput(element.Title, index, event.target.checked)}
                                                />)
                                            } else {
                                                return (<Form.Check
                                                    label={item.Title}
                                                    name={`${element?.multiple}-${i}`}
                                                    type={'radio'}
                                                    id={`${element?.multiple}-${index}`}
                                                    defaultChecked={item.Is_Correct ?? false}
                                                    className="custom-checkbox"
                                                    key={'qq' + index}
                                                    onChange={(event)=>handleSelectionInput(element.Title, index, event.target.checked)}
                                                />)
                                            }
                                        })
                                    }
                                </Form>
                            </Accordion.Body>
                            <div className='accordFooter'>
                                <a className='footerBtnStyle' onClick={() => addAnswerOption('Mcq_Questions', element.Title, element, element?.multiple)}> <img src={Images.Plus} /> {'Edit Option'} </a>
                                <a className='footerBtnStyle' onClick={() => handleOnDeleteMcqQuestion('Mcq_Questions', element.Title, 'delete')}> <img src={Images.Trash} /> {'Delete QnA'} </a>
                            </div>
                        </Accordion.Item>
                    </Accordion>)
                })
            }

           


            <div className='newButtonSection'>
                <Button className='addnewButton ' onClick={()=>addQuestion('Mcqs', memoizedData.Title)}>{`+ Add New Question`} </Button>
            </div>
        </section>
    );
}

export default McqsEdit;