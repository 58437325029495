import { GET_ORGANIZATIONS,ADD_ORGANIZATION,UPDATE_ORGANIZATION,GET_ORGANIZATION_DETAILS } from "./action";

const initialState = {
  organizationsList: null,
  createOrganization: null,
  updateOrganization: null,
  contentDetails:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATIONS: {
      return {
        ...state,
        organizationsList: action.payload,
      };
    }
    case ADD_ORGANIZATION: {
      return {
        ...state,
        createOrganization: action.payload,
      };
    }
    case UPDATE_ORGANIZATION: {
      return {
        ...state,
        updateOrganization: action.payload,
      };
    }
    case GET_ORGANIZATION_DETAILS: {
      return {
        ...state,
        contentDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
