import React from 'react';
import './index.scss';
import PropTypes from "prop-types";

const PageHeading = (props) => {
    const { title, buttonName, buttonLink, buttonIcon } = props;

    return (
        <div className="d-sm-flex align-items-center justify-content-between mb-4 pageHeading" >
            <h1 className="h3 mb-0 text-gray-800">{title}</h1>
            <div>
                {(buttonLink !== '') ? (
                    <a href={buttonLink} className="d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                        {buttonIcon !== "" ? (<i className={`fas ${buttonIcon} fa-sm text-white-50 me-1`}></i>) : null}
                        {buttonName}</a>
                ) : null}

            </div>
        </div >
    )
}

export default PageHeading;
PageHeading.propTypes = {
    title: PropTypes.string,
    buttonName: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonIcon: PropTypes.string,
};

PageHeading.defaultProps = {
    title: '',
    buttonName: 'Add New',
    buttonLink: '',
    buttonIcon: "fa-plus",
};