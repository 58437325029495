
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Form from 'react-bootstrap/Form';
import './index.scss'
import Accordion from 'react-bootstrap/Accordion';
import ReadMoreReact from 'read-more-react';
import Images from '../../constants/Images';
import { Button } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getConversationReplies, queryAssistance } from './action';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh'
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '70vh',
        overflowY: 'auto'
    }
});

const Chat = (props) => {
    const { chatId } = props;
    const listRef = useRef(null);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [question, setQuestion] = useState('')
    const { replies } = useSelector((state) => state.assistantReducer);
    const {profileDetails} = useSelector((state)=>state.accountReducer);

    useEffect(() => {
      if(chatId)
      dispatch(getConversationReplies(chatId))
      .catch((error) => {
        toast.error( error?.response?.data?.message || "Something went wrong, please try again.")
    })
    }, [chatId])

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
          }
    }, [replies])
    
    const askGPT = () => {
        const data = {
            conversation_id: chatId,
            text: question
        }
        dispatch(queryAssistance(data))
        .then((res) => {
            setQuestion('');
        })
        .catch((error) => {
            toast.error( error?.response?.data?.message || "Something went wrong, please try again.")
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(question?.trim().length > 0)
        askGPT();
    }

    function isLink(value) {
        const urlPattern = /^(www|http|https):\/\/[^ "]+$/;

        return urlPattern.test(value);
    }

    function canBeParsedAsJSON(text) {
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    return (
        <>

            <Modal size="lg" show={show} onHide={handleClose} className='Chat-Popup' centered dialogClassName="modal-90w">
            <Button onClick={handleClose} style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
					<img src={require('../../assets/table_close_icon.png')} style={{width:32,height:32,marginTop:-50}}/>
				</Button>
                <Modal.Body>
                    <div className="button" onClick={handleShow2} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 9, marginTop: 5,gap:10 }}>
                        <Button style={{ backgroundColor: '#004AAD', color: '#fff', textTransform: 'capitalize', width: '100%' }} className='white' >{`Test your skills`}</Button>
                        <Button style={{ backgroundColor: 'transparent', color: '#001F49', textTransform: 'capitalize', width: '100%',}} className='white blue-border' >{`Open SkillMap`}</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={show2}  onHide={handleClose2} centered className='Chat-Popup' dialogClassName="modal-90w">
            <Button onClick={handleClose2} style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
					<img src={require('../../assets/table_close_icon.png')} style={{width:32,height:32,marginTop:-50}}/>
				</Button>
            <Modal.Body>
                    <div className="button" onClick={handleShow2} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 9, marginTop: 5,gap:10 }}>
                        <Button style={{ backgroundColor: '#004AAD', color: '#fff', textTransform: 'capitalize', width: '100%' }} className='white' >{`MCQ`}</Button>
                        <Button style={{ backgroundColor: 'transparent', color: '#001F49', textTransform: 'capitalize', width: '100%',}} className='white blue-border' >{`Case Study`}</Button>
                        <Button style={{ backgroundColor: 'transparent', color: '#001F49', textTransform: 'capitalize', width: '100%',}} className='white blue-border' >{`Coding simulation`}</Button>
                    </div>
                </Modal.Body>
            </Modal> 
       
        <div className='Chat'>
            <Grid container component={Paper} className={classes.chatSection}>
                <Grid item xs={9}>
                    <List className={classes.messageArea} ref={listRef}>
                            {
                                replies?.map((chat, index) =>
                                    chat?.from_gpt ?
                                    
                                        <ListItem key={index}>
                                            <Grid container>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                                                    <img src={Images.Assistant} style={{ width: 56, borderRadius: '50%', marginRight: 10, }} />
                                                    <div >
                                                        <Grid item xs={12}>
                                                            {
                                                                chat.text && canBeParsedAsJSON(chat?.text) ?
                                                                    Object.values(JSON.parse(chat?.text))?.map(text =>
                                                                        isLink(text) ?
                                                                            <a href={text} target='_blank'>
                                                                                <ListItemText className='receiverMessage' align="left" primary={text} ></ListItemText>
                                                                            </a>
                                                                            :
                                                                            <ListItemText className='receiverMessage' align="left" primary={text}></ListItemText>

                                                                    ) :
                                                                    <ListItemText className='receiverMessage' align="left" primary={chat?.text}></ListItemText>
                                                            }
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </ListItem>
                                    :
                                    <ListItem key={index}>
                                        <Grid container>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                                <Grid item xs={12}>
                                                    <ListItemText className='senderMessage' align="right" primary={chat?.text}></ListItemText>
                                                </Grid>
                                                <img src={(profileDetails['image']?.media)?profileDetails['image']?.media:Images.UserIcon}  style={{ width: 56, borderRadius: '50%', marginLeft: 10, transform: 'scaleX(-1)' }} />
                                            </div>
                                        </Grid>
                                    </ListItem>
                                )
                            }
                        {/* <ListItem key="2">
                            <Grid container>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                                    <img src="https://material-ui.com/static/images/avatar/1.jpg" style={{ width: 56, borderRadius: '50%', marginRight: 10, }} />
                                    <div >
                                        <Grid item xs={12}>
                                            <ListItemText className='receiverMessage' align="left" primary="Hey man, What's up ?"></ListItemText>
                                        </Grid>
                                        <Accordion className='AccordianChat'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Body className='receiverQuestions'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                </Accordion.Body>
                                                <Accordion.Header>show AI generated Questions</Accordion.Header>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Grid>
                        </ListItem>
                        <ListItem key="3">
                            <Grid container>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                    <Grid item xs={12}>
                                        <ListItemText className='senderMessage' align="right" primary="Hey man, What's up ?"></ListItemText>
                                    </Grid>
                                    <img src="https://material-ui.com/static/images/avatar/1.jpg" style={{ width: 56, borderRadius: '50%', marginLeft: 10, transform: 'scaleX(-1)' }} />
                                </div>
                            </Grid>
                        </ListItem>
                        <ListItem key="4">
                            <Grid container>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                                    <img src="https://material-ui.com/static/images/avatar/1.jpg" style={{ width: 56, borderRadius: '50%', marginRight: 10, }} />
                                    <Grid item xs={12}>
                                        <ListItemText className='receiverMessage' align="left" primary="Hey man, What's up ?"></ListItemText>
                                    </Grid>
                                </div>
                            </Grid>
                        </ListItem>
                        <ListItem key="5">
                            <Grid container>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                    <Grid item xs={12}>
                                        <ListItemText className='senderMessage' align="right" primary="Hey man, What's up ?"></ListItemText>
                                    </Grid>
                                    <img src="https://material-ui.com/static/images/avatar/1.jpg" style={{ width: 56, borderRadius: '50%', marginLeft: 10, transform: 'scaleX(-1)' }} />
                                </div>
                            </Grid>
                        </ListItem>

                        <ListItem key="6">
                            <Grid container>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                                    <img src="https://material-ui.com/static/images/avatar/1.jpg" style={{ width: 56, borderRadius: '50%', marginRight: 10, }} />
                                    <div style={{ width: '100%' }}>
                                        <Grid item xs={12}>
                                            <div style={{ width: '70%', padding: 12, backgroundColor: '#FAFAFA', color: '#00295F', borderRadius: 8, borderColor: '#2FC08B', fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, }}>
                                                <ReadMoreReact text={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic ty    Read More...`}
                                                    min={3}
                                                    ideal={100}
                                                    max={300}
                                                    readMoreText={`Read More...`} />
                                            </div>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </ListItem>

                        <ListItem key="7">
                            <Grid container>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
                                        <Grid item xs={12}>
                                            <div className='uploadPdf' style={{ padding: 12, backgroundColor: '#336EBD', color: '#FFF', borderRadius: 8, borderColor: '#2FC08B', fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, }}>
                                                <img src={Images.ChatPDF} style={{ width: 19, marginRight: 10, }} />
                                                AdityaSaxenaResume.pdf
                                                <img src={Images.CrossChat} style={{ width: 24, marginLeft: 10, cursor: 'pointer' }} />

                                            </div>
                                        </Grid>
                                    </div>
                                    <img src="https://material-ui.com/static/images/avatar/1.jpg" style={{ width: 56, borderRadius: '50%', marginLeft: 10, transform: 'scaleX(-1)' }} />
                                </div>
                            </Grid>
                        </ListItem>

                        <ListItem key="8">
                            <Grid container>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                                    <img src="https://material-ui.com/static/images/avatar/1.jpg" style={{ width: 56, borderRadius: '50%', marginRight: 10, }} />
                                    <div style={{ width: '100%' }}>
                                        <Grid item xs={12}>
                                            <div style={{ width: '70%', padding: 12, backgroundColor: '#FAFAFA', color: '#00295F', borderRadius: 8, borderColor: '#2FC08B', fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, }}>
                                                Choose the skills you wish to learn
                                                <Form>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`default-${type}`}
                                                                label={`Strong foundation in statistics and mathematics`}
                                                            />
                                                            <Form.Check
                                                                type={type}
                                                                id={`default-${type}`}
                                                                label={`Proficient in programming languages such as Python or R`}
                                                            />
                                                            <Form.Check
                                                                type={type}
                                                                id={`default-${type}`}
                                                                label={`Ability to visualize data in a clear and concise manner`}
                                                            />
                                                        </div>
                                                    ))}
                                                </Form>
                                                <div className="button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 9, marginTop: 5 }} onClick={handleShow} >
                                                    <Button style={{ backgroundColor: '#004AAD', color: 'white', textTransform: 'capitalize' }} className='white' >{`Learn`}</Button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </ListItem>

                        <ListItem key="8">
                            <Grid container>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
                                        <Grid item xs={12}>
                                            <div className='mcqSet' style={{ width: '100%', padding: 12, backgroundColor: '#FAFAFA', color: '#00295F', borderRadius: 8, borderColor: '#2FC08B', fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, }}>
                                                <Accordion defaultActiveKey="0"  style={{ width: '100%',}}>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header >1. What is a blockchain?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div style={{ width: '100%', height: 2, backgroundColor: '#FFAF0D', marginBottom: 20 }} />
                                                            <Form>
                                                                {['radio'].map((type) => (
                                                                    <div key={`default-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            label="A decentralized database"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`${type}-1`}
                                                                        />
                                                                        <Form.Check
                                                                            label="A centralized database"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`${type}-2`}
                                                                        />
                                                                        <Form.Check
                                                                            label="A type of programming language"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`${type}-3`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            <div className="button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 9, marginTop: 5 }} >
                                                    <Button style={{ backgroundColor: '#FFAF0D', color: 'white', textTransform: 'capitalize' }} className='white' >{`Submit Response`}</Button>
                                                </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </Grid>
                                    </div>
                                    <img src="https://material-ui.com/static/images/avatar/1.jpg" style={{ width: 56, borderRadius: '50%', marginLeft: 10, transform: 'scaleX(-1)' }} />
                                </div>
                            </Grid>
                        </ListItem> */}
                    </List>
                    <Divider />
                    <Grid container className='FormBackground' style={{ padding: '20px', paddingTop: 10 }}>
                        <div style={{ display: 'flex ', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Form className='ChatForm' onSubmit={handleSubmit}>
                                <Form.Group className="" controlId="formBasicEmail">
                                    <Form.Control  placeholder="Ask me any question based on the source you've provided, and I'll happily assist you." value={question}
                                    onChange={(e)=> setQuestion(e.target.value)} />
                                </Form.Group>
                            </Form>
                            <img className='sendIcon' src={require('../../assets/send_locn.png')} onClick={askGPT} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        </>
    );
}

export default Chat;