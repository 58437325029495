import { SAVED_THINGS_TO_DO_ERROR, SAVED_THINGS_TO_DO_LOADING, SAVED_THINGS_TO_DO_SUCCESS, SAVED_THINGS_TO_READ_ERROR, SAVED_THINGS_TO_READ_LOADING, SAVED_THINGS_TO_READ_SUCCESS, SAVED_THINGS_TO_WATCH_ERROR, SAVED_THINGS_TO_WATCH_LOADING, SAVED_THINGS_TO_WATCH_SUCCESS } from "./action";

const initialState = {  
    savedThingsToDoSuccess: null,
    savedThingsToDoLoading: false,
    savedThingsToDoError: null,
    
    savedThingsToWatchSuccess: null,
    savedThingsToWatchLoading: false,
    savedThingsToWatchError: null,

    savedThingsToReadSuccess: null,
    savedThingsToReadLoading: false,
    savedThingsToReadError: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVED_THINGS_TO_DO_SUCCESS: {
            return {
                ...state,
                savedThingsToDoLoading: false,
                savedThingsToDoSuccess: action.payload,
                savedThingsToDoError: null
            }
        }
        case SAVED_THINGS_TO_DO_LOADING: {
            return {
                ...state,
                savedThingsToDoLoading: true,
                savedThingsToDoError: null
            }
        }
        case SAVED_THINGS_TO_DO_ERROR: {
            return {
                ...state,
                savedThingsToDoLoading: false,
                savedThingsToDoError: action.payload
            }
        }

        case SAVED_THINGS_TO_READ_SUCCESS: {
            return {
                ...state,
                savedThingsToReadLoading: false,
                savedThingsToReadSuccess: action.payload,
                savedThingsToReadError: null
            }
        }
        case SAVED_THINGS_TO_READ_LOADING: {
            return {
                ...state,
                savedThingsToReadLoading: true,
                savedThingsToReadError: null
            }
        }
        case SAVED_THINGS_TO_READ_ERROR: {
            return {
                ...state,
                savedThingsToReadLoading: false,
                savedThingsToReadError: action.payload
            }
        }

        case SAVED_THINGS_TO_WATCH_SUCCESS: {
            return {
                ...state,
                savedThingsToWatchLoading: false,
                savedThingsToWatchSuccess: action.payload,
                savedThingsToWatchError: null
            }
        }
        case SAVED_THINGS_TO_WATCH_LOADING: {
            return {
                ...state,
                savedThingsToWatchLoading: true,
                savedThingsToWatchError: null
            }
        }
        case SAVED_THINGS_TO_WATCH_ERROR: {
            return {
                ...state,
                savedThingsToWatchLoading: false,
                savedThingsToWatchError: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default reducer;