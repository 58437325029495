import jwt_decode from 'jwt-decode';
import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
export const USER_LOGIN = "USER_LOGIN";
// export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const ISADMIN = "ISADMIN";
export const FORGOTPASSWORD = "FORGOTPASSWORD";
export const RESETPASSWORD = "RESETPASSWORD";
export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_ERROR = "SEND_OTP_ERROR";
export const USER_LOGIN_SUCCESS="USER_LOGIN_SUCCESS";


export const userLogging = (requestObj) => async (dispatch) => {

    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.login,
        data: requestObj
    };
    return await api(data)
        .then(async (response) => {           
            dispatch({
                type: USER_LOGIN,
                payload: response.data,
            });
            if (requestObj?.isAdmin) dispatch({
                type: ISADMIN,
                payload: "true"
            })
            else dispatch({
                type: ISADMIN,
                payload: "false"
            })
           
            const decodedToken = jwt_decode(response.data.access);
            if(decodedToken){
                const { user_id } = decodedToken;
                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: user_id,
                });
            }
            return response.data
        })
        .catch((error) => {
            dispatch({
                type: USER_LOGIN_ERROR,
                payload: true,
            });
            throw error?.response?.data?.message
        });
};

export const googleLogging = (requestObj) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.googleToken,
        data: requestObj
    };

    return api(data)
        .then(async (response) => {
            dispatch({
                type: USER_LOGIN,
                payload: response.data,
            });
            dispatch({
                type: ISADMIN,
                payload: "false"
            })

            const decodedToken = jwt_decode(response.data.access);
            if(decodedToken){
                const { user_id } = decodedToken;
                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: user_id,
                });
            }
            return response.data
        })
        .catch((error) => {
            console.log("error",error);
            dispatch({
                type: USER_LOGIN_ERROR,
                payload: true,
            });
            throw error?.response?.message
        });
};

export const facebookLogging = (requestObj) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.facebook,
        data: requestObj
    };
    return api(data)
        .then(async (response) => {
            if (response?.data?.result?.accountVerified === "approved") {
                dispatch({
                    type: USER_LOGIN,
                    payload: response.data,
                });
                dispatch({
                    type: ISADMIN,
                    payload: "user"
                })
            }
            return response.data
        })
        .catch((error) => {
            dispatch({
                type: USER_LOGIN_ERROR,
                payload: true,
            });
            throw error?.response?.data?.message
        });
};

export const logout = () => async (dispatch) => {
    dispatch({
        type: USER_LOGIN,
        payload: null,
    });
    dispatch({
        type: ISADMIN,
        payload: null
    })
}

export const forgotPassword = (emailData) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.post_forgotPassword,
        data: { email: emailData }
    };
    return api(data)
        .then(async (response) => {
            if (response.status) {
                dispatch({
                    type: FORGOTPASSWORD,
                    payload: response.data,
                });
            }
            return response.data
        })
        .catch((error) => {
            dispatch({
                type: FORGOTPASSWORD,
                payload: true,
            });
            throw error?.response?.data?.message
        });
};

export const resetPassword = (password, token, id) => async (dispatch) => {
    console.log('password', password, id, token)
    let data = {
        method: METHOD_TYPE.post,
        url: `/api/reset-password/${token}/${id}`,
        data: { password: password }
    };
    return api(data)
        .then(async (response) => {
            if (response.status) {
                dispatch({
                    type: RESETPASSWORD,
                    payload: response.data,
                });
            }
            return response.data
        })
        .catch((error) => {
            dispatch({
                type: RESETPASSWORD,
                payload: true,
            });
            throw error?.response?.data?.message
        });
};

export const sendForgotpasswordOTP = (requestObj) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.send_forgotpassword_OTP,
        data: requestObj
    };
    return await api(data)
        .then(async (response) => {
            if (response.status) {
                return {
                    status: true,
                    result: response.data,
                };
            }
        }).catch((error) => {
            return {
                status: false,
                result: error?.response?.data
            };
        });
};
export const verifyForgotpasswordOTP = (requestObj) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.verify_forgotpassword_OTP,
        data: requestObj
    };
    return await api(data)
        .then(async (response) => {
            if (response.status) {
                return {
                    status: true,
                    result: response.data,
                };
            }
        }).catch((error) => {
            return {
                status: false,
                result: error?.response?.data
            };
        });
};
export const changePassword = (requestObj) => async () => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.forgot_password,
        data: requestObj
    };
    return await api(data)
        .then(async (response) => {
            if (response.status) {
                return {
                    status: true,
                    result: response.data,
                };
            }
        }).catch((error) => {
            return {
                status: false,
                result: error?.response?.data
            };
        });
};