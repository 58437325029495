import React from 'react';
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';
import "./loader.scss";

const Loader = (props) => {
  const { loaderText, spinLoading, textLoading } = props;

  return (
    <div className='page-loader'>
      {spinLoading && !loaderText &&
        <div className='loaderDive'>
          <CircularProgress />
        </div>
      }

      {loaderText &&
        <div className="textLoader">
          <h2>{loaderText}</h2>
          <div style={{ marginTop: 5 }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      }
    </div>
  );
}


export default Loader;

Loader.propTypes = {
  loaderText: PropTypes.string,
  textLoading: PropTypes.bool,
  spinLoading: PropTypes.bool,
};

Loader.defaultProps = {
  loaderText: "Please wait, it will take some time",
  spinLoading: true,
  textLoading: false,
};