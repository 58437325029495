import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, ButtonGroup, FormControl, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import './index.scss'
import Images from '../../constants/Images';
// import MockData from './data'
import Colors from '../../constants/Colors';
import { TextStyles } from '../../constants/TextStyles';
import UpskillrUtils from '../../utilities/UpskillrUtils';
import SkillMap from '../../components/ourFeatures/SkillMap';
import CurriculumBuilder from '../../components/curriculumBuilder/CurriculumBuilder';
import Tools from '../../components/tools/Tools';
import UpSkillrAssistant from '../../components/upSkillrAssistant/UpSkillrAssistant';
import MultiSearch from '../../components/multiSearch/MultiSearch';
import CareerPal from '../../components/carrerPal/CarrerPal';
import { connect,useDispatch, useSelector } from 'react-redux';
import { getCurriculumDetail } from './action';
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTools } from './action';
import Loader from '../../components/Loader';
import { getAllCurriculumBuilder, getCurriculamByUserId } from '../../components/curriculumBuilder/action';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabPanelBody tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
  children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const OurFeatures = (props) => {
    const [value, setValue] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState(0);
    const [courseId, setCourseId] = React.useState(null);
    const history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { height, width } = UpskillrUtils.useWindowDimensions();
    const [searchValue, setSearchValue] = useState(null);
    const [skillSearch, setSetskillSearch] = useState('')
    const dispatch = useDispatch();
    const toolsData = useSelector((state)=>state.toolReducer.toolDetail);
    const { loading, curriculumDeleted, error, suggestionList} = useSelector((state)=> state.CurriculumReducer);

    const handleChange = (event, newValue) => {
      setValue(newValue);
      setSearchParams({selectedTab:newValue})
      if(newValue === 1){
        dispatch(getCurriculamByUserId());
        dispatch(getAllCurriculumBuilder({ limit: 3, offset: 0 }));
      }
      // window.history.replaceState(null,"", `/our-features?selectedTab=${newValue}`);
      let CId = searchParams.get("courseId");
      if(CId) setCourseId(CId);
    };


    useEffect(()=>{
      let selectedTab = searchParams.get("selectedTab");
      if(selectedTab){
        setActiveTab(selectedTab);
        setValue(parseInt(selectedTab));
      }
      window.scrollTo(0, 0);
    },[searchParams]);

    const handleToolClick = (search) => {
      setValue(0)
      setSearchParams({selectedTab:0})
      setSetskillSearch(search)
    }

    return (
        <div className="OurFeatures">
           {loading && <Loader /> }
            <Box sx={{backgroundColor:Colors.lavender, display:'flex', justifyContent:'center' }}>
                <Tabs value={value} onChange={handleChange} className='tabBar'
                    variant="scrollable"
                    allowScrollButtonsMobile
                    scrollButtons="auto">
                    <Tab label="Skill Map" className='tabHeading' />
                    <Tab label="Curriculum Builder" className='tabHeading' />
                    <Tab label="Tools Advisory" className='tabHeading' />
                    <Tab label="Upskillr Assistant" className='tabHeading' />
                    <Tab label="Curated Writing" className='tabHeading' />
                    <Tab label="Career Pal" className='tabHeading' />
                </Tabs>
            </Box>
            {/* <div className='tabContentHeader'>
                <div className='tabContentHeaderBox'>
                  <h2 className='pageHeading'>Lorem Ipsum</h2>
                  <p className='tabContentSubHeading'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                  <div className='searchBoxArea'>
                    <Autocomplete
                      freeSolo
                      disableClearable
                      id="seachBox"
                      options={[]}
                      renderInput={(params) => (
                        <div style={{position:'relative', backgroundColor: '#FFFFFF', borderRadius: 16}}>
                          <img src={Images.SearchIcon} className='searchImg'/>
                          <TextField {...params} label="" placeholder='Input you skill here' classes={{root: 'placeholderSTyle',notchedOutline:'oulineNone'}} InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }} />
                        </div>
                      )}
                onInputChange={(event, newValue) => {
                  setSearchValue(newValue);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    searchTools()
                  }
                }}
                    />
                  </div>
                </div>
              </div> */}
            <TabPanel value={value} index={0} className="tabContentArea">
              <SkillMap search={skillSearch}  />
            </TabPanel>
            <TabPanel value={value} index={1} className="curriculumBuilderSection">
                <CurriculumBuilder curriculumDetail={props.curriculumDetail} width={width}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Tools toolsData={toolsData} handleClick={handleToolClick}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
               <UpSkillrAssistant/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <MultiSearch/>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <CareerPal/>
            </TabPanel>
            
        </div>
    )
}


export default OurFeatures;

const styles = {
  searchIconStyle:{width:24, height:24, position:'absolute', top:16, left:24}
}