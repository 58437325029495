import React, { useState, useEffect } from 'react'
import {Accordion} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReadMoreReact from 'read-more-react';
import Colors from '../../constants/Colors';

const McqReviewScreen = (props) => {
    const { mcqQA } = props;

    const infoTextColor = {
        correct : {
            borderColor : "#2FC08B",
            textColor : "#1D7454",
            backgroundColor : "#EBFBF5"
        },
        wrong : {
            borderColor : Colors.wrongAns,
            textColor : Colors.wrongAns,
            backgroundColor : Colors.wrongAnsBg
        },
        no : {
            borderColor : Colors.dark_gray,
            textColor : Colors.dark_gray,
            backgroundColor : Colors.dark_gray
        }
    }

    return (
        <>
            {
                mcqQA?.questions?.map((element, questionIndex) => (
                    <Accordion style={{ marginBottom: 12 }} key={'mcqQA_Mcq_Questions' + questionIndex}>
                        <Accordion.Item eventKey="0" className='mcq' style={{borderWidth:1.5, borderColor: (element.options.filter(obj => obj.user_selected === 'wrong')).length > 0 || ((element.options.filter(obj => obj.user_selected === 'right')).length != (element.options.filter(obj => obj.is_correct)).length) ? infoTextColor['wrong'].borderColor : ((element.options.filter(obj => obj.user_selected === null)).length === 4 ? infoTextColor['no'].borderColor: infoTextColor['correct'].borderColor)}}>
                            <Accordion.Header  >{element.title}</Accordion.Header>
                            <Accordion.Body>
                                <div style={{ width: '100%', height: 1.5, backgroundColor: (element.options.filter(obj => obj.user_selected === 'wrong')).length > 0 || ((element.options.filter(obj => obj.user_selected === 'right')).length != (element.options.filter(obj => obj.is_correct)).length) ? infoTextColor['wrong'].borderColor : ((element.options.filter(obj => obj.user_selected === null)).length === 4 ? infoTextColor['no'].borderColor: infoTextColor['correct'].borderColor), marginBottom: 20 }} />
                                {
                                    element?.options.map((item, i) => {
                                        return (
                                            <div key={'Mcq_Answers' + i + element.id}>
                                                <Form.Check
                                                    label={item.title}
                                                    name={'question'+i}
                                                    type={(element.multiple)?'checkbox':'radio'}
                                                    id={`${element?.multiple}-${i}`}
                                                    checked={(item.user_selected !== null) ? true : false}
                                                    readOnly
                                                    className={"custom-checkbox " + (item.is_correct ? 'rightAns' : 'wrongAns')}
                                                />
                                                {/* {item.is_correct &&
                                                    <div className='successOrError' style={{ width: '100%', padding: 12, backgroundColor:Colors.rightAnsBg, color: Colors.rightAns, borderRadius: 8, borderColor: Colors.rightAns, fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, marginBottom: 16, marginTop: -8 }}>
                                                        <ReadMoreReact text={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the indust Lorem Ipsum is simply dummy text of the printing and typesetting industry`}
                                                            min={3}
                                                            ideal={150}
                                                            max={1000}
                                                            readMoreText={`Read More...`} />
                                                    </div>
                                                } */}
                                            </div>
                                        )
                                    })
                                }
                                
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ))
            }
        </>
    );
}

export default McqReviewScreen;