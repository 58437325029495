import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { createHomeOverviews,updateHomeOverviews,getHomeOverviewsDetail } from "../action";
import { uploadImage } from "../../../action";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../utilities/constants";
import Sidebar from "../../../Components/Navigation/Sidebar";
import Topbar from "../../../Components/Navigation/Topbar";
import PageHeading from "../../../Components/PageHeading";
import { toast } from "react-toastify";
import { useTheme } from '@mui/material/styles';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import QuillTextEditor from "../../../../components/multiSearch/QuillTextEditor";

const label = { inputProps: { 'aria-label': 'controlled' } };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateHomeOverview = (props) => {
  const fileInputRef = useRef();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [formData, setFormData] = useState({ sensitiveContent: false });
  const { state } = useLocation();
  const [type, setType] = React.useState('');
  const theme = useTheme();
  const [videoSrc, setVideoSrc] = useState(null);
  const [title, setTitle] = useState('');
  const [subtitle, setSubTitle] = useState('');
  const [description, setDescription] = useState('');
  const [video_id, setVideoId] = useState('');


  useEffect(() => {
    if (state?.id) {
      props.getHomeOverviewsDetail(state.id);
      
    }
  }, [state?.id]);

  useEffect(() => {
    if(props.getDetails){
      setTitle(props.getDetails.title);
      setSubTitle(props.getDetails.subtitle);
      setDescription(props.getDetails.description);
      setVideoId(props.getDetails.video.id);
      setPreview(props.getDetails.video.media);
    }
  }, [props.getDetails]);


  // const videoRef = useRef();
  // useEffect(() => {
  //   videoRef.current?.load();
  // }, [videoSrc]);

  useEffect(() => {
    if (!videoSrc) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(videoSrc);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [videoSrc]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setVideoSrc(undefined);
      return;
    }
    setVideoSrc(e.target.files[0]);
  };
console.log('videoSrc',videoSrc)
  // const handleOnChange = (e) => {
  //   setFormDataDesc((prev) => ({
  //     ...prev,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  // const handleSubmit = async (e) => {
  //   try {
  //     e.preventDefault();
  //     const requestParam = { ...formDataDesc };
  //     console.log(requestParam);
  //   } catch (error) {
  //     toast.error(error || "Request failed !");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataItem = {
      title,
      subtitle,
      description,
    };
    if (!title|| !subtitle || !description || (!state?.id && !videoSrc )) {
      toast.error('All fields are required.');
      return;
    }
    if (state?.id) {
      let uploadedImage;
      if(videoSrc){
        let formData = new FormData();
        formData.append('media', videoSrc);
        uploadedImage = await props.uploadImage(formData)
      }
      
      props.updateHomeOverviews(formDataItem, state?.id, videoSrc ? uploadedImage.data.id : video_id)
          .then(res => {
            toast.success('HomeOverview updated successfully');
            navigate(`/admin/content/homeOverviews`);
          }).catch(error => {
            toast.error(error || "Error in add HomeOverview");
          });

    } else {
      // onAdd(newItem);
        let formData = new FormData();
        formData.append('media', videoSrc);
        props.uploadImage(formData)
          .then((res) => {
            const videoId = res.data.id;
            props.createHomeOverviews(formDataItem, videoId)
              .then(res => {
                toast.success('HomeOverview added successfully');
                navigate(`/admin/content/homeOverviews`);
              }).catch(error => {
                toast.error(error || "Error in add HomeOverview");
              });
          }).catch((error) => {
            console.log("Image Required");
          });

      } 
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  // const handleVideoChange = (event) => {
  //   const file = event.target.files[0];
  //   if (!file) return;
  //   setVideoSrc(URL.createObjectURL(file));
  // };

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content" className="overviewAddContent">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Add New"
                buttonName=""
                buttonLink=""
                buttonIcon=""
              />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form ref={formRef} method="post" onSubmit={handleSubmit}>
                    <div className="row">
                      {/* <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">Type</Form.Label>
                          <Select
                            displayEmpty
                            value={type}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em className="type_placeholder">Please select type...</em>;
                              }
                              return selected
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="form-control select-div"
                            name="type"
                          >
                            <MenuItem disabled value="">
                              <em>Please select type...</em>
                            </MenuItem>
                            <MenuItem value={'Curriculum Builder'} style={getStyles('name', type, theme)}>
                              Curriculum Builder
                            </MenuItem>
                            <MenuItem value={'Upskillr Assistant'} style={getStyles('name', type, theme)}>
                              Upskillr Assistant
                            </MenuItem>
                            <MenuItem value={'Mid Search Write'} style={getStyles('name', type, theme)}>
                              Mid Search Write
                            </MenuItem>
                            <MenuItem value={'CareerPal'} style={getStyles('name', type, theme)}>
                              CareerPal
                            </MenuItem>
                            <MenuItem value={'Our Chrome Extension'} style={getStyles('name', type, theme)}>
                              Our Chrome Extension
                            </MenuItem>
                          </Select>
                        </Form.Group>
                      </div> */}
                      <div className="form-group col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Title
                          </Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="Title"
                            name="title"
                            value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="form-group col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Sub title
                          </Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="SubTitle"
                            name="subtitle"
                            value={subtitle}
                            onChange={(e) => setSubTitle(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="form-group col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Description
                          </Form.Label>
                          {/* <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDescription(data);
                            }}
                            config={{
                              toolbar: {
                                items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'undo', 'redo'],
                              },
                            }}
                          /> */}
                          <QuillTextEditor
                            placeholder={""}
                            editorValue={description}
                            handleChange={(html) => setDescription(html)}
                          />
                        </Form.Group>
                      </div>
                      {(type && type === 'Our Chrome Extension') &&
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">Button link</Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="Button link"
                            name="buttonLink"
                            // onChange={handleOnChange}
                            />
                        </Form.Group>
                      </div>
                      }
                      {/* <div className="form-group col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">Video tagline</Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="Video Tagline"
                            name="tagline"
                            value={tagline}
                            onChange={(e) => setTagline(e.target.value)}
                          />
                        </Form.Group>
                      </div> */}
                      <div className="form-group col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">Video</Form.Label>
                        </Form.Group>
                        <div className="uploadVideo">
                          <video width='260' height='150' controls>
                          {videoSrc ? (
                          <source src={preview} />
                        ) : (
                          props.getDetails && (
                            <source src={`${props?.getDetails?.video?.media}`} />
                          )
                        )}
                          </video>
                        </div>
                      </div>
                      <div className="">
                        <Form.Group className="mb-3">
                          <a className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => fileInputRef.current.click()}>Upload Video</a>
                          <Form.Control
                            type="file"
                            className="form-control"
                            accept='video/mp4,video/x-m4v,video/*'
                            placeholder=""
                            onChange={onSelectFile}
                            ref={fileInputRef}
                            hidden
                          />
                        </Form.Group>
                      </div>
                      <div className=" mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ admingetDetailsReducer }) => {
  const { getDetails } = admingetDetailsReducer;
  console.log('getDetails',getDetails)
  return {
    getDetails,
  };
};

export default connect(mapStateToProps, {
  createHomeOverviews,uploadImage,updateHomeOverviews,getHomeOverviewsDetail
})(UpdateHomeOverview);

