import { API_ENDPOINTS,METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";
import Images from "../../../constants/Images";


export const GET_MEETOUTTEAM = "GET_MEETOUTTEAM";
export const ADD_MEETOURTEAM = "ADD_MEETOURTEAM";
export const UPDATE_MEETOURTEAM = "UPDATE_MEETOURTEAM";
export const DELETE_MEETOURTEAM = "DELETE_MEETOURTEAM";
export const GET_MEETOURTEAMDETAILS = "GET_MEETOURTEAMDETAILS";

export const getMeetOurTeam = (value) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: `${API_ENDPOINTS.get_meetourteam}`,
  };
  return api(data)
  .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
        if (response) {
        dispatch({
          type: GET_MEETOUTTEAM,
          payload: response,
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};
export const createMeetOurTeam = (teamData,id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.post,
    url: `${API_ENDPOINTS.get_meetourteam}`,
    data: {...teamData,image_id:id},
  };
  return api(data)
  .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
        if (response) {
        dispatch({
          type: ADD_MEETOURTEAM,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const deleteMeetOurTeam = (id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.delete,
      url: API_ENDPOINTS.get_meetourteam+id+"/",
    };
    return api(data)
    .then(async (response) => {
        return response.data;
      })
      .then(async (response) => {
        if (response) {
          dispatch({
            type: DELETE_MEETOURTEAM,
            payload: response,
          });
          getMeetOurTeam();
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
};
export const updateMeetOurTeam = (teamDetails,mainId, id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.put,
    url: API_ENDPOINTS.get_meetourteam+mainId+"/",
    data: {...teamDetails,image_id:id},
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: UPDATE_MEETOURTEAM,
          payload: response.data,
        });
      }
      return response;
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const getMeetOurTeamDetail = (id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: API_ENDPOINTS.get_meetourteam+id+"/",
  };
  return api(data)
    .then(async (response) => {
        return response;
      })
      .then(async (response) => {
          if (response) {
              dispatch({
                  type: GET_MEETOURTEAMDETAILS,
                  payload: response.data,
              });
          }
      return response;
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};






