import React from 'react'
import './index.scss'

function PrivacyPolicy() {
    return (
        <div className='container  privacyPolicy'>
            <section className='flexRow'>
                <div className='col-md-4 leftScroll'>
                    <h2>Table of content</h2>
                    <ul className='tabContent'>
                        <li><a href="#introduction">Introduction</a></li>
                        <li><a href="#cookies">Cookies</a></li>
                        <li><a href="#serviceProviders">Service Providers</a></li>
                        <li><a href="#european">GDPR</a></li>
                        <li><a href="#economic">EEA</a></li>
                        <li><a href="#california">California Notice of Collection</a></li>
                        <li><a href="#additional">Additional Disclosures for California Compliance</a></li>
                        <li><a href="#australianPrivacy">International Transfers of Personal Information</a></li>
                        <li><a href="#security">Security</a></li>
                        <li><a href="#otherSites">Other Sites</a></li>
                        <li><a href="#childrenPrivacy">Children’s Privacy</a></li>
                        <li><a href="#changesPrivacyPolicy">Changes to This Privacy Policy</a></li>
                        <li><a href="#contactUs">Contact Us</a></li>
                    </ul>
                </div>
                <div className='col-md-8'>
                    <div id="introduction" className='content'>
                        <h2>Privacy Policy of Upskillr Software Solutions Private Limited</h2>
                        <p>Welcome to Upskillr.ai! These Terms and Conditions and our Privacy Policy govern your use of our website located at www.upskillr.ai (the "Site") and the online platform services provided by Upskillr Software Solutions Pvt. Ltd ("Upskillr.ai").</p>
                        <p> By using our Service, you acknowledge and consent to the collection and use of your Personal Information as outlined in this Privacy Policy. The Personal Information we collect is utilized to provide and enhance the Service. We will not disclose or share your information with any third party except as described in this Privacy Policy.</p>
                        <p>The terminology used in this Privacy Policy holds the same meanings as stated in our Terms and Conditions, which can be accessed at www.upskillr.ai unless explicitly defined differently in this Privacy Policy.</p>
                    </div>
                    <div className='content'>
                        <div>
                            <h2>Information Collection and Use</h2>
                            <p>
                                To enhance your experience with our Service, we may request certain personally identifiable information, such as your name, phone number, and email address. The collected information will be utilized to contact and identify you.</p>
                            <p> Information we collect falls into one of two categories: 'voluntarily provided' information and 'automatically collected' information.
                                'Voluntarily provided' information refers to any information you knowingly and actively provide us when using or participating in any of our services and promotions.</p>
                            <p> 'Automatically collected' information refers to any information automatically sent by your devices in the course of accessing our products and services.
                            </p>
                        </div>
                        <div>
                            <h4>1.1 What personal data do we collect?</h4>
                            <p>
                                We collect the following personal data from you, referred to as the "collective data list":
                                <ul>
                                    <li>i) Personal identification information, such as name, email address, phone number, picture, designation within your organization, location, and other profile details.</li>
                                    <li>ii) Log data, which encompasses your computer's Internet Protocol address, browser version, pages of our Service that you visit, the time and date of your visit, and the duration spent on those pages.</li>
                                </ul>


                            </p>
                        </div>
                        <div>
                            <h4>1.2 How do we collect your personal data?</h4>
                            <p>
                                We primarily collect your personal data directly from you, as provided in the collectible data list. Your personal data is collected and processed when you:
                                <ul>
                                    <li>i) Register online for our Services, including our ChatGPT Plugin;</li>
                                    <li>ii) Use or access our website (www.upskillr.ai) through your browser's cookies.</li>
                                </ul>
                                Additionally, we may receive your personal data indirectly from your employer to deliver the Services as per the contractual arrangement established, either directly or indirectly.
                            </p>
                        </div>
                        <div>
                            <h4>1.3 How will we use your personal data?</h4>
                            <p>
                                We collect your personal data for the following purposes:
                                <ul>
                                    <li>(a) Providing, improving, and developing our services in accordance with the agreement between you and Upskillr.</li>
                                    <li>(b) Researching, designing, and launching new features or products to enhance your experience.</li>
                                    <li>(c) Sending you alerts, updates, materials, or information about our services based on your preferences and requests.</li>
                                    <li>(d) Collecting overdue amounts related to transactions and purchases.</li>
                                    <li>(e) Responding to legal proceedings or seeking professional advice for the legitimate interests of Upskillr or third parties.</li>
                                    <li>(f) Compliance with legal obligations.</li>
                                    <li>(g) Communicating with you and addressing your inquiries or requests.</li>
                                    <li>(h) Other purposes directly related to the above or for which you have given consent.</li>
                                </ul>

                                To ensure smooth operations, we may engage third-party companies and individuals in the following capacities:
                                <ul>
                                    <li>i) Facilitating the Services;</li>
                                    <li>ii) Providing the Services on our behalf;</li>
                                    <li>iii) Delivering support services related to the Services;</li>
                                    <li>iv) Assisting us in analyzing how our Services are used.</li>
                                </ul>

                                However, it is essential to note that we do not share your personal data with any third parties, and they do not have access to your personal data unless necessary under contractual or legal obligations to enable the Services available to you.</p>
                        </div>
                        <div>
                            <h4>1.4 How do we store your personal data?</h4>
                            <p>
                                We securely store your personal data in our servers located at respective regions of cloud service providers (AWS, Azure, and Google Cloud Platform). To render the Services as agreed under the contractual arrangement we may agree to transfer your personal data as per contractual terms and conditions.
                            </p>

                            <p> We have implemented industry-standard information security mechanisms to protect your personal data. We adopt the following security precautions to ensure the safety of your personal data:
                                <ul>
                                    <li>i) Industry standard security protocols for data communication</li>
                                    <li>ii) Data storage encryption standards</li>
                                    <li>iii) End-to-end data encryption in transit</li>
                                    <li>iv) Identity, Authentication, Authorization, Accounting, and Auditing standards</li>
                                    <li>v) Infrastructure and physical security standards</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <h4>1.5 How long we keep it?</h4>
                            <p>
                                We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. For example, if you have provided us with personal information as part of creating an account with us, we may retain this information for the duration your account exists on our system. If your personal information is no longer required for this purpose, we will delete it or make it anonymous by removing all details that identify you.
                            </p>

                            <p>However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.</p>
                        </div>
                        <div>
                            <h4>1.6 What are your data protection rights?</h4>
                            <p>
                                We want to ensure that you are fully aware of your data protection rights, which are as follows:
                                <ul>
                                    <li>i) The right to be informed: You have the right to request information about the personal data we collect, how it is used, how long it will be retained, and whether it will be shared with any third parties.</li>
                                    <li>ii) The right to access: You have the right to request copies of your personal data from us. A small fee may be charged for this service.</li>
                                    <li>iii) The right to rectification: You have the right to request corrections to any inaccurate or incomplete personal data we hold about you.</li>
                                    <li>iv) The right to erasure: You have the right to request the deletion of your personal data under certain conditions.</li>
                                    <li>v) The right to restrict processing: You have the right to request the restriction of the processing of your personal data under certain conditions.</li>
                                    <li>vi) The right to object to processing: You have the right to object to the processing of your personal data under certain conditions.</li>
                                    <li>vii) The right to data portability: You have the right to request the transfer of your personal data that we have collected from you to another organization or directly to you, under certain conditions.</li>
                                    <li>viii) The right related to automated decision-making, including profiling: You have the right to request information about our automated processing activities if you believe your personal data is being processed unlawfully.</li>
                                </ul>


                                If you make a request, we have 1 (one) month to respond to you. To exercise any of these rights, please contact us at our email: help@upskillr.ai.
                            </p>
                        </div>
                    </div>

                    <div id="cookies" className='content'>
                        <h2>Cookies</h2>
                        <p>
                            Cookies are small files containing a limited amount of data that are often used as anonymous unique identifiers. When you visit a website, these cookies are sent to your browser and stored on your computer's hard drive.
                            Our Service utilizes these "cookies" to collect information and enhance the user experience. You have the option to accept or decline these cookies and receive notifications when a cookie is being sent to your computer. We utilize cookies and technical information to personalize your Website visit, analyze website traffic, and monitor user trends, patterns, and selections for authorized downloads, as well as for technical reasons related to your use of our Website.
                            However, if you choose to decline our cookies, certain parts of our Service may not be accessible
                        </p>
                        <h4>2.1 How do we use cookies?</h4>
                        <p>
                            The Upskillr.ai website primarily uses "first-party cookies," which are cookies set and controlled by Upskillr.ai, not by any external organization or third party. However, for certain pages, you may need to accept cookies from external organizations or third parties to access them.
                        </p>
                        <h4>2.2 Why do we use cookies?</h4>
                        <p>
                            We utilize both first-party and third-party cookies for various purposes. "Essential" or "strictly necessary" cookies are necessary for the technical functioning of our Websites. Additionally, other cookies help us track and target user interests to enhance the experience on our Online Properties. Third parties may also serve cookies through our Websites for advertising, analytics, and other purposes. Please note that the specific types of cookies served and their purposes may vary depending on the specific Online Properties you visit. Further details are provided below.
                            Cookies can be either permanent (stored on your computer until deleted) or temporary (lasting only until you close your browser). We may store both temporary and permanent cookies on your computer/device to store certain non-sensitive data. You have the option to erase or block these cookies from your computer. Additionally, you can configure your browser to receive alerts when we attempt to send you a cookie, allowing you to choose to accept or refuse it. If you disable cookies, certain features of the Website may become inaccessible.
                        </p>
                    </div>


                    <div id="serviceProviders" className='content'>
                        <h2>Service Providers</h2>
                        <p>
                            For various reasons, we may engage third-party companies and individuals, including:
                            <ul>
                                <li>- Facilitating our Service</li>
                                <li>- Providing the Service on our behalf</li>
                                <li>- Performing Service-related services</li>
                                <li>- Assisting us in analyzing how our Service is used</li>
                            </ul>

                            It is important to note that these third parties do not have access to your Personal Information. We prioritize the protection of your information and ensure it remains confidential.

                        </p>
                    </div>

                    <div id="european" className='content'>
                        <h2>European Union General Data Protection Regulation (“GDPR”)</h2>
                        <p>
                        If you are a European resident, you have the right to access the personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please email us at help@upskillr.ai with the subject “GDPR Request” and please provide your specific request within the body of the email so we can efficiently accommodate your request.
                        </p>
                        <p>
                        Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you, or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe.
                        </p>
                    </div>

                    <div id="economic" className='content'>
                        <h2>International Transfers Outside of the European Economic Area (EEA)</h2>
                        <p>
                            We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or the use of binding corporate rules or other legally accepted means.
                        </p>
                    </div>

                    <div id="california" className='content'>
                        <h2>California Notice of Collection</h2>
                        <p>
                            We collect the following categories of personal information enumerated in the California Consumer Privacy Act:
                        </p>
                        <p>Identifiers, such as name, email address, phone number account name, IP address, and an ID or number assigned to your account.</p>
                    </div>

                    <div id="additional" className='content'>
                        <h2>Additional Disclosures for California Compliance (US)</h2>
                        <p>
                            Under California Civil Code Section 1798.83, if you live in California and your business relationship with us is mainly for personal, family, or household purposes, you may ask us about the information we release to other organizations for their marketing purposes.
                        </p>

                        <p>
                            To make such a request, please contact us using the details provided in this privacy policy with “Request for California privacy information” in the subject line. You may make this type of request once every calendar year. We will email you a list of categories of personal information we revealed to other organisations for their marketing purposes in the last calendar year along with their names and addresses. Not all personal information shared in this way is covered by Section 1798.83 of the California Civil Code.
                        </p>
                    </div>

                    <div id="australianPrivacy" className='content'>
                        <h2>Additional Disclosures for Australian Privacy Act Compliance (AU)
                            International Transfers of Personal Information</h2>
                        <p>
                            Where the disclosure of your personal information is solely subject to Australian privacy laws, you acknowledge that some third parties may not be regulated by the Privacy Act and the Australian Privacy Principles in the Privacy Act. You acknowledge that if any such third party engages in any act or practice that contravenes the Australian Privacy Principles, it would not be accountable under the Privacy Act, and you will not be able to seek redress under the Privacy Act.
                        </p>
                    </div>

                    <div id="security" className='content'>
                        <h2>Security</h2>
                        <p>
                            We highly value your trust in sharing your Personal Information with us, and we make every effort to safeguard it using commercially acceptable methods. However, it is essential to be aware that no method of transmission over the Internet or electronic storage can be guaranteed to be 100% secure and infallible. Therefore, we cannot assure absolute security for your information. Rest assured, we are committed to employing the best possible measures to protect your data.

                        </p>
                    </div>

                    <div id="otherSites" className='content'>
                        <h2>Links to Other Sites</h2>
                        <p>
                        Within our Service, you may find links to other websites. If you choose to click on a third-party link, you will be redirected to that site. It is essential to be aware that these external sites are independently operated and not under our control. As a result, we strongly recommend reviewing the Privacy Policy of these websites. We hold no authority over their content, privacy policies, or practices and assume no responsibility for any third-party sites or services. Your interactions with such sites are at your own discretion and risk.
                        </p>
                    </div>

                    <div id="childrenPrivacy" className='content'>
                        <h2>Children’s Privacy</h2>
                        <p>
                            Our Services are not intended for individuals under the age of 13. The Service complies with the rules of the Children’s Online Privacy Protect Act (“COPPA”). We do not knowingly collect personally identifiable information from children under 13. If we become aware that a child under 13 has provided us with personal information, we promptly delete it from our servers. If you are a parent or guardian and are aware that your child has provided us with personal information, please contact us so that we can take appropriate action. Your cooperation is appreciated in ensuring the protection of children's privacy online.
                        </p>
                    </div>

                    <div id="changesPrivacyPolicy" className='content'>
                        <h2>Changes to This Privacy Policy</h2>
                        <p>
                            Our Privacy Policy may undergo updates periodically. Therefore, we recommend that you check this page regularly for any changes. These changes become effective immediately after being posted on this page. Your continued use of our Service after any modifications indicates your acceptance of the updated Privacy Policy.
                        </p>
                    </div>

                    <div id="contactUs" className='content'>
                        <h2>Contact Us</h2>
                        <p>
                            If you have any inquiries or suggestions concerning our Privacy Policy, feel free to contact us at <a href='help@upskillr.ai'>help@upskillr.ai</a>. We are here to assist and address any concerns you may have.
                        </p>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy