import { API_ENDPOINTS,METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";
import Images from "../../../constants/Images";


export const GET_MEETOURCOFOUNDER = "GET_MEETOURCOFOUNDER";
export const ADD_MEETOURCOFOUNDER = "ADD_MEETOURCOFOUNDER";
export const UPDATE_MEETOURCOFOUNDER = "UPDATE_MEETOURCOFOUNDER";

export const getMeetOurCoFounder = () => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: `${API_ENDPOINTS.get_meetourteam}`,
  };
  return api(data)
    .then(async (response) => {
        console.log('response',response.data)
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: GET_MEETOURCOFOUNDER,
          payload: response,
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

  export const getUploadURL = (fileDetail) => async () => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.get_uploadUrl}?fileName=${fileDetail.name}&fileType=${fileDetail.type}`,
    };
    return api(data)
        .then(async (response) => {
            return response?.data?.result
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};

export const updateUploadURL = (url, file) => async () => {
    let data = {
        method: METHOD_TYPE.put,
        url: url,
        data: file,
        headers: {
            "Content-Type": "image/jpeg",
        },
    };
    return api(data)
        .then(async (response) => {
            // toast.success(response?.data?.message);
            // throw success?.response?.data?.message;
            return response.data
        })
        .catch((error) => {
            // toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
            throw error?.response?.data?.message;
        });
};

export const uploadAndUpdateImageTag = (payload) =>  async () => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.upload_and_update_image_tag,
        data : payload
    };
    return api(data)
        .then(async (response) => {
            return response.data;
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
}

export const getTagsByUploadImage = (file) => async () => {

    let formData = new FormData();
    formData.append('file', file);

    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.get_tags_by_upload_image,
        data: formData
    };
    
    return api(data).then(async (response) => {
        // toast.success(response?.data?.message);
        return response.data
    }).catch((error) => {
        throw error?.response?.data?.message;
    });
};
export const addContent = (content) => async () => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.add_content,
        data: content
    };
    return api(data)
        .then(async (response) => {
            // toast.success(response?.data?.message);
            return response.data
        })
        .catch((error) => {
            // toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};

export const updateAdminContent = (content) => async () => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.admin_update_content,
        data: content
    };
    return api(data)
        .then(async (response) => {
            // toast.success(response?.data?.message);
            return response.data
        })
        .catch((error) => {
            // toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};




