import Colors from '../../constants/Colors';
import VideoPlayer from '../../components/VideoPlayer';
import './index.scss'
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getOverview, } from '../homePage/action';


function Tutorial(props) {
    const [overview, setOverview] = useState([]);

    useEffect(() => {
        props.getOverview();
    }, []);

    useEffect(() => {

        if ((props.overviewData).length > 0) {
            setOverview(props.overviewData);
        }

    }, [props.overviewData]);
    return (
        <>
            <div className='tutorialSection'>
                <div className='tabContentHeader'>
                    <div className='tabContentHeaderBox'>
                        <h2 className='pageHeading'>Tutorial</h2>
                    </div>
                </div>
                {/* <div className='container cardStyle yellowCardStyle'>
                    <div className='flexRow mt80'>
                        <div className='textBoxArea'>
                            <div className='col-lg-6 col-sm-12'>
                                <h3 className='cardHeading h2Heading'>Curriculum Builder : Your AGI-accelerated LMS</h3>
                                <p className='txt_23_28'>Acquire promptly available, adaptable preliminary study resources for your degree program's syllabus or professional subject matter at any proficiency level across all domains, and share them with others.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <div className='videoPlayerDiv'>
                                <VideoPlayer />
                                <iframe src="https://drive.google.com/file/d/1OynvwfvUi2L2o1iEOkxm1oL1v1ni_3qs/preview" width="440" height="280" allow="autoplay"></iframe>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='container cardStyle yellowCardStyle'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <h3 className='cardHeading h2Heading'>Curriculum Builder : Your AGI-accelerated LMS</h3>
                            <p className='txt_23_28'>Acquire promptly available, adaptable preliminary study resources for your degree program's syllabus or professional subject matter at any proficiency level across all domains, and share them with others.</p>
                        </div>
                        <div className='col-lg-6 col-sm-12 d-flex justify-content-center'>
                            <div className='videoPlayerDiv'>
                                {/* <VideoPlayer /> */}
                                <iframe src="https://drive.google.com/file/d/1OynvwfvUi2L2o1iEOkxm1oL1v1ni_3qs/preview" width="340" height="180" allow="autoplay" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 
                <div className=' container cardStyle blueCardStyle'>
                    <div className='flexRow mt80 columnReverse'>
                        <div className='videoPlayerDiv'>
                            <VideoPlayer backgroundColor={Colors.lightCobaltBlue} />
                            <iframe src="https://drive.google.com/file/d/18ljPX2oyqBV_fSTnFyYBpLL84Rg2zzvj/preview" width="440" height="280" allow="autoplay"></iframe>
                        </div>
                        <div className='textBoxArea'>
                            <h3 className='cardHeading h2Heading ml131'>Upskillr Assistant : Smart-Info Retrieval Simplified</h3>
                            <p className='txt_23_28 ml131'>Obtain immediate responses and suggestions from any document, webpage, or video, and avoid spending numerous hours reading, scrolling, searching, and waiting.</p>
                        </div>
                    </div>
                </div> */}
                <div className='container cardStyle blueCardStyle'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <h3 className='cardHeading h2Heading'>Upskillr Assistant : Smart-Info Retrieval Simplified</h3>
                            <p className='txt_23_28'>Obtain immediate responses and suggestions from any document, webpage, or video, and avoid spending numerous hours reading, scrolling, searching, and waiting.</p>
                        </div>
                        <div className='col-lg-6 col-sm-12 d-flex justify-content-center'>
                            <div className='videoPlayerDiv'>
                                {/* <VideoPlayer /> */}
                                <iframe src="https://drive.google.com/file/d/18ljPX2oyqBV_fSTnFyYBpLL84Rg2zzvj/preview" width="340" height="180" allow="autoplay" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true"></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='container cardStyle yellowCardStyle' >
                    <div className='flexRow mt80'>
                        <div className='textBoxArea'>
                            <h3 className='cardHeading h2Heading'>Curated Writing : Smart Writing Begins Here</h3>
                            <p className='txt_23_28'>Compose educational or professional articles by leveraging your domain knowledge to determine the content and structure.</p>
                        </div>
                        <div className='videoPlayerDiv'>
                            <VideoPlayer />
                            <div className='video'>
                                <iframe src="https://drive.google.com/file/d/1pHGRXO63TNtnIc3AYO-T5WCjGaOzgD4K/preview" width="440" height="280" allow="autoplay"></iframe>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='container cardStyle yellowCardStyle'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <h3 className='cardHeading h2Heading'>Curated Writing : Smart Writing Begins Here</h3>
                            <p className='txt_23_28'>Compose educational or professional articles by leveraging your domain knowledge to determine the content and structure.</p>
                        </div>
                        <div className='col-lg-6 col-sm-12 d-flex justify-content-center'>
                            <div className='videoPlayerDiv'>
                                {/* <VideoPlayer /> */}
                                <iframe src="https://drive.google.com/file/d/1pHGRXO63TNtnIc3AYO-T5WCjGaOzgD4K/preview" width="340" height="180" allow="autoplay" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true"></iframe>
                            </div>
                        </div>
                    </div>
                </div>




                {/* <div className='container cardStyle blueCardStyle'>
                    <div className='flexRow mt80 columnReverse'>
                        <div className='videoPlayerDiv'>
                            <VideoPlayer backgroundColor={Colors.lightCobaltBlue} />
                            <iframe src="https://drive.google.com/file/d/12FNLrEHeZrZKoVJsszpRRbxcgLFu9gWL/preview" width="440" height="280" allow="autoplay"></iframe>
                        </div>
                        <div className='textBoxArea'>
                            <h3 className='cardHeading h2Heading ml131'>Career Pal: Your Career Catalyst</h3>
                            <p className='txt_23_28 ml131'>Unlock your potential with an all-in-one AI-powered companion that provides personalized support for tutoring, skill building, case studies, interview prep, and beyond.</p>
                        </div>
                    </div>
                </div> */}
                <div className='container cardStyle blueCardStyle'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <h3 className='cardHeading h2Heading'>Career Pal: Your Career Catalyst</h3>
                            <p className='txt_23_28'>Unlock your potential with an all-in-one AI-powered companion that provides personalized support for tutoring, skill building, case studies, interview prep, and beyond.</p>
                        </div>
                        <div className='col-lg-6 col-sm-12 d-flex justify-content-center'>
                            <div className='videoPlayerDiv'>
                                {/* <VideoPlayer /> */}
                                <iframe src="https://drive.google.com/file/d/12FNLrEHeZrZKoVJsszpRRbxcgLFu9gWL/preview" width="340" height="180" allow="autoplay" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true"></iframe>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}

const mapStateToProps = ({ homePageReducer }) => {
    const { overviewData } = homePageReducer;
    return {
        overviewData
    };
}

export default connect(mapStateToProps, {
    getOverview
})(Tutorial);

// export default Tutorial;