import $ from "jquery";
import './index.scss'
import React, { useEffect } from 'react';
import { getCredits } from './action';
import { connect, useSelector } from 'react-redux';

//jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Loader from '../../components/Loader';

function Credit(props) {

    const { creditDetailsSuccess, creditDetailsLoading, creditDetailsError } = useSelector((state) => state.creditsReducer);

    $(document).ready(function () {
        setTimeout(function () {
            $("#dataTable").DataTable( {
                destroy: true,
                "order": [],
                "ordering": false, 
            } 
            );
        }, 1000);
    });

    useEffect(() => {
        props.getCredits()
    }, []);


    return (
        <>
        {creditDetailsLoading && <Loader/>}
            {/* <ViewHeading
                buttonText={'Save Changes'}
                containerStyles={{ backgroundColor: '#CCE2FF', paddingTop: 18, paddingBottom: 18, marginBottom: 0 }}
                btnStyle2={{ color: '#004AAD', marginRight: 10, }}
                btnStyle={{ backgroundColor: '#004AAD', opacity: 0.4 }}
            /> */}
            <div className='FreePlanHeader'>
                <div className='tabContentHeader mb-4'>
                    <div className='tabContentHeaderBox'>
                        <h2 className='pageHeading'>Credits</h2>
                        <p className="headingDetails">Effortlessly track and view your credits with our intuitive Credits feature.</p>
                    </div>
                </div>
            </div>
            <div className='container FreePlan' >
                {/* <div className="row justify-content-center">
                    <div className="coloumPlan col-lg-3 d-flex " >
                        <div>
                            <h3>Curriculum Builder</h3>
                            <h1>0/2</h1>
                        </div>
                        <div>
                            <img src={Images.CurriculumBuilderIcon} style={{ width: 130, objectFit: 'contain' }} />
                        </div>
                    </div>
                    <div className="coloumPlan col-lg-3 d-flex " >
                        <div>
                            <h3>Curriculum Builder</h3>
                            <h1>0/2</h1>
                        </div>
                        <div>
                            <img src={Images.UpskillrAssistantIcon} style={{ width: 130, objectFit: 'contain' }} />
                        </div>
                    </div>
                    <div className="coloumPlan col-lg-3 d-flex " >
                        <div>
                            <h3>Curriculum Builder</h3>
                            <h1>0/2</h1>
                        </div>
                        <div>
                            <img src={Images.MultiSearchWritingIcon} style={{ width: 130, objectFit: 'contain' }} />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="coloumPlan col-lg-3 d-flex " >
                        <div>
                            <h3>Curriculum Builder</h3>
                            <h1>0/2</h1>
                        </div>
                        <div>
                            <img src={Images.CareerPalIcon} style={{ width: 130, objectFit: 'contain' }} />
                        </div>
                    </div>
                    <div className="coloumPlan col-lg-3 d-flex " >
                        <div>
                            <h3>Curriculum Builder</h3>
                            <h1>0/2</h1>
                        </div>
                        <div>
                            <img src={Images.ToolsAdvisoryIcon} style={{ width: 130, objectFit: 'contain' }} />
                        </div>
                    </div>

                </div> */}

                {/* {
                props.creditsData.length > 0 && (
                <div className="row justify-content-center">
                    {
                    props.creditsData.map((item, index) => (
                    <div className="coloumPlan col-lg-3 d-flex " >
                        <div>
                            <h3>{item.title}</h3>
                            <h1>{item.value}</h1>
                        </div>
                        <div>
                            <img src={item.image} style={{ width: 130, objectFit: 'contain' }} />
                        </div>
                    </div>
                    ))}
                </div>
                    )
                } */}

                <div className=' justify-content-center'>
                    <div className="coloumPlan" >
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <h2>Credits Left to Consume/ Total Credits Bought</h2>
                            <h3>{creditDetailsSuccess?.balance ? creditDetailsSuccess.balance.toFixed(6) : 0}/ {creditDetailsSuccess?.total_credits}</h3>
                        </div>

                    </div>

                    <div className="card-body ">
                        <div className="table-responsive">
                            <table
                                className="table table-bordered"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead className='creditTableHead'>
                                    <tr>
                                        <th className="text-capitalize">Feature</th>
                                        <th className="text-capitalize">Model</th>
                                        <th className="text-capitalize">Credits</th>
                                        <th className="text-capitalize">Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        creditDetailsSuccess && creditDetailsSuccess['credit_transactions'].map((obj, index) => {
                                            return (
                                                <tr>
                                                    <td className="text-capitalize">{obj.comments}</td>
                                                    <td className="text-capitalize">{obj.model_name}</td>
                                                    <td className="text-capitalize">{obj.credits ?  (obj.credits).toFixed(6) : 0 }</td>
                                                    <td className="text-capitalize">{obj.type}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            {/* <div className="button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 40, marginTop: 30 }}>
                <Button style={{ backgroundColor: '#004AAD', color: 'white', textTransform: 'capitalize' }} className='white' >{`Buy Credits`}</Button>
            </div> */}
        </>
    );
}


const mapStateToProps = ({ creditsReducer }) => {
    const { creditDetailsSuccess } = creditsReducer;
    return {
        creditDetailsSuccess
    };
}

export default connect(mapStateToProps, {
    getCredits
})(Credit);

// export default FreePlan;