const Colors = {
    primary: '#ea7713',
    text_color: '#323A46',
    text_color_blue: '#00439D',
    text_color_dark: '#303030',
    textLight: '#858585',
    textLighter: '#c3c3c3',
    textRed: '#fb501e',
    textGreen: '#008500',
    textWhite: '#ffffff',
    gray: '#7a7a7a',
    light_gray: '#b5b5b5',
    lighter_gray: '#e7e7e7',
    lightest_grey: '#e9e9e9',
    dark_gray: '#5d5d5d',
    darkest_gray: '#aeaeae',
    btn_color_red: '#fb501e',
    btn_color_light_red: '#fb8058',
    btn_color_lighter_red: '#fdb99b',
    modal_background_color: '#FFFFFF',
    modal_highlight_color: '#d8d8d8',
    section_bg_color: '#eeeeee',
    violet: '#8d8bf2',
    dark_blue:'#001F49',
    border_light_blue:'#CCE2FF',
    border_light_yellow:'#FFE59E',
    textBlack:'#000',
    background_light_yellow:'#FFF1CB',
    background_light2_yellow:'#FFFCF2',
    background_light_blue:'#F2F8FF',
    linkdein_background_color:'#EAF5FF',
    light_white_background:'#F5FAFF',
    lightCobaltBlue:'#CCE2FF',
    lavender:'#E6EDF7',
    babyBlueEyes:'#A4CBFF',
    blue:'#00439D',
    borderColor:'#E7EAEE',
    cadetBlue:'#A0ABBB',
    viewAll:'#004AAD',
    text_dark_title:'#212121',
    rightAns:"#2FC08B",
    rightAnsBg:"#EBFBF5",
    wrongAns:"#C82323",
    wrongAnsBg:"#FCE9E9",
};
export default Colors;
