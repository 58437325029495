import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import './index.scss';

const QuillTextEditor = ({ placeholder, editorValue, handleChange, quillRef, disableEditor}) => {

    return (
        <div className="textEditor">
            <ReactQuill
            ref={quillRef}
                className="custom-editor"
                theme={'snow'}
                onChange={handleChange}
                value={editorValue}
                modules={QuillTextEditor.modules}
                placeholder={placeholder}
                readOnly={(typeof disableEditor != 'undefined')?disableEditor:false} 
            />
        </div>
    );
};

QuillTextEditor.modules ={
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { align: [] },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        [{ color: [] }, { background: [] }],
    ],
    clipboard: {
        matchVisual: false,
    },
};

QuillTextEditor.propTypes = {
    placeholder: PropTypes.string,
};

export default QuillTextEditor;
