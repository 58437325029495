import React, { useState } from 'react';
import Slider from 'react-slick';
import PropTypes from "prop-types";
import "./slider.scss";

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 780,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                autoplay: true,
                speed: 3000
            }
        },
    ]
};


function CardSlider(props) {
    const { children } = props;

    return (
        <div className='cardSlider'>
            <Slider
            {...settings}>
                {children}
            </Slider>
        </div>
    );
}

export default CardSlider;

CardSlider.propTypes = {
    children: PropTypes.element,
  };