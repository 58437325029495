import { API_ENDPOINTS, METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";
import Images from "../../../constants/Images";


export const GET_KEY_FEATURE_LIST = "GET_KEY_FEATURE_LIST";
export const ADD_KEY_FEATURE = "ADD_KEY_FEATURE";
export const UPDATE_KEY_FEATURE = "UPDATE_KEY_FEATURE";
export const DELETE_KEY_FEATURE = "DELETE_KEY_FEATURE";
export const GET_KEY_FEATURE_DETAILS = "GET_KEY_FEATURE_DETAILS";

export const getKeyFeatures = (value) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.get_keyFeature}`,
    };
    return api(data)
        .then(async (response) => {
            return response;
        })
        .then(async (response) => {
            if (response) {
                console.log('response',response.data)
                dispatch({
                    type: GET_KEY_FEATURE_LIST,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};
export const deleteKeyFeatures = (id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.delete,
        url: `${API_ENDPOINTS.get_keyFeature+id+"/"}`,
    };
    return api(data)
    .then(async (response) => {
        return response;
    })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: DELETE_KEY_FEATURE,
                    payload: response.data,
                });
                getKeyFeatures();
            }
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};
export const createKeyFeatures = (keyFeatureData,id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.get_keyFeature}`,
        data:{...keyFeatureData,image_id:id}
    };
    return api(data)
    .then(async (response) => {
        return response;
    })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: ADD_KEY_FEATURE,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};

export const updateKeyFeatures = (keyFeatureDetails,mainId, id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.put,
      url: API_ENDPOINTS.get_keyFeature+mainId+"/",
      data: {...keyFeatureDetails,image_id:id},
    };
    return api(data)
      .then(async (response) => {
        return response.data;
      })
      .then(async (response) => {
        if (response) {
          dispatch({
            type: UPDATE_KEY_FEATURE,
            payload: response.data,
          });
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };

  export const getKeyFeaturesDetail = (id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.get_keyFeature+id+"/",
    };
    return api(data)
      .then(async (response) => {
          return response;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: GET_KEY_FEATURE_DETAILS,
                    payload: response.data,
                });
            }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };
  


