import { GET_MEETOURCOFOUNDER,ADD_MEETOURCOFOUNDER,UPDATE_MEETOURCOFOUNDER } from "./action";

const initialState = {
  meetOurCoFounderList: null,
  createMEETOURCOFOUNDER: null,
  updateMEETOURCOFOUNDERList: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEETOURCOFOUNDER: {
      return {
        ...state,
        meetOurCoFounderList: action.payload,
      };
    }
    case ADD_MEETOURCOFOUNDER: {
      return {
        ...state,
        createMEETOURCOFOUNDER: action.payload,
      };
    }
    case UPDATE_MEETOURCOFOUNDER: {
      return {
        ...state,
        updateMEETOURCOFOUNDERList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
