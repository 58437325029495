import React, { useRef, useState } from 'react'
import '../upSkillrAssistant/index.scss'
import { useCollapse } from 'react-collapsed'
import MultiSearchLeftCard from './MultiSearchLeftCard'
import TextEditor from './TextEditor'
import QuillTextEditor from './QuillTextEditor'
import { generateArticle } from './action'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Loader from '../Loader';
import { useEffect } from 'react'
import { saveAs } from 'file-saver';
import {pdfExporter} from 'quill-to-pdf';
import * as quillToWord from 'quill-to-word';
import htmlDocx from 'html-docx-js/dist/html-docx';
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfmake = require("html-to-pdfmake");

function MultiSearch() {
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editorHtml, setEditorHtml] = useState(null);
    const [showGenerateBtn, setShowGenerateBtn] = useState(true);
    const dispatch = useDispatch();
    const { generateArticleSuccess, generateArticleLoading, generateArticleError } = useSelector((state)=> state.multiSearchReducer);
    const quillRef = useRef(null);

    useEffect(()=>{
        if(generateArticleSuccess){
            setEditorHtml(generateArticleSuccess);
            setShowGenerateBtn(false)
        }
    },[generateArticleSuccess])


    // Define a function to update editorHtml
    const updateEditorHtml = () => {
        setShowGenerateBtn(true);
        setEditorHtml(null);
        dispatch({
            type: 'GENERATE_ARTICLE_SUCCESS',
            payload: null
        });
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubmission = (data) => {       
        dispatch(generateArticle(data)).catch(err=>{
            console.log("Error", err);
            toast.error(err);
        });
    }

    const download = async (type) => {
        const quill = quillRef.current.getEditor();
        const delta = quill.getContents(); // Get the Delta object
        debugger;
        if (type === 'docx') {
            // const converted = htmlDocx.asBlob(modifiedHtmlContent);        
            // const url = URL.createObjectURL(converted);
            // const link = document.createElement('a');
            // link.href = url;
            // link.download = 'document.docx';
            // link.click();
            const quillToWordConfig = {
                exportAs: 'blob'
            };
            const docAsBlob = await quillToWord.generateWord(delta, quillToWordConfig);
            saveAs(docAsBlob, 'document.docx');
        }

        if (type === 'pdf') {
            // Modify the HTML content to include width and height attributes for images
            const modifiedPdfHtmlContent = editorHtml.replace(/<img/g, '<img style="min-width:400px;max-width:700px" '); // Adjust width and height as needed
            var val = htmlToPdfmake(modifiedPdfHtmlContent);
            var dd = { content: val };
            pdfMake.createPdf(dd).download();

            // const pdfBlob = await pdfExporter.generatePdf(delta);
            // adjustImageSizesInDelta(delta, 800, 800);

            // saveAs(pdfBlob, 'quill_content.pdf');
        }
    }

    return (
        <>
            {(generateArticleLoading) && <Loader textLoading loaderText={"Crafting your Article with AI. ⏳ Please wait up to 5 minutes. Do not close the tab or log out."}/>}
            <div className='container-fluid upSkillrAssistant leftViewMcq'>
                <div className='row '>
                    <div className='col-md-4 leftView' style={{ backgroundColor: '#F7F8F9', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <MultiSearchLeftCard  editorHtml={editorHtml} showGenerateBtn={showGenerateBtn} resetEditor={updateEditorHtml} handleSubmission={handleSubmission} download={download}/>
                    </div>

                    <div className='col-md-8 rightView'>
                    {/* <TextEditor /> */}
                    <QuillTextEditor
                    quillRef={quillRef}
                    placeholder={"Fill up the information in the left bar to generate curated article...."}
                    editorValue={(editorHtml)?editorHtml.replace(/\n/g, '<br />'):''}
                    handleChange={(html)=>setEditorHtml(html)}
                    />
                    </div>
                </div>
            </div>
        </>
    )
}
export default MultiSearch;