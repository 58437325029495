import { GET_MARKETPLACE_LIST, ADD_MARKETPLACE, UPDATE_MARKETPLACE,DELETE_MARKETPLACE,GET_MARKETPLACE_DETAILS } from "./action";

const initialState = {
  arrayList: null,
  createData: null,
  updateData: null,
  deleteData: null,
  getDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKETPLACE_LIST: {
      return {
        ...state,
        arrayList: action.payload,
      };
    }
    case ADD_MARKETPLACE: {
      return {
        ...state,
        createData: action.payload,
      };
    }
    case UPDATE_MARKETPLACE: {
      return {
        ...state,
        updateData: action.payload,
      };
    }
    case DELETE_MARKETPLACE: {
      return {
        ...state,
        deleteData: action.payload,
      };
    }
    case GET_MARKETPLACE_DETAILS: {
      return {
        ...state,
        getDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
