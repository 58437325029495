import React from 'react'
import { Navigate, useNavigate, useLocation } from "react-router-dom";
// import { responseInterceptor } from './api';
import { store } from '../..';

const PrivateRoute = ({
    component: Component,
}) => {
    const history = useNavigate();
    const location = useLocation();
    const isAdmin = location.pathname.includes("/admin")
    // responseInterceptor(history, store)
    const isAdminAuthenticated = localStorage.getItem('isAdmin');
    const isUserAuthenticated = localStorage.getItem('user');

    const currentLocation = location.pathname + location.search;

    if (isAdminAuthenticated || isUserAuthenticated) {
        if(isAdmin && isAdminAuthenticated){
            return <Component />
        }else if(!isAdmin && isUserAuthenticated){
            return <Component />
        }else{
            return <Navigate
                to={isAdmin ? "/admin/login" : "/login"}
                replace
                state={{ from: currentLocation }}
            />
        }
    } else {
        return <Navigate
            to={isAdmin ? "/admin/login" : "/login"}
            replace
            state={{ from: currentLocation }}
        />
    }
}

export default PrivateRoute;