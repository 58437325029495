import React, { useEffect, useState } from 'react';
import "../VisionMission/VisionMission.css"
import Colors from '../../constants/Colors';

function VisionMission(props) {
    const { vision } = props;
    const [index, setIndex] = useState(0);
    const [discription, setdiscription] = useState();
    const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering((prev => !prev));
  };
  const handleMouseOut = () => {
    setIsHovering((prev => !prev));
  };

  useEffect(() => {
    setdiscription(vision[0]?.desc)
  },[vision])
console.log('HOVERRRRR',isHovering,index)

    return (
        <>
        {/* {    
        vision && vision.length > 0 ? ( */}
            <div className='VisionMissionTab' style={{ backgroundColor: Colors.text_color_white }}>
                <div className='VisionMission'>
                    {/* {
                        vision.map((item, idx) => (

                    <div className='VisionMissionContainer'
                        onMouseEnter={() => { setIndex(idx); setdiscription(item.desc) }}>
                        <p className={`VisionMissionTitle ${index === idx ? 'VisionMissionTitle_active' : null}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{item.title}</p>
                        <div className='tabcontentMobile'>{item.desc}</div>
                    </div>
                        ))
                    } */}
                    <div className='VisionMissionContainer'
                        onMouseEnter={() => { setIndex(0) }}>
                        <p className={`VisionMissionTitle ${index === 0 ? 'VisionMissionTitle_active' : null}`}  onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Vision</p>
                        <div className='tabcontentMobile'>Navigating the future with AGI-powered, transformative learning.
                        </div>
                    </div>
                    <div className='VisionMissionContainer'
                        onMouseEnter={() => { setIndex(1) }}>
                        <p className={`VisionMissionTitle ${index === 1 ? 'VisionMissionTitle_active' : null}`}  onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Commitment</p>
                        <div className='tabcontentMobile'>Unleashing innovative, personalized learning solutions for holistic success.
                        </div>
                    </div>
                    <div className='VisionMissionContainer'
                        onMouseEnter={() => { setIndex(2) }}>
                        <p className={`VisionMissionTitle ${index === 2 ? 'VisionMissionTitle_active' : null}`}  onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Goal</p>
                        <div className='tabcontentMobile'>Leading the charge in edtech evolution for empowering lifelong learning and career progress.
                        </div>
                    </div>
                </div>
                <div className='VisionMissionDetails'>
                    {(isHovering || !isHovering) && index == 0 &&<div className='tabcontent' >Navigating the future with AGI-powered, transformative learning.</div>}
                    {(isHovering || !isHovering) && index == 1 && <div className='tabcontent' >Unleashing innovative, personalized learning solutions for holistic success.</div>}
                    {(isHovering || !isHovering) && index == 2 && <div className='tabcontent' >Leading the charge in edtech evolution for empowering lifelong learning and career progress.</div>}
                </div>

            </div>
        {/* ) : null
        } */}
        </>
    );
}

export default VisionMission;