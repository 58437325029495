
import { toast } from "react-toastify";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";

export const SUBSCRIPTION_LIST = "SUBSCRIPTION_LIST";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const GET_SUBSCRIPTION_DETAIL = "GET_SUBSCRIPTION_DETAIL";

export const getSubscriptions = () => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.get_subscriptions,
    };
    return api(data)
        .then(async (response) => {
            return response
        }).then(async (response) => {
            if (response.status) {
                dispatch({
                    type: SUBSCRIPTION_LIST,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};
export const createSubscription = (bodyData) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.get_subscriptions,
        data: bodyData,
    };
    return api(data)
        .then(async (response) => {
            return response
        }).then(async (response) => {
            if (response.status) {
                dispatch({
                    type: ADD_SUBSCRIPTION,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};

export const deleteSubscription = (id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.delete,
      url: API_ENDPOINTS.get_subscriptions+id+"/",
      // data: { id: id},
    };
    return api(data)
    .then(async (response) => {
        return response;
      })
      .then(async (response) => {
        if (response.status) {
          dispatch({
            type: DELETE_SUBSCRIPTION,
            payload: response.data,
          });
          getSubscriptions();
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };
  export const updateSubscriptionDetails = (subscriptionData, id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.put,
      url: API_ENDPOINTS.get_subscriptions+id+"/",
      data: subscriptionData,
    };
    return api(data)
      .then(async (response) => {
        return response.data;
      })
      .then(async (response) => {
        if (response.status) {
          dispatch({
            type: UPDATE_SUBSCRIPTION,
            payload: response.data,
          });
        }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };

  export const getSubscriptionDetail = (id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.get_subscriptions+id+"/",
    };
    return api(data)
      .then(async (response) => {
          return response?.data;
        })
        .then(async (response) => {
            
            if (response) {
                dispatch({
                    type: GET_SUBSCRIPTION_DETAIL,
                    payload: response,
                });
            }
        return response;
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
  };
  