import React, { useState } from 'react';
import "../meetourteam/TeamCard.css"
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';


function TeamCard(props) {
    const { name, description, teamImage, backgroundColorImage, borderColor } = props
    return (
        <>
            <div className="col blogsCard ">
                <div className='TeamCardContainer mb-3' style={{ backgroundColor: Colors.text_color_white, borderColor: borderColor, borderRadius: 24, alignItems: 'center', justifyContent: 'center', padding: 16, }}>
                    <div style={{ position: 'relative', width: '100%', height: 220 }}>
                        <img src={backgroundColorImage} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 220 }} />
                        <img src={teamImage} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 220,objectFit:'contain' }} />
                    </div>
                    <div className="card-body blogs-text-part text-center pb-0">
                        <h3 style={{ fontSize: 20,marginBottom:0 }}>{name}</h3>
                        <p className="card-text text-center" style={{ padding: 0 }}>{description}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TeamCard;