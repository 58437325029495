import React, { useState } from 'react'
import Images from '../../constants/Images';
import { Menu, MenuItem, Button } from '@mui/material';
import McqsEdit from './McqsEdit';

function McqsQAEdit(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <div>
                            <McqsEdit /> 
                            <McqsEdit /> 
                            <McqsEdit /> 
                            <McqsEdit /> 
                            <McqsEdit /> 
                            <McqsEdit /> 
                            <McqsEdit /> 
                            <McqsEdit /> 

                            <div className='rightSection'>
                                <Button className='AddnewButton ' aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}>{`+ Add New Question`} </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>
                                        <img src={Images.ReviewIcon} style={{ width: 16, height: 16, marginRight: 8 }} />Review
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <img src={Images.RoundClose} style={{ width: 16, height: 16, marginRight: 8 }} />Close
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
        </>
    );
}

export default McqsQAEdit;