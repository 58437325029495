import React from 'react'
import './index.scss'

function TermsOfService() {
    return (
        <div className='container privacyPolicy'>
            <section className='flexRow'>
                <div className='col-md-4 leftScroll'>
                    <h2>Table of content</h2>
                    <ul className='tabContent'>
                        <li><a href="#introduction">Introduction</a></li>
                        <li><a href="#registration">Registration as a User; Your Account</a></li>
                        <li><a href="#licenses">Licenses</a></li>
                        <li><a href="#billing">Billing, Payment, And Other subscriptions/plans</a></li>
                        <li><a href="#illegalBehavior">Online Conduct And Illegal Behavior</a></li>
                        <li><a href="#thirdParty">Third-Party Content</a></li>
                        <li><a href="#generatedContent">User-Generated Content</a></li>
                        <li><a href="#ownership">Ownership Of And License To Use The Services</a></li>
                        <li><a href="#disclaimers">Disclaimers And Liability Provisions</a></li>
                        <li><a href="#amendments">Amendments To This Agreement</a></li>
                        <li><a href="#term">Term And Termination</a></li>
                        <li><a href="#miscellaneous">Miscellaneous</a></li>
                        <li><a href="#refundPolicy">Refund Policy</a></li>
                        <li><a href="#privacyPolicy">Privacy Policy</a></li>
                        <li><a href="#cookiesPolicy">Cookies Policy</a></li>
                        <li><a href="#changesTerms">Changes to This Terms of Service</a></li>
                        <li><a href="#contactUs">Contact Us</a></li>

                    </ul>
                </div>
                <div className='col-md-8'>
                    <div id="introduction" className='content'>
                        <h2>Terms and Conditions Agreement</h2>
                        <p>This is a legally binding document that outlines your rights and responsibilities as a user of the Upskillr Application provided by Upskillr Software Solutions Pvt Ltd. (referred to as the "Company").</p>
                        <h2>Upskillr.ai Application and Services</h2>
                        <p>The Upskillr.ai Application is an online service offered by the Company. By accessing or using the Website, the App, or any other platform linked to the Services, registering an account, or accessing any content, information, services, features, or resources available through the Services (collectively known as the "Services"), you agree to be bound by this Agreement.</p>
                        <p>Additionally, you confirm that you are of legal age in your jurisdiction of residence to enter into a binding contract and have the authority to agree to this Agreement personally and, if applicable, on behalf of any company, organization, or legal entity.</p>
                        <p>If you do not agree with this Agreement, you are not permitted to access or use the Services.</p>
                    </div>
                    <div className='content'>
                        <div id="registration">
                            <h2>Registration as a User; Your Account</h2>
                            <p>
                                To become a user ("User") of the Services, you must complete the registration process and create an account ("Account"). By completing the registration and accepting these terms, you signify your agreement to be bound by this Agreement.
                            </p>
                            <p>
                                You must be at least 13 years old to become a User. The Services are not intended for individuals under the age of 13, and the Company will not knowingly collect personal information from such individuals.
                            </p>
                            <p>
                                When registering an Account, you must provide accurate and current information in the registration form (the "Registration Data"). You agree to keep your Registration Data up-to-date. The Registration Data may include personally identifiable information like your email address, name, phone number, postal address, and other relevant information. Your submission of Registration Data is subject to the Company's Privacy Policy, which you are required to read before using the Services.
                            </p>
                            <p>
                                You are responsible for all activities that occur under your Account. You agree not to share your Account or password with anyone and to notify the Company immediately of any unauthorized use of your Account. Additionally, you must ensure that minors and unauthorized users do not access or use your Account.
                            </p>
                        </div>
                        <div>
                            <h4>A. Contracting Party</h4>
                            <p>
                                Your contractual relationship for any interactions with the Services is with the Company. Any transactions on the Services are made directly with the Company unless otherwise indicated at the time of the transaction.
                            </p>
                        </div>
                        <div>
                            <h4>B. Subscriptions; Content and Services</h4>
                            <p>
                                As a User, you may access certain services, software, and content available through the Services. The services, software, content, and updates downloaded or accessed via the Services are collectively referred to as "Content and Services." Each Subscription allows you access to specific Content and Services, and some Subscriptions may have additional specific terms ("Subscription Terms"). Your acceptance of this Agreement and any Subscription Terms is binding when you use the Services.
                            </p>
                        </div>
                        <div>
                            <h4>C. Your Account</h4>
                            <p>
                                Your Account may contain billing information related to Subscriptions, Content and Services, and other purchases. You must keep your login and password confidential and are responsible for the security of your Account and computer system. The Company is not liable for any unauthorized use of your Account or password, except where the Company's negligence or fault is involved. If you suspect any compromise of your login or password, you must promptly notify the Company.
                            </p>
                            <p>
                                You may not sell or transfer your Account or Subscriptions to others, except as expressly permitted by this Agreement or the Company.
                            </p>
                        </div>
                        <div>
                            <h4>D. Payment Processing</h4>
                            <p>
                                Payment processing for Content and Services and plans purchased through the Services is performed by the Company or its affiliates.
                            </p>
                        </div>
                        <div>
                            <h4>E. Tokens</h4>
                            <p>
                                Tokens are utility tokens issued by the Company for use within the Services as a means of payment. Tokens may be issued to members monthly based on the membership plan, and additional tokens may be purchased from the Company. Tokens carry no ownership or other rights except for accessing the Services. They are not financial instruments and do not confer any ownership, security, or investment rights.
                            </p>
                        </div>
                    </div>

                    <div id="licenses" className='content'>
                        <h2>Licenses</h2>
                        <div>
                            <h4>A. General Content and Services License</h4>
                            <p>
                                By accepting this Agreement, you receive a non-exclusive license to use the Content and Services for personal, non-commercial purposes, subject to the termination of this Agreement or the associated Subscription. Content and Services are licensed, not sold, and you have no ownership rights
                            </p>
                        </div>
                        <div>
                            <h4>B. License to Use the Company’s Content in Derivative Work</h4>
                            <p>
                                You may incorporate Company content into your Derivative Work, but solely for personal purposes. You must obtain the necessary rights for any third-party content in your Derivative Work.
                            </p>
                        </div>
                        <div>
                            <h4>C. Ownership of Content and Services</h4>
                            <p>
                                All title, ownership rights, and intellectual property rights in the Content and Services are owned by the Company and its affiliates. The Content and Services are protected by copyright laws and other applicable laws.
                            </p>
                        </div>
                        <div>
                            <h4>D. Restrictions on Use of Content and Services</h4>
                            <p>
                                You may use the Content and Services only for permitted access and personal, except as expressly allowed in this Agreement or applicable Subscription Terms. You may not copy, reproduce, distribute, modify, or exploit the Content and Services without the Company's prior written consent. Additionally, you may not sell or transfer reproductions of the Content and Services or use the Services to emulate or redirect communication protocols without the Company's consent.
                            </p>
                            <p>Sure, here's the provided text in plain text format:</p>
                        </div>
                    </div>


                    <div id="billing" className='content'>
                        <h2>Billing, Payment, And Other subscriptions/plans</h2>
                        <p>
                            You agree to pay all fees or charges to your Account in accordance with the fees, charges and billing terms in effect at the time a fee or charge is due and payable. You also agree to pay all applicable taxes. You must provide the Company with valid payment information in connection with your orders.
                            By providing the Company with your payment information, you agree that:


                            <ul>
                                <li>(i) The Company is authorized to immediately invoice your Account for all fees and charges due and payable to the Company hereunder</li>
                                <li>(ii) the Company is authorized to share any payment information and instructions required to complete the payment transactions with its third-party payment service providers (e.g., credit card transaction processing, merchant settlement, and related services)</li>
                                <li>(iii) no additional notice or consent is required for the foregoing authorizations.</li>
                            </ul>

                            You agree to immediately notify the Company of any change in your payment information. The Company reserves the right at any time to change its prices and billing methods. If payment cannot be charged to your payment card or your payment is returned for any reason, the Company reserves the right to either suspend or terminate your access to the paid-for services
                        </p>
                        <div>
                            <h4>A. Payment Authorization</h4>
                            <p>
                                When you provide payment information to the Company or to one of its payment processors, you represent to the Company that you are the authorized user of the card, PIN, key or account associated with that payment, and you authorize the Company to charge your credit card or to process your payment with the chosen third-party payment processor for any Subscription, Product or other fees incurred by you. The Company may require you to provide your address or other information in order to meet its obligations under applicable tax law.
                            </p>
                            <p>
                                For Subscriptions subject to automatic renewal after an agreed usage period, where recurring payments are made in exchange for continued use ("Recurring Payment Subscriptions"), by continuing to use the Recurring Payment Subscription you agree and reaffirm that the Company is authorized to charge your credit card, or to process your payment with any other applicable third-party payment processor, for any applicable recurring payment amounts. If you have purchased any Recurring Payment Subscriptions, you agree to notify the Company promptly of any changes to your credit card account number, its expiration date and/or your billing address, or your PayPal or other payment account number, and you agree to notify the Company promptly if your credit card or PayPal or other payment account expires or is canceled for any reason.
                            </p>
                            <p>
                                If your use of Services is subject to any type of use or sales tax or VAT, then the Company may also charge you for those taxes, in addition to the Subscription or other fees published in the Rules of Use
                            </p>
                            <p>
                                The European Union VAT ("VAT") tax amounts collected by the Company reflect VAT due on the value of any Content and Services, Product, or Subscription.
                            </p>
                            <p>
                                You agree that you will not use IP proxying or other methods to disguise the place of your residence, whether to circumvent geographical restrictions on content, to purchase at pricing not applicable to your geography, or for any other purpose. If you do this, the Company may terminate your access to your Account.
                            </p>
                        </div>
                        <div>
                            <h4>B. Responsibility for Charges Associated With Your Account</h4>
                            <p>
                                As the Account holder, you are responsible for all charges incurred, including applicable taxes, and all purchases made by you or anyone that uses your Account, including your family or friends. If you cancel your Account, the Company reserves the right to collect fees, surcharges or costs incurred before cancellation. Any delinquent or unpaid Accounts must be settled before the Company will allow you to register again.
                            </p>
                        </div>
                        <div>
                            <h4>C. Free Tokens</h4>
                            <p>
                                In some cases, the Company may offer free tokens for certain services, software, and content. As with all tokens, you are always responsible for any Internet service provider, telephone, and other connection fees that you may incur when using Services, even when the Company offers a free token.
                            </p>
                        </div>
                        <div>
                            <h4>D. Third-Party Sites</h4>
                            <p>
                                Services may provide links to other third-party sites. Some of these sites may charge separate fees, which are not included in and are in addition to any Subscription or other fees that you may pay to the Company. Services may also provide access to third-party vendors, who provide content, goods, and/or services on Services or the Internet.
                            </p>
                            <p>
                                Any separate charges or obligations you incur in your dealings with these third parties are your responsibility. The Company makes no representations or warranties, either express or implied, regarding any third-party site. In particular, the Company makes no representation or warranty that any service or subscription offered via third-party vendors will not change or be suspended or terminated.
                            </p>
                            <p>
                                The app does use third-party services that may collect information used to identify you. Please bear in mind that once you start to use our services and products you also accept the policies that are published and shared with the third parties' policies. Kindly read their Privacy Policy and Terms of Service separately:
                                <ul>
                                    <li>- Google Privacy Policy</li>
                                    <li>- Google Analytics</li>
                                    <li>- Youtube Terms of Service</li>
                                    <li>- Coursera Terms of Service</li>
                                    <li>- Udemy Terms of Service</li>
                                    <li>- Medium Terms of Service</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div id="illegalBehavior" className='content'>
                        <h2>Online Conduct And Illegal Behavior</h2>
                        <p>
                            Your online conduct and interaction with other Users should be guided by common sense and basic etiquette. The Company may terminate your Account or a particular Subscription for any conduct or activity that we deem as illegal, improper, or otherwise negatively affects the enjoyment of Services by other Users. You acknowledge that the Company is not required to provide you notice before terminating your Subscription(s) and/or Account.
                        </p>
                    </div>

                    <div id="thirdParty" className='content'>
                        <h2>Third-Party Content</h2>
                        <p>
                            In regard to all Subscriptions, Contents, and Services that are not authored by the Company, the Company does not screen such third-party content available on Services or through other sources. The Company assumes no responsibility or liability for such third-party content. Some third-party application software is capable of being used by businesses for business purposes – however, you may only acquire such software via Services for private personal use.
                        </p>
                    </div>

                    <div id="generatedContent" className='content'>
                        <h2>User-Generated Content</h2>
                        <div>
                            <h4>A. General Provisions</h4>
                            <p>
                                Services provide interfaces and tools for you to submit Content, including sharing, requests or comments to generate content and make it available to other users and/or to the Company at your sole discretion. "User Content" means any content you make available through the Services, or otherwise provided to the Company or other users, whether online or offline and whether or not solicited by the Company, or to the Company or its affiliates through your use of the Content and Services or otherwise.
                            </p>
                            <p>
                                For clarity, you retain all of your ownership rights in your User Content. However, by submitting User Content to the Company, you hereby grant the Company a worldwide, non-exclusive, royalty-free, sub-licensable, and transferable license to use, amend, reproduce, distribute, prepare derivative works of, display, publish, adapt, make available online or electronically transmit, and perform the User Content in connection with the Service and the Company's (and its successors' and affiliates') business, including without limitation for promoting and redistributing part or all of the Service (and derivative works thereof) in any media formats and through any media channels.
                            </p>
                            <p>
                                You also hereby grant each Member/user of the Service a non-exclusive license to access your User Content through the Service, and to use, reproduce, distribute, display, publish, make available online, or electronically transmit, and perform such User Content as permitted through the functionality of the Service and under these Terms and Services. The above licenses granted by you in User Content you submit to the Service cannot be terminated or deleted (save for any personal information submitted, which will be subject to local privacy law). You understand and agree, however, that the Company may continue to distribute or perform server copies of your User Content relating to templates and documents. The above licenses granted by you in the user comments you submit are perpetual and irrevocable.
                            </p>
                            <p>
                                If you provide the Company with any feedback or suggestions about Services, the Content and Services, or any Company products or services, the Company is free to use the feedback or suggestions however it chooses, without any obligation to account to you.
                            </p>
                            <p>
                                The Company has no obligation to pre-screen any content. You use all User Content and interact with other users at your own risk. Without limiting the foregoing, the Company reserves the right in its sole discretion to pre-screen, refuse, or remove any content. The Company shall have the right to remove any content that violates this Agreement or is otherwise objectionable.
                            </p>
                        </div>
                        <div>
                            <h4>B. Representations and Warranties</h4>
                            <p>
                                You represent and warrant to us that you have sufficient rights in all User Content to grant the Company and other affected parties the licenses described under A above. This includes, without limitation, any kind of intellectual property rights or other proprietary or personal rights affected by or included in the User Content.
                            </p>
                            <p>
                                You further represent and warrant that the User Content, your submission of that Content, and your granting of rights in that Content does not violate any applicable contract, law, or regulation.
                            </p>
                        </div>
                        <div>
                            <h4>C. Feedbacks, Ratings, and Reviews</h4>
                            <p>
                                Feedbacks, ratings, and reviews posted by users on our Services are User Content that is not endorsed by the Company and does not represent the views of the Company. The Company does not assume liability for ratings and reviews or for any claims for economic loss resulting from such feedback, ratings, and reviews. Because we expect users to maintain a high level of integrity with respect to feedback, ratings, and reviews posted through the Services, you agree:

                                <ul>
                                    <li>(i) to base any rating or review of your post only on your first-hand experience with the applicable business, product, or service</li>
                                    <li>(ii) you will not provide feedback, rating, or review for any business, product, or service with respect to which you have a competitive, ownership, or other economic interest, employment relationship, or other affiliation</li>
                                    <li>(iii) you will not submit feedback, rating, or review in exchange for payment or other benefits from any individual or entity</li>
                                    <li>(iv) your review will comply with the terms of this Agreement</li>
                                    <li>(v) to represent and warrant that you have all rights necessary to submit the feedback, rating, and reviews</li>
                                    <li>(vi) to grant to the Company the right to use any feedback, ratings, and reviews in any way at any time without any additional approval or compensation. If we determine, in our sole discretion, that any rating or review could diminish the integrity of the feedback, ratings, and reviews, we may exclude such User Content without notice.</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div id="ownership" className='content'>
                        <h2>Ownership Of And License To Use The Services</h2>
                        <div>
                            <h4>A. Use of the Services</h4>
                            <p>
                                Except with respect to User Content, The Company, and its suppliers own all rights, titles, and interests in the Services. The Services are protected by copyright and other intellectual property laws throughout the world. Subject to this Agreement, the Company grants you a limited license to use the Services solely for your personal non-commercial purposes. Any future release, update, or other addition to the Services shall be subject to this Agreement. The Company, its suppliers, and service providers reserve all rights not granted in this Agreement.
                            </p>
                        </div>
                        <div>
                            <h4>B. Trademarks</h4>
                            <p>
                                The Company’s stylized name and other related graphics, logos, service marks, and trade names used on or in connection with the Services are the trademarks of the Company and may not be used without permission in connection with any third-party products or services. Other trademarks, service marks, and trade names that may appear on or in the Services are the property of their respective owners. You will not remove, alter, or obscure any copyright notice, trademark, service mark, or other proprietary rights notices incorporated in or accompanying the Services.
                            </p>
                        </div>
                        <div>
                            <h4>C. Restrictions on Use of Services</h4>
                            <p>
                                The rights granted to you in this Agreement are subject to the following restrictions:

                                <ul>
                                    <li>(a) you shall not license, sell, rent, lease, transfer, assign, reproduce, distribute, host, or otherwise commercially exploit the Services or any portion of the Services;</li>
                                    <li>(b) you shall not frame or use framing techniques to enclose any trademark, logo, or Services (including images, text, page layout, or form) of the Company</li>
                                    <li>(c) you shall not use any metatags or other “hidden text” using the Company’s name or trademarks</li>
                                    <li>(d) you shall not modify, translate, adapt, merge, make derivative works of, disassemble, decompile, reverse compile, or reverse engineer any part of the Services except to the extent the foregoing restrictions are expressly prohibited by applicable law</li>
                                    <li>(e) you shall not use any manual or automated software, devices, or other processes (including but not limited to spiders, robots, scrapers, crawlers, avatars, data mining tools, or the like) to “scrape” or download data from the Services (except that we grant the operators of public search engines revocable permission to use spiders to copy materials from the Website for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials)</li>
                                    <li>(f) you shall not access the Services to build a similar or competitive website, application, or service</li>
                                    <li>(g) except as expressly stated herein, no part of the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted, or transmitted in any form or by any means</li>
                                    <li>(h) you shall not remove or destroy any copyright notices or other proprietary markings contained on or in the Services</li>
                                    <li>(i) you shall not interfere with or attempt to interfere with the proper functioning of the Services or use the Services in any way not expressly permitted by this Agreement</li>
                                    <li>(j) you shall not attempt to harm our Services, including but not limited to, violating or attempting to violate any related security features, introducing viruses, worms, or similarly harmful code into the Services, or interfering or attempting to interfere with the use of the Services by any other user, host, or network, including by means of overloading, “flooding,” “spamming,” “mail bombing”, or “crashing” the Services. Any unauthorized use of the Services terminates the licenses granted by the Company pursuant to this Agreement.</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <h4>D. Third-Party Links</h4>
                            <p>
                                The Services may contain links to third-party services such as third-party websites, applications, or ads (“Third-Party Links”). When you click on such a link, we will not warn you that you have left the Services. The Company does not control and is not responsible for Third-Party Links.
                            </p>
                            <p>
                                The Company provides these Third-Party Links only as a convenience and does not review, approve, monitor, endorse, warrant, or make any representations with respect to them, or any content, products, or services accessible through such links. Your use of all Third-Party Links is at your own risk.
                            </p>
                        </div>
                    </div>

                    <div id="disclaimers" className='content'>
                        <h2>Disclaimers And Liability Provisions</h2>
                        <div>
                            <h4>A. Disclaimers</h4>
                            <p>
                                You Expressly Understand And Agree That To The Fullest Extent Permitted By Applicable Law, Your Use Of The Services And Any Products Offered Through The Services Is At Your Sole Risk, And The Services And Any Products Are Provided On An “As Is” And “As Available” Basis, With All Faults. To The Fullest Extent Permitted By Applicable Law, The Company Parties Expressly Disclaim All Warranties, Representations, And Conditions Of Any Kind, Whether Express Or Implied, Including, But Not Limited To, The Implied Warranties Or Conditions Of Merchantability, Fitness For A Particular Purpose, Title, And Non-Infringement Arising From The Use Of The Services And Products. The Company Parties Make No Warranty, Representation, Or Condition That:
                                <ul>
                                    <li>(1) The Services Or Any Products Will Meet Your Requirements Or</li>
                                    <li>(2) Your Use Of The Services Will Be Uninterrupted, Timely, Secure, Or Error-Free.</li>
                                </ul>
                            </p>
                            <p>
                                To The Maximum Extent Permitted By Applicable Law, The Company Parties Shall Not Be Liable For Any Loss Of Profits Or Revenue Or For Indirect, Incidental, Punitive, Exemplary, Special, Or Consequential Damages, Or Damages Or Costs Due To Loss Of Data, Production, Or Use, Business Interruption, Or Procurement Of Substitute Goods Or Services, Whether Or Not The Company Has Been Advised Of The Possibility Of Such Damages.
                            </p>
                            <p>
                                The Company Parties Assume No Responsibility For The Timeliness, Deletion, Misdelivery, Or Failure To Store Any Content, User Communications, Or Personalization Settings. Without Limiting The Foregoing, The Company Will Not Be Liable For Damages Of Any Kind Resulting From Your Use Of Or Inability To Use The Service Or From Any Products Or Transactions Or Transfers Relating To Products, Or From Any Third-Party Materials, Including From Any Virus That May Be Transmitted In Connection Therewith, And Including From Any Dispute With Any Other User Of The Service.
                            </p>
                        </div>
                        <div>
                            <h4>B. Limitation Of Liability</h4>
                            <p>
                                To The Maximum Extent Permitted By Applicable Law, Neither The Company, Its Licensors, Nor Their Affiliates, Nor Any Of The Company’s Service Providers, Shall Be Liable In Any Way For Loss Or Damage Of Any Kind Resulting From The Use Or Inability To Use The Services, Your Account, Your Subscriptions, And The Content And Services Including, But Not Limited To, Loss Of Goodwill, Work Stoppage, Computer Failure Or Malfunction, Or Any And All Other Commercial Damages Or Losses. In No Event Will The Company Be Liable For Any Indirect, Incidental, Consequential, Special, Punitive, Or Exemplary Damages, Or Any Other Damages Arising Out Of Or In Any Way Connected With The Services, The Content And Services, The Subscriptions, And Any Information Available In Connection Therewith, Or The Delay Or Inability To Use The Content And Services, Subscriptions, Or Any Information, Even In The Event Of The Company’s Or Its Affiliates’ Fault, Tort (Including Negligence), Strict Liability, Or Breach Of The Company’s Warranty And Even If It Has Been Advised Of The Possibility Of Such Damages. These Limitations And Liability Exclusions Apply Even If Any Remedy Fails To Provide Adequate Recompense.
                            </p>
                            <p>
                                Your Sole And Exclusive Remedy For Dissatisfaction With The Services Or Any Third-Party Materials Is To Stop Using The Services. Without Limiting The Foregoing, Under No Circumstances Will The Total Aggregate Amount That The Company Parties Are Liable To You Exceed The Greater Of (A) The Total Amount Actually Paid To The Company By You During The Twelve-Month Period Prior To The Act, Omission, Or Occurrence Giving Rise To Such Liability, (B) The Remedy Or Penalty Imposed By The Statute Or Regulation Under Which Such Claim Arises, Or (C) Currency100. The Foregoing Cap On Liability Shall Not Apply To Liability Of A The Company Party For (X) Death, Tangible Property Damage, Or Personal Injury Caused By A The Company Party’S Gross Negligence Or For (Y) Any Injury Caused By A The Company Party’s Fraud Or Fraudulent Misrepresentation.
                            </p>
                        </div>
                        <div>
                            <h4>C. No Guarantees</h4>
                            <p>
                                To The Maximum Extent Permitted By Applicable Law, Neither The Company Nor Its Affiliates Guarantee Continuous, Error-Free, Virus-Free, Or Secure Operation And Access To The Services, The Content And Services, Your Account And/Or Your Subscriptions(S) Or Any Information Available In Connection Therewith.
                            </p>
                        </div>
                        <div>
                            <h4>D. Limited Warranty</h4>
                            <p>
                                Certain Products Purchased From The Company Are Subject To A Limited Warranty, Which Is Described In Detail With The Product.
                            </p>
                        </div>
                        <div>
                            <h4>E. Indemnification</h4>
                            <p>
                                You Agree To Indemnify And Hold The Company, Its Corporate Parents, Subsidiaries, And Affiliates, And The Officers, Directors, Employees, Agents, Representatives, Partners, And Licensors Of Each (Collectively, The “Company Parties”) Harmless From Any Damages, Losses, Costs, Liabilities, And Expenses (Including Reasonable Attorneys’ Fees) Relating To Or Arising Out Of Any Claims Concerning:
                                <ul>
                                    <li>(A) Your Content;</li>
                                    <li>(B) Your Use/Misuse Of The Services;</li>
                                    <li>(C) Your Violation Of This Agreement;</li>
                                    <li>(D) Your Violation Of Any Rights Of Another Party, Including Any Users;</li>
                                    <li>(E) Your Violation Of Any Applicable Laws, Rules, Or Regulations;</li>
                                    <li>(F) Third-Party Claims That You Or Someone Using Your Password Did Something That, If True, Would Violate Any Of These Terms;</li>
                                    <li>(G) Any Misrepresentations Made By You; Or</li>
                                    <li>(H) A Breach Of Any Representations Or Warranties You’ve Made To Us.</li>
                                </ul>
                            </p>
                            <p>
                                The Company Reserves The Right, At Its Own Cost, To Assume The Exclusive Defense And Control Of Any Matter Otherwise Subject To Indemnification By You, In Which Event You Will Fully Cooperate With The Company In Asserting Any Available Defenses. This Provision Does Not Require You To Indemnify Any Of The Company Parties For Any Unconscionable Commercial Practice By Such Party Or For Such Party’s Negligence, Fraud, Deception, False Promise, Misrepresentation, Or Concealment, Suppression, Or Omission Of Any Material Fact. You Agree That The Provisions In This Section Will Survive Any Termination Of Your Account, This Agreement, Or Your Access To The Services.
                            </p>
                        </div>
                        <p>
                            Where the disclosure of your personal information is solely subject to Australian privacy laws, you acknowledge that some third parties may not be regulated by the Privacy Act and the Australian Privacy Principles in the Privacy Act. You acknowledge that if any such third party engages in any act or practice that contravenes the Australian Privacy Principles, it would not be accountable under the Privacy Act, and you will not be able to seek redress under the Privacy Act.
                        </p>
                    </div>

                    <div id="amendments" className='content'>
                        <h2>Amendments To This Agreement</h2>
                        <p>
                            This Agreement may at any time be mutually amended by your explicit consent to changes proposed by the Company. Furthermore, the Company may amend this Agreement (including any Subscription Terms or Rules of Use) unilaterally at any time in its sole discretion. In this case, you will be notified by e-mail of any amendment to this Agreement made by the Company within 20 (twenty) days before the entry into force of the said amendment. Your failure to cancel your Account within ten (20) days after the entry into force of the amendments will constitute your acceptance of the amended terms. If you don’t agree to the amendments or to any of the terms in this Agreement, your only remedy is to cancel your Account or to cease use of the affected Subscription(s). The Company shall not have any obligation to refund any fees that may have accrued to your Account before the cancellation of your Account or termination of your access to a particular Subscription, nor shall the Company have any obligation to prorate any fees in such circumstances.
                        </p>
                    </div>

                    <div id="term" className='content'>
                        <h2>Term And Termination</h2>
                        <div>
                            <h4>A. Term</h4>
                            <p>
                                The term of this Agreement (the “Term”) commences on the date you first indicate your acceptance of these terms and will continue in effect until otherwise terminated in accordance with this Agreement.
                            </p>
                        </div>
                        <div>
                            <h4>B. Termination by You</h4>
                            <p>
                                You may cancel your Account at any time. You may cease use of a Subscription at any time or, if you choose, you may request that the Company terminate your access to a Subscription. Subscriptions are not transferable. Access to Subscriptions purchased as a part of a pack or bundle cannot be terminated individually, termination of access to one product/service purchased in the pack. Your cancellation of an Account, your cessation of use of any Subscription, or your request that access to a Subscription be terminated will not entitle you to any refund, including of any Subscription fees. The Company reserves the right to collect fees, surcharges, or costs incurred before the cancellation of your Account or termination of your access to a particular Subscription. In addition, you are responsible for any charges incurred to third-party vendors or content providers before your cancellation.
                            </p>
                        </div>
                        <div>
                            <h4>C. Termination by the Company</h4>
                            <p>
                                The Company may cancel your Account or any particular Subscription(s) at any time in the event that:
                                <ul>
                                    <li>(a) the Company ceases providing such Subscriptions to similarly situated Users generally; or</li>
                                    <li>(b) you breach any terms of this Agreement (including any Subscription Terms or Rules of Use).</li>
                                </ul>
                                In the event that your Account or a particular Subscription is terminated or canceled by the Company for a violation of this Agreement or improper or illegal activity, no refund, including of any Subscription fees or of any unused credits in your Services, will be granted.
                            </p>
                        </div>
                    </div>

                    <div id="miscellaneous" className='content'>
                        <h2>Miscellaneous </h2>
                        <div>
                            <h4>A. Electronic Communications</h4>
                            <p>
                                The communications between you and the Company use electronic means, whether you visit the Services or send the Company e-mails, or whether the Company posts notices on the Services or communicates with you via e-mail. For contractual purposes, you (1) consent to receive communications from the Company in an electronic form; and (2) agree that all terms and conditions, agreements, notices, disclosures, and other communications and documents that the Company provides to you electronically will have the same legal effect that such communications or documents would have if they were set forth in "writing." The foregoing sentence does not affect your statutory rights.
                            </p>
                        </div>
                        <div>
                            <h4>B. Assignment</h4>
                            <p>
                                This Agreement, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated, or otherwise transferred by you without the Company's prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.
                            </p>
                        </div>
                        <div>
                            <h4>C. Force Majeure</h4>
                            <p>
                                The Company shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including, but not limited to, acts of God, war, terrorism, riots, embargos, acts of civil or military authorities, fire, floods, accidents, strikes or shortages of transportation facilities, fuel, energy, labor or materials.

                            </p>
                        </div>
                        <div>
                            <h4>D. Questions, Complaints, Claims</h4>
                            <p>
                                If you have any questions, complaints, or claims with respect to the Services, please contact our customer service department using the contact information available on the Services (help@upskillr.ai). We will do our best to address your concerns.
                            </p>
                        </div>
                        <div>
                            <h4>E. Notice</h4>
                            <p>
                                Where the Company requires that you provide an e-mail address, you are responsible for providing the Company with your most current e-mail address. In the event that the last e-mail address you provided to the Company is not valid, or for any reason is not capable of delivering to you any notices required/permitted by this Agreement, the Company's dispatch of the e-mail containing such notice will nonetheless constitute effective notice.
                            </p>
                        </div>
                        <div>
                            <h4>F. Waiver</h4>
                            <p>
                                Any waiver or failure to enforce any provision of this Agreement on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.
                            </p>
                        </div>
                        <div>
                            <h4>G. Severability</h4>
                            <p>
                                Except as otherwise expressly set forth in this Agreement, in the event that any provision of this Agreement shall be held by a court or other tribunal of competent jurisdiction to be unenforceable, such provision shall be construed in a manner to reflect, as nearly as possible, the original intention of the parties, and the remaining portions shall remain in full force and effect.
                            </p>
                        </div>
                        <div>
                            <h4>H. Export Control</h4>
                            <p>
                                You agree to comply with all applicable import/export laws and regulations. You agree not to export the Content and Services or Product or allow the use of your Account by individuals of any terrorist-supporting countries to which encryption exports are at the time of exportation restricted. You represent and warrant that you are not located in, under the control of, or a national or resident of any such prohibited country.
                            </p>
                        </div>
                        <div>
                            <h4>I. Entire Agreement</h4>
                            <p>
                                This Agreement, including any Terms and Services, Privacy Policy, and other policies of the Company, constitutes and contains the entire agreement between the parties with respect to the subject matter hereof and supersedes any prior oral or written agreements.
                            </p>
                        </div>
                        <div>
                            <h4>J. Rights of Third Parties</h4>
                            <p>
                                You agree that this Agreement is not intended to confer and does not confer any rights or remedies upon any person other than the parties to this Agreement.
                            </p>
                        </div>
                        <div>
                            <h4>K. Complying with Law</h4>
                            <p>
                                The Company's obligations are subject to existing laws and legal processes, and the Company may comply with law enforcement or regulatory requests or requirements notwithstanding any contrary term.
                            </p>
                        </div>
                    </div>

                    <div id="refundPolicy" className='content'>
                        <h2>Refund Policy </h2>
                        <p>
                            At Upskillr.ai, we aim to provide our customers with high-quality educational content and services:
                        </p>
                        <p>
                            Our credit-based pricing system in the form of web services and subscription/one-time pricing system in the form of ChatGPT plugin and web features allows customers to access our products and services.
                        </p>
                        <p>
                            We understand that circumstances may arise that require a refund, and our refund policy is designed to be fair and transparent to ensure customer satisfaction and confidence in our products. Please note that the refund policy is applicable only for products where a refund is allowed at our sole discretion.
                        </p>
                        <div>
                            <h4>Refund eligibility:</h4>
                            <p>
                                Customers may be eligible for a refund if they meet the following criteria
                                <ul>
                                    <li>1. The customer has purchased services from Upskillr Software Solutions Private Limited within the last 30 days.</li>
                                    <li>2. The customer has not used any credits to access our products and services.</li>
                                    <li>3. The customer has not violated our terms of service.</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <h4>Refund process:</h4>
                            <p>
                                To request a refund, customers must email our customer support team at help@upskillr.ai with the subject line "Refund Request." The email must include the following information:
                                <ul>
                                    <li>1. The customer's name and email address</li>
                                    <li>2. The service/plan customer purchased</li>
                                    <li>3. The reason for the refund request</li>
                                </ul>
                                Upon receipt of the refund request, our customer support team will review the request and respond within 5-7 business days. If the request is approved, we will issue a refund of the purchase price of the credits to the original payment method within 10 business days.
                            </p>
                        </div>
                        <div>
                            <h4>Refund exceptions:</h4>
                            <p>
                                The following products and services are not eligible for a refund:
                                <ul>
                                    <li>1. Any credits that have been used to access our products and services.</li>
                                    <li>2. Any promotional credits or services that were given for free as part of a marketing campaign or promotion.</li>
                                    <li>3. Any services that were purchased more than 30 days prior to the refund request.</li>
                                </ul>
                                We reserve the right to modify our refund policy at any time, and any changes will be communicated to customers via email or on our website.
                            </p>
                        </div>
                    </div>

                    <div id="privacyPolicy" className='content'>
                        <h2>Privacy Policy</h2>
                        <p>
                            Upskillr.ai has a Privacy Policy which is incorporated into the Terms by this reference. This policy includes information on how we collect, use, and disclose information from our Users and your agreement to the Terms and use of the Services also constitutes your acceptance of the terms of the Privacy Policy.
                        </p>
                        <p>Find our Privacy Policy here:<a href='https://www.upskillr.ai/privacyPolicy' target="_blank"> www.upskillr.ai/privacyPolicy</a></p>
                    </div>

                    <div id="cookiesPolicy" className='content'>
                        <h2>Cookies Policy</h2>
                        <p>
                            Upskillr.ai has a Cookie Policy which is incorporated into the Terms by this reference. This policy includes information on how we collect, store, and use cookies, and your agreement to the Terms and Use of the Services also constitutes your acceptance of the terms of the Cookie Policy. By using our website, you agree to our Privacy Policy and our cookies usage.
                        </p>
                        <p>Find our Privacy Policy here:<a href='https://www.upskillr.ai/privacyPolicy' target="_blank"> www.upskillr.ai/privacyPolicy</a></p>
                    </div>

                    <div id="changesTerms" className='content'>
                        <h2>Changes to This Terms of Service</h2>
                        <p>
                            Our Terms and Service may undergo updates periodically. Therefore, we recommend that you check this page regularly for any changes. These changes become effective immediately after being posted on this page. Your continued use of our Service after any modifications indicates your acceptance of the updated Terms and Services.
                        </p>
                    </div>

                    <div id="contactUs" className='content'>
                        <h2>Contact Us</h2>
                        <p>
                            If you have any inquiries or suggestions concerning our Privacy Policy, feel free to contact us at <a href='help@upskillr.ai'>help@upskillr.ai</a>. We are here to assist and address any concerns you may have.
                        </p>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default TermsOfService