import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
import { FAILUR, PROCCESSING, STOPPROCESSING } from "../../utilities/constants";
export const CURRICULUM_DETAIL = 'CURRICULUM_DETAIL';
export const TOOLS_LIST = "TOOLS_LIST";

export const getCurriculumDetail = () => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.get_curriculum_details,
    };
    return api(data)
        .then(async (response) => {
    //         if (response.status) {
                dispatch({
                    type: CURRICULUM_DETAIL,
                    payload: response.data
                });

                return response.data;
        //     }
        })
        // .catch((error) => {
        //     throw error?.response?.data?.message;
        // });
};
export const getTools = (params) => async (dispatch) => {
    dispatch({ type: PROCCESSING });
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.get_tools(params),
    };
    return api(data).then(async (response) => {
            console.log('response', response)
            if (response) {
                dispatch({
                    type: TOOLS_LIST,
                    payload: response.data
                });
                dispatch({ type: STOPPROCESSING });
            }
        })
        .catch((error) => {
            dispatch({
                type: FAILUR,
                payload: error,
            });
            dispatch({ type: STOPPROCESSING });
            throw error?.response?.data?.message;
        });
};
