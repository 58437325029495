import { API_ENDPOINTS, METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";
import Images from "../../../constants/Images";


export const GET_RECOGNITIONS = "GET_RECOGNITIONS";
export const ADD_RECOGNITIONS = "ADD_RECOGNITIONS";
export const UPDATE_RECOGNITIONS = "UPDATE_RECOGNITIONS";
export const DELETE_RECOGNITIONS = "DELETE_RECOGNITIONS";
export const GET_RECOGNITIONS_DETAILS = "GET_RECOGNITIONS_DETAILS";

export const getAllRecognitions = (value) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.get_recognitions}`,
    };
    return api(data)
        .then(async (response) => {
            return response;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: GET_RECOGNITIONS,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};
export const createRecognition = (id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.get_recognitions,
        data: { image_id: id },
    };
    return api(data)
        .then(async (response) => {
            return response;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: ADD_RECOGNITIONS,
                    payload: response.data,
                });
            }
            return response;
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};
export const updateRecognition = (mainId, id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.get_recognitions + mainId + '/',
        data: { image_id: id },
    };
    return api(data)
        .then(async (response) => {
            console.log('response?.data', response?.data)
            return response;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: UPDATE_RECOGNITIONS,
                    payload: response?.data,
                });
            }
            return response;
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};

export const deleteRecognition = (id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.delete,
        url: API_ENDPOINTS.get_recognitions + id + "/",
    };
    return api(data)
        .then(async (response) => {
            return response;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: DELETE_RECOGNITIONS,
                    payload: response.data,
                });
                getAllRecognitions();
            }
            return response;
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};
export const getRecognitionDetails = (id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.get_recognitions + id + "/",
    };
    return api(data)
        .then(async (response) => {
            return response;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: GET_RECOGNITIONS_DETAILS,
                    payload: response.data,
                });
            }
            return response;
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};

export const getUploadURL = (fileDetail) => async () => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.get_uploadUrl}?fileName=${fileDetail.name}&fileType=${fileDetail.type}`,
    };
    return api(data)
        .then(async (response) => {
            return response?.data?.result
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};

export const updateUploadURL = (url, file) => async () => {
    let data = {
        method: METHOD_TYPE.put,
        url: url,
        data: file,
        headers: {
            "Content-Type": "image/jpeg",
        },
    };
    return api(data)
        .then(async (response) => {
            // toast.success(response?.data?.message);
            // throw success?.response?.data?.message;
            return response.data
        })
        .catch((error) => {
            // toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
            throw error?.response?.data?.message;
        });
};

export const uploadAndUpdateImageTag = (payload) => async () => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.upload_and_update_image_tag,
        data: payload
    };
    return api(data)
        .then(async (response) => {
            return response.data;
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
}


export const getTagsByUploadImage = (file) => async () => {

    let formData = new FormData();
    formData.append('file', file);

    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.get_tags_by_upload_image,
        data: formData
    };

    return api(data).then(async (response) => {
        // toast.success(response?.data?.message);
        return response.data
    }).catch((error) => {
        throw error?.response?.data?.message;
    });
};
export const addContent = (content) => async () => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.add_content,
        data: content
    };
    return api(data)
        .then(async (response) => {
            // toast.success(response?.data?.message);
            return response.data
        })
        .catch((error) => {
            // toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};

export const updateAdminContent = (content) => async () => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.admin_update_content,
        data: content
    };
    return api(data)
        .then(async (response) => {
            // toast.success(response?.data?.message);
            return response.data
        })
        .catch((error) => {
            // toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};




