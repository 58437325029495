import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Images from '../constants/Images';
import Colors from '../constants/Colors';
import './commonComponentCss.scss'

const VideoPlayer = (props) => {

    
    const { overview } = props;
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <>
        {
            overview && (

            <div className='videoPlayer' style={{ backgroundColor: props.backgroundColor }}>
                <video ref={videoRef} width="100%" height="100%" controls={props.controls ? props.controls : false} style={{ borderRadius: 12 }}>
                    <source src={overview.video.media} type="video/mp4" />
                </video>
                <button onClick={togglePlay} className='videoPlyaButton' style={{ opacity: isPlaying ? 0 : 1 }}>
                    <img src={Images.VideoPlayIcon} className='videoPlyaIcon' style={props.playIconStyle} />
                </button>

                {/* {overview.video.media &&
                    <div className='playerText'>A 1 minute video clip that summarizes all the key features</div>
                } */}
            </div>
            )
        }
        </>
    )
}

VideoPlayer.propTypes = {
    showLineAbove: PropTypes.bool,
    backgroundColor: PropTypes.string,
};

VideoPlayer.defaultProps = {
    showLineBelow: true
};

const styles = {
    rowItemStyle: {
        flexDirection: 'row',
        paddingTop: 8,
        paddingBottom: 8,
        alignItems: 'center'
    },
    arrowIconStyle: {
        marginLeft: 8,
        width: 16,
        height: 16
    }
};

export default VideoPlayer