
import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Form from 'react-bootstrap/Form';
import './index.scss'
import Accordion from 'react-bootstrap/Accordion';
import ReadMoreReact from 'read-more-react';
import Images from '../../constants/Images';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import Loader from '../Loader';
import { useSelector } from 'react-redux';
import { convertFileToText, generateExam } from './action';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { MCQS_ERROR, MCQS_LOADING, MCQS_SUCCESS } from "./action";
import Colors from '../../constants/Colors';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh'
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '70vh',
        overflowY: 'auto'
    }
});

const Chat = ({handleSubmission, data, type, loading, filePicker, dispatch, filetotextLoading}) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [message, setMessage] = useState('');
    const bottomRef = useRef(null);
    const fileInputRef = useRef(null);
    const {profileDetails} = useSelector((state)=>state.accountReducer);
    const [selectedFile, setSelectedFile] = useState(null);
    const { handleSubmit, register,control, formState: { errors } } = useForm();
    const [params, setParams] = useState({
        difficulty_level: ''
      })
    const [generateExamPayload, setGenerateExamPayload] = useState({
        topic_name : '',
        subtopic_name : '',
        difficulty_level : ''
    })

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth',block: 'end'});
        }
    }, [data]);

    const handleFileIconClick = () => {
        fileInputRef.current.click();
      };

    const submit = (data) => {
        if (message !== '') {
            const updatedSuccess = {
                ...data,
                conversationHistory: [...data.conversationHistory, {
                    role: 'user',
                    content: message
                }],
              };
              
            setMessage('');
            handleSubmission(updatedSuccess);
        } else {
            toast.error("Please type message");
        }
    }

    const onSubmit = (payload) => {
        const updatedSuccess = {
            ...data,
            conversationHistory: [...data.conversationHistory, {
                role: 'assistant',
                content: `Selected value are \n <ul>
                    <li>Topic Name : ${payload.topic_name}</li>
                    <li>SubTopic Name : ${payload.subtopic_name}</li>
                    <li>Difficulty Level : ${payload.difficulty_level}</li>
                </ul>`
            }],
        };
        setGenerateExamPayload({
            topic_name : payload.topic_name,
            subtopic_name : payload.subtopic_name,
            difficulty_level : payload.difficulty_level
        })
        handleSubmission(payload,updatedSuccess);
    };

    const generateMoreMcqs = ()=>{
        dispatch(generateExam(generateExamPayload)).then((response) => {
            const choicesArray = data.conversationHistory.find(item => item.content.choices);
            if (choicesArray) {
                let content = choicesArray['content'].choices;
                content.push(...response.Questions.choices)
                dispatch({
                    type: MCQS_SUCCESS,
                    payload: data,
                });
            }
        }).catch(err => {
            console.log("Error", err);
            toast.error(err);
        });
    }

    const handleOptions = (event) => {
        setParams(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const handleSelectionInput = (choicesIndex, questionIndex, type, answer) => {
        findAndUpdateAnswer(choicesIndex, questionIndex, type, answer)
    }

    const colors = {
        correct : Colors.rightAns,
        wrong : Colors.wrongAns,
    }

    const infoTextColor = {
        correct : {
            borderColor : "#1D7454",
            textColor : "#1D7454",
            backgroundColor : "#EBFBF5"
        },
        wrong : {
            borderColor : Colors.wrongAns,
            textColor : Colors.wrongAns,
            backgroundColor : Colors.wrongAnsBg
        }
    }

    const findAndUpdateAnswer = (choicesIndex, questionIndex, type, value) => {
        const choicesArray = data.conversationHistory.find(item => item.content.choices);
        if (choicesArray) {
            let content = choicesArray['content'].choices;
            const questionToUpdate = content[choicesIndex]?.text[questionIndex];
            if (questionToUpdate) {
                if(type === 'selected_answer'){
                    questionToUpdate['answer_given'] = value.answer;
                    questionToUpdate['result'] = (questionToUpdate['Correct_Answer'] === value.option) ? 'correct' : 'wrong';
                }else if(type === 'submit_response'){
                    if(typeof questionToUpdate['answer_given'] != 'undefined'){
                        questionToUpdate['submitted'] = value;
                    }else{
                        toast.error("Please select any option");
                    }
                }
            }
        }
        
        dispatch({
            type: MCQS_SUCCESS,
            payload: data,
        });
    }

    const handleFileSelect = async (e) => {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);
        setSelectedFile(selectedFile);
        await dispatch(convertFileToText(formData)).then((res) => {
            const updatedSuccess = {
                ...data,
                conversationHistory: [...data.conversationHistory, {
                    role: 'user',
                    content: res.text
                }],
            };
            e.target.value = '';
            handleSubmission(updatedSuccess);
        }).catch((error) => {
            console.log("Error on conversion from file to text", error);
            toast.error("Error on conversion from file to text");
        });  
    };

    const handleImageError = (event) => {
        event.target.src = Images.UserIcon;
      };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (message !== '') {
                const updatedSuccess = {
                    ...data,
                    conversationHistory: [...data.conversationHistory, {
                        role: 'user',
                        content: message
                    }],
                };
                setMessage('');
                handleSubmission(updatedSuccess);
            } else {
                toast.error("Please type message");
            }
        }
      };

    return (
        <>

            <Modal size="lg" show={show} onHide={handleClose} className='Chat-Popup' centered dialogClassName="modal-90w">
            <Button onClick={handleClose} style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
					<img src={require('../../assets/table_close_icon.png')} style={{width:32,height:32,marginTop:-50}}/>
				</Button>
                <Modal.Body>
                    <div className="button" onClick={handleShow2} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 9, marginTop: 5,gap:10 }}>
                        <Button style={{ backgroundColor: '#004AAD', color: '#fff', textTransform: 'capitalize', width: '100%' }} className='white' >{`Test your skills`}</Button>
                        <Button style={{ backgroundColor: 'transparent', color: '#001F49', textTransform: 'capitalize', width: '100%',}} className='white blue-border' >{`Open SkillMap`}</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={show2}  onHide={handleClose2} centered className='Chat-Popup' dialogClassName="modal-90w">
            <Button onClick={handleClose2} style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
					<img src={require('../../assets/table_close_icon.png')} style={{width:32,height:32,marginTop:-50}}/>
				</Button>
            <Modal.Body>
                    <div className="button" onClick={handleShow2} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 9, marginTop: 5,gap:10 }}>
                        <Button style={{ backgroundColor: '#004AAD', color: '#fff', textTransform: 'capitalize', width: '100%' }} className='white' >{`MCQ`}</Button>
                        <Button style={{ backgroundColor: 'transparent', color: '#001F49', textTransform: 'capitalize', width: '100%',}} className='white blue-border' >{`Case Study`}</Button>
                        <Button style={{ backgroundColor: 'transparent', color: '#001F49', textTransform: 'capitalize', width: '100%',}} className='white blue-border' >{`Coding simulation`}</Button>
                    </div>
                </Modal.Body>
            </Modal> 
       
        <div className='Chat'>
            <Grid container component={Paper} className={classes.chatSection}>
                <Grid item xs={9}>
                    <List className={classes.messageArea}>
                            {(loading || filetotextLoading) && <Loader textLoading/>}
                        {
                            data?.conversationHistory.map((item, index)=>{
                                return <div key={index}>
                                    {item?.role === 'user' && <ListItem>
                                        <Grid container>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                                <Grid item xs={12}>
                                                    <ListItemText className='senderMessage' align="right" primary={                                                        
                                                        (index === 1 && selectedFile && (type === 'cv-bot-career-pal' || type === 'skillset-bot-career-pal'))?selectedFile.name:item.content                                                        
                                                    }></ListItemText>
                                                </Grid>
                                                <img src={(profileDetails['image']?.media)?profileDetails['image']?.media:Images.UserIcon} style={{ width: 56, height: 56, borderRadius: '50%', border:"1px solid rgb(208, 213, 221)", marginLeft: 10, objectFit: "cover" }} onError={handleImageError}/>
                                            </div>
                                        </Grid>
                                    </ListItem>
                                    }
                                    {
                                        item?.role === 'assistant' && <ListItem>
                                            <Grid container>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                                    <img src={require('../../assets/logo.png')} style={{ width: 56, height : 56,  borderRadius: '50%', marginRight: 10, objectFit: "contain", border: "1px solid #D0D5DD" }} />
                                                    <div >
                                                        <Grid item xs={12}>
                                                            {
                                                                (Array.isArray(item.content)) &&
                                                                    <div style={{ padding: 12, backgroundColor: '#FAFAFA', color: '#00295F', borderRadius: 8, borderColor: '#2FC08B', fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, }}>
                                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                                            {item['content'].map((formField, index) => (
                                                                                <div key={index} className="mb-3">
                                                                                    {
                                                                                        formField.type === 'text' && <>
                                                                                        {/* <label>{formField.label}</label>
                                                                                        <input
                                                                                        type={formField.type}
                                                                                        name={formField.name}
                                                                                        {...register(formField.name, formField.validation)}
                                                                                        /> */}

                                                                                            <Form.Group controlId={'field_' + index}>
                                                                                                <Form.Label>{formField.label}</Form.Label>
                                                                                                <Form.Control type={formField.type} name={formField.name} {...register(formField.name, formField.validation)} />
                                                                                            </Form.Group>

                                                                                        </>
                                                                                    }

                                                                                    {
                                                                                        formField.type === 'select' && 
                                                                                        <>
                                                                                        {/* <InputLabel htmlFor="select-input-label">{formField.label}</InputLabel> */}
                                                                                        <FormControl fullWidth>
                                                                                        <label className="w-100" htmlFor="select-input-label">{formField.label}</label>
                                                                                            <Controller
                                                                                                name={formField.name}
                                                                                                control={control}
                                                                                                value={params?.difficulty_level}
                                                                                                rules={formField.validation}
                                                                                                render={({ field, fieldState }) => (
                                                                                                    <Select
                                                                                                    displayEmpty
                                                                                                    labelId="select-input-label"
                                                                                                        label={field.name}
                                                                                                        name={field.name}
                                                                                                        style={{ width: '100%' }}
                                                                                                        value={params?.difficulty_level}
                                                                                                        onChange={(e) => {
                                                                                                            field.onChange(e.target.value);
                                                                                                            handleOptions(e); // Call your custom handler here if needed
                                                                                                        }}
                                                                                                        error={fieldState.invalid}
                                                                                                    >
                                                                                                        <MenuItem disabled value="">
                                                                                                            <em>Please Select</em>
                                                                                                        </MenuItem>
                                                                                                        {formField.options?.map((data, i) => (
                                                                                                            <MenuItem key={i} value={data.value}>
                                                                                                                {data.label}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>
                                                                                                )}
                                                                                            />
                                                                                            </FormControl>
                                                                                        </>
                                                                                    }

                                                                                    {
                                                                                        formField.type === 'submit' && <div className="button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 9, marginTop: 5 }} >
                                                                                        <Button type={formField.type} style={{ backgroundColor: '#004AAD', color: 'white', textTransform: 'capitalize' }} className='white' >{formField.label}</Button>
                                                                                    </div>
                                                                                    }
                                                                                    
                                                                                    {errors[formField.name] && (
                                                                                        <p className='pt-1 text-danger small'>{errors[formField.name].message}</p>
                                                                                    )}
                                                                                </div>
                                                                             ))} 
                                                                        </form>
                                                                    </div>
                                                            }       
                                                            {
                                                                (typeof item.content === 'string') && <ListItemText className='receiverMessage' align="left" primary={<div dangerouslySetInnerHTML={{ __html: item.content.replace(/\n/g, '<br />') }} />}></ListItemText>
                                                            }     
                                                            {
                                                                (typeof item.content === 'object' && typeof item.content['choices'] != 'undefined') && 
                                                                item['content']['choices'].map((obj,choicesIndex) => (
                                                                    <div key={`default-${choicesIndex}`} className="mb-3">
                                                                        {
                                                                            obj['text'].map((ob, questionIndex)=>(
                                                                                <div className="mb-3" key={questionIndex}>  
                                                                                    <Grid container>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                                                                                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                                                                                                <Grid item xs={12}>
                                                                                                    <div style={{ border: `2px solid ${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?colors[ob['result']]:'#FFAF0D'}`, width: '100%', padding: 12, backgroundColor: '#FAFAFA', color: '#00295F', borderRadius: 8, fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, }}>
                                                                                                        <Accordion defaultActiveKey="0"  style={{ width: '100%',}}>
                                                                                                            <Accordion.Item eventKey="0">
                                                                                                                <Accordion.Header >{`${choicesIndex + 1}.${ob['Question_Number']} . ${ob['Question']}`}</Accordion.Header>
                                                                                                                <Accordion.Body>
                                                                                                                    <div style={{ width: '100%', height: 2, backgroundColor: `${(typeof ob['result'] !== 'undefined' && typeof ob['submitted'] !== 'undefined')?colors[ob['result']]:'#FFAF0D'}`, marginBottom: 20 }} />
                                                                                                                    <Form>
                                                                                                                            <div key={`default`} className="mb-3">
                                                                                                                                <Form.Check
                                                                                                                                    label={ob['Correct_Options']['A']}
                                                                                                                                    name={`${choicesIndex}`}
                                                                                                                                    type={'radio'}
                                                                                                                                    id={`Correct_Options-A`}
                                                                                                                                    disabled={(typeof ob['submitted'] != 'undefined')}
                                                                                                                                    className={`${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?ob['result']:''}`}
                                                                                                                                    onChange={(event)=>handleSelectionInput(choicesIndex, questionIndex,'selected_answer',{answer:  ob['Correct_Options']['A'],option : 'A'})}
                                                                                                                                />
                                                                                                                                {
                                                                                                                                    (ob['Correct_Options']['A'] === ob['answer_given'] && typeof ob['submitted'] != 'undefined') && <div className='successOrError' style={{ width: '100%', padding: 12, backgroundColor: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].backgroundColor:''}`, color: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].textColor:''}`, borderRadius: 8, borderColor: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].borderColor:''}`, fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, marginBottom: 16, marginTop: -8 }}>
                                                                                                                                    <ReadMoreReact text={ob['Explanation']}
                                                                                                                                        min={3}
                                                                                                                                        max={1000}
                                                                                                                                        readMoreText={`Read More...`} />
                                                                                                                                </div>
                                                                                                                                }
                                                                                                                                <Form.Check
                                                                                                                                    label={ob['Correct_Options']['B']}
                                                                                                                                    name={`${choicesIndex}`}
                                                                                                                                    type={'radio'}
                                                                                                                                    id={`Correct_Options-B`}
                                                                                                                                    disabled={(typeof ob['submitted'] != 'undefined')}
                                                                                                                                    className={`${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?ob['result']:''}`}
                                                                                                                                    onChange={(event)=>handleSelectionInput(choicesIndex, questionIndex,'selected_answer' ,{answer:  ob['Correct_Options']['B'],option : 'B'})}
                                                                                                                                />
                                                                                                                                {
                                                                                                                                    (ob['Correct_Options']['B'] === ob['answer_given'] && typeof ob['submitted'] != 'undefined') && <div className='successOrError' style={{ width: '100%', padding: 12, backgroundColor: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].backgroundColor:''}`, color: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].textColor:''}`, borderRadius: 8, borderColor: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].borderColor:''}`, fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, marginBottom: 16, marginTop: -8 }}>
                                                                                                                                    <ReadMoreReact text={ob['Explanation']}
                                                                                                                                        min={3}
                                                                                                                                        max={1000}
                                                                                                                                        readMoreText={`Read More...`} />
                                                                                                                                </div>
                                                                                                                                }
                                                                                                                                <Form.Check
                                                                                                                                    label={ob['Correct_Options']['C']}
                                                                                                                                    name={`${choicesIndex}`}
                                                                                                                                    type={'radio'}
                                                                                                                                    disabled={(typeof ob['submitted'] != 'undefined')}
                                                                                                                                    id={`Correct_Options-C`}
                                                                                                                                    className={`${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?ob['result']:''}`}
                                                                                                                                    onChange={(event)=>handleSelectionInput(choicesIndex, questionIndex,'selected_answer' ,{answer:  ob['Correct_Options']['C'],option : 'C'})}
                                                                                                                                />
                                                                                                                                {
                                                                                                                                    (ob['Correct_Options']['C'] === ob['answer_given'] && typeof ob['submitted'] != 'undefined') && <div className='successOrError' style={{ width: '100%', padding: 12, backgroundColor: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].backgroundColor:''}`, color: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].textColor:''}`, borderRadius: 8, borderColor: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].borderColor:''}`, fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, marginBottom: 16, marginTop: -8 }}>
                                                                                                                                    <ReadMoreReact text={ob['Explanation']}
                                                                                                                                        min={3}
                                                                                                                                        max={1000}
                                                                                                                                        readMoreText={`Read More...`} />
                                                                                                                                </div>
                                                                                                                                }
                                                                                                                                <Form.Check
                                                                                                                                    label={ob['Correct_Options']['D']}
                                                                                                                                    name={`${choicesIndex}`}
                                                                                                                                    type={'radio'}
                                                                                                                                    disabled={(typeof ob['submitted'] != 'undefined')}
                                                                                                                                    id={`Correct_Options-D`}
                                                                                                                                    className={`${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?ob['result']:''}`}
                                                                                                                                    onChange={(event)=>handleSelectionInput(choicesIndex, questionIndex, 'selected_answer',{answer:  ob['Correct_Options']['D'],option : 'D'})}
                                                                                                                                />
                                                                                                                                {
                                                                                                                                    (ob['Correct_Options']['D'] === ob['answer_given'] && typeof ob['submitted'] != 'undefined') && <div className='successOrError' style={{ width: '100%', padding: 12, backgroundColor: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].backgroundColor:''}`, color: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].textColor:''}`, borderRadius: 8, borderColor: `${(typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined')?infoTextColor[ob['result']].borderColor:''}`, fontFamily: 'Poppins', fontWeight: '500', fontSize: 14, marginBottom: 16, marginTop: -8 }}>
                                                                                                                                    <ReadMoreReact text={ob['Explanation']}
                                                                                                                                        min={3}
                                                                                                                                        max={1000}
                                                                                                                                        readMoreText={`Read More...`} />
                                                                                                                                </div>
                                                                                                                                }
                                                                                                                                { (typeof ob['result'] != 'undefined' && typeof ob['submitted'] != 'undefined') &&
                                                                                                                                    <p style={{textTransform :'capitalize',color : `${(typeof ob['result'] !== 'undefined' && typeof ob['submitted'] !== 'undefined')?colors[ob['result']]:''}`}}> {ob['result']} Answer!</p>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                    </Form>
                                                                                                                    {
                                                                                                                        (typeof ob['submitted'] === 'undefined' && !ob['submitted']) && <div className="button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 9, marginTop: 5 }} >
                                                                                                                        <Button style={{ backgroundColor: '#FFAF0D', color: 'white', textTransform: 'capitalize' }} onClick={()=> handleSelectionInput(choicesIndex, questionIndex, 'submit_response',true)} className='white' >{`Submit Response`}</Button>
                                                                                                                    </div>
                                                                                                                    }                                                                                                                    
                                                                                                                    
                                                                                                                </Accordion.Body>
                                                                                                            </Accordion.Item>
                                                                                                        </Accordion>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                ))
                                                            }    
                                                            {
                                                                (typeof item.content === 'object' && typeof item.content['choices'] != 'undefined') &&
                                                            <Button onClick={generateMoreMcqs} style={{ backgroundColor: '#004AAD', color: 'white', textTransform: 'capitalize' }} className='white' >Generate More Mcqs</Button>
                                                            }                                           
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </ListItem>
                                    }
                                </div>
                            })
                        }
                        <div ref={bottomRef} />
                    </List>
                    <Divider />
                        {
                            type !== 'mcqs-bot-career-pal' && <Grid container className='FormBackground' style={{ padding: '20px', paddingTop: 10 }}>
                                <div style={{ display: 'flex ', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <Form className='ChatForm'>
                                        <Form.Group className="" controlId="formBasicEmail">
                                            <Form.Control placeholder="Type Your Text Here" autoComplete="off" value={message} onKeyDown={handleKeyPress} onChange={(e) => setMessage(e.target.value)} />
                                        </Form.Group>
                                    </Form>
                                    <div >
                                    <input type="file" accept=".pdf" hidden ref={fileInputRef} onChange={handleFileSelect} />
                                        {
                                            (type === 'cv-bot-career-pal' || type === 'skillset-bot-career-pal') && 
                                            <img onClick={handleFileIconClick} style={{ marginRight: 15 }} className='sendIcon' src={require('../../assets/upload_clip.png')} />
                                        }
                                        <img onClick={() => submit(data)} className='sendIcon' src={require('../../assets/send_locn.png')} />
                                    </div>

                                </div>
                            </Grid>
                        }
                </Grid>
            </Grid>
        </div>
        </>
    );
}

export default Chat;