import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import ReadMoreReact from 'read-more-react';

function Mcqs(props) {
    const { mcqQA, type, questionIndex } = props;


    return (
        <>
            <Accordion style={{ marginBottom: 12 }}>
                <Accordion.Item eventKey="0" className='mcq'>
                
                    <Accordion.Header  >{mcqQA.Title}</Accordion.Header>
               
                    <Accordion.Body>
                        <div style={{ width: '100%', height: 2, backgroundColor: '#FFAF0D', marginBottom: 20 }} />
                        <Form>
                            {[type ? type : 'radio'].map((type,i) => (
                                <div key={`${type}`+i+questionIndex} className="mb-3">
                                      {mcqQA.Mcq_Answers.map((Mcq_Answers, index)  => (<Form.Check  key={index}
                                        label={Mcq_Answers.Title}
                                        name={"group1"+questionIndex+i}
                                        type={type}
                                        // id={`${type}-1`}
                                    />))}
                                    {/* <Form.Check
                                        label="1"
                                        name="group1"
                                        type={type}
                                        // id={`${type}-1`}
                                    />
                                    <Form.Check
                                        label="2"
                                        name="group1"
                                        type={type}
                                        // id={`${type}-2`}
                                    />
                                    <Form.Check
                                        label="3"
                                        name="group1"
                                        type={type}
                                        // id={`${type}-3`}
                                    />
                                    <Form.Check
                                        label="4"
                                        name="group1"
                                        type={type}
                                        // id={`${type}-4`}
                                    /> */}

                                </div>
                            ))}
                        </Form>
            {/* <div className='successOrError' style={{width:'100%',padding:12,backgroundColor:'#EBFBF5',color:'#1D7454',borderRadius:8,borderColor:'#2FC08B',fontFamily:'Poppins',fontWeight:'500',fontSize:14,marginBottom:16,marginTop:-8}}>
                    <ReadMoreReact text={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the indust`}
            min={3}
            ideal={100}
            max={100}
            readMoreText={`Read More...`}/>
            </div> */}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

export default Mcqs;