import { CREDIT_DETAILS_ERROR, CREDIT_DETAILS_LOADING, CREDIT_DETAILS_SUCCESS } from "./action";

const initialState = {
  creditDetailsSuccess: null,
  creditDetailsLoading: false,
  creditDetailsError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case CREDIT_DETAILS_SUCCESS: {
      return {
        ...state,
        creditDetailsLoading: false,
        creditDetailsSuccess: action.payload,
        creditDetailsError: null
      }
    }
    case CREDIT_DETAILS_LOADING: {
      return {
        ...state,
        creditDetailsLoading: true,
        creditDetailsError: null
      }
    }
    case CREDIT_DETAILS_ERROR: {
      return {
        ...state,
        creditDetailsLoading: false,
        creditDetailsError: action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default reducer;
