export const ChevronIcon = (props) => (
	<svg
		aria-hidden="true"
		xmlns="http://www.w3.org/2000/svg"
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		stroke="currentColor"
		strokeWidth="2"
		stroke-linecap="round"
		stroke-linejoin="round"
		style={{transition: 'transform 200ms ease-out',marginTop:4}}
	>
		<polyline points="6 9 12 15 18 9"></polyline>
	</svg>
);