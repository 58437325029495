import { api } from "../../utilities/api";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";

export const CREDIT_DETAILS_SUCCESS = 'CREDIT_DETAILS_SUCCESS';
export const CREDIT_DETAILS_ERROR = 'CREDIT_DETAILS_ERROR';
export const CREDIT_DETAILS_LOADING = 'CREDIT_DETAILS_LOADING';

export const getCredits = () => async (dispatch) => {

  let data = {
    method: METHOD_TYPE.get,
    url: API_ENDPOINTS.getCreditDetails,
  };
  dispatch({ type: CREDIT_DETAILS_LOADING });
  return api(data)
    .then(async (response) => {
      return await response.data
    }).then(async (response) => {
      if (response) {
        dispatch({
          type: CREDIT_DETAILS_SUCCESS,
          payload: response
        })

      }
    })
    .catch((error) => {
      dispatch({
        type: CREDIT_DETAILS_ERROR,
        payload: error,
      });
      throw error?.response?.message;
    });
};