import { API_ENDPOINTS, METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";
import Images from "../../../constants/Images";


export const GET_MARKETPLACE_LIST = "GET_MARKETPLACE_LIST";
export const ADD_MARKETPLACE = "ADD_MARKETPLACE";
export const UPDATE_MARKETPLACE = "UPDATE_MARKETPLACE";
export const DELETE_MARKETPLACE = "DELETE_MARKETPLACE";
export const GET_MARKETPLACE_DETAILS = "GET_MARKETPLACE_DETAILS";

export const getMarketplace = (value) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.get_marketing}`,
    };
    return api(data)
      .then(async (response) => {
          return response.data;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
        type: GET_MARKETPLACE_LIST,
        payload: response
    });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};
export const deleteMarketplace = (id) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.delete,
        url: `${API_ENDPOINTS.get_marketing+id+'/'}`,
    };
    return api(data)
      .then(async (response) => {
          return response.data;
        })
        .then(async (response) => {
            if (response) {
                dispatch({
                    type: DELETE_MARKETPLACE,
        payload: response
    });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

export const createMarketplace = (marketingData,id) => async (dispatch) => {
    let data = {
      method: METHOD_TYPE.post,
      url: `${API_ENDPOINTS.get_marketing}`,
      data: {...marketingData,image_id:id},
    };
    return api(data)
    .then(async (response) => {
        return response.data;
    })
      .then(async (response) => {
          if (response) {
              dispatch({
                  type: ADD_MARKETPLACE,
            payload: response,
          });
        }
      })
      .catch((error) => {
        throw error?.response?.data?.message;
      });
};
export const getMarketplaceDetails = (id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: `${API_ENDPOINTS.get_marketing + id + '/'}`,
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        console.log('response',response)
        dispatch({
          type: GET_MARKETPLACE_DETAILS,
          payload: response
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};
export const updateMarketplace = (marketingData,mainId,id) => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.put,
    url: `${API_ENDPOINTS.get_marketing + mainId + '/'}`,
    data:{...marketingData,image_id:id},
  };
  return api(data)
    .then(async (response) => {
      return response.data;
    })
    .then(async (response) => {
      if (response) {
        dispatch({
          type: UPDATE_MARKETPLACE,
          payload: response
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};

