import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";
import { api } from "../../utilities/api";
export const CVEVALUATION_SUCCESS = 'CVEVALUATION_SUCCESS';
export const CVEVALUATION_ERROR = 'CVEVALUATION_ERROR';
export const CVEVALUATION_LOADING = 'CVEVALUATION_LOADING';

export const SKILLSETEVALUATION_SUCCESS = 'SKILLSETEVALUATION_SUCCESS';
export const SKILLSETEVALUATION_ERROR = 'SKILLSETEVALUATION_ERROR';
export const SKILLSETEVALUATION_LOADING = 'SKILLSETEVALUATION_LOADING';

export const MCQS_SUCCESS = 'MCQS_SUCCESS';
export const MCQS_ERROR = 'MCQS_ERROR';
export const MCQS_LOADING = 'MCQS_LOADING';

export const CASESTUDY_SUCCESS = 'CASESTUDY_SUCCESS';
export const CASESTUDY_ERROR = 'CASESTUDY_ERROR';
export const CASESTUDY_LOADING = 'CASESTUDY_LOADING';

export const CODINGSTIMULATION_SUCCESS = 'CODINGSTIMULATION_SUCCESS';
export const CODINGSTIMULATION_ERROR = 'CODINGSTIMULATION_ERROR';
export const CODINGSTIMULATION_LOADING = 'CODINGSTIMULATION_LOADING';

export const INTERVIEW_PREPARATION_SUCCESS = 'INTERVIEW_PREPARATION_SUCCESS';
export const INTERVIEW_PREPARATION_ERROR = 'INTERVIEW_PREPARATION_ERROR';
export const INTERVIEW_PREPARATION_LOADING = 'INTERVIEW_PREPARATION_LOADING';

export const CAREERPATH_SUCCESS = 'CAREERPATH_SUCCESS';
export const CAREERPATH_ERROR = 'CAREERPATH_ERROR';
export const CAREERPATH_LOADING = 'CAREERPATH_LOADING';

export const RESULTORIENTED_SUCCESS = 'RESULTORIENTED_SUCCESS';
export const RESULTORIENTED_ERROR = 'RESULTORIENTED_ERROR';
export const RESULTORIENTED_LOADING = 'RESULTORIENTED_LOADING';

export const FILETOTEXT_SUCCESS = 'FILETOTEXT_SUCCESS';
export const FILETOTEXT_ERROR = 'FILETOTEXT_ERROR';
export const FILETOTEXT_LOADING = 'FILETOTEXT_LOADING';

export const GENERATE_EXAM_SUCCESS = 'GENERATE_EXAM_SUCCESS';
export const GENERATE_EXAM_ERROR = 'GENERATE_EXAM_ERROR';
export const GENERATE_EXAM_LOADING = 'GENERATE_EXAM_LOADING';

export const type = {
    "cv-bot-career-pal": "CVEVALUATION",
    "skillset-bot-career-pal": "SKILLSETEVALUATION",
    "interview-preparation-bot-career-pal": "INTERVIEW_PREPARATION",
    "case-study-bot-career-pal": "CASESTUDY",
    "job-simulation-bot-career-pal": "CODINGSTIMULATION",
    "path-provider-bot-career-pal": "CAREERPATH",
    "result-oriented-upskilling-bot-career-pal": "RESULTORIENTED"
}

export const careerPalResponse = (formData) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.carrerpal,
        data: formData
    };
    dispatch({
        type: `${type[formData.promptName]}_SUCCESS`,
        payload: formData,
    });
    dispatch({ type: `${type[formData.promptName]}_LOADING` });

    return api(data).then(async (response) => {
        if (response) {
            const updatedSuccess = {
                ...formData,
                conversationHistory: [...formData.conversationHistory, {
                    role: 'assistant',
                    content: response.data.data.response
                }],
              };

            dispatch({
                type: `${type[formData.promptName]}_SUCCESS`,
                payload: updatedSuccess,
            });
            return response.data;
        }
    }).catch((error) => {
        dispatch({
            type: `${type[formData.promptName]}_ERROR`,
            payload: error,
        });
        throw error.response.data.message || error.message;
    });
}

export const convertFileToText = (file) => async (dispatch) => {
    
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.convertFileToText}`,
        data: file,
        headers: {
            'Content-Type': 'application/pdf', // Set the Content-Type header to indicate the CSV file
        },
    };
    dispatch({
        type: FILETOTEXT_LOADING
    })

    return api(data)
        .then(async (response) => {
            return await response.data
        }).then(async (response) => {
            dispatch({
                type: FILETOTEXT_SUCCESS,
                payload: response.data,
            });
            return response
        })
        .catch((error) => {
            dispatch({
                type: FILETOTEXT_ERROR,
                payload: error,
            });
            throw error;
        });
}

export const generateExam = (payload) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.post,
        url: `${API_ENDPOINTS.generateExam}`,
        data: payload,
    }
    dispatch({
        type: GENERATE_EXAM_LOADING
    })
    return api(data).then(async (response) => {
        if (response) {

            dispatch({
                type: GENERATE_EXAM_SUCCESS,
                payload: response.data,
            });
            return response.data;
        }
    }).catch((error) => {
        dispatch({
            type: GENERATE_EXAM_ERROR,
            payload: error,
        });
        throw error.response.data.message || error.message;
    });
}
