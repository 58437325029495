import { useEffect, useState } from "react";
import Colors from "../../constants/Colors";
import Images from "../../constants/Images";
import UpskillrUtils from "../../utilities/UpskillrUtils";
import './index.scss';
import moment from 'moment';
import { getUserOrders } from "./action";
import { connect, useSelector } from "react-redux";
import Loader from '../../components/Loader';


const OrderHistory = (props) => {

    const { loading } = useSelector((state)=> state.CurriculumReducer);

    const { height, width } = UpskillrUtils.useWindowDimensions();
    useEffect(()=>{
        props.getUserOrders()
    },[])

    return (
        <>
        {loading && <Loader /> }
        <div className="orderHistory">
            <div className='tabContentHeader mb-4'>
                <div className='tabContentHeaderBox'>
                    <h2 className='pageHeading'>Order History</h2>
                    <p className="headingDetails">Effortlessly track and manage your past orders with our intuitive Order History feature.</p>
                </div>
            </div>
            {props.orderList &&  props.orderList.length < 1 &&
                <div style={styles.noData(height / 2)}>
                    <div>
                        <img src={Images.CartIcon} style={styles.cartIconStyle} />
                    </div>
                    <p className="no-cart-details">You don’t have any plan purchases.</p>
                </div>
            }
            { (props.orderList &&  props.orderList.length > 0) &&
                props.orderList.map((item,index)=>(
                    <div className='container mb-4 cardStyle' key={'cc'+index} >
                        <div className="d-flex align-items-center cardHeaderBar">
                            <div className="d-flex align-items-center">
                            {item.active &&  <div style={styles.greenElepse}></div>}
                                <span className={item.active ? "planType active-plan" : "planType"}>{item?.Type}</span>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="dotSTyle"></div>
                                <span style={{color: '#778599'}}>Purchased on {moment(item?.order_date).format('MMM DD, dddd')}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <div>
                                    <ul>
                                        {item.highlights.split(',').map((listData, i)=>(
                                            <li key={'list'+i}>{listData}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className={item.active?"credit-earn active-plan":"credit-earn"}>Credits Earned </div>
                                <div className={item.active?"credit-amount active-plan":"credit-amount"}>{item?.Credits}</div>
                            </div>
                        </div>
                    </div>
                ))
                

            }
        </div>
        </>
    );
}

const mapStateToProps = ({ userOrderHistory }) => {
    const { orderList } = userOrderHistory;
    return { orderList };
}

export default connect(mapStateToProps, {
    getUserOrders
})(OrderHistory);

const styles = {
    noData(height) {
        return { display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: height }
    },
    cartIconStyle: { width: 50, height: 50, objectFit: 'contain' },
    cardStyle:{borderWidth:1, borderColor:'red'},
    greenElepse:{ width: 20, height: 20, backgroundColor: '#13873A', borderRadius: 20, marginRight:15 }
}