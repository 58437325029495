import  React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import dateFormat from 'dateformat';
import UpskillrTypes from "./UpskillrTypes";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const UpskillrUtils = {
    useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    
        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
    
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);
    
        return windowDimensions;
    },
    getNormalizedSize(px = 0, vw = 0, vh = 0){
        const {width, height} = this.useWindowDimensions();
        if(px != 0){
            if(vw){
                return (300 * px / width);
            } else {
                return (300 * px / height);
            }
        } else if(vw != 0 && vh != 0){
            var w_h_arr = [];
            w_h_arr["width"] = Math.ceil((width * vw / 100));
            w_h_arr["height"] = Math.ceil((height * vh / 100));
            return w_h_arr;
        } else if(vw != 0){
            return Math.ceil((width * vw / 100));
        } else if(vh != 0){
            return Math.ceil((height * vh / 100));
        }
    },
    ScrollToTop(){
        const { pathname } = useLocation();
      
        useEffect(() => {
          // "document.documentElement.scrollTo" is the magic for React Router Dom v6
          document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
        }, [pathname]);
      
        return null;
      },
    
    timeStampToDateString(timeStamp, format) {
        if (!format)
            format = "dd/mm/yyyy";
        let date = new Date(timeStamp);
        timeStamp = timeStamp + (date.getTimezoneOffset() * 60000);
        if (timeStamp)
            return this.getDateString(new Date(timeStamp), format);
        return '';
    },
    timeStampToDate(timeStamp, format) {
        if (!format)
            format = "dd/mm/yyyy";
        let date = new Date(timeStamp);
        timeStamp = timeStamp + (date.getTimezoneOffset() * 60000);
        if (timeStamp)
            return this.getDate(new Date(timeStamp), format);
        return new Date();
    },
    timeNowInMilli() {
        return (new Date).getTime();
    },
    timeStampToDateTime(timestamp) {
        return moment(timestamp).format("DD MMM YYYY | hh:mm A")
    },
    getDateString(date, format) {
        if (!format)
            format = "dd mmm yyyy";
        let formattedDate = dateFormat(date, format);
        return formattedDate.toString();
    },
    dateStringToTimeStamp(dateString, dateFormat) {
        let finalDateFormat = dateFormat;
        if (finalDateFormat == null) {
            finalDateFormat = 'yyyy-mm-dd hh:mm:ss';
        }
        let date = moment.utc(dateString, finalDateFormat).toDate();
        return date.getTime();
    },
    getDate(date, format) {
        if (!format)
            format = "dd mmm yyyy";
        let formattedDate = dateFormat(date, format);
        return formattedDate;
    },
    daysBetweenDates(first, second) {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
    days2TimeMillis(days) {
        return (days * 1000 * 60 * 60 * 24);
    },
    timeMillis2Days(time) {
        return UpskillrTypes.getIntValue(time / (1000 * 60 * 60 * 24));
    },
    getOnlyAlphabets(s) {
        return s.replace(/[^a-z]/gi, '');
    },
    getTimeFromSeconds(seconds) {
        momentDurationFormatSetup(moment);
        return moment.duration(seconds, "seconds").format('hh:mm:ss');
    },
    isPastDate(timeInMillis) {
        return timeInMillis != null && timeInMillis <= (this.timeNowInMilli() - this.days2TimeMillis(1));
    },
    isEmptyString(str) {
        if (str == null) {
            return true;
        }
        else if (str.toString().trim().length === 0) {
            return true;
        }
    },
    

    formatPercentage(percentage) {
        percentage = Number(percentage.toFixed(2));
        let decimalPart = Math.trunc(percentage);
        if (percentage - decimalPart === 0) {
            return decimalPart;
        }
        return percentage;
    },
    formatWithSpecialCharacters(value) {
        return value.replace(/[^\w\s'-']/gi, '');
    },
    removeAllWhiteSpaces(value) {
        return value.replace(/\s/g, '');
    },
    customListItems(list, idKey, nameKey) {
        let listItems = [];
        for (let i = 0; i < list.length; i++) {
            listItems.push({ id: list[i][idKey], name: list[i][nameKey] });
        }
        return listItems;
    },
    getCurrentMonth() {
        return moment().format('MMMM')
    },
    getCurrentDay() {
        return new Date().getDay();
    },
    getCurrentTime() {
        return new Date().getHours();
    },
    getStartOfTheDay(date) {
        return moment.utc(date).startOf('day').toDate();
    },
    validateNameText(text) {
        let t = /^[a-zA-Z][a-zA-Z\s]*$/;
        return t.test(text);
    },
    getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    },
    isJson(item) {
        item = typeof item !== "string"
            ? JSON.stringify(item)
            : item;
        try {
            item = JSON.parse(item);
        }
        catch (e) {
            return false;
        }
        return typeof item === "object" && item !== null;
    },
    getDaysDiff(start_date, end_date, date_format = 'MM/DD/YYYY') {
        const getDateAsArray = (date) => {
            return moment(date.split(/\D+/), date_format);
        };
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days');
    },
    getDateStringFromTimeStamp(timeStamp, format) {
        return moment.utc(timeStamp).format((format != null ? format : "MM/DD/YYYY"));
    },
    getCurrentDateUTCTimeStamp() {
        return moment.utc().valueOf();
    },
    getMonthNameByMonthId(monthNo) {
        return moment().month(monthNo - 1).format("MMMM");
    },
    getShortMonthNameByMonthId(monthNo) {
        return moment().month(monthNo - 1).format("MMM");
    },
    addExtraMonthForSameDate(date, NoOfMonths) {
        return moment.utc(date).add(NoOfMonths, 'M').toDate();
    },
    addExtraYearForSameDate(date, NoOfYears) {
        return moment.utc(date).add(NoOfYears, 'Y').toDate();
    },
    extractNumber(str) {
        //returns number from string
        return str ? str.replace(/[^0-9]/g, '') : 0;
    },
    isEmptyObj(obj) {
        if (!obj)
            return false;
        return JSON.stringify(obj) === JSON.stringify({});
    },
    /*
        Function to return value inside of object by accepting nested path as string
        e.g. if we have to access Data from a nested obj like:
        let tempObj = {a: {b: "value of b"},c: "value of c"}
        we can access b which is inside a as:
        let valueOfB = resolvePath(tempObj, 'a.b');
    returns 'value of b'
    */
    resolvePath(obj, path) {
        try {
            return eval("obj." + path);
        }
        catch (e) {
            return undefined;
        }
    },
    //To get 1st 2nd 3rd and 4th as number
    nth(num) {
        if (num > 3 && num < 21)
            return 'th';
        switch (num % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    },
    // returns 1st, 2nd...31st
    getDaysOfMonth() {
        return [...Array(32).keys()].slice(1).map(i => `${i}${this.nth(i)}`);
    },
    
    //Convert normal int to kth number ksh format 1k 1.2k
    intToKthString(num, digits = 1) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "K" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    },
    /*
    *   This function return base64 from blob to be used by PDF viewer
    *   Used in invoice API by augmont
    *   add then and in the param you'll get base64
    * */
    blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    },
    /*
    *   For Digigold timer to start countdown timer, with Day:Hrs:Min:seconds
    *    7:6:30:02
    * */
    timeMillis2DHMs(ms) {
        const days = Math.floor(ms / (24 * 60 * 60 * 1000));
        const daysms = ms % (24 * 60 * 60 * 1000);
        const hours = Math.floor(daysms / (60 * 60 * 1000));
        const hoursms = ms % (60 * 60 * 1000);
        const minutes = Math.floor(hoursms / (60 * 1000));
        const minutesms = ms % (60 * 1000);
        const sec = Math.floor(minutesms / 1000);
        return this.padTo2Digits(days) + ":" + this.padTo2Digits(hours) + ":" + this.padTo2Digits(minutes) + ":" + this.padTo2Digits(sec);
    },
    /*
    *   Convert number to start with 0
    *   e.g 6 to 06
    * */
    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    },
    getIndianCurrencyFormatter(num, rupeeSymbol) {
        //It will separate a number with commas as per indian standards
        // e.g. 1000 -> 1,000 and 1000000 -> 10,00,000
        // if(!rupeeSymbol)
        //     rupeeSymbol = Images.indianRs;
        if (!num) {
            num = 0
        }
        let parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
        return rupeeSymbol ? rupeeSymbol +  parts.join(".") : parts.join(".");
    },

    // Updated helper function to build URL with dynamic parameters
    buildUrlWithParams(baseUrl, dynamicParams) {
        // Replace 'YOUR_BASE_URL' with the base URL of your API endpoint
        let url = baseUrl;
        // Loop through the dynamicParams object and append parameters to the URL
        if(dynamicParams){
            for (const [index, [key, value]] of Object.entries(Object.entries(dynamicParams))) {
                if (dynamicParams.hasOwnProperty(key)) {
                    url += (index === "0" ? '?' : '&') + `${key}=${encodeURIComponent(value)}`;
                }
            }
        }
        return url;
    },
};
export default UpskillrUtils;
