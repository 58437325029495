import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import Sidebar from "../../Components/Navigation/Sidebar";
import Topbar from "../../Components/Navigation/Topbar";
import PageHeading from "../../Components/PageHeading";
import {
  usersList,
  createUser,
  updateUser,
  deleteUser,
  getUserDetail,
} from "./action";
import { DELETE_MESSAGE } from "../../../utilities/message";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from 'react-toastify'
import CustomDialog from "../../Components/CustomDialog";

//jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

const Users = (props) => {
  $(document).ready(function () {
    setTimeout(function () {
      $("#dataTable").DataTable();
    }, 1000);
  });

  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState(null);
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const [userListArr, setUserListArr] = useState([]);
  const [type, setType] = useState('')
  const [check, setCheck] = useState(Boolean)
  const [userId, setUserId] = useState(null)
  const history = useNavigate();

  useEffect(() => {
    props.usersList(type);
  }, []);

  const handleDialogClose = () => {
    setId(null);
    setShowDialog(false);
  };

  const deleteUser = async (id) => {
    setId(id);
    setDialogSubmit(true);
    setMessage(DELETE_MESSAGE);
    setShowDialog(true);
  };

  const handleDialogSubmit = () => {
    props?.deleteUser(id)
      .then(async (response) => {
        onResponseRecieved(response);
      })
      .catch((error) => {
        onResponseFailed(error);
      });
      
  };

  const onResponseRecieved = (response) => {
    props?.usersList();
    setDialogSubmit(false);
    setMessage(response?.message);
    setOpenModal(false);
    setShowDialog(false);
    toast.success(response?.message)
  };
  
  const onResponseFailed = (error) => {
    setDialogSubmit(false);
    setMessage(error?.response?.message);
    setOpenModal(false);
    setShowDialog(true);
    toast.success(error?.response?.message)
  };

  const handleEditData = (userData) => {
    history("/admin/user/addNew", {
      state: { id: userData?.id },
    });
  };

  const deleteUserDialog = () => {
      props?.deleteUser(id)
      .then(response => {
        setShowDialog(false)
        toast.success(response?.message)
      }).catch(error =>{
        setShowDialog(false)
        toast.error('Something went wrong')
      }
      )
  }

  const handleChange = (e, id) => {
    setUserId(id)
    setShowDialog(true)
  }

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="contents">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Users"
                buttonName=""
                buttonLink=''
                buttonIcon=""
              />

              <div>

              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    User Management
                  </h6>
                  {/* <div className="form-group mb-0">
                    <select
                      id="profession"
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">Admin</option>
                      <option value="users">Users</option>
                    </select>
                  </div> */}
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >
                        <thead>
                          <tr>
                            <th className="text-capitalize">User Id</th>
                            <th className="text-capitalize">Full Name</th>
                            <th className="text-capitalize">User Name</th>
                            <th className="text-capitalize">Email</th>
                            <th className="text-capitalize">Phone Number</th>
                            {/* <th className="text-capitalize">Password</th> */}
                            <th className="text-capitalize">Institute</th>
                            <th className="text-capitalize">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props?.allUsersList?.map((user, index) => (
                            <tr key={"tr" + index}>
                              <td>{user?.id}</td>
                              <td className="text-capitalize">{user?.fullname}</td>
                              <td className="text-capitalize">{user?.username}</td>
                              <td>{user?.email}</td>
                              <td className="text-capitalize">
                                {user?.phone}
                              </td>
                              {/* <td className="text-capitalize">
                                {user?.password}
                              </td> */}
                              <td className="text-capitalize">
                                {user?.institute}
                              </td>
                              {/* <td className="text-capitalize">
                                {user?.subscription?.planType || null}
                              </td>
                              <td className="text-capitalize">
                                {user?.subscription?.endDate && user?.subscription?.endDate.split('T')[0]}
                              </td>
                              <td className="text-capitalize">
                                {user?.isActive ? "Active" : "Inactive"}
                              </td> */}
                              <td className="action">
                                  {
                                    <a className="text-primary">
                                      <i
                                        className="far fa-edit"
                                        onClick={() => handleEditData(user)}
                                        style={{marginRight:10}}
                                      ></i>
                                    </a>
                                  }
                                  {
                                    <a
                                      className="deletePost delete text-danger"
                                      rowid="18"
                                      onClick={() => deleteUser(user?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  }
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        message={DELETE_MESSAGE}
        showDialog={showDialog}
        handleClose={() => handleDialogClose()}
        handleDialogSubmit={() => handleDialogSubmit()}
        isSubmit
      />
    </div>
  );
};


// const mapStateToProps = (state) => {
//   const { allUsersList } = state.adminUserReducer;
//   return  {allUsersList} ;
// };
const mapStateToProps = (state) => {
  const { usersList, updateUserDetail,allUsersList } = state.adminUserReducer;
  const { rbackRules } = state.adminReducer;
  return { userList: usersList, updateUserDetail, rbackRules ,allUsersList};
};

export default connect(mapStateToProps, {
  usersList,
  createUser,
  updateUser,
  deleteUser,
  getUserDetail,
})(Users);

// export default (Users);
