import axios from "axios";
import { BASE_URL } from "./constants";
import { logout } from "../pages/login/action";
import { API_ENDPOINTS, METHOD_TYPE } from "./apiUrls";

export const api = axios.create({
    baseURL: `${BASE_URL}/`,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    },
    // headers: {
    //     'ngrok-skip-browser-warning' : true,
    //     'user-agent' : 'axios'
    // },
});

api.interceptors.request.use(function (config) {
    const token = localStorage.getItem('user');
    token && (config.headers.Authorization = "Bearer " + token);
    return config;
});
    let isRefreshing= false;
    let failedQueue= [];

    const processQueue = (error, token) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const attachTokenToRequest = (request, token) => {
        if(request.url === "/engine/article-generator/"){
            request.headers['Content-Type'] = 'multipart/form-data';
        }
        request.headers['Authorization'] = 'Bearer ' + token;
    };

    const shouldIntercept = (error) => {
        try {
            return error.response.status === 401
        } catch (e) {
            return false;
        }
    };

    const expiredRefreshToken = (error, url) => {
        try {
            return error.response.status === 401 && API_ENDPOINTS.refreshToken === url
        } catch (e) {
            return false;
        }
    };

export const ResponseInterceptor = (dispatch) => {

    return api.interceptors.response.use(
        (response) => {
            return response;
        },async function (error) {
            
            if (expiredRefreshToken(error, error.config.url)) {
                console.log("Token expired.........")
                delete error.config.headers.Authorization;
                localStorage.removeItem('refresh');
                localStorage.removeItem('user_id');
                await localStorage.removeItem('user')
                await localStorage.removeItem('isAdmin')
                dispatch(logout());
                window.location.replace('/login');
            }
    
            if (!shouldIntercept(error)) {
                return Promise.reject(error);
            }
    
            if (error.config._retry || error.config._queued) {
                return Promise.reject(error);
            }
    
            const originalRequest = error.config;

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject})
                }).then(token => {
                    originalRequest._queued = true;
                    attachTokenToRequest(originalRequest, token);
                    return api.request(originalRequest);
                }).catch(err => {
                    return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
                })
            }
    
            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                let data = {
                    method: METHOD_TYPE.post,
                    url: API_ENDPOINTS.refreshToken,
                    data: {"refresh":localStorage.getItem('refresh')}
                };
                api.request(data).then((data) => {
                    const {access} = data.data;
                    localStorage.setItem('user', access);
                    attachTokenToRequest(originalRequest, access);
                    processQueue(null, access);
                    resolve(api.request(originalRequest));
                }).catch((err) => {
                    processQueue(err, "");
                    reject(err);
                }).finally(() => {
                    isRefreshing = false;
                })
            });
        }
    );
}

