import React, { useState } from 'react'
import { useCollapse } from 'react-collapsed'
import { ChevronIcon } from './ChevronIcon'
import './CollapsableView.css'
import './Table.scss'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem, Button } from '@mui/material';
import Images from '../../constants/Images';
import Colors from '../../constants/Colors';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import AddIcon from '../../assets/add_icon.png'
import EditIcon from '../../assets/edit_icon.png'
import DeleteIcon from '../../assets/delete_icon.png'
import { useRef } from 'react'
import { useEffect } from 'react'
import AutoWidthInput from '../AutoWidthInput/AutoWidthInput'


function CollapsableView(props) {
	const { children, title, childStyles, titleStyles, containerStyles, showIcon, showDots, showButtons, addIconCLick, editIconCLick, deleteIconCLick, showAddIcon, currentIndex, handleEditClick, editingItemIndex, handleInputChange, handleInputCrossClick, builderCheckRight, handleDeleteClick, handleAddClick, handleInputBlur, inputTitle} = props
	const [isExpanded, setExpanded] = useState(true)
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [show, setShow] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		if (editingItemIndex === currentIndex && inputRef.current) {
			inputRef.current.focus();
		}
	}, [editingItemIndex, currentIndex]);

	const handleCloseResult = () => setShow(false);
	const handleShowResult = () => setShow(true);
	

	return (
		<>
			<div className='Collapsablecard' style={{ padding: 12, backgroundColor: '#B5D2F9', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', ...containerStyles }} onClick={props.onClick}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }} {...getToggleProps({
					onClick: () => setExpanded((prevExpanded) => !prevExpanded),
				})}>
					{showIcon !== false && <span class={`ChevronButton ${isExpanded ? 'ActiveChevron' : ''}`}>
						<ChevronIcon />
					</span>}

					{editingItemIndex === currentIndex ? (
						<div className="input-field position-relative" style={{width : '100%'}}>
							{/* <input
								type="text"
								value={title}
								className={`form-control`}
								style={{
									minWidth: `100px`,
									maxWidth:'99.9%',
									transition:'width 0.25s'
								  }}
								onChange={(e) => handleInputChange(e, currentIndex)}
								onBlur={() => handleInputBlur(title, currentIndex)}
								ref={inputRef}
							/> */}

							<AutoWidthInput key={currentIndex} title={title} initialValue={inputTitle} editingItemIndex={editingItemIndex} currentIndex={currentIndex} onBlurFunction={handleInputBlur} onChangeFunction={handleInputChange}/>

							<span onClick={()=>builderCheckRight(inputTitle, title, currentIndex)} className='position-absolute' style={{right:window.innerWidth < 500 ?'24%':'25%',top:10}}><i style={{color:'green'}} class="fa fa-check"></i> </span>
							<span className='position-absolute' onClick={handleInputCrossClick} style={{right:window.innerWidth < 500 ? '15%':'22%',top:10}}><i style={{color:'red'}} class="fa fa-times-circle"></i> </span>
						</div>

					) : (
						<h1 style={{ width: '100%', fontSize: 16, marginLeft: 12, marginBottom: 0, ...titleStyles }}>{title}</h1>
					)}

				</div>
				{showDots !== false &&
					<div>
						<div className='floatingBtn'>
							<Button
								// id="basic-button"
								aria-controls={open ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
								style={{ color: Colors.dark_blue }}
							>
								<MoreHorizIcon />
							</Button>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
							>
								<MenuItem onClick={handleClose}>
									<img src={Images.Rename} style={{ width: 16, height: 16, marginRight: 8 }} />Rename
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<img src={Images.CirclePlus} style={{ width: 16, height: 16, marginRight: 8 }} />New Sub-topic
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<img src={Images.GenerateMcqs} style={{ width: 16, height: 16, marginRight: 8 }} /> Generate MCQ’s
								</MenuItem>
								<MenuItem onClick={() => { handleClose(); handleShowResult() }}>
									<img src={Images.CircleCheck} style={{ width: 16, height: 16, marginRight: 8 }} /> See Results
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<img src={Images.Trash} style={{ width: 16, height: 16, marginRight: 8 }} /> Delete Sub-topic
								</MenuItem>
							</Menu>
						</div>
					</div>
				}
				{showButtons === true &&
					<div className='d-flex align-items-center' style={{ gap: window.innerWidth < 500 ? 10 : 20, marginRight: window.innerWidth < 500 ? 0 : 20 }}>
						{showAddIcon !== false && <img onClick={()=>handleAddClick(currentIndex)} src={AddIcon} style={{ width: 22, cursor: 'pointer' }} />}
						<img onClick={()=>handleEditClick(currentIndex, title)} src={EditIcon} style={{ width: 22, cursor: 'pointer' }} />
						<img onClick={()=>handleDeleteClick(currentIndex)} src={DeleteIcon} style={{ width: 22, cursor: 'pointer' }} />
					</div>
				}
			</div>
			<section {...getCollapseProps()}>
				<div style={{ backgroundColor: '#E9F3FF', cursor: 'pointer', ...childStyles }}>
					<p style={{ color: '#001F49', fontFamily: 'Poppins', fontSize: 12, marginBottom: 0 }}>{children}</p>
				</div>
			</section>




			<Modal size="lg" className='Tables'
				aria-labelledby="contained-modal-title-vcenter"
				centered show={show} onHide={handleCloseResult}>
				{/* <Modal.Header closeButton style={{marginTop:-50}} /> */}
				<Button onClick={handleCloseResult} style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
					<img src={require('../../assets/table_close_icon.png')} style={{ width: 32, height: 32, marginTop: -50 }} />
				</Button>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th className='Headtable'>Sr. No</th>
							<th className='Headtable'>Name</th>
							<th className='Headtable'>Email</th>
							<th className='Headtable'>Score</th>
							<th className='Headtable'>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>01</td>
							<td>Arnav Chakraborty</td>
							<td>arnavchakraborty@gmail.com</td>
							<td>09 / 10</td>
							<td className='ViewDetails'>View Details</td>
						</tr>
						<tr>
							<td>02</td>
							<td>Aditi Gupta</td>
							<td>aditi.gupta@gmail.com</td>
							<td>06 / 10</td>
							<td className='ViewDetails'>View Details</td>
						</tr>
						<tr>
							<td>03</td>
							<td>Arnav Chakraborty</td>
							<td>arnavchakraborty@gmail.com</td>
							<td>06 / 10</td>
							<td className='ViewDetails'>View Details</td>
						</tr>
					</tbody>
				</Table>
			</Modal>
		</>
	)
}
export default CollapsableView;