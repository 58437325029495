import React, { useState, useEffect } from "react";
import "./index.scss";
import Sidebar from "../../Components/Navigation/Sidebar";
import Topbar from "../../Components/Navigation/Topbar";
import PageHeading from "../../Components/PageHeading";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify'
import Loader from "../../../components/Loader";
import TextEditor from "../../../components/multiSearch/TextEditor";
import { createSubscription, updateSubscriptionDetails, getSubscriptionDetail } from "../action"
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import QuillTextEditor from "../../../components/multiSearch/QuillTextEditor";

const AddSubscription = (props) => {
    const [message, setMessage] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(null);
    const [Type, setType] = useState('');
    const [Price, setPrice] = useState('');
    const [Name, setName] = useState('');
    const [credits, setCredits] = useState('');
    const [Description, setDescription] = useState('');
    const [Highlights, setHighlights] = useState('');
    const [selectedValue, setSelectedValue] = useState('');

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
        setType(event.target.value);
    };

    const history = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state?.id) {
            props.getSubscriptionDetail(state.id);
        }
    }, [state?.id]);

    useEffect(() => {
        if (props.subscriptionDetailData) {
            setSelectedValue(props.subscriptionDetailData.Type)
            setType(props.subscriptionDetailData.Type);
            setName(props.subscriptionDetailData.Name);
            setCredits(props.subscriptionDetailData.credits);
            setPrice(props.subscriptionDetailData.Price);
            setDescription(props.subscriptionDetailData.Description);
            setHighlights(props.subscriptionDetailData.Highlights);
        }
    }, [props.subscriptionDetailData]);

    // const handleChange = (e) => {
    //     setFormData((prev) => ({
    //         ...prev,
    //         [e.target.name]: e.target.value,
    //     }));
    // };

    const submitModal = async (e) => {
        e.preventDefault();
        const formDataItem = {
            Type,
            Price,
            Description,
            Highlights,
            Name,
            credits
          };
          if (!Type || Price === '' || !Description || !Highlights || !Name || !credits) {
            toast.error('All fields are required.');
            return;
          }
        if (state?.id) {
            props.updateSubscriptionDetails(formDataItem, state?.id)
                .then(async (response) => {
                    onResponseRecieved(response);
                    toast.success("Subscription Updated SUccessfully")

                })
                .catch((error) => {
                    onResponseFailed(error);
                });
        } else {

            props.createSubscription(formDataItem)
                .then(async (response) => {
                    onResponseRecieved(response);
                    toast.success("Subscription Added SUccessfully")
                })
                .catch((error) => {
                    onResponseFailed(error);
                });
        }
    };
    const onResponseRecieved = (response) => {
        // setDialogSubmit(true);
        setMessage(response?.message);
        setOpenModal(true);
        setShowDialog(true);
        toast.success(response?.message)
        history("/admin/subscriptions");

    };
    const onResponseFailed = (error) => {
        // setDialogSubmit(false);
        setMessage(error?.message);
        setOpenModal(false);
        setShowDialog(true);
    };


    return (
        <div>
            {/* <!-- Page Wrapper --> */}
            <div id="wrapper" className="addSubscription">
                {/* <!-- Sidebar --> */}
                {false && <Loader />}
                <Sidebar />
                {/* <!-- End of Sidebar --> */}

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        {/* <!-- Topbar --> */}
                        <Topbar />
                        {/* <!-- End of Topbar --> */}

                        {/* <!-- Begin Page Content --> */}
                        <div className="container-fluid">
                            {/* <!-- Page Heading --> */}
                            <PageHeading title="Add new subscription" />

                            {/* <!-- Content Row --> */}

                            <div className="card shadow mb-4" id="addNewMaster">
                                <div className="card-body">
                                    <Form>
                                        <div className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="text-capitalize">
                                                    Type
                                                </Form.Label>
                                                {/* <Form.Control
                                                    className="form-control"
                                                    placeholder="Enter subscription type"
                                                    name="Type"
                                                    value={Type}
                                                    onChange={(e) => setType(e.target.value)}
                                                /> */}
                                                <Form.Select onChange={handleSelectChange} value={selectedValue} className="form-control w-100">
                                                    <option>Please select Type</option>
                                                    <option value="Mini Starter">Mini Starter</option>
                                                    <option value="Quick Boost">Quick Boost</option>
                                                    <option value="Monthly Saver">Monthly Saver</option>
                                                    <option value="Tailored">Tailored</option>
                                                    <option value="Starter Pack">Starter Pack</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="text-capitalize">
                                                    Price
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholder="Enter price"
                                                    name="Price"
                                                    value={Price}
                                                    onChange={(e) => setPrice(e.target.value)}
                                                    inputMode="numeric"
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="text-capitalize">
                                                    Name
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholder="Enter name"
                                                    name="Name"
                                                    value={Name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="text-capitalize">
                                                    Credits
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholder="Enter Credits"
                                                    name="credits"
                                                    value={credits}
                                                    onChange={(e) => setCredits(e.target.value)}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="text-capitalize">
                                                    Descriptions
                                                </Form.Label>
                                                {/* <CKEditor
                                                    editor={ClassicEditor}
                                                    data={Description}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setDescription(data);
                                                    }}
                                                    config={{
                                                        toolbar: {
                                                            items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'undo', 'redo'],
                                                        },
                                                    }}
                                                /> */}
                                                <QuillTextEditor
                                                    placeholder={""}
                                                    editorValue={Description}
                                                    handleChange={(html) => setDescription(html)}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="text-capitalize">
                                                    Highlights
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholder="Enter highlight"
                                                    name="Highlights"
                                                    value={Highlights}
                                                    onChange={(e) => setHighlights(e.target.value)}
                                                />
                                                {/* {highlights.map((field, index) => (
                                                <div key={index} className="hightlighList">
                                                 <Form.Control type="text" placeholder="Enter highlight" value={field.value} onChange={(event) => handleHighlightsChange(index, event)} />
                                                <a className="btnStyle deleteHightligh text-danger" onClick={() => removeField(index)}>
                                                    <i className="fa fa-trash"></i>
                                                </a>
                                                </div>
                                                ))
                                                }
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', }}>
                                                    <Button className='btnStyle' onClick={addField}><i className="fa fa-plus"></i></Button>
                                                </div> */}
                                            </Form.Group>
                                        </div>
                                        <div className="form-group mt-5 d-flex justify-content-end">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={submitModal}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ adminUpdateSubscriptionReducer }) => {
    const { subscriptionDetailData } = adminUpdateSubscriptionReducer;
    return { subscriptionDetailData };
};
export default connect(mapStateToProps, {
    createSubscription,
    updateSubscriptionDetails,
    getSubscriptionDetail
})(AddSubscription);

