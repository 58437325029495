import React, { useState } from 'react';
import "../Recognitions/Recognitions.css"
import Colors from '../../constants/Colors';
import Slider from 'react-slick';
import Images from '../../constants/Images';


var settings = {
  dots: false,
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  initialSlide: 0,
  arrow:false,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
};
function Recognitions(props) {
  const { recognition } = props;
  if(recognition && recognition.length < 7){
    settings.slidesToShow = recognition.length;
  }
  
    return (
        <>
        {
          recognition && recognition.length > 0 ? (
            <section style={{paddingTop:25}}>
                <h1 className='section_title' style={{color:Colors.dark_blue}}>Recognitions</h1>
                
                <Slider {...settings} style={{marginTop:40,marginBottom:80}}>
                {recognition.map((el, i)=>{
                  return(
                    <div key={i}>
                      <img src={el.image.media} style={{width:'100%',height:115, objectFit:'contain'}}/>
                    </div>
                  )
                })}
              </Slider>
            </section>
            ) : null
          }
        </>
    );
}

export default Recognitions;