import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { getHomeOverviews,deleteHomeOverviews } from "./action";
import { DELETE_MESSAGE } from "../../../utilities/message";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from 'react-toastify'
import CustomDialog from "../../Components/CustomDialog";

//jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Sidebar from "../../Components/Navigation/Sidebar";
import Topbar from "../../Components/Navigation/Topbar";
import PageHeading from "../../Components/PageHeading";

const HomeOverviews = (props) => {
  $(document).ready(function () {
    setTimeout(function () {
      $("#dataTable").DataTable();
    }, 1000);
  });

  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState(null);
  const [dialogSubmit, setDialogSubmit] = useState(false);
  const [userId, setUserId] = useState(null)
  const history = useNavigate();

  const handleDialogClose = () => {
    setId(null);
    setShowDialog(false);
  };

  const deleteHomeOverviews = async (id) => {
    setId(id);
    setDialogSubmit(true);
    setMessage(DELETE_MESSAGE);
    setShowDialog(true);
  };

  const handleDialogSubmit = () => {
    props
      .deleteHomeOverviews(id)
      .then(async (response) => {
        onResponseRecieved(response);
      })
      .catch((error) => {
        onResponseFailed(error);
      });
  };

  const onResponseRecieved = (response) => {
    props.getHomeOverviews();
    setDialogSubmit(false);
    setMessage(response?.message);
    setOpenModal(false);
    setShowDialog(false);
  };

  const onResponseFailed = (error) => {
    setDialogSubmit(false);
    setMessage(error?.response?.message);
    setOpenModal(false);
    setShowDialog(true);
  };

  const handleEditData = (userData) => {
    history("/admin/content/homeOverview/addNew", {
      state: { id: userData?.id },
    });
  };

  useEffect(() => {
    props.getHomeOverviews();
  }, []);

  React.useEffect(() => {
  }, [props?.getHomeOverviews]);


  const handleChange = (e, id) => {
    setUserId(id)
    setShowDialog(true)
  }



  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="contents" className="overviews">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Home Overviews"
                buttonName="Add New"
                buttonLink={"/admin/content/homeOverview/addNew"}
                buttonIcon="fa-plus"
              />

              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">Overviews</h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >

                      <>
                        <thead>
                          <tr>
                            <th className="text-capitalize">Id</th>
                            <th className="text-capitalize">Video</th>
                            <th className="text-capitalize">Title</th>
                            <th className="text-capitalize action-section">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props?.arrayList?.map((item, index) => (
                            <tr key={"tr" + index}>
                              <td>{item?.id}</td>
                              <td className="text-capitalize">
                                <video  width='180' height='100' controls>
                                  <source src={item?.video?.media} />
                                </video>
                              </td>
                              <td className="">{item?.title}</td>
                              <td className="action">
                                {
                                  <a className="text-primary">
                                    <i
                                      className="far fa-edit"
                                      onClick={() => handleEditData(item)}
                                      style={{ marginRight: 10 }}
                                    ></i>
                                  </a>
                                }
                                {
                                  <a
                                    className="deletePost delete text-danger"
                                    rowid="18"
                                    onClick={() => deleteHomeOverviews(item?.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        message={DELETE_MESSAGE}
        showDialog={showDialog}
        handleClose={() => handleDialogClose()}
        handleDialogSubmit={() => handleDialogSubmit()}
        isSubmit
      />
    </div>
  );
};

const mapStateToProps = ({ adminHomeOverviewReducer }) => {
  const { arrayList } = adminHomeOverviewReducer;
  return { arrayList };
};

export default connect(mapStateToProps, {
  getHomeOverviews,deleteHomeOverviews,
})(HomeOverviews);
