import {
    USER_LOGIN,
    USER_LOGIN_LOADING,
    USER_LOGIN_ERROR,
    ISADMIN,
    FORGOTPASSWORD,
    RESETPASSWORD,
    USER_LOGIN_SUCCESS
} from './action';

export const initialState = {
    userDetails: localStorage.getItem("user") ? localStorage.getItem("user") : null,
    isAdmin: localStorage.getItem("isAdmin") ? localStorage.getItem("isAdmin") : null,
    user_id: localStorage.getItem("user_id") ? localStorage.getItem("user_id") : null,
    userLoading: false,
    userError: false,
    changePassword: null,
    resetPass: null,
    googleLogin: []
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN: {
            return {
                ...state,
                userDetails: action.payload,
            };
        }
        case USER_LOGIN_SUCCESS: {
            return {
                ...state,
                user_id: action.payload,
            };
        }
        case USER_LOGIN_LOADING: {
            return {
                ...state,
                userLoading: action.payload,
            };
        }
        case USER_LOGIN_ERROR: {
            return {
                ...state,
                userError: action.payload,
            };
        }
        case ISADMIN: {
            return {
                ...state,
                isAdmin: action.payload,
            };
        }
        case FORGOTPASSWORD: {
            return {
                ...state,
                changePassword: action.payload,
            };
        }
        case RESETPASSWORD: {
            return {
                ...state,
                resetPass: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
