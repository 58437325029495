import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { adminRoutes, routes } from './client/utilities/routes';
import Header from "./client/components/header/header";
import Footer from "./client/components/footer/Footer";
import UpskillrUtils from "./client/utilities/UpskillrUtils";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {

  const location = useLocation();
  const { isAdmin } = useSelector((state) => state.loginReducer);
  const userType = isAdmin;
  let temp = [];
  switch (userType) {
    case "true":
      temp = adminRoutes
      break;
    default:
      break;
  }

  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <UpskillrUtils.ScrollToTop/>
        {
          (!userType || userType === 'false' || !location.pathname.includes('/admin')) &&
          !location.pathname.includes('/admin/login') && !location.pathname.includes('/login') && !location.pathname.includes('/register') && !location.pathname.includes('/change-password') &&
          <Header/>
        }
        <Routes>
          {
            routes.map((route, key) => { 
              return <Route key={key} {...route} />
            })
          }
          {
            temp.map((route, key) => <Route key={key} {...route} />)
          }
        </Routes>
        {
          (!userType || userType === 'false' || !location.pathname.includes('/admin')) &&
          !location.pathname.includes('/admin/login') && !location.pathname.includes('/login') && !location.pathname.includes('/register')  && !location.pathname.includes('/change-password') &&
          <Footer/>
        }
      {/* </BrowserRouter> */}
      <ToastContainer />
    </div>
  );
}

export default App;
