import React, { useState, useEffect, useRef } from 'react';

function AutoWidthInput({ initialValue, onChangeFunction, currentIndex, onBlurFunction, editingItemIndex, title }) {
  const [inputValue, setInputValue] = useState(initialValue);
  const inputRef = useRef(null);

    // useEffect(() => {
    //     const setInputWidth = () => {
    //         if (inputRef.current) {
    //             const textWidth = getTextWidth(initialValue, '16px Arial');
    //             const extraWidth = 100;
    //             inputRef.current.style.width = `${textWidth + extraWidth}px`;
    //         }
    //     };

    //     setInputWidth();

    //     inputRef.current.addEventListener('input', setInputWidth);

    //     return () => {
    //         if (inputRef.current) {
    //             inputRef.current.removeEventListener('input', setInputWidth);
    //         }
    //     };
    // }, [initialValue]);

  
    useEffect(() => {
        if (editingItemIndex === currentIndex && inputRef.current) {
            inputRef.current.focus();
            const textLength = inputRef.current.value.length;
            inputRef.current.setSelectionRange(textLength, textLength);
        }
    }, [editingItemIndex, currentIndex]);

//   const getTextWidth = (text, font) => {
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');
//     context.font = font;
//     const metrics = context.measureText(text);
//     debugger;
//     return metrics.width;
//   };

  return (
    // <input
    // type="text"
    // className={`form-control`}
    // value={initialValue}
    // onChange={(e) => onChangeFunction(e, currentIndex)}
    // onBlur={() => onBlurFunction(initialValue, currentIndex)}
    // ref={inputRef}
    // />
    <textarea 
    className={`form-control`}
    value={initialValue}
    style={{width : window.innerWidth < 500 ?'90%':'80%', paddingRight : '50px'}}
    rows={1}
    onChange={(e) => onChangeFunction(e, currentIndex)}
    onBlur={() => onBlurFunction(initialValue, title, currentIndex)}
    ref={inputRef}
    />
  );
}

export default AutoWidthInput;