import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { addContent, getBlogById,updateContent } from "../action";
import { uploadImage } from "../../action";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL } from "../../../utilities/constants";
import Sidebar from "../../Components/Navigation/Sidebar";
import Topbar from "../../Components/Navigation/Topbar";
import PageHeading from "../../Components/PageHeading";
import Loader from '../../../components/Loader';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from "react-toastify";
import QuillTextEditor from "../../../components/multiSearch/QuillTextEditor";

const UpadteBlog = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const fileInputRef = useRef();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [blogId, setBlogId] = useState(null);
  const [pageTitle, setPageTitle] = useState('Add New');
  const { state } = useLocation();
  const [preview, setPreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [image_id, setImageId] = useState('');

  useEffect(() => {
    let id = searchParams.get("update_id")
    if (id) {
      setBlogId(id);
      setPageTitle('Update Organization');
      props.getBlogById(id);
      
    }
  }, []);

  useEffect(() => {
    if (props?.blogDetails){
      setHeading(props.blogDetails.heading);
      setDescription(props.blogDetails.description);
      setTitle(props.blogDetails.title);
      setImageId(props.blogDetails.image.id);
      setPreview(props.blogDetails.image.media);
    }
  }, [props?.blogDetails]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    let formData = new FormData();
    formData.append('media', e.target.files[0]);
    props.uploadImage(formData)
    .then((res) => {
      const imageId = res.data.id;
      setImageId(imageId)
      }).catch((error) => {
        console.log("Error on image upload", error)
      });
  };

  const handleReset = () => {
    formRef.current.reset();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(false);
    const formDataItem = {
      heading,
      description,
      title,
      image_id
    };
    if (!heading || !description || !title) {
      toast.error('All fields are required.');
      return;
    }
    if(state?.id){
      await props.updateContent(formDataItem,state?.id).then(res => {
        toast.success('Blog updated successfully');
        navigate(`/admin/blogs`);
      }).catch(error => {
        toast.error(error || "Error in add team");
      });
    }else{
      // onAdd(newItem);
      if(image_id){
        await props.addContent(formDataItem).then(res => {
          toast.success('Blog added successfully');
          setLoading(true);
          navigate(`/admin/blogs`);
         }).catch(error => {
           toast.error(error || "Error in add blog");
           setLoading(false);
         });
       }else{
         setLoading(false);
         toast.error("Image is required");
       }
    }
  };


  return (
    <div>
      {props.blogDetails && console.log(props.blogDetails)}
      {loading && <Loader />}
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading title={pageTitle} />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewBlog">
                <div className="card-body">
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">heading</Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="Blog heading"
                            name="heading"
                            value={heading}
                            onChange={(e) => setHeading(e.target.value)}
                            validation={{
                              required: {
                                value: true,
                                message: 'required',
                              },
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">title</Form.Label>
                          <Form.Control
                            className="form-control"
                            placeholder="Blog title"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">descriptions</Form.Label>
                          {/* <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDescription(data);
                            }}
                            config={{
                              toolbar: {
                                items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'undo', 'redo'],
                              },
                            }}
                          /> */}
                          <QuillTextEditor
                            placeholder={""}
                            editorValue={description}
                            handleChange={(html)=>setDescription(html)}
                          />
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Blog Image
                          </Form.Label>
                        </Form.Group>
                        {selectedFile ? (
                          <img src={preview} className="imgthumb" style={{ width: '100%', height: 200, objectFit: 'contain' }} />
                        ) : (
                          props.blogDetails && (
                            <img
                              src={props.blogDetails.image.media}
                              className="imgthumb"
                              style={{ width: '100%', height:200, objectFit: 'contain' }}
                            />
                          )
                        )}
                      </div>
                      <div className="">
                        <Form.Group className="mb-3">
                          <a
                            className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <i
                              className={`fas fa-plus fa-sm text-white-50 p-1`}
                            ></i>
                          </a>
                          <Form.Control
                            type="file"
                            className="form-control"
                            accept='image/*'
                            placeholder=""
                            onChange={onSelectFile}
                            ref={fileInputRef}
                            hidden
                          />
                        </Form.Group>
                      </div>

                      <div className=" mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminBlogsReducer }) => {
  const { blogDetails } = adminBlogsReducer;
  return { blogDetails };
};

export default connect(mapStateToProps, {
  addContent,
  uploadImage,
  getBlogById,
  updateContent
})(UpadteBlog);

