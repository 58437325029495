import Recognitions from "../../components/Recognitions/Recognitions";
import VisionMission from "../../components/VisionMission/VisionMission";
import Cofounder from "../../components/cofounder/Cofounder";
import GetInTouch from "../../components/getintouch/getInTouch";
import OurPartner from "../../components/ourPartners/OurPartners";
import MeetOurTeam from "../../components/meetourteam/MeetOurTeam";
import "../aboutus/Aboutus.css"
import Images from "../../constants/Images";
import React, { useState,useEffect } from 'react';
import { getVision, getRecognition, getOurPartner, getCoFounder, getOurTeam } from './action';
import { connect } from 'react-redux';

function App(props) {

  // let dispatch = useDispatch();
  const [vision , setVision] = useState([]);
  const [recognition , setRecognition] = useState([]);
  const [partner, setPartner] = useState([]);
  const [cofounder, setCofounder] = useState([]);
  const [ourTeam, setOurTeam] = useState([]);

  useEffect(() => {
    props.getVision();
    props.getRecognition();
    props.getOurPartner();
    props.getCoFounder();
    props.getOurTeam();
  }, []);

useEffect(() => {
  if ((props.visionData).length > 0) {     
    setVision(props.visionData);
  }  

  if ((props.ourpartnerData).length > 0) {     
    setPartner(props.ourpartnerData);
  }  
  
  if((props.recognitionData).length > 0){
    setRecognition(props.recognitionData);
  }
  
  if((props.cofounderData).length > 0){
    setCofounder(props.cofounderData);
  }
  
  if((props.ourTeamData.length > 0)){
    setOurTeam(props.ourTeamData);
  }

}, [props.visionData,props.ourpartnerData,props.recognitionData,props.cofounderData,props.ourTeamData]);

  return (
    <div className="AboutUs">
      <div className='homePageBannerSection'>
        <div className='AboutbannerSubTitle' style={{ fontSize: 48,marginTop:40 }}>At Upskillr.ai, we strive to revolutionize the way <br />people learn and grow professionally.</div>
        <div className='AboutbannerSubTitleMobile'>At Upskillr.ai, we strive to revolutionize the way people learn and grow professionally.</div>
        <div className="aboutusbannerImages" style={{position:'relative'}}>
        <a href={'https://www.linkedin.com/in/vikrampandya/'} target="_blank" rel="noopener noreferrer" > <img src={Images.LinkedInVikram} className="linkedinprofile" style={{width:'15%',position:'absolute',top: '60%',left:250,}}/> </a>
          <img src={Images.AboutusBannerImage} className='homeBanner' style={{ width: '38%' }} />
          <a href={'https://www.linkedin.com/in/aditya-saxena-09a50719b/'} target="_blank" rel="noopener noreferrer" > <img src={Images.AdityaSaxenaLinkedIn} className="linkedinprofile" style={{width:'15%',position:'absolute',top: '60%',right:250}}/></a>
        </div>
      </div>
      <VisionMission vision={vision}/>
      <Cofounder cofounder={cofounder}/>
      <OurPartner partner={partner}/>
      <Recognitions recognition={recognition}/>
      <GetInTouch />
      <MeetOurTeam ourTeam={ourTeam}/>
    </div>
  );
}

const mapStateToProps = ({ aboutUsReducer }) => {
  const { visionData, ourpartnerData, recognitionData, cofounderData, ourTeamData } = aboutUsReducer;
  return {
    visionData, ourpartnerData, recognitionData, cofounderData, ourTeamData
   };
}

export default connect(mapStateToProps, {
  getVision, getRecognition, getOurPartner, getCoFounder, getOurTeam
})(App);
