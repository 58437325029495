import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { getSeeResult } from './action';
import { toast } from 'react-toastify';

const SeeResults = (props) =>{
    const { show, closeModal, mcqQA, handleOnDetailClick, dispatch, seeResultSuccess } = props;

    useEffect(()=>{
        if(mcqQA && mcqQA.id){
            dispatch(getSeeResult(mcqQA.id)).catch(err=>{
                toast.error(err.message || "Something Went Wrong");
            });
        }
    },[mcqQA])

    if (show) {
        return (
            <Modal size="lg" className='Tables' aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={closeModal}>
                <a onClick={closeModal} style={{ position:'absolute',right:0, width: 32, height: 32, top: -50 }}>
                    <img src={require('../../assets/table_close_icon.png')} style={{  width: 32, height: 32 }} />
                </a>
                {(typeof seeResultSuccess != 'undefined') && seeResultSuccess?.length > 0 ?
                <Table striped hover className='mt-1'>
                    <thead>
                        <tr>
                            <th className='Headtable'>Sr. No</th>
                            <th className='Headtable'>Name</th>
                            <th className='Headtable'>Email</th>
                            <th className='Headtable'>Score</th>
                            <th className='Headtable'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                            {seeResultSuccess.map((item, i) => (
                                <tr key={'mcqmdl_'+i}>
                                    <td>{i + 1}</td>
                                    <td>{item?.result_data?.MCQ_Test?.User.fullname}</td>
                                    <td>{item?.result_data?.MCQ_Test?.User.email}</td>
                                    <td>{`${item?.result_data?.correct_answers} / ${item?.result_data?.total_questions}`}</td>
                                    <td className='ViewDetails' onClick={()=>handleOnDetailClick(mcqQA.id)}>View Details</td>
                                </tr>
                            ))}
                        
                    </tbody>
                </Table>
                : <Modal.Body>No attempts yet</Modal.Body>
                        }
            </Modal>
        )
    }
}

export default SeeResults;