import React, { useEffect } from 'react';
import { Link,useNavigate } from "react-router-dom";
import "../footer/Footer.css"
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import { PropTypes } from "prop-types";

const Footer = (props) => {

  const navigate = useNavigate();

  const handleContactUsClick = () => {
    navigate("/aboutus");
    setTimeout(() => {
      const element = document.getElementById("getInTouchForm");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed
  };
  const handleTestimonialsClick = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("HomeTestimonial");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed
  };
  const handleChatGptClick = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("HomeChatGpt");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed
  };

  return (
    <>
      <div className='Footer' style={{ backgroundColor: Colors.dark_blue }}>
        <div className='container'>
          <div className='main-footer'>
            <div className='row'>
              <div className='col-3 coloum products' >
                <h3>Features</h3>
                <li>
                  <Link to="/our-features?selectedTab=1">Curriculum Builder</Link>
                </li>
                <li>
                  <Link to="/our-features?selectedTab=3">Upskillr Assistant</Link>
                </li>
                <li>
                  <Link to="/our-features?selectedTab=5">Career Pal</Link>
                </li>
                <li>
                  <Link to="/our-features?selectedTab=4">Curated Writing</Link>
                </li>
                <li>
                  <Link to="/our-features?selectedTab=0">Skill Map</Link>
                </li>
                <li>
                  <Link to="/our-features?selectedTab=2">Tools Advisory</Link>
                </li>
                <li>
                  <Link to="/our-features?selectedTab=0">Marketplace</Link>
                </li>
              </div>
              <div className='col-3 coloum' >
                <h3>Company</h3>
                  {/* <li>
                  <Link to="/">Home</Link>
                </li> */}
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/" onClick={handleChatGptClick}>ChatGPT Plugin</Link>
                </li>
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li>
                  <Link  to="/aboutus" onClick={handleContactUsClick}>Contact Us</Link>
                </li>
                <li>
                  <Link to="/" onClick={handleTestimonialsClick}>Testimonials</Link>
                </li>
              </div>
              <div className='col-3 coloum'>
                <h3>Resources</h3>
                <li>
                  <Link to="/blogs">Blog</Link>
                </li>
                <li>
                  <Link to="/termsOfService">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/privacyPolicy">Privacy Policy</Link>
                </li>
                <h3>Use Cases</h3>
                <li>
                  <a href="https://upskillrai.notion.site/Use-Cases-For-Individuals-62da50b70ed44466a63b6b96810a842f?pvs=4" target="_blank">For Individuals</a>
                </li>
                <li>
                  <a href="https://upskillrai.notion.site/Use-Cases-For-Educational-Institutions-9b530b1b4ea34bd1a2f8140ad52b3b06?pvs=4" target="_blank">For Educational Institutions</a>
                </li>
                <li>
                  <a href="https://upskillrai.notion.site/Use-Cases-For-Enterprises-7e1edf58bd6442f187e463a3e7dd1a24?pvs=4" target="_blank">For Enterprises</a>
                </li>
              </div>
              <div className='col-3 coloum'>
                <h3>Office</h3>
                <li>
                  <span>Plot 173, Parsik Hill, CBD Belapur, Sector 27, Navi Mumbai Maharashtra India 400614</span>
                </li>
                <h3>Reach Us</h3>
                <li>
                  <Link to="tel:+91 9769236850">help@upskillr.ai</Link>
                </li>
                <li>
                  <a href="https://tally.so/r/wkGZ1J">Report Issue</a>
                </li>
                {/* <li>
                  <a href="">Book a Demo</a>
                </li> */}
              </div>
            </div>
          </div>
          <div className='socialMediaLinks'>
            <h3 className='title'>Stay Connected</h3>
            <div className='socialMediaLinksImages'>
              <a href='https://www.instagram.com/upskillr.ai/' target="_blank"><img src={Images.InstagramLogo} style={{width:56,height:56}}/></a>
              <a href='https://www.linkedin.com/company/upskillr-ai/' target="_blank"><img src={Images.LinkedInLogo} style={{width:56,height:56}}/></a>
              <a href='https://twitter.com/upskillr_ai' target="_blank"><img src={Images.TwitterLogo} style={{width:56,height:56}}/></a>
              <a href='https://www.youtube.com/@upskillrai5517' target="_blank"><img src={Images.YouTubeLogo} style={{width:56,height:56}}/></a>
              <a href='https://www.facebook.com/upskillrai/' target="_blank"><img src={Images.FacebookLogo} style={{width:56,height:56}}/></a>
              </div>
          </div>
          <hr className='footer_line' />
          <div className='footer-tag'>
            <p>&#169; 2023 Upskillr, Inc. All right Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
}

Footer.propTypes = {
  showFooter : PropTypes.bool
};

Footer.defaultProps = {
  showFooter : true
}

export default Footer;