import $ from "jquery";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";

import CustomDialog from "../Components/CustomDialog";
import Sidebar from "../Components/Navigation/Sidebar";
import Topbar from "../Components/Navigation/Topbar";
import PageHeading from "../Components/PageHeading";
import { DELETE_MESSAGE } from "../../utilities/message";
import { toast } from 'react-toastify'

//jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { getSubscriptions,deleteSubscription} from './action';
import { connect } from "react-redux";

const Subscriptions = (props) => {
    $(document).ready(function () {
        setTimeout(function () {
          $("#dataTable").DataTable();
        }, 1000);
      });

    const [message, setMessage] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(false);
    const [id, setId] = useState(null);
    const history = useNavigate();

    useEffect(() => {
        props.getSubscriptions();
    }, []);

      const handleEditData = (subscriptionData) => {
          history("/admin/subscription/addNew", {
              state: { id: subscriptionData?.id },
            });
      };

      const deleteSubscription = async (id) => {
        setId(id);
        setDialogSubmit(true);
        setMessage(DELETE_MESSAGE);
        setShowDialog(true);
      };

      const handleDialogSubmit = () => {
        props?.deleteSubscription(id)
          .then(async (response) => {
            onResponseRecieved(response);
          })
          .catch((error) => {
            onResponseFailed(error);
          });
          
      };
      const handleDialogClose = () => {
        setId(null);
        setShowDialog(false);
      };
      const onResponseRecieved = (response) => {
        props?.getSubscriptions();
        setDialogSubmit(false);
        setMessage(response?.message);
        setOpenModal(false);
        setShowDialog(false);
        toast.success(response?.message)
      };
      
      const onResponseFailed = (error) => {
        setDialogSubmit(false);
        setMessage(error?.response?.message);
        setOpenModal(false);
        setShowDialog(true);
        toast.success(error?.response?.message)
      };
      
    return (
        <div>
            {/* <!-- Page Wrapper --> */}
            <div id="wrapper">
                {/* <!-- Sidebar --> */}
                <Sidebar />
                {/* <!-- End of Sidebar --> */}

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="contents">
                        {/* <!-- Topbar --> */}
                        <Topbar />
                        {/* <!-- End of Topbar --> */}

                        {/* <!-- Begin Page Content --> */}
                        <div className="container-fluid">
                            {/* <!-- Page Heading --> */}
                            <PageHeading
                                title="Subscriptions"
                                buttonName="Add New"
                                buttonLink={"/admin/subscription/addNew"}
                                buttonIcon="fa-plus"
                            />

                            <div>

                            </div>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                                    <h6 className="m-0 font-weight-bold text-primary">
                                        User Management
                                    </h6>

                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table
                                            className="table table-bordered"
                                            id="dataTable"
                                            width="100%"
                                            cellSpacing="0"
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="text-capitalize">Id</th>
                                                    <th className="text-capitalize">Name</th>
                                                    <th className="text-capitalize">Type</th>
                                                    <th className="text-capitalize">Credits</th>
                                                    <th className="text-capitalize">Price</th>
                                                    <th className="text-capitalize">Description</th>
                                                    <th className="text-capitalize">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props?.allList?.map((item, index) => (
                                                    <tr key={"tr" + index}>
                                                        <td>{item?.id}</td>
                                                        <td className="text-capitalize">{item?.Name}</td>
                                                        <td className="text-capitalize">{item?.Type}</td>
                                                        <td className="text-capitalize">{item?.credits}</td>
                                                        <td className="text-capitalize">{item?.Price}</td>
                                                        <td className="text-capitalize">{item?.Description}</td>
                                                        <td className="action">
                                                            {
                                                                <a className="text-primary">
                                                                    <i
                                                                        className="far fa-edit"
                                                                        onClick={() => handleEditData(item)}
                                                                        style={{ marginRight: 10 }}
                                                                    ></i>
                                                                </a>
                                                            }
                                                            {
                                                                <a
                                                                    className="deletePost delete text-danger"
                                                                    rowid="18"
                                                                    onClick={() => deleteSubscription(item?.id)}
                                                                >
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </a>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomDialog
                message={DELETE_MESSAGE}
                showDialog={showDialog}
                handleClose={() => handleDialogClose()}
                handleDialogSubmit={() => handleDialogSubmit()}
                isSubmit
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { allSubscriptions } = state.adminSubscriptionsReducer;
    return { allList: allSubscriptions };
};

export default connect(mapStateToProps, {
    getSubscriptions,
    deleteSubscription,
})(Subscriptions);
