import React, { useState } from 'react';
import { PropTypes } from "prop-types";
import Modal from 'react-bootstrap/Modal';
import { Menu, MenuItem, Button, Checkbox } from '@mui/material';
import { Card, Image, Tab, Tabs } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import './index.scss';
import Images from '../../constants/Images';
import ListGroup from 'react-bootstrap/ListGroup';

const ComparisonCard = (props) => {
    const { money, students, title, language, imageUrl, hours, level, totalStudents, totalHours, coursePrize, machineLearningCardStyle, cardContentStyle, showBottomLine, cardImageStyles, showButtons, showChexkBox, centerTitle, imageStyles, quickView } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [checked, setChecked] = React.useState(false);
    const [key, setKey] = useState('home');
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheckBoxChecked = () => {
        setChecked(!checked);
    }
    const [modalShow, setModalShow] = useState(false);

    const handleCloseModal = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end',marginBottom:8,cursor:'pointer' }}>
                <img src={Images.RemoveComprasion} style={{ width: 36, height: 36, backgroundColor: '#fff', objectFit: 'contain', borderRadius: 5, }} />
            </div>
            <Card className='machineLearningCard' style={{ ...machineLearningCardStyle }}>
                <div className='cardHeader'>
                    <img src={imageUrl ? imageUrl : Images.Coursera} style={{ width: 50, height: 50, backgroundColor: '#fff', objectFit: 'contain', borderRadius: 5, }} />
                    {title && <div className='cardTitle'>{title}</div>}
                </div>
                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Information</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Rupees} className='listIcons' />
                                        {money && <div className='infomationMoney'>{money}</div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.GraducationCap} className='listIcons' />
                                        {students && <div className='infomationMoney' style={{ display: 'flex' }}>{students} <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500' }} >Students</div> </div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Clock} className='listIcons' />
                                        {hours && <div className='infomationMoney' style={{ display: 'flex' }}>{hours} <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500' }} >Hours</div> </div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.IntermediateLevel} className='listIcons' />
                                        {level && <div className='infomationMoney'>{level}</div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Globe} className='listIcons' />
                                        {language && <div className='infomationMoney'>{language}</div>}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Topics</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Rupees} className='listIcons' />
                                        {money && <div className='infomationMoney'>{money}</div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.GraducationCap} className='listIcons' />
                                        {students && <div className='infomationMoney' style={{ display: 'flex' }}>{students} <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500' }} >Students</div> </div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Clock} className='listIcons' />
                                        {hours && <div className='infomationMoney' style={{ display: 'flex' }}>{hours} <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500' }} >Hours</div> </div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.IntermediateLevel} className='listIcons' />
                                        {level && <div className='infomationMoney'>{level}</div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Globe} className='listIcons' />
                                        {language && <div className='infomationMoney'>{language}</div>}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Review Keywords</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Rupees} className='listIcons' />
                                        {money && <div className='infomationMoney'>{money}</div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.GraducationCap} className='listIcons' />
                                        {students && <div className='infomationMoney' style={{ display: 'flex' }}>{students} <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500' }} >Students</div> </div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Clock} className='listIcons' />
                                        {hours && <div className='infomationMoney' style={{ display: 'flex' }}>{hours} <div className='infomationMoney' style={{ marginLeft: 5, fontWeight: '500' }} >Hours</div> </div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.IntermediateLevel} className='listIcons' />
                                        {level && <div className='infomationMoney'>{level}</div>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img src={Images.Globe} className='listIcons' />
                                        {language && <div className='infomationMoney'>{language}</div>}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {showButtons !== false && <div className='cardHeader' style={{ justifyContent: 'center', paddingTop: 10, paddingBottom: 10, marginTop: 16 }}>
                    <Button onClick={handleModalShow} style={{ backgroundColor: '#004AAD' }} className='btnstyle'>Go to course </Button>
                </div>}
            </Card>
        </>
    )
}

export default ComparisonCard;

ComparisonCard.propTypes = {
    cardWidth: PropTypes.number,
    cardHeight: PropTypes.number,
    type: PropTypes.any,
    showChexkBox: PropTypes.bool,
    quickView: PropTypes.func,
};

