import React, { useState } from 'react';
import "../meetourteam/TeamCard.css"
import Colors from '../../constants/Colors';
import Images from '../../constants/Images';
import TeamCard from './TeamCard';


function MeetOurTeam(props) {

    const { ourTeam } = props;

    return (
        <>
        {
        ourTeam && ourTeam.length > 0 ? (
            <div className='container' style={{marginBottom:40}}>
                <h1 className='section_title' style={{ color: Colors.dark_blue }}>Meet our Team</h1>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 justify-content-center">
                {ourTeam.map((ourTeam, index) =>{
                     const even = index % 2 === 0?true:false;
                     return(
                    <TeamCard key={index}
                        name={ourTeam.name}
                        description={ourTeam.designation}
                        backgroundColorImage={Images.TeamBlueCard}
                        teamImage={ourTeam.image.media}
                        borderColor={even?'rgb(204, 226, 255)':Colors.border_light_yellow}
                    />
                     )
                })}
                    
                </div>
            </div>
            ) : null
        }
        </>
    );
}

export default MeetOurTeam;