import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from "prop-types";
import Modal from 'react-bootstrap/Modal';
import { Menu, MenuItem, Button, CircularProgress } from '@mui/material';
import { Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import './index.scss';
import Images from '../../constants/Images';
import { getRecommendedList, updateCurriculumByID, updateRecommendedByID } from './action';
import Loader from '../Loader';
import { uploadImage } from '../../admin/action';

const CurriculamListCard = (props) => {
    const { title, imageUrl, cardContentStyle, imageStyles, floatingMenu = true, by, date, cardData, dispatch, cardType, isPublic, cloneCurriculum } = props;
    const { loading, curriculumUpdated, error} = useSelector((state)=> state.CurriculumReducer);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const fileInputRef = useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [showModal, setShowModal] = useState(false);
    const [modalContentType, setModalContentType] = useState("rename");
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = (type) => {
        handleClose();
        setShowModal(true);
        setModalContentType(type?type:'rename');
    }

    
    const renameCourseForm = useForm({
        resolver: yupResolver(yup.object().shape({
            Name: yup.string().required("Name is required"),
        })),
    });
    
    useEffect(() => {
        const { setValue } = renameCourseForm;
        setValue('Name', cardData.Name);
    }, [renameCourseForm, cardData]);

    const handleUpdateData = async (formData) => {
            let formObject = {...cardData, ...formData, image_id:cardData?.image?.id};
            if(selectedFile && modalContentType === 'updateImage'){
                let uploadedImage;
                if (selectedFile) {
                    let formData = new FormData();
                    formData.append('media', selectedFile);
                    uploadedImage = await dispatch(uploadImage(formData));
                    formObject.image_id = uploadedImage.data.id;
                }
            }
            if(cardType){
                 dispatch(updateRecommendedByID(formObject.id, formObject, setShowModal));
                 dispatch(getRecommendedList());
            }else{
                dispatch(updateCurriculumByID(formObject.id, formObject, setShowModal, props.setListArray, props.limit, props.offset)).then(() => {
                    setShowModal(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 200)
                }).catch(() => {
                });
            }
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
          setSelectedFile(undefined);
          return;
        }
        setSelectedFile(e.target.files[0]);
      };

    const deleteData=()=>{
        props.handleOnDeleteClick();
        handleClose();
    }
    const renderEditCardName = () =>{
        return(
            <form onSubmit={renameCourseForm.handleSubmit(handleUpdateData)} className='renameCourseForm'>
                <label htmlFor="fname" className='formLabel'>Title</label>
                <div className='mb-4'>
                    <div className=''>
                        <input type="text" placeholder='Enter course name' {...renameCourseForm.register('Name')} className="form-control"/>
                    </div>
                    <p className='validationError'>{renameCourseForm.formState.errors.Name?.message}</p>
                </div>
                {<button className='formBtn mt-4' type="submit">Update {loading && <CircularProgress size={15} style={{marginLeft:20}}/>}</button>}
            </form>
        )
    }
    
    const renderEditCardImage = () =>{
        return(
            <form onSubmit={renameCourseForm.handleSubmit(handleUpdateData)} className='renameCourseForm'>
                <label htmlFor="fname" className='formLabel'>Image</label>
                <div className='mb-4'>
                    <div className='form-input'>
                        {selectedFile ? (
                            <img src={preview} className="imgthumb" style={{ width: 150, height: 150, objectFit: 'contain' }} />
                        ) : (
                            imageUrl && (
                                <img
                                    src={`${imageUrl.media}`}
                                    className="imgthumb"
                                    style={{ width: 150, height: 150, objectFit: 'contain' }}
                                />
                            )
                        )}
                        <a className="d-sm-inline-block btn btn-sm btn-primary shadow-sm ml-3" onClick={() => fileInputRef.current.click()}
                        >Change</a>
                        <Form.Control
                            type="file"
                            className="form-control"
                            accept='image/*'
                            placeholder=""
                            onChange={onSelectFile}
                            ref={fileInputRef}
                            hidden
                        />
                    </div>
                    <p className='validationError'>{renameCourseForm.formState.errors.Name?.message}</p>
                </div>
                {<button className='formBtn mt-4' type="submit">Update {loading && <CircularProgress size={15} style={{marginLeft:20}}/>}</button>}
            </form>
        )
    }

    return (
        <>       
        <div className='machineLearningCard curriculamCard' style={{...props.cardStyle}}>
            {floatingMenu && <div className='floatingBtn'>
                <Button
                    // id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <img src={Images.FloatingMenu} />
                </Button>
                <Menu
                    className="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        !isPublic && <><MenuItem onClick={()=>handleShowModal("rename")} style={styles.menuTextStyle}>
                        <img src={require('./../../assets/Rename.png')} style={styles.menuIconStyle} />Rename
                    </MenuItem>
                    <MenuItem onClick={()=>handleShowModal("updateImage")} style={styles.menuTextStyle}>
                        <img src={require('./../../assets/image-outline-icon.png')} style={styles.menuIconStyle} />Edit Image
                    </MenuItem>
                    <MenuItem onClick={deleteData} style={styles.menuTextStyle}>
                        <img src={require('./../../assets/trash.png')} style={styles.menuIconStyle} />Delete
                    </MenuItem>
                    </>
                    }

                    {
                        isPublic && <MenuItem onClick={cloneCurriculum} style={styles.menuTextStyle}>
                            <img src={require('./../../assets/trash.png')} style={styles.menuIconStyle} />Edit
                        </MenuItem>
                    }
                    
                </Menu>
            </div>}
            <div className='curriculamListCard' onClick={props.onCardClick}>
                {imageUrl?
                <img src={imageUrl?.media} style={{ width: '100%',height: 136, objectFit:'cover',backgroundColor:'rgba(204, 226, 255, 0.15)',...imageStyles }}/>
                :<div style={{height: 136, backgroundColor:'#E6EDF7B2'}}></div>}
                <div className='cardContentStyle' style={{ ...cardContentStyle }}>
                    <div className='cardTitle'>{title}</div>
                    {
                        !isPublic && <div className='by'>By {by} <span>{date}</span></div>
                    }
                </div>

            </div>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={handleCloseModal} backdrop="static" className='renameListCardModal'>
            {!loading &&
                (
                    <Modal.Header closeButton={false} style={{ alignItems: 'center' }}>
                        <Modal.Title className='mdlTitle'>Update {modalContentType === "rename" && 'title'} {modalContentType === "updateImage" && 'image'}</Modal.Title>
                        <Button onClick={handleCloseModal} style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                            <img src={require('../../assets/table_close_icon.png')} style={{ width: 32, height: 32 }} />
                        </Button>
                    </Modal.Header>
                )}
            <Modal.Body>
            {
                modalContentType === "rename" && renderEditCardName()
            }
            {
                modalContentType === "updateImage" && renderEditCardImage()
            }
            </Modal.Body>
        </Modal>
    </>
    )
}

export default CurriculamListCard;

CurriculamListCard.propTypes = {
    type: PropTypes.any,
    floatingMenu: PropTypes.bool,
    cardData:PropTypes.any
};

const styles = {
    thumImg: { width: 150, height: 150, borderRadius: 16, objectFit: 'cover' },
    rightBtnImg: { width: 14, height: 12, marginLeft: 8 },
    menuIconStyle: { width: 16, height: 16, marginRight: 8 },
    menuTextStyle: {fontSize:12, color:"#001F49" },
}