import { HIDDEN_THINGS_TO_DO_ERROR, HIDDEN_THINGS_TO_DO_LOADING, HIDDEN_THINGS_TO_DO_SUCCESS, HIDDEN_THINGS_TO_READ_ERROR, HIDDEN_THINGS_TO_READ_LOADING, HIDDEN_THINGS_TO_READ_SUCCESS, HIDDEN_THINGS_TO_WATCH_ERROR, HIDDEN_THINGS_TO_WATCH_LOADING, HIDDEN_THINGS_TO_WATCH_SUCCESS } from "./action";

const initialState = {  
    hiddenThingsToDoSuccess: null,
    hiddenThingsToDoLoading: false,
    hiddenThingsToDoError: null,
    
    hiddenThingsToWatchSuccess: null,
    hiddenThingsToWatchLoading: false,
    hiddenThingsToWatchError: null,

    hiddenThingsToReadSuccess: null,
    hiddenThingsToReadLoading: false,
    hiddenThingsToReadError: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HIDDEN_THINGS_TO_DO_SUCCESS: {
            return {
                ...state,
                hiddenThingsToDoLoading: false,
                hiddenThingsToDoSuccess: action.payload,
                hiddenThingsToDoError: null
            }
        }
        case HIDDEN_THINGS_TO_DO_LOADING: {
            return {
                ...state,
                hiddenThingsToDoLoading: true,
                hiddenThingsToDoError: null
            }
        }
        case HIDDEN_THINGS_TO_DO_ERROR: {
            return {
                ...state,
                hiddenThingsToDoLoading: false,
                hiddenThingsToDoError: action.payload
            }
        }

        case HIDDEN_THINGS_TO_READ_SUCCESS: {
            return {
                ...state,
                hiddenThingsToReadLoading: false,
                hiddenThingsToReadSuccess: action.payload,
                hiddenThingsToReadError: null
            }
        }
        case HIDDEN_THINGS_TO_READ_LOADING: {
            return {
                ...state,
                hiddenThingsToReadLoading: true,
                hiddenThingsToReadError: null
            }
        }
        case HIDDEN_THINGS_TO_READ_ERROR: {
            return {
                ...state,
                hiddenThingsToReadLoading: false,
                hiddenThingsToReadError: action.payload
            }
        }

        case HIDDEN_THINGS_TO_WATCH_SUCCESS: {
            return {
                ...state,
                hiddenThingsToWatchLoading: false,
                hiddenThingsToWatchSuccess: action.payload,
                hiddenThingsToWatchError: null
            }
        }
        case HIDDEN_THINGS_TO_WATCH_LOADING: {
            return {
                ...state,
                hiddenThingsToWatchLoading: true,
                hiddenThingsToWatchError: null
            }
        }
        case HIDDEN_THINGS_TO_WATCH_ERROR: {
            return {
                ...state,
                hiddenThingsToWatchLoading: false,
                hiddenThingsToWatchError: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default reducer;