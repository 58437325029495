import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {
  createRecognition, getRecognitionDetails, updateRecognition
} from "../../Recogniations/action";
import { uploadImage, } from '../../../action'
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../utilities/constants";
import Sidebar from "../../../Components/Navigation/Sidebar";
import Topbar from "../../../Components/Navigation/Topbar";
import PageHeading from "../../../Components/PageHeading";
import { ToastContainer, toast } from "react-toastify";

const label = { inputProps: { 'aria-label': 'controlled' } };

const ImageFileUpload = (props) => {
  const fileInputRef = useRef();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [startDate, setStartDate] = useState();
  const [dropDownData, setDropDownData] = useState([]);
  const { state } = useLocation();

  useEffect(() => {
    if (state?.id) {
      props.getRecognitionDetails(state.id);
      console.log('state?.id', state?.id)
    }
  }, [state?.id]);


  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);

  };

  const handleReset = () => {
    formRef.current.reset();
  };


  const handleSubmit = async (e, data) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error('Please select an image to upload.');
      return;
    }
    if (state?.id) {
      let formData = new FormData();
      formData.append('media', selectedFile);
      props.uploadImage(formData)
        .then(async (res) => {
          const imageId = res.data.id;
          console.log('ImageID', imageId)
          props.updateRecognition(state?.id, imageId)
            .then(async (res) => {
              toast.success('Update Sucessful');
              navigate(`/admin/content/recogniations`);
              toast.success(res.message);
            })
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      let formData = new FormData();
      formData.append('media', selectedFile);
      props.uploadImage(formData)
        .then(async (res) => {
          const imageId = res.data.id;
          props.createRecognition(imageId)
            .then(async (res) => {
              toast.success('Uploaded Sucessful');
              navigate(`/admin/content/recogniations`);
              toast.success(res.message);
            })
        })
        .catch((error) => {

          toast.error(error);
        });
    }
    setSelectedFile(null);
  };



  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <PageHeading
                title="Add New"
                buttonName=""
                buttonLink=""
                buttonIcon=""
              />

              {/* <!-- Content Row --> */}

              <div className="card shadow mb-4" id="addNewMaster">
                <div className="card-body">
                  <Form ref={formRef} method="post" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="text-capitalize">
                            Add Image
                          </Form.Label>
                        </Form.Group>
                        {selectedFile ? (
                          <img src={preview} className="imgthumb" style={{ width: 150, height: 150, objectFit: 'contain' }} />
                        ) : (
                          props.getDetails && (
                            <img
                              src={`${props?.getDetails?.image?.media}`}
                              className="imgthumb"
                              style={{ width: 150, height: 150, objectFit: 'contain' }}
                            />
                          )
                        )}
                      </div>
                      <div className="">
                        <Form.Group className="mb-3">
                          <a
                            className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <i
                              className={`fas fa-plus fa-sm text-white-50 p-1`}
                            ></i>
                          </a>
                          <Form.Control
                            type="file"
                            className="form-control"
                            accept='image/*'
                            placeholder=""
                            onChange={onSelectFile}
                            ref={fileInputRef}
                            hidden
                          />
                        </Form.Group>
                        <ToastContainer />
                      </div>
                      <div className=" mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminRecognitionDetailReducer }) => {
  const { getDetails } = adminRecognitionDetailReducer;
  return {
    getDetails,
  };
};

export default connect(mapStateToProps, {
  uploadImage,
  createRecognition,
  getRecognitionDetails,
  updateRecognition,
})(ImageFileUpload);

