import { PROCCESSING, STOPPROCESSING } from "../../utilities/constants";
import { api } from "../../utilities/api";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utilities/apiUrls";


export const BLOG = "BLOG"

export const getBlog = (formData) => async (dispatch) => {

  let data = {
    method: METHOD_TYPE.get,
    url: API_ENDPOINTS.blogs,
  };
  dispatch({ type: PROCCESSING });
  return api(data)
    .then(async (response) => {
      return await response.data
    }).then(async (response) => {
      if (response) {
        dispatch({
          type: BLOG,
          payload: response,
        });
        dispatch({ type: STOPPROCESSING });
      }
    })
    .catch((error) => {
      dispatch({ type: STOPPROCESSING });
      throw error?.response?.message;
    });
};