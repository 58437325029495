import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import Accordion from 'react-bootstrap/Accordion';

import Images from "../../constants/Images";
import ViewHeading from "../ViewHeading";
import MachineLearningCard from "./MachineLearningCard";
import marketData from "./marketData.json" 

const MockData = {
    ["Development"]: { imageUrl: Images.ItIcon, cardTitle:"Development" },
    ["IT & Software"]:{ imageUrl: Images.ItSoftware, cardTitle:"IT & Software" },
    ["Finance & Accounting"]:{ imageUrl: Images.FinanceAccounting, cardTitle:"Finance & Accounting" },
    ["Business"]:{ imageUrl: Images.Business, cardTitle:"Business" },
    ["Office Productivity"]:{ imageUrl: Images.OfficeProductivity, cardTitle:"Office Productivity" },
    ["Personal Development"]:{ imageUrl: Images.PersonalDevelopment, cardTitle:"Personal Development" },
    ["Design"]:{ imageUrl: Images.Design, cardTitle:"Design" },
    ["Marketing"]:{ imageUrl: Images.Marketing, cardTitle:"Marketing" },
    ["Photography & Video"]:{ imageUrl: Images.PhotographyVideo, cardTitle:"Photography & Video" },
    ["Health and Fitness"]:{ imageUrl: Images.HealthFitness, cardTitle:"Health and Fitness" },
    ["Music"]:{ imageUrl: Images.Music, cardTitle:"Music" },
    ["Teaching & Academics"]:{ imageUrl: Images.TeachingAcademics, cardTitle:"Teaching & Academics" },
};

const MarketPlace = (props) =>{
    const [modalShow, setModalShow] = useState(false);
    const [modalData , setModalData] = useState(null);
    const [topic , setTopic] = useState(null);

    const handleCloseModal = () => setModalShow(false);
    const handleModalShow = (data, topic) => {setModalData(data);setTopic(topic);setModalShow(true)};

    const ViewCardDetailModal = () => {

        return (
            <Modal
                show={modalShow} onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='viewCardModal'
            >
                <Button onClick={handleCloseModal} className="modalCloseButton">
                    <img src={Images.FilledCloseIcon} style={{ width: 36, height: 36 }} />
                </Button>
                <Modal.Body className="p-0 row">
                  <div className="col-md-6 leftSection">
                    <img src={MockData[topic].imageUrl} className="mdlImgStyle" />
                    <h2 className="title">{topic}</h2>
                  </div>
                  <div className="col-md-6 rightSection">
                        <Accordion defaultActiveKey="0">
                            {
                                Object.entries(modalData).map(([key, value], i) => 
                                
                            <Accordion.Item eventKey={i}>
                                {
                                    console.log(value)
                                }
                                <Accordion.Header>{key}</Accordion.Header>
                                <Accordion.Body>
                                  <ul className="lists">
                                    {
                                        value?.Skills?.map((skill) => 
                                            <li onClick={()=>props.handleClick(`${skill} (${key})`)}>{skill}</li>
                                        )
                                    }
                                  </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                                )
                            }
                        </Accordion>
                  </div>
                </Modal.Body>
            </Modal>
        )
    }

    return(
        <section className="container marketPlaceSection" style={{marginTop:32}}>
         <ViewHeading title={'Market Place'} />

         <div className="row">
                {
                    Object.entries(marketData).map(([key, value], i) => (
                        <div className="col-md-3" key={'MarketPlace' + i}>
                            <MachineLearningCard
                                onCardClick={() => handleModalShow(value, key)}
                                centerTitle={key}
                                showButtons={false}
                                imageUrl={MockData[key].imageUrl}
                                imageStyles={{ paddingRight: 46, paddingLeft: 46, objectFit: 'contain', paddingTop: 21, paddingBottom: 21 }}
                                floatingMenu={false}
                            />
                        </div>
                    ))
                }
         </div>
         {modalShow && ViewCardDetailModal()}
        </section>
    )
}

export default MarketPlace;

const styles = {
    thumImg: { width: 150, height: 150, borderRadius: 16, objectFit: 'cover' },
    modalTitle: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 23,
        lineHeight: '28px',
        letterSpacing: '-0.02em',
        color: '#00295F',
        marginTop:10
    },
    modalCloseButton:{position:'absolute', right:-16, top:-58},
    goToCourseModalBtn:{ backgroundColor: '#004AAD',color:'white', fontFamily:'Poppins', fontSize:14, fontWeight:'700',padding:'10px 31px', },
    rightBtnImg:{ width:14, height:12, marginLeft: 8 },
    menuIconStyle:{ width: 16, height: 16, marginRight: 8 },
    hrLine:{ backgroundColor: '#E7EAEE', marginTop: 16, height: 2, border: 0, marginBottom: 0 },
    modalHeaderBg:{background: '#E5F1FF', padding:24, borderTopLeftRadius:7, borderTopRightRadius:7}
}