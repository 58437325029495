import { API_ENDPOINTS,METHOD_TYPE } from "../../../utilities/apiUrls";
import { api } from "../../../utilities/api";
import Images from "../../../constants/Images";


export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const DELETE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const GET_ORGANIZATION_DETAILS = "GET_ORGANIZATION_DETAILS";

export const getAllOrganizations = () => async (dispatch) => {
  let data = {
    method: METHOD_TYPE.get,
    url: `${API_ENDPOINTS.organizations}`,
  };
  return api(data)
    .then(async (response) => {
      if (response.status) {
        dispatch({
          type: GET_ORGANIZATIONS,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      throw error?.response?.data?.message;
    });
};
export const getContentDetailsById = (contentId) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.get,
        url: `${API_ENDPOINTS.organizations + contentId}/`,
    };
    return api(data)
        .then(async (response) => {
            if (response.status) {
                dispatch({
                    type: GET_ORGANIZATION_DETAILS,
                    payload: response.data,
                });
            }
        }).catch((error) => {
            throw error?.response?.data?.message;
        });
};
export const addContent = (content) => async () => {
    let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.organizations,
        data: content
    };
    return api(data).then(async (response) => {
            return response.data
        })
        .catch((error) => {
            throw error?.response?.data?.message;
        });
};

export const updateAdminContent = (content) => async () => {
    let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.admin_update_content,
        data: content
    };
    return api(data)
        .then(async (response) => {
            // toast.success(response?.data?.message);
            return response.data
        })
        .catch((error) => {
            // toast.error(error?.response?.data?.message);
            throw error?.response?.data?.message;
        });
};


export const deleteRecordById = (recordId) => async (dispatch) => {
    let data = {
        method: METHOD_TYPE.delete,
        url: `${API_ENDPOINTS.organizations}${recordId}/`,
    };
    return api(data)
        .then(async (response) => {
            if(response.status && response.data===""){
                return {status:true, message:"Data deleted successfully"}
            }
        }).catch((error) => {
           return  {status:false, message:"Getting error in delete record"}
        });
};




