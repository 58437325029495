import * as React from 'react';
import './index.scss'
import Images from '../../constants/Images';
import KeyFeatureCard from '../../components/home/KeyFeatureCard';

import MockData from './data'
import VideoPlayer from '../../components/VideoPlayer';
import Colors from '../../constants/Colors';
import UpskillrSlider from '../../components/home/UpskillrSlider';
import { Button } from '@mui/material';
import HomeTestimonial from '../../components/home/HomeTestimonial';
import { getKeyFeatures, getOrganizationsLogo, getBackedBy, getOverview, getMarket } from './action';
import { connect, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import HomeBackedBy from '../../components/home/HomeBackedBy';
import { useSearchParams } from 'react-router-dom';
import { googleLogging } from '../login/action';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';


const Home = (props) => {
    const dispatch = useDispatch();
    const [keyFeatures, setkeyFeatures] = useState([]);
    const [organizationsLogo, setOrganizationsLogo] = useState([]);
    const [backedBy, setBackedBy] = useState([]);
    const [overview, setOverview] = useState([]);
    const [market, setMarket] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        props.getKeyFeatures();
        props.getOrganizationsLogo();
        props.getBackedBy();
        props.getOverview();
        props.getMarket();
    }, []);

    useEffect(() => {
        let code = searchParams.get("code");
        if (code) {
            dispatch(googleLogging({ code: code })).then(user => {
                localStorage.setItem('user', user.access)
                localStorage.setItem('refresh', user.refresh);
                localStorage.setItem('isAdmin', false);
                if (user?.access) {
                    const decodedToken = jwt_decode(user.access);
                    if (decodedToken) {
                        const { user_id } = decodedToken;
                        localStorage.setItem('user_id', user_id);
                        toast.success('Login Successfull');
                        window.location.href = "/our-features";
                    } else {
                        toast.error('Invalid token');
                    }
                } else {
                    toast.error('No token found in response');
                }
            }).catch(err => {
                console.log("Err", err);
                toast.error("error in logging");
            })
        }
    }, []);

    useEffect(() => {
        if ((props.keyFeaturesData).length > 0) {
            setkeyFeatures(props.keyFeaturesData);
        }

        if ((props.organizationsLogoData).length > 0) {
            setOrganizationsLogo(props.organizationsLogoData);
        }

        if ((props.backedByData).length > 0) {
            setBackedBy(props.backedByData);
        }

        if ((props.overviewData).length > 0) {
            setOverview(props.overviewData);
        }

        if ((props.marketplaceData).length > 0) {
            setMarket(props.marketplaceData);
        }

    }, [props.keyFeaturesData, props.organizationsLogoData, props.backedByData, props.overviewData, props.marketplaceData]);

    //   console.log("backedBy",backedBy);
    const images = ['lavenderBlueRighBgCard', 'peachRightCard', 'peachLeftCard', 'lavenderBlueLeftBgCard']

    return (
        <div className="home">
            <div className='homePageBannerSection containerFluid'>
                <div className='bannerTitle'>Be Future Ready</div>
                <div className='bannerSubTitle'>Learning has no limits, nor should you.</div>
                <img src={require('../../assets/home-banner.png')} className='homeBanner' />
            </div>

            {
                keyFeatures.length > 0 && (
                    <div className='keyFeatureSection'>
                        <div className='featureSectionTitle'>Overview of our Key Features</div>
                        <div className='keyFeatureArea'>
                            {
                                keyFeatures.map((item, index) => {
                                    const imageIndex = index % 4;
                                    const cardtype = images[imageIndex]
                                    return (
                                        <KeyFeatureCard {...item} key={index}
                                            cardType={cardtype}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }

            {
                overview.length > 0 ? (
                    overview.map((item, index) => (
                        <div className={`cardStyle ${index % 2 === 0 ? 'yellowCardStyle' : 'blueCardStyle'}`} key={index}>
                            <h2 className='cardHeading'>{item.title}</h2>
                            <div className={`flexRow mt80 align-items-center justify-content-between ${index % 2 === 0 ? '' : 'columnReverse'}`}>
                                <div className={`textBoxArea ${index % 2 === 0 ? 'mr-3' : 'ml-3'}`}>
                                    <h3 className='cardHeading h2Heading'>{item.subtitle}</h3>
                                    <p className='txt_23_28' dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                </div>
                                <div className='videoPlayerDiv'>
                                    <VideoPlayer overview={item} />
                                </div>
                            </div>
                        </div>
                    ))
                ) : null
            }

            {/* <div className='cardStyle blueCardStyle'>
                <h2 className='cardHeading'>Overview of our Upskillr Assistant</h2>
                <div className='flexRow mt80 columnReverse'>
                    <div className='videoPlayerDiv'>
                        <VideoPlayer backgroundColor={Colors.lightCobaltBlue} />
                    </div>
                    <div className='textBoxArea'>
                        <h3 className='cardHeading h2Heading ml131'>Upskillr Assistant</h3>
                        <p className='txt_23_28 ml131'>Obtain immediate responses and suggestions from any document, webpage, or video, and avoid spending numerous hours reading, scrolling, searching, and waiting..</p>
                    </div>
                </div>
            </div>

            <div className='cardStyle yellowCardStyle' >
                <h2 className='cardHeading'>Overview of our Mid Search Writer</h2>
                <div className='flexRow mt80'>
                    <div className='textBoxArea'>
                        <h3 className='cardHeading h2Heading'>Multi Search Writer</h3>
                        <p className='txt_23_28'>Compose educational or professional articles that can determine, based on a user's inquiry, which data source to utilize in order to provide a response.</p>
                    </div>
                    <div className='videoPlayerDiv'>
                        <VideoPlayer />
                    </div>
                </div>
            </div>

            <div className='cardStyle blueCardStyle'>
                <h2 className='cardHeading'>Overview of CareerPal</h2>
                <div className='flexRow mt80 columnReverse'>
                    <div className='videoPlayerDiv'>
                        <VideoPlayer backgroundColor={Colors.lightCobaltBlue} />
                    </div>
                    <div className='textBoxArea'>
                        <h3 className='cardHeading h2Heading ml131'>CareerPal</h3>
                        <p className='txt_23_28 ml131'>Unlock your potential with an all-in-one AI-powered companion that provides personalized support for tutoring, skill building, case studies, interview prep, and beyond.</p>
                    </div>
                </div>
            </div> */}

            {
                market.length > 0 ? (
                    <div className='cardStyle pr-0 marketPlaceSection'>
                        <div className='flexRow alignItems marketPlaceArea'>
                            <div className='col-md-4 marketLeft'>
                                <h3 className='txt_39_47_bold'>Marketplace</h3>
                                <div className='txt_23_28'>One-stop destination to get skill mapping for critical and
                                    in-demand skills in trending job and industry domains.</div>
                            </div>
                            <div className='col-md-8 marketPlaceSlider'>
                                <UpskillrSlider>
                                    {
                                        market.map((item, index) => (
                                            <div className='cardContainer'>
                                                <img className='sliderCardImg' src={item.image.media} style={{}} />
                                                <h3 className='cardTitleStyle'>{item.name}</h3>
                                            </div>

                                        ))
                                    }

                                </UpskillrSlider>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            <div className='smartLibrarySection flexRow'>
                <div className='col-md-6 pr72'>
                    <h3 className='txt_39_47_bold'>Gather resources for your own smart library</h3>
                    <div className='txt_23_28'>Make topic-specific playlists and share them with your community.</div>
                </div>
                <div className='col-md-6'>
                    <div className='sectionImg'>
                        <img src={Images.SmartLibrary} style={{ width: '100%', height: '100%' }} />
                    </div>
                </div>
            </div>

            {
                organizationsLogo.length > 0 ? (
                    <section>
                        <div className='sectionTitle'>We Are Backed By</div>
                        <div className='organizations'>
                            {/* {
                        organizationsLogo.map((item, i)=>{
                            return <div key={'logo'+i} className='logoArea'>
                                {
                                    item.data.map((logo, indx)=>{
                                        return(
                                            <img src={logo} key={'l'+indx}/>
                                            )
                                        })
                                }
                            </div>
                        })
                    } */}

                            <div className='logoArea'>
                                {organizationsLogo.slice(0, 4).map((item, index) => (

                                    <img key={index} src={item.image.media} />
                                ))}
                            </div>
                            <div className='logoArea'>
                                {organizationsLogo.slice(4, 9).map((item, index) => (

                                    <img key={index} src={item.image.media} />
                                ))}
                            </div>
                            <div className='logoArea'>
                                {organizationsLogo.slice(9).map((item, index) => (

                                    <img key={index} src={item.image.media} />
                                ))}
                            </div>

                        </div>

                    </section>
                ) : null
            }

            <section style={{ backgroundColor: Colors.background_light_blue }} id='HomeChatGpt' className='flexRow'>
                {/* <div className='col-lg-6 col-sm-12' style={{alignItems:'center', display:'flex'}}>
                    <div className='pr72'>
                        <h3 className='txt_39_47_bold'>Use our ChatGPT Curriculum Builder Plugin to level-up your experience</h3>
                        <Button variant="contained" style={{backgroundColor:'#004AAD'}}>1.5K+ Downloads</Button>
                    </div>
                </div>
                <div className='col-lg-6 col-sm-12'>
                    <div style={{backgroundColor:'#DDECFF', padding:16, borderRadius:25}}>
                        <VideoPlayer backgroundColor={'#D0E4FF'} />
                        <iframe src="https://drive.google.com/file/d/187zHW-fEq4BBTYXkLkrfvhks0mWl6iyp/preview" width="320" height="330" allow="autoplay"></iframe>
                    </div>
                </div> */}
                <div className='row'>
                    <div className='col-lg-6 col-sm-12'>
                        <h3 className='cardHeading h2Heading'>Use our ChatGPT Curriculum Builder Plugin to level-up your experience</h3>
                        <Button variant="contained" style={{backgroundColor:'#004AAD'}}>1.5K+ Downloads</Button>
                    </div>
                    <div className='col-lg-6 col-sm-12 d-flex justify-content-center'>
                        <div className='videoPlayerDiv' style={{backgroundColor:'#DDECFF', padding:16, borderRadius:25}}>
                            {/* <VideoPlayer /> */}
                            <iframe src="https://drive.google.com/file/d/187zHW-fEq4BBTYXkLkrfvhks0mWl6iyp/preview" width="340" height="180" allow="autoplay" frameborder="0" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true"></iframe>
                        </div>
                    </div>
                </div>
            </section>

            {
                backedBy.length > 0 ? (
                    <div className='cardStyle'>
                        <h2 className='cardHeading'>We Are Backed By</h2>
                        {/* <div className='spaceBetween'> */}
                        <div className=''>
                            {/* {
                        backedBy.map((item,key)=>(
                            <div className='logoSize'>
                                <img src={item.image.media} style={{width:'100%', height:'auto'}} />
                            </div>
                        ))
                    } */}
                            <HomeBackedBy sliderData={backedBy} />
                            {/* <div className='logoSize'>
                        <img src={Images.MapMyIndia} style={{width:'100%', height:'auto'}} />
                    </div>
                    <div className='logoSize'>
                        <img src={Images.BIRAC_1_Transformed} style={{width:'100%', height:'auto'}}/>
                    </div> */}
                        </div>
                    </div>
                ) : null
            }

            <div id="HomeTestimonial" className='homeTestimonialArea'>
                <h2 className='cardHeading'>Why Choose Upskillr?</h2>
                <div className='slide-container'>
                    <HomeTestimonial sliderData={MockData.WhyChooseUpskillr} />
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = ({ homePageReducer }) => {
    const { keyFeaturesData, organizationsLogoData, backedByData, overviewData, marketplaceData } = homePageReducer;
    return {
        keyFeaturesData, organizationsLogoData, backedByData, overviewData, marketplaceData
    };
}

export default connect(mapStateToProps, {
    getKeyFeatures, getOrganizationsLogo, getBackedBy, getOverview, getMarket
})(Home);

// export default Home;