export const CLICK_OPEN_MENU = 'CLICK_OPEN_MENU';
export const USER_LOGIN = "USER_LOGIN";
export const ISADMIN = "ISADMIN";

export const clickMenuOpen = value => ({
    type: CLICK_OPEN_MENU,
    newValue: value
});

export const logout = () => async(dispatch) => {
    dispatch({
        type: USER_LOGIN,
        payload: null,
    });
    dispatch({
        type: ISADMIN,
        payload: null
    })
}
