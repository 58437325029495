import { SIGNUPLOADER } from "./action";


const initialState = {
    signupLoader: false
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      
      case SIGNUPLOADER: {
        return {
          ...state,
          signupLoader: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default reducer;
  